import cordova from '../api/cordovaAPI';


const getLargeImageUrl = (uri) => {
    if(!uri || uri.indexOf('http') == 0) {
        return uri ||  "../../../no-clinic-img.svg"
    }
    let parts = uri.split('public://');
    

    if (parts.length==2) {
        return 'https://www.colfisiocv.com/sites/default/files/styles/large_retina/public/' + parts[1];
    } 

    return '';
}

const getLargeImageUrlAdvertisments = (uri) => {
    if(!uri || uri.indexOf('http') == 0) {
        return uri
    }
    let parts = uri.split('public://');
    

    if (parts.length==2) {
        return 'https://www.colfisiocv.com/sites/default/files/styles/large_retina/public/' + parts[1];
    } 

    return '';
}


const downloadFile = (uri) => {
    if (cordova.isApp()) {
        cordova.openExternalUrl(uri);
    }
    else
    {
        window.open(uri,'_blank')
    }
}

const openExternalUrl = (uri) => {
    if (cordova.isApp()) {
        cordova.openExternalUrl(uri);
    }
    else
    {
        window.open(uri,'_blank')
    }
}

export default {
    getLargeImageUrl,
    downloadFile,
    openExternalUrl,
    getLargeImageUrlAdvertisments
  }