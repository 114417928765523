const convertToBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(file.name, reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  }

  export default {
    convertToBase64
  }