import { SET_STRIPE_PAYMENT_EVENT, CLEAR_STRIPE_PAYMENT_EVENT } from '../constants/actionTypes'

const defaultState = {
	type: null,
	sessionId: null
}

const stripePaymentEvent = (state = defaultState, action) => {
    switch (action.type) {
			case SET_STRIPE_PAYMENT_EVENT:
				return Object.assign({}, action.stripePaymentEvent)

			case CLEAR_STRIPE_PAYMENT_EVENT:
				return defaultState
  
      default:
        return state
    }
}
  
export default stripePaymentEvent;

