import React from 'react'


interface Props {
  showModal: boolean,
  closeModal: () => void,
  logoutSession: () => void
}

export const ModalLogout: React.FC<Props> = ({ children, showModal, closeModal, logoutSession }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-60 md:top-0 inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-2 w-full max-w-md h-full md:h-auto">
              {/*content*/}
              <div className="relative bg-white rounded-lg shadow">
                {/*header*/}
                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                  <h3 className="text-lg font-medium">Cerrar sesión</h3>
                  <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModal}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                  <p className="my-2 text-sm font-medium text-neutral-500">¿Quieres salir de la aplicación?</p>
                  <p className="my-2 text-sm text-neutral-500">Si deseas salir de la aplicación haz clic en Cerrar sesión o en Cancelar para continuar en la aplicación.</p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                  <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModal}>Cancelar</button>
                  <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" onClick={logoutSession}>Cerrar sesión</button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}