import * as yup from "yup";

const isRequiredMessage = "Completa este campo";

export default yup.object().shape({
    firstname: yup.string().required(isRequiredMessage)
    .trim()
    .max(20)
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras"),
    lastname: yup.string().required(isRequiredMessage)
    .trim()
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(30),
    phone: yup.string().required(isRequiredMessage)
    .trim()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    email: yup.string().trim().required(isRequiredMessage).email(),
    numCollegiate: yup.string().required(isRequiredMessage),
    dni: yup.string()
    .required(isRequiredMessage)
    .matches(/[a-zA-Z][0-9]{7}[a-zA-Z]{1}|[0-9]{8}[a-zA-Z]{1}$/g, "Formato incorrecto")
    .min(9, 'Introduzca exactamente 9 caracteres')
    .max(9, 'Introduzca exactamente 9 caracteres')
});


