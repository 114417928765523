import React from 'react'
import { useState, useEffect } from 'react'
import { getDocument } from '../../api/drupalAPI'
import { Document } from '@icofcv/common';
import CheckBox from './CheckBox'
import contentUtils from '../../lib/contentUtils'

export function InformedConsents() {

    const [documents, setDocuments] = useState<Document[]>([]);
    const [inputValue1, setInputValue1] = useState("")
    const [inputValue2, setInputValue2] = useState("")
    const [inputValue3, setInputValue3] = useState("")
    const [inputValue4, setInputValue4] = useState("")
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(isChecked)
        setIsChecked(e.target.checked);
    };

    const fetchDocuments = async () => {
        getDocument().then((response) => {
            console.log(response)
            setDocuments(response);

        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const handleInputChange1 = (e) => {
        e.preventDefault()
        inputValue1 === "Seleccione consentimiento" ? null
            :
            contentUtils.downloadFile(inputValue1)
    }

    const handleInputChange2 = (e) => {
        e.preventDefault()
        inputValue2 === "Seleccione consentimiento" ? null
            :
            contentUtils.downloadFile(inputValue2)
    }

    const handleInputChange3 = (e) => {
        e.preventDefault()
        inputValue3 === "Select consent" ? null
            :
            contentUtils.downloadFile(inputValue3)
    }

    const handleInputChange4 = (e) => {
        e.preventDefault()
        inputValue4 === "Choisir le consentement" ? null
            :
            contentUtils.downloadFile(inputValue4)
    }

    useEffect(() => {

        fetchDocuments();
    }, []);

    return (
        <>
            <div className="card bg-white px-2 py-3 md:p-6">
                <h1 className="pb-3 font-medium">Consentimientos informados</h1>
                <div className="card-body py-2 md:px-12 mx-auto">
                    <p className="text-neutral-600 text-sm">Todos los consentimientos informados deberán ser adaptados por el profesional a las peculiaridades de cada paciente. No es correcto entregar al paciente un consentimiento informado estándar para que lo firme sin más.</p>
                    <div className="flex items-center my-4">
                        <CheckBox handleChange={handleChange} isChecked={isChecked} />
                        <label htmlFor="CheckBox" className="ml-2 text-xs text-neutral-600">He leído y entiendo el texto arriba indicado (hacer click para descargar consentimientos)</label>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Consentimiento en Castellano*</label>
                            {isChecked === false ?
                                <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" disabled />
                                :
                                <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" >
                                    <option selected>Seleccione consentimiento</option>
                                    {documents.slice(30, 36).map(docu =>
                                        <option key={docu.id} value={docu.contentUrl}>{docu.name}</option>
                                    )}
                                </select>
                            }
                        </div>
                        <button disabled={!inputValue1 || isChecked === false} onClick={handleInputChange1} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 md:ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Descargar</button>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Consentiments en Valencià*</label>
                            {isChecked === false ?
                                <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" disabled />
                                :
                                <select onChange={(e) => setInputValue2(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option selected>Seleccione consentimiento</option>
                                    {documents.slice(36, 42).map(docu =>
                                        <option value={docu.contentUrl}>{docu.name}</option>
                                    )}
                                </select>
                            }
                        </div>
                        <button disabled={!inputValue2 || isChecked === false} onClick={handleInputChange2} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 md:ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Descàrrega</button>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Consents in English* </label>
                            {isChecked === false ?
                                <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" disabled />
                                :
                                <select onChange={(e) => setInputValue3(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option selected>Select consent</option>
                                    {documents.slice(42, 48).map(docu =>
                                        <option value={docu.contentUrl}>{docu.name}</option>
                                    )}
                                </select>
                            }
                        </div>
                        <button disabled={!inputValue3 || isChecked === false} onClick={handleInputChange3} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 md:ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Download</button>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Consentement en Français*</label>
                            {isChecked === false ?
                                <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" disabled />
                                :
                                <select onChange={(e) => setInputValue4(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option selected>Choisir le consentement</option>
                                    {documents.slice(48, 54).map(docu =>
                                        <option value={docu.contentUrl}>{docu.name}</option>
                                    )}
                                </select>
                            }
                        </div>
                        <button disabled={!inputValue4 || isChecked === false} onClick={handleInputChange4} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 md:ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Télécharger</button>
                    </div>
                </div>
            </div>
        </>
    )
}