import React, { useState } from 'react'
import { ModalRegister } from './ModalRegister'
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index';
import { Link } from "react-router-dom";
import contentUtils from '../../lib/contentUtils';
import ScrollLock from 'react-scrolllock'

interface Props {
  showModal: boolean,
  closeModal: () => void
}

export const ModalNoLogin: React.FC<Props> = ({ children, showModal, closeModal }) => {

  const session = useSelector((state: RootState) => state.session);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const openModalRegister = () => setShowModalRegister(true);
  const closeModalRegister = () => setShowModalRegister(false);
  const urlGuiaUsuario = "https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/icofcv-files/GUIA_USO_APP_ICOFCV.pdf";
  const openLinkGuiaUsuario = () => contentUtils.openExternalUrl(urlGuiaUsuario);

  return (
    <>
      {!session.isLoggedIn ?
        showModalRegister ? showModalRegister :

          showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-20">
                <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                  {/*content*/}
                  <div className="relative bg-white rounded-lg shadow">
                    {/*header*/}
                    <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                      <h3 className="text-lg font-medium">Contenido bloqueado</h3>
                      <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModal}>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                        </svg>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                      <div className="w-16 h-16 rounded-full border-2 border-teal-600 flex items-center justify-center mx-auto my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock h-8 w-8 stroke-teal-600" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="5" y="11" width="14" height="10" rx="2" />
                          <circle cx="12" cy="16" r="1" />
                          <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                        </svg>
                      </div>
                      <p className="my-3 text-sm text-neutral-500">Para acceder a este contenido es necesario que inicies sesión en la aplicación, con tu usuario y contraseña.</p>
                      <p className="my-3 text-sm text-neutral-500">
                        Si aún no estás registrado pulsa en el siguiente enlace, <button onClick={openModalRegister} className="text-teal-600 underline">Solicitar acceso</button>.
                      </p>
                      <p className="my-3 text-sm text-neutral-500">Si tienes dudas puedes consultar la guia de usuario de la APP <a className="text-teal-600 underline" onClick={openLinkGuiaUsuario}>aquí</a>.</p>
                    </div>
                    {/*footer*/}
                    <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                      <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModal}>Cancelar</button>
                      <Link to="/loginview" className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded whitespace-nowrap" onClick={closeModal}  >Iniciar sesión</Link>
                      {/* <button onClick={cognitoUtils.openCognitoSigninUrl} className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" >Iniciar sesión</button> */}
                    </div>
                  </div>
                </div>
                <ScrollLock />
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        : null
      }
      <ModalRegister showModalRegister={showModalRegister} closeModalRegister={closeModalRegister} />
    </>
  )
}