import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'
import appConfig from '../config/app-config.json'
import cordova from '../api/cordovaAPI';
import { getSessionUser} from '../api/icofcvAPI';

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: cordova.isApp() ? appConfig.appCallbackUri : appConfig.webCallbackUri,
    RedirectUriSignOut: cordova.isApp() ? appConfig.appSignoutUri : appConfig.webSignoutUri
  })
  return auth
}


// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: appConfig.userPool,
  ClientId: appConfig.clientId
})

// Get the URI of the hosted sign in screen
const getWebCognitoSignInUri = () => {
  const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.webCallbackUri}`
  return signinUri
}

const getAppCognitoSignInUri = () => {
    const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.appCallbackUri}`
    return signinUri
  }

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      }
    }
    auth.parseCognitoWebResponse(href)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()
    if (!cognitoUser) {
        reject("Cannot create cognito user");
    }
    cognitoUser?.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))

      const userName = result.idToken.payload['cognito:username'];
      const idToken = result.idToken.jwtToken;
      getSessionUser(idToken).then((userInfo) => {

        const session = {
            credentials: {
              accessToken: result.accessToken.jwtToken,
              idToken: result.idToken.jwtToken,
              refreshToken: result.refreshToken.token,
              expires: result.idToken.payload.exp
            },
            user: {
              userName: userName,
              email: result.idToken.payload.email
            },
            collegiate: userInfo
          }
 
        localStorage.setItem('cognito-auth', JSON.stringify(session));
        cordova.setLocalStorageItem('cognito-auth', JSON.stringify(session));
        resolve(session)
        
      }).catch(() => {
        console.log('Cannot find collegiate: ' + userName)
        resolve({
            isLoggedIn: false
        });
      })
      
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {

  localStorage.setItem('cognito-auth', '');
  cordova.removeLocalStorageItem('cognito-auth');

  // Desactivamos al no utilizar cognito hosted ui
//   const auth = createCognitoAuth();
//   auth.signOut();
}


const getStoredCognitoSession = async () => {
    let sSession = localStorage.getItem('cognito-auth');

    if (!sSession) {
        sSession = '{}';
    } 
    
    let storedSession = JSON.parse(sSession);
    if (!storedSession || !storedSession.credentials || !storedSession.credentials.expires || storedSession.credentials.expires > (new Date().getTime() + 60 * 60 * 5))
    {
        localStorage.setItem('cognito-auth', '');
        cordova.removeLocalStorageItem('cognito-auth');
        return {
            isLoggedIn: false
        };
    };

    const idToken = storedSession.credentials.idToken;

    try {
        const userInfo = await getSessionUser(idToken);
        storedSession.collegiate = userInfo;
        storedSession.isLoggedIn = true;
    } catch (e) {
        storedSession = {
            isLoggedIn: false
        };
    }
    
    localStorage.setItem('cognito-auth', JSON.stringify(storedSession));
    cordova.setLocalStorageItem('cognito-auth', JSON.stringify(storedSession));
    return storedSession;
}

const mapLoginResult = (result) => {
    let data = JSON.parse(result);
    return {
        credentials: {
            expires: data.ExpiresIn,
            accessToken: data.AccessToken,
            idToken: data.IdToken,
            refreshToken: data.RefreshToken,
            tokenType: data.TokenType
        }
    }
}

const openCognitoSigninUrl= () => {
    if (cordova.isApp()) {
        cordova.openUrl(getAppCognitoSignInUri());
    }
    else
    {
        window.open(getWebCognitoSignInUri(),'_self')
    }
}


export default {
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  getCognitoSession,
  openCognitoSigninUrl,
  parseCognitoWebResponse,
  signOutCognitoSession,
  getStoredCognitoSession,
  mapLoginResult,
}