import { useState } from 'react';
import { ModalNoLogin } from '../miProfile/ModalNoLogin';

export const ButtonAccessUsser = () => {

    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <>
            <button className="bg-teal-700 hover:bg-teal-800 text-white flex items-center justify-between rounded-lg h-14 p-4 w-100 mt-3"
                onClick={openModal}  >
                <span className="font-medium">Acceso usuario</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                </svg>
            </button>
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
        </>
    )
}