import { SELECT_CLINICS } from '../constants/actionTypes'

const selectedClinics = (state = null, action) => {
  switch (action.type) {
    case SELECT_CLINICS:
      return Object.assign({},
        action.selectedClinics)

    default:
      return state
  }
}

export default selectedClinics;