import { useState, useEffect } from 'react';
import contentUtils from '../../lib/contentUtils'
import './EmploymentDetail.css'
import { Link } from "react-router-dom";
import { getPublicEmploymentById } from '../../api/drupalAPI'
import dateUtils from '../../lib/dateUtils'

export const PublicEmploymentDetail = () => {

    const [currentPublicEmployment, setCurrentPublicEmployment] = useState<any>(null);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        getPublicEmploymentById(id)
            .then((response) => {
                console.log(response);
                setCurrentPublicEmployment(response)
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/college/employmentlist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6 w-1/3 ml-0" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title">{currentPublicEmployment?.title}</span>
                </Link>
            </div>
            <div key={currentPublicEmployment?.id} className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college/employmentlist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">{currentPublicEmployment?.title}</h1>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Fecha publicación:</span>
                                <span className="text-teal-600 text-sm mb-4 mx-2">{dateUtils.utcToDate(currentPublicEmployment?.publishStartDate)}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Fecha Fin:</span>
                                <span className="text-teal-600 text-sm mb-4 mx-2">{dateUtils.utcToDate(currentPublicEmployment?.publishEndDate)}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Oferta:</span>
                                <span className="text-sm text-neutral-600 font-normal mx-2">{currentPublicEmployment?.title}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Descripción:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description" dangerouslySetInnerHTML={{ __html: currentPublicEmployment?.description }}></div>
                            </div>

                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Año:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentPublicEmployment?.year}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Órgano convocante:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentPublicEmployment?.conveningBody}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Link:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description"><a target="_blank" onClick={() => contentUtils.openExternalUrl(currentPublicEmployment?.link)}>{currentPublicEmployment?.link}</a></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}  