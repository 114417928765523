import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import QRCode from "react-qr-code";

export function ProfileImage() {

    const session = useSelector((state: RootState) => state.session);
    const qrCodeDetails = {
        name: session.collegiate.firstname,
        lastname: session.collegiate.lastname,
        collegiateNumber: session.collegiate.memberId,
        idNumber: session.collegiate.fiscalId,
        email: session.collegiate.email
    };

    const codeDetails = JSON.stringify(qrCodeDetails);

    return (
        <>
            <div className="card bg-white px-2 md:p-6 my-3">
                <div className="card-body py-1 px-4">
                    <div className="flex flex-col items-center justify-around my-4">
                        <div>
                            <QRCode value={codeDetails} className="h-auto mb-2 mx-auto max-w-full" />
                            <p className="text-neutral-800 text-sm font-medium">Código QR Identificador</p><br></br>
                            <p className="text-neutral-800">
                                <p className="text-teal-600 text-md font-medium">{session.collegiate.firstname} {session.collegiate.lastname}</p>
                                <>
                                    <span className="font-medium text-sm">Nº colegiado: </span>{session.collegiate.memberId}<br></br>
                                    <span className="font-medium text-sm">Colegiado desde: </span>{format(new Date(session.collegiate.creationDate), 'dd/MM/yyyy', { locale: es })}
                                </>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}