export const CLEAR_SESSION = 'CLEAR_SESSION'
export const SET_SESSION = 'SET_SESSION'

export const SELECT_NEWS = 'SELECT_NEWS'
export const SELECT_YOUTUBE = 'SELECT_YOUTUBE'
export const SELECT_GUIDES = 'SELECT_GUIDES'
export const SELECT_CLINICS = 'SELECT_CLINICS'
export const SELECT_CLINICS_INFO = 'SELECT_CLINICS_INFO'
export const SELECT_ADVERTISMENTS = 'SELECT_ADVERTISMENTS'
export const SELECT_EMPLOYMENT = 'SELECT_EMPLOYMENT'
export const SELECT_PROVINCE = 'SELECT_PROVINCE'

export const UPDATE_CONFIG_NAVIGATION = 'UPDATE_CONFIG_NAVIGATION'

export const UPDATE_DEVICE_INFO = 'UPDATE_DEVICE_INFO'

export const ON_NATIVE_EVENT = 'ON_NATIVE_EVENT'

export const SET_STRIPE_PAYMENT_EVENT = 'SET_STRIPE_PAYMENT_EVENT'
export const CLEAR_STRIPE_PAYMENT_EVENT = 'CLEAR_STRIPE_PAYMENT_EVENT'