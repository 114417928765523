import {Course} from '@icofcv/common';
import {Where} from '@loopback/repository';

export function createCoursesWhereFilter(courseFilter:any) {
    const searchParam = courseFilter.search ? `%${courseFilter.search}%` : '';
    const searchEmailParam = courseFilter.searchEmail ? `%${courseFilter.searchEmail}%` : '';
    const searchCreationDateParam = courseFilter.searchCreationDate ? `${courseFilter.searchCreationDate}` : '';
    const searchCourseDate = courseFilter.searchCourseDate ? `%${courseFilter.searchCourseDate}%` : '';
    const searchOrganizer = courseFilter.searchOrganizer ? `%${courseFilter.searchOrganizer}%` : '';
    const searchType = courseFilter.searchType;
    const searchModality = courseFilter.searchModality;
    const searchStatusParam = courseFilter.searchStatus;

    const searchParamsOr:Where<Course>[] = [];
    if (searchParam && searchParam.trim().length > 0) {
        console.log(`*** searchParamsOr - title: '${searchParam}'`);
        searchParamsOr.push({ title: { like: searchParam, options: 'i' } });
    }

    const searchParamsAnd:Where<Course>[] = [];
    if (searchStatusParam && searchStatusParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - status: '${searchStatusParam}'`);
        searchParamsAnd.push({ status: { eq: searchStatusParam } });
    }
    if (searchEmailParam && searchEmailParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - creatorUsername: '${searchEmailParam}'`);
        searchParamsAnd.push({ creatorUsername: { like: searchEmailParam, options: 'i' } });
    }

    if (searchCreationDateParam && searchCreationDateParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - creationDate: '${searchCreationDateParam}'`);
        searchParamsAnd.push({ creationDate: { between: [ searchCreationDateParam + 'T00:00:00Z', searchCreationDateParam + 'T23:59:59Z' ] } });
    }

    if (searchCourseDate && searchCourseDate.trim().length > 0) {
        console.log(`*** searchParamsAnd - durationDates: '${searchCourseDate}'`);
        searchParamsAnd.push({ durationDates:  { like: searchCourseDate, options: 'i' } });
    }

    if (searchOrganizer && searchOrganizer.trim().length > 0) {
        console.log(`*** searchParamsAnd - organizer: '${searchOrganizer}'`);
        searchParamsAnd.push({ organizer: { like: searchOrganizer, options: 'i'  } });
    }

    if (searchType && searchType.trim().length > 0) {
        console.log(`*** searchParamsAnd - typeId: '${searchType}'`);
        searchParamsAnd.push({ typeId: { eq: searchType } });
    }

    if (searchModality && searchModality.trim().length > 0) {
        console.log(`*** searchParamsAnd - modality: '${searchModality}'`);
        searchParamsAnd.push({ modality: { eq: searchModality } });
    }

    let where = {};
    if (searchParamsOr.length > 0 || searchParamsAnd.length > 0) {
        if (searchParamsOr.length > 0 && searchParamsAnd.length > 0) {
            where = { 
                and: [
                    { or: searchParamsOr },
                    { searchParamsAnd }
                ]
            };
        } 
        if (searchParamsOr.length > 0 && searchParamsAnd.length == 0) {
            where = { 
                or: searchParamsOr
            };
        }
        if (searchParamsOr.length == 0 && searchParamsAnd.length > 0) {
            where = { 
                and: searchParamsAnd
            };
        }
    }

    return where;
}