import { useEffect, useState } from 'react';
import appConfig from '../../config/app-config.json';
import {v4 as uuidv4} from 'uuid';

export const PaymentRefused = () => {

	useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search);
        const courseId = queryParameters.get("course_id");

		window.close();

		if (window.opener == null) {
			window.location.replace(`${appConfig.webSignoutUri}/college/formationlist/formationdetail?id=${courseId}&payment_result=cancelled&uuid=${uuidv4()}`);
		} else {
			window.opener.postMessage('STRIPE_COURSE_PAYMENT_CANCELED', '*');
		}		
	},[]);

	return (
		<>
		</>
	)
}