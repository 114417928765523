import React from 'react'

interface Props {
  showModalStripeOldAppVersion: boolean,
  closeModalStripeOldAppVersion: () => void,
}

export const ModalStripeOldAppVersion: React.FC<Props> = ({children, showModalStripeOldAppVersion, closeModalStripeOldAppVersion }) => {

  return (
    <>
      {showModalStripeOldAppVersion ? (
        <>
          <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-0">
              <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                  {/*content*/}
                  <div className="relative bg-white rounded-lg shadow pt-3 top-36">
                    {/*header*/}
                    <div className="flex justify-center items-start pt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="teal" className="w-20 h-auto">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>
                    </div>
                    {/*body*/}
                    <div className="relative px-3 pb-3 flex-auto overflow-auto modal-body">
                        <p className="text-sm text-neutral-500 text-center my-3">Para utilizar la pasarela de pago, es necesario que actualices a la última versión de la App.</p>
                        {/*<p className="text-sm text-neutral-500 text-center my-3">Se ha producido un error al vincular tu acceso biométrico a la aplicación ICOFCV. Por favor, inténtelo de nuevo</p>*/}
                        <button type="button" className="flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={closeModalStripeOldAppVersion}>Volver</button>
                    </div>
                    {/*footer*/}                
                  </div>
              </div> 
          </div>
          
          <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
        </>
      ) : null}
    </>
  )
}