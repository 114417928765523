import { SELECT_PROVINCE } from '../constants/actionTypes'

const selectedProvinces = (state = null, action) => {
  switch (action.type) {
    case SELECT_PROVINCE:
      return Object.assign({},
        action.selectedProvinces)

    default:
      return state
  }
}

export default selectedProvinces;

