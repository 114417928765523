import { UPDATE_CONFIG_NAVIGATION } from '../constants/actionTypes'

export function setTopLevelNavigation(moduleId) {
    return { type: UPDATE_CONFIG_NAVIGATION, 
                configNavigation: {
                    moduleId: moduleId,
                    showMainHeader: true,
                    showDetailHeader: false,
                    detailTitle: null 
                } 
            }
  }

  export function setDetailNavigation(moduleId, detailTitle) {
    return { type: UPDATE_CONFIG_NAVIGATION, 
                configNavigation: {
                    moduleId: moduleId,
                    showMainHeader: false,
                    showDetailHeader: true,
                    detailTitle: detailTitle 
                } 
            }
  }