import { useState, useEffect } from 'react';
import contentUtils from '../../lib/contentUtils'
import './AdvertismentsDetail.css'
import { Link } from "react-router-dom";
import { getAdvertismentById } from '../../api/drupalAPI'
import dateUtils from '../../lib/dateUtils'

export const AdvertismentsDetail = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [currentAdvertisment, setCurrentAdvertisment] = useState<any>(null);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")

        setIsLoading(true);
        getAdvertismentById(id)
            .then((response) => {
                console.log(response);
                setCurrentAdvertisment(response)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });


    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/college/advertismentslist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">{currentAdvertisment?.title}</span>
                </Link>
            </div>
            <div key={currentAdvertisment?.id} className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college/advertismentslist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">{currentAdvertisment?.title}</h1>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Fecha de creación:</span>
                                <span className="text-teal-600 text-sm mb-4 mx-2">{dateUtils.utcToDate(currentAdvertisment?.creationDate)}</span>
                            </div>

                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Título:</span>
                                <span className="text-sm text-neutral-600 font-normal mx-2">{currentAdvertisment?.title}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Descripción:</span>
                                <div className="text-neutral-600 font-sans news-text-description" ><p className="parrafo">{currentAdvertisment?.description}</p></div>
                            </div>
                            {currentAdvertisment?.imagePath ?
                                <div className="mb-2">
                                    <span className="text-neutral-800 text-sm font-medium">Imagen:</span>
                                    <img className="rounded-lg my-2 w-1/2 md:w-1/3 h-32 md:h-96 object-cover object-center" src={contentUtils.getLargeImageUrlAdvertisments(currentAdvertisment?.imagePath)} alt="Sin imagen" />

                                </div>
                                : <div></div>
                            }
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Categoría:</span>
                                {currentAdvertisment?.categoryId === 0 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Alquiler</div>
                                    : null
                                }
                                {currentAdvertisment?.categoryId === 1 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Compra</div>
                                    : null}
                                {currentAdvertisment?.categoryId === 2 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Traspaso</div>
                                    : null
                                }
                                {currentAdvertisment?.categoryId === 3 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Venta</div>
                                    : null
                                }
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Provincia:</span>
                                {currentAdvertisment?.province === "V" ?
                                    <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                    : null}
                                {currentAdvertisment?.province === "A" ?
                                    <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                    : null}
                                {currentAdvertisment?.province === "C" ?
                                    <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                    : null}
                            </div>
                            <h2 className="text-neutral-800 font-medium my-3  border-neutral-300">Datos de contacto</h2>
                            <div className="text-neutral-600 font-normal text-sm news-text-description">Nombre: {currentAdvertisment?.contactFirstname}</div>
                            <div className="text-neutral-600 font-normal text-sm news-text-description">Apellido: {currentAdvertisment?.contactLastname}</div>
                            <div className="text-neutral-600 font-normal text-sm news-text-description">Teléfono: {currentAdvertisment?.contactPhone}</div>
                            <div className="text-neutral-600 font-normal text-sm news-text-description">Email: <a>{currentAdvertisment?.contactEmail} </a></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}  