import { ON_NATIVE_EVENT } from '../constants/actionTypes'

const defaultState = {
    type: null,
    payload: null
}

  const nativeEvent = (state = defaultState, action) => {
    switch (action.type) {
      case ON_NATIVE_EVENT:
        return Object.assign({}, action.nativeEvent)
  
      default:
        return state
    }
  }
  
  export default nativeEvent;

