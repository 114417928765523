import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from '../../validation-form-advertisments/validationSchema';
import React, { useState } from 'react'
import { PictureInput } from './PictureInput'
import base64Utils from '../../lib/base64Utils';
import dateUtils from '../../lib/dateUtils';
import { advertismentPush } from '../../api/icofcvAPI';
import { toast } from 'react-toastify';
import { PopUpMessage } from "./PopUpMessage";

interface Props {
    showModal: boolean,
    closeModal: () => void,
    updateTable: () => void,

}

interface FormValues {
    title: string,
    description: string,
    imagePath: string,
    imageName: string,
    contactFirstname: string,
    contactLastname: string,
    contactPhone: string,
    contactEmail: string,
    categoryId: string,
    publishStartDate: string,
    publishEndDate: string,
    province: string,
    imageUpload: null,
}

export const ModalAdvertisments: React.FC<Props> = ({ showModal, closeModal, updateTable }) => {

    const [showModalMessage, setShowModalMessage] = useState(false);
    const openModalMessage = () => setShowModalMessage(true)
    const closeModalMessage = () => setShowModalMessage(false)
    const [isLoading, setIsLoading] = useState(false)

    const SubDate = (subDay) => {
        let tgDate = new Date();
        tgDate.setMonth(tgDate.getMonth() + subDay)
        return tgDate.toISOString().split('T')[0];
    }

    const initialValues: FormValues = {
        title: "",
        description: "",
        imagePath: "",
        imageName: "",
        contactFirstname: "",
        contactLastname: "",
        contactPhone: "",
        contactEmail: "",
        categoryId: "",
        publishStartDate: "",
        publishEndDate: "",
        province: "",
        imageUpload: null,
    };

    function convertImageToBase64(files, values, key, cb) {
        if (!files || files.length == 0) {
            cb();
            return;
        }
        base64Utils.convertToBase64(files[0], (filename, path) => {
            values[key + 'Name'] = filename;
            values[key + 'Path'] = path;
            cb();
        });
    }

    const onSubmit = (values: FormValues) => {
        setIsLoading(true)
        console.log('valores', values);
        convertImageToBase64(values.imageUpload, values, 'image', () => {
            convertImageToBase64(values.imageUpload, values, 'image', () => continueSubmit(values))
        });

    }

    const continueSubmit = (values: FormValues) => {
        setIsLoading(true)
        setTimeout(() => {
            console.log(values);
            const data = {
                creationDate: new Date(),
                title: values.title,
                description: values.description,
                imagePath: values.imagePath,
                imageName: values.imageName ? values.imageName : 'thumb.png',
                contactFirstname: values.contactFirstname,
                contactLastname: values.contactLastname,
                contactPhone: values.contactPhone,
                contactEmail: values.contactEmail,
                categoryId: parseInt(values.categoryId),
                publishStartDate: dateUtils.isoDateToIsoDateTime(values.publishStartDate),
                publishEndDate: dateUtils.isoDateToIsoDateTime(values.publishEndDate),
                status: 0,
                province: values.province
            };
            advertismentPush(data)
                .then((response) => {
                    console.log(response)
                    updateTable()
                    closeModal()
                    openModalMessage()
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                    throw toast.error('No se ha podido procesar su petición, error:' + error);
                });
        }, 800);
    };

    return (
        <>
            {showModal ? (
                <>
                    <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none p-0">

                        <div className="relative p-2 w-full max-w-3xl h-screen z-50 md:h-auto">
                            {/*content*/}
                            <div className="relative bg-white rounded-lg shadow top-4">
                                {/*header*/}
                                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                    <h3 className="text-lg font-medium">Crear anuncio</h3>
                                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModal}>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                {/*body*/}
                                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                                    <Form>
                                        <div className="relative px-3 py-3 flex-auto overflow-auto modal-body" style={{ maxHeight: '76vh', }}>
                                            <div className="grid md:grid-cols-1 gap-4">
                                                <div className="relative z-0 w-full group max-w-xs">
                                                    <label htmlFor="title" className="block mb-2 text-xs font-medium text-stone-600">Título*</label>
                                                    <Field name="title" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="title" component="span" className="mt-2 text-xs text-red-600" />
                                                </div>
                                                <div className="relative z-0 w-full group max-w-xs">
                                                    <label htmlFor="description" className="block mb-2 text-xs font-medium text-stone-600">Descripción*</label>
                                                    <Field name="description" as="textarea" id="" rows="4" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="description" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-3 max-w-xs">
                                                    <div className="relative max-w-xs">
                                                        <div>
                                                            <label htmlFor="imageUpload" className="block text-xs font-medium text-stone-600">Imagen</label>
                                                            <Field name="imageUpload" component={PictureInput} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="imageUpload" component="span" className="mt-2 text-xs text-red-600" />
                                                        </div>
                                                    </div>
                                                    <p className="my-2 text-xs text-neutral-500 max-w-xs md:max-w-full">Puedes incluir una imagen de un peso no superior a <b>10MB</b>. Tipos de archivo permitidos: <b>.png, .gif, .jpg, .jpeg</b>.</p>
                                                </div>
                                            </div>

                                            <div className="grid md:grid-cols-2 gap-4 mb-6 last:mb-0">
                                                <div className="relative max-w-xs">
                                                    <label className="block mb-2 text-xs font-medium text-stone-600">Fecha publicación*</label>
                                                    <Field type="date" min={new Date().toISOString().split('T')[0]} max={SubDate(12)} name='publishStartDate' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" placeholder="Seleccionar fecha" />
                                                    <ErrorMessage name="publishStartDate" component="span" className="mt-2 text-xs text-red-600" />
                                                </div>

                                                <div className="relative max-w-xs">
                                                    <label className="block mb-2 text-xs font-medium text-stone-600">Fecha baja*</label>
                                                     <>
                                                        <Field type="date" min={new Date().toISOString().split('T')[0]} max={SubDate(12)} name="publishEndDate" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" placeholder="Seleccionar fecha" />
                                                        <ErrorMessage name="publishEndDate" component="span" className="mt-2 text-xs text-red-600" />
                                                     </>
                                                </div>
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-4 mb-6 last:mb-0">
                                                <div className="relative max-w-xs">
                                                    <label htmlFor="categoryId" className="block mb-2 text-xs font-medium text-stone-600">Categoría*</label>
                                                    <Field name="categoryId" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                                        <option value={""}>Seleccionar</option>
                                                        <option value={"0"}>Alquiler</option>
                                                        <option value={"1"}>Compra</option>
                                                        <option value={"2"}>Traspaso</option>
                                                        <option value={"3"}>Venta</option>
                                                    </Field>
                                                    <ErrorMessage name="categoryId" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative max-w-xs">
                                                    <label htmlFor="province" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                    <Field name="province" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                                        <option value={""}>Seleccionar</option>
                                                        <option value={"A"}>Alicante</option>
                                                        <option value={"C"}>Castellón</option>
                                                        <option value={"V"}>Valencia</option>
                                                    </Field>
                                                    <ErrorMessage name="province" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                            </div>

                                            <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Datos de contacto</h2>
                                            <div className="grid md:grid-cols-2 gap-4">
                                                <div className="relative z-0 w-full group mb-2 max-w-xs">
                                                    <label htmlFor="contactFirstname" className="block mb-2 text-xs font-medium text-stone-600">Nombre*</label>
                                                    <Field name="contactFirstname" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="contactFirstname" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2 max-w-xs">
                                                    <label htmlFor="contactLastname" className="block mb-2 text-xs font-medium text-stone-600">Apellidos*</label>
                                                    <Field name="contactLastname" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="contactLastname" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2 max-w-xs">
                                                    <label htmlFor="contactEmail" className="block mb-2 text-xs font-medium text-stone-600">Correo electrónico*</label>
                                                    <Field name="contactEmail" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="contactEmail" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2 max-w-xs">
                                                    <label htmlFor="contactPhone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono*</label>
                                                    <Field name="contactPhone" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    <ErrorMessage name="contactPhone" component="span" className="mt-2 text-xs text-red-600" />
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* footer */}
                                        <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModal}>Cancelar</button>
                                            {!isLoading ?
                                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit"  >Aceptar</button>
                                                :
                                                <button className="bg-teal-600 hover:bg-teal-100 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" disabled >Aceptar</button>
                                            }
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <PopUpMessage showModalMessage={showModalMessage} closeModalMessage={closeModalMessage} />
        </>
    )
}