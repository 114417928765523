import React from 'react'
import { useNavigate } from "react-router-dom";

export const AccessCollegiate = () => {

    const navigate = useNavigate();

    function goToPageCollegiateList() {
        navigate('collegiatelist');
    }

    return (
        <>
            <div className="card md:h-60 bg-white px-2 py-2 md:p-6">
                <h1 className="pb-2 ml-1 font-medium text-lg flex align-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-physotherapist mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 15l-1 -3l4 -2l4 1h3.5" />
                        <circle cx="4" cy="19" r="1" />
                        <circle cx="12" cy="6" r="1" />
                        <path d="M12 17v-7" />
                        <path d="M8 20h7l1 -4l4 -2" />
                        <path d="M18 20h3" />
                    </svg>
                    <span>Colegiados</span>
                </h1>
                <div className="card-body py-1 px-4 flex justify-center items-center flex-col md:flex-row gap-4">
                    <div className="w-100 text-center">
                        <h3 className="font-medium text-lg text-neutral-500 text-ellipsis line-clamp-1 overflow-hidden">LISTADO COLEGIADOS DE LA COMUNIDAD VALENCIANA</h3>
                        <p className="my-2 text-sm text-neutral-500 text-ellipsis line-clamp-2 overflow-hidden">Puedes iniciar los trámites de alta como colegiado ejerciente de forma online, sin necesidad de acudir personalmente a las sedes del Colegio.</p>
                        <div className="card-actions flex justify-center mb-2">
                            <button className="btn bg-teal-600 hover:bg-teal-700 text-white px-8 mt-2" onClick={() => goToPageCollegiateList()} >Ver listado de colegiados</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}