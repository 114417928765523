import { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import Loader from '../components/spinner/Loader';
import { Link } from "react-router-dom";
import validationSchema from '../validation-check-collegiate-status/validationSchema';
import { checkCollegiateStatus } from '../api/sugarAPI';
import { getPartnerDetailsByToken } from '../api/icofcvAPI';
import { Partners } from '@icofcv/common';

interface Props {
    resetForm: () => void
}

interface FormValues {
    documento: string;
}

export const CheckCollegiateStatus = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [partnerDetails, setPartnerDetails] = useState<Partners>();

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token");
        setIsLoading(true);
        getPartnerDetailsByToken(token)
            .then((partnerDetails: any) => {
                partnerDetails.map(partner => {
                    setPartnerDetails(partner);
                });
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                throw error;
            });
    }, []);

    const initialValues: FormValues = {
        documento: ''
    };
    const onSubmit = async (values: FormValues) => {
        setIsLoading(true);
        checkCollegiateStatus(values).then((response) => {
            if (response.length === 0) {
                toast.error(`El colegiado con DNI ${values.documento} no es elegible para el descuento`);
            } else {
                toast.success(`Enhorabuena, el colegiado con DNI ${values.documento} puede optar al descuento`);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error('Ha habido un error con la comprobación del colegiado, por favor contacta con el ICOFCV');
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:white w-10" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">Comprobación de colegiado</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-100 hidden  md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Comprobación de colegiado</span>
                    </Link>
                    <div className="mt-0">
                        <div className="w-100">
                            <div className="md:col-span-2 md:mt-0">
                                {partnerDetails ?
                                    <div className="ml-10 mt-3">
                                        <p>Con esta herramienta del ICOFCV puede comprobar introduciendo el DNI/NIE o Nº de pasaporte si el colegiado puede optar a los descuentos que ofrece el Colegio para su evento/producto.</p>
                                        <img className="h-20 mt-3" src={partnerDetails.logo} alt="Partner's logo" />
                                        <Formik initialValues={initialValues} onSubmit={async (values, { resetForm }) => {
                                            await onSubmit(values)
                                            resetForm()
                                        }} validationSchema={validationSchema} >
                                            {props => {
                                                return (
                                                    <Form>
                                                        <div className="overflow-hidden sm:rounded-md">
                                                            <div className="bg-white sm:p-6">
                                                                <div className="relative px-3 py-3 flex-auto overflow-y-hidden">
                                                                    <div className="grid md:grid-cols-2 gap-3">
                                                                        <div className="relative z-0 w-full group mb-2">
                                                                            <label htmlFor="documento" className="block mb-2 text-xs font-medium text-stone-600">NIF/NIE/Nº de pasaporte</label>
                                                                            <Field name="documento" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                            <ErrorMessage name="documento" component="span" className="mt-2 text-xs text-red-600" />
                                                                            <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bg-white px-4 py-3 text-right sm:px-6">
                                                                    {!isLoading ?
                                                                        <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit">Comprobar estado</button>
                                                                        :
                                                                        <button className="bg-teal-600 hover:bg-teal-100 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" disabled>Comprobar estado</button>
                                                                    }
                                                                </div>
                                                                {isLoading && <Loader />}
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                    :
                                    <div className="ml-10 mt-3">
                                        <h1>Por favor contacta con el ICOFCV para resolver la incidencia</h1>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


