import React from 'react'

interface Props {
    showModalMessage: boolean,
    closeModalMessage: () => void
}

export const PopUpMessage: React.FC<Props> = ({ showModalMessage, closeModalMessage }) => {
    return (
        <>
            {showModalMessage ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div id="toast-notification" className="w-full max-w-xs  p-4 text-gray-900 bg-white rounded-lg shadow" role="alert">
                                <div className="flex items-center mb-3">
                                    <div className="text-md font-semibold text-gray-900">Gracias por crear el empleo</div>
                                    <button onClick={closeModalMessage} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-notification" aria-label="Close">
                                        <span className="sr-only">Close</span>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex items-center flex-column justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check w-12 h-12 my-2 stroke-teal-600" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="12" cy="12" r="9" />
                                        <path d="M9 12l2 2l4 -4" />
                                    </svg>
                                    <div className="text-sm font-normal text-center text-neutral-600">durante las próximas horas la administración se encargará de valorar su empleo y a publicarlo siempre y cuando siga la normas establecidas por el Colegio. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

