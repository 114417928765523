import React, { useState } from 'react'
import cognitoUtils from '../../lib/cognitoUtils'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index';
import { Link } from "react-router-dom";

interface Props {
    showModalAlertTouchId: boolean,
    showModalAccessInformation: boolean,
    showModalErrorTouchId: boolean,
    showModalLinkedFingerprint:boolean,
    showModalNoBiometricOldAppVersion:boolean,
    closeModalAlertTouchId: () => void,
    closeModalAccessInformation: () => void,
    closeModalErrorTouchId: () => void,
    closeModalLinkedFingerprint: () => void,
    closeModalNoBiometricOldAppVersion: () => void,
    modalAccessInformationCallback: (data: any) => void,
}

export const ModalsLoginBiometric: React.FC<Props> = ({ children, showModalAlertTouchId,showModalAccessInformation,showModalErrorTouchId, showModalLinkedFingerprint, showModalNoBiometricOldAppVersion, closeModalAlertTouchId, closeModalAccessInformation, closeModalErrorTouchId, closeModalLinkedFingerprint, closeModalNoBiometricOldAppVersion, modalAccessInformationCallback }) =>{

    const session = useSelector((state: RootState) => state.session);
    const [showPassword, setShowPassword] = useState (false);
    const [biometricUsername, setBiometricUsername] = useState ('');
    const [biometricPassword, setBiometricPassword] = useState ('');
    
    const getBiometricCredentials = () => {

      if(biometricUsername != null || biometricUsername != undefined) {
        console.log('biometricUsername: ' + biometricUsername);
      }
      
      if(biometricPassword != null || biometricPassword != undefined) {
        console.log('biometricPassword: ' + biometricPassword);
      }

      const FpCredentialsBase64Str = btoa(`{ "username": "${biometricUsername}", "password": "${biometricPassword}" }`);
      console.log('FpCredentialsBase64Str: ' + FpCredentialsBase64Str);

      if (modalAccessInformationCallback) {
        modalAccessInformationCallback(FpCredentialsBase64Str);
      }
      closeModalAccessInformation(); 
    }

    return(
        <>
       
         
         { showModalAlertTouchId ?
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-0">
                <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                  {/*content*/}
                  <div className="relative bg-white rounded-lg shadow pt-3 top-36">
                    {/*header*/}
                    <div className="flex justify-center items-center py-3 ">
                        <h3 className="text-xl font-medium mt-2">Aviso</h3>
                    </div>
                    {/*body*/}
                    <div className="relative px-3 pb-2 flex-auto overflow-auto modal-body">
                        <p className="text-sm text-neutral-500 text-center mb-4">Para acceder con biometría activa la configuraciónde acceso biométrico en tu dispositivo.</p>
                        <button type="button" className="flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={closeModalAlertTouchId}>Aceptar</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
            </>
          : null
          }
          { showModalAccessInformation ?
                <>
                
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-20">
                    <div className="w-full max-w-md space-y-8 rounded-lg card bg-white py-4 px-3 login-container mx-2">
                          <div className="h-20 w-20 rounded-full border-2 border-teal-600 flex flex-col items-center justify-center mx-auto">
                              {/* Icono huella dactilar */}
                               <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-auto text-teal-600" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M17.81,4.47C17.73,4.47 17.65,4.45 17.58,4.41C15.66,3.42 14,3 12,3C10.03,3 8.15,3.47 
                                6.44,4.41C6.2,4.54 5.9,4.45 5.76,4.21C5.63,3.97 5.72,3.66 5.96,3.53C7.82,2.5 9.86,2 12,2C14.14,2 16,2.47 
                                18.04,3.5C18.29,3.65 18.38,3.95 18.25,4.19C18.16,4.37 18,4.47 17.81,4.47M3.5,9.72C3.4,9.72 3.3,9.69 3.21,9.63C3,9.47 
                                2.93,9.16 3.09,8.93C4.08,7.53 5.34,6.43 6.84,5.66C10,4.04 14,4.03 17.15,5.65C18.65,6.42 19.91,7.5 20.9,8.9C21.06,9.12 
                                21,9.44 20.78,9.6C20.55,9.76 20.24,9.71 20.08,9.5C19.18,8.22 18.04,7.23 16.69,6.54C13.82,5.07 10.15,5.07 7.29,6.55C5.93,7.25 
                                4.79,8.25 3.89,9.5C3.81,9.65 3.66,9.72 3.5,9.72M9.75,21.79C9.62,21.79 9.5,21.74 9.4,21.64C8.53,20.77 8.06,20.21 7.39,19C6.7,17.77 
                                6.34,16.27 6.34,14.66C6.34,11.69 8.88,9.27 12,9.27C15.12,9.27 17.66,11.69 17.66,14.66A0.5,0.5 0 0,1 17.16,15.16A0.5,0.5 
                                0 0,1 16.66,14.66C16.66,12.24 14.57,10.27 12,10.27C9.43,10.27 7.34,12.24 7.34,14.66C7.34,16.1 7.66,17.43 8.27,18.5C8.91,19.66 9.35,20.15 
                                10.12,20.93C10.31,21.13 10.31,21.44 10.12,21.64C10,21.74 9.88,21.79 9.75,21.79M16.92,19.94C15.73,19.94 14.68,19.64 
                                13.82,19.05C12.33,18.04 11.44,16.4 11.44,14.66A0.5,0.5 0 0,1 11.94,14.16A0.5,0.5 0 0,1 12.44,14.66C12.44,16.07 
                                13.16,17.4 14.38,18.22C15.09,18.7 15.92,18.93 16.92,18.93C17.16,18.93 17.56,18.9 17.96,18.83C18.23,18.78 18.5,18.96 
                                18.54,19.24C18.59,19.5 18.41,19.77 18.13,19.82C17.56,19.93 17.06,19.94 16.92,19.94M14.91,22C14.87,22 14.82,22 
                                14.78,22C13.19,21.54 12.15,20.95 11.06,19.88C9.66,18.5 8.89,16.64 8.89,14.66C8.89,13.04 10.27,11.72 11.97,11.72C13.67,11.72 
                                15.05,13.04 15.05,14.66C15.05,15.73 16,16.6 17.13,16.6C18.28,16.6 19.21,15.73 19.21,14.66C19.21,10.89 15.96,7.83 
                                11.96,7.83C9.12,7.83 6.5,9.41 5.35,11.86C4.96,12.67 4.76,13.62 4.76,14.66C4.76,15.44 4.83,16.67 5.43,18.27C5.53,18.53 
                                5.4,18.82 5.14,18.91C4.88,19 4.59,18.87 4.5,18.62C4,17.31 3.77,16 3.77,14.66C3.77,13.46 4,12.37 4.45,11.42C5.78,8.63 
                                8.73,6.82 11.96,6.82C16.5,6.82 20.21,10.33 20.21,14.65C20.21,16.27 18.83,17.59 17.13,17.59C15.43,17.59 14.05,16.27 
                                14.05,14.65C14.05,13.58 13.12,12.71 11.97,12.71C10.82,12.71 9.89,13.58 9.89,14.65C9.89,16.36 10.55,17.96 11.76,19.16C12.71,20.1 
                                13.62,20.62 15.03,21C15.3,21.08 15.45,21.36 15.38,21.62C15.33,21.85 15.12,22 14.91,22Z" />
                              </svg>
                              {/* Icono face id */}
                              {/*<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-face-id w-12 h-auto stroke-teal-600" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                <path d="M9 10l.01 0" />
                                <path d="M15 10l.01 0" />
                                <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                              </svg>*/}

                          </div>
                          <p className="mt-3 w-64 text-center text-md tracking-tight text-neutral-500 mx-auto">Introduce tus datos de acceso para vincular la huella</p>
                          {/*<p className="mt-3 w-64 text-center text-md tracking-tight text-neutral-500 mx-auto">Introduce tus datos de acceso para vincular el acceso biométrico</p>*/}
                          
                      
                          <div className="mt-2">
                              <input type="hidden" name="remember" defaultValue="true" />
                              <div>
                                  <div className="h-20">
                                      <label htmlFor="biometricUsername" className="text-sm my-2">
                                      Correo electrónico
                                      </label>
                                      <input
                                      onChange={(e)=> setBiometricUsername(e?.target.value)}
                                      id="biometricUsername"
                                      name="biometricUsername"
                                      type="email"
                                      autoComplete="email"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                      placeholder="Correo electrónico"
                                      />
                                  </div>
                                  
                                  <div>
                                      <label htmlFor="biometricPassword" className="text-sm my-2">
                                      Contraseña
                                      </label>
                                      {showPassword === true ?
                                      <input 
                                      onChange={(e)=> setBiometricPassword(e?.target.value)}
                                      id="biometricPassword"
                                      name="biometricPassword"
                                      // type="password"
                                      autoComplete="current-password"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                      placeholder="Contraseña"
                                      />
                                      :
                                      <input 
                                      onChange={(e)=> setBiometricPassword(e?.target.value)}
                                      id="biometricPassword"
                                      name="biometricPassword"
                                      type="password"
                                      autoComplete="current-password"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                      placeholder="Contraseña"
                                      />
                                      }
                                      {showPassword === false ?
                                      <a className="relative bottom-8 left-72 md:left-96" onClick={()=>setShowPassword(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off stroke-neutral-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5"  fill="none" stroke-linecap="round" stroke-linejoin="round">
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                              <line x1="3" y1="3" x2="21" y2="21" />
                                              <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                              <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                              </svg>
                                      </a>
                                      :
                                      <a className="relative bottom-8 left-72 md:left-96" onClick={()=>setShowPassword(false)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye stroke-neutral-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                              <circle cx="12" cy="12" r="2" />
                                              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                          </svg>
                                      </a>
                                      }
                                  </div>
                              </div>
                              <div>
                                  <button onClick={getBiometricCredentials} className="group relative flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none">Confirmar</button>
                                  <button onClick={closeModalAccessInformation} className="group relative flex w-full justify-center items-center bg-neutral-300 hover:bg-neutral-500 text-neutral-600 hover:text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none">Cancelar</button>    
                              </div>
                            
                          </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
                </>
                :null
               }

            { showModalErrorTouchId ?
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-0">
                  <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                      {/*content*/}
                      <div className="relative bg-white rounded-lg shadow pt-3 top-36">
                        {/*header*/}
                        <div className="flex justify-center items-start pt-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="teal" className="w-20 h-auto">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                          </svg>
                        </div>
                        {/*body*/}
                        <div className="relative px-3 pb-3 flex-auto overflow-auto modal-body">
                            <p className="text-sm text-neutral-500 text-center my-3">Se ha producido un error al vincular tu huella dactilar a la aplicación ICOFCV. Por favor, inténtelo de nuevo</p>
                            {/*<p className="text-sm text-neutral-500 text-center my-3">Se ha producido un error al vincular tu acceso biométrico a la aplicación ICOFCV. Por favor, inténtelo de nuevo</p>*/}
                            <button type="button" className="flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={closeModalErrorTouchId}>Volver</button>
                        </div>
                        {/*footer*/}                
                      </div>
                  </div> 
              </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
            </>
          : null
          }

        { showModalLinkedFingerprint?
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-0">
                <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                  {/*content*/}
                  <div className="relative bg-white rounded-lg shadow pt-3 top-36">
                        {/*header*/}
                        <div className="h-20 w-20 rounded-full border-2 border-teal-600 flex flex-col items-center justify-center mx-auto">
                              {/* Icono huella dactilar */}
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-auto text-teal-600" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M17.81,4.47C17.73,4.47 17.65,4.45 17.58,4.41C15.66,3.42 14,3 12,3C10.03,3 8.15,3.47 
                                6.44,4.41C6.2,4.54 5.9,4.45 5.76,4.21C5.63,3.97 5.72,3.66 5.96,3.53C7.82,2.5 9.86,2 12,2C14.14,2 16,2.47 
                                18.04,3.5C18.29,3.65 18.38,3.95 18.25,4.19C18.16,4.37 18,4.47 17.81,4.47M3.5,9.72C3.4,9.72 3.3,9.69 3.21,9.63C3,9.47 
                                2.93,9.16 3.09,8.93C4.08,7.53 5.34,6.43 6.84,5.66C10,4.04 14,4.03 17.15,5.65C18.65,6.42 19.91,7.5 20.9,8.9C21.06,9.12 
                                21,9.44 20.78,9.6C20.55,9.76 20.24,9.71 20.08,9.5C19.18,8.22 18.04,7.23 16.69,6.54C13.82,5.07 10.15,5.07 7.29,6.55C5.93,7.25 
                                4.79,8.25 3.89,9.5C3.81,9.65 3.66,9.72 3.5,9.72M9.75,21.79C9.62,21.79 9.5,21.74 9.4,21.64C8.53,20.77 8.06,20.21 7.39,19C6.7,17.77 
                                6.34,16.27 6.34,14.66C6.34,11.69 8.88,9.27 12,9.27C15.12,9.27 17.66,11.69 17.66,14.66A0.5,0.5 0 0,1 17.16,15.16A0.5,0.5 
                                0 0,1 16.66,14.66C16.66,12.24 14.57,10.27 12,10.27C9.43,10.27 7.34,12.24 7.34,14.66C7.34,16.1 7.66,17.43 8.27,18.5C8.91,19.66 9.35,20.15 
                                10.12,20.93C10.31,21.13 10.31,21.44 10.12,21.64C10,21.74 9.88,21.79 9.75,21.79M16.92,19.94C15.73,19.94 14.68,19.64 
                                13.82,19.05C12.33,18.04 11.44,16.4 11.44,14.66A0.5,0.5 0 0,1 11.94,14.16A0.5,0.5 0 0,1 12.44,14.66C12.44,16.07 
                                13.16,17.4 14.38,18.22C15.09,18.7 15.92,18.93 16.92,18.93C17.16,18.93 17.56,18.9 17.96,18.83C18.23,18.78 18.5,18.96 
                                18.54,19.24C18.59,19.5 18.41,19.77 18.13,19.82C17.56,19.93 17.06,19.94 16.92,19.94M14.91,22C14.87,22 14.82,22 
                                14.78,22C13.19,21.54 12.15,20.95 11.06,19.88C9.66,18.5 8.89,16.64 8.89,14.66C8.89,13.04 10.27,11.72 11.97,11.72C13.67,11.72 
                                15.05,13.04 15.05,14.66C15.05,15.73 16,16.6 17.13,16.6C18.28,16.6 19.21,15.73 19.21,14.66C19.21,10.89 15.96,7.83 
                                11.96,7.83C9.12,7.83 6.5,9.41 5.35,11.86C4.96,12.67 4.76,13.62 4.76,14.66C4.76,15.44 4.83,16.67 5.43,18.27C5.53,18.53 
                                5.4,18.82 5.14,18.91C4.88,19 4.59,18.87 4.5,18.62C4,17.31 3.77,16 3.77,14.66C3.77,13.46 4,12.37 4.45,11.42C5.78,8.63 
                                8.73,6.82 11.96,6.82C16.5,6.82 20.21,10.33 20.21,14.65C20.21,16.27 18.83,17.59 17.13,17.59C15.43,17.59 14.05,16.27 
                                14.05,14.65C14.05,13.58 13.12,12.71 11.97,12.71C10.82,12.71 9.89,13.58 9.89,14.65C9.89,16.36 10.55,17.96 11.76,19.16C12.71,20.1 
                                13.62,20.62 15.03,21C15.3,21.08 15.45,21.36 15.38,21.62C15.33,21.85 15.12,22 14.91,22Z" />
                              </svg>
                              {/* Icono face id */}
                              {/*<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-face-id w-12 h-auto stroke-teal-600" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                <path d="M9 10l.01 0" />
                                <path d="M15 10l.01 0" />
                                <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                              </svg>*/}
                          </div>
                          <div className="flex justify-center items-center justify-center px-4">
                              <h3 className="text-lg font-medium mt-2">Biométrico Vinculado</h3>
                              {/*<h3 className="text-lg font-medium mt-2">Acceso biométrico vinculado</h3>*/}
                          </div>
                          {/*body*/}
                          <div className="relative px-3 pb-3 flex-auto overflow-auto modal-body">
                              <p className="text-sm text-neutral-500 text-center my-3">Se ha vinculado el acceso biométrico de este dispositivo con la aplicación de ICOFCV. Podrá acceder la próxima vez que inicie sesión</p>
                              {/*<p className="text-sm text-neutral-500 text-center my-3">Se ha vinculado el acceso biométrico de este dispositivo con la aplicación de ICOFCV. Podrá acceder la próxima vez que inicie sesión.</p>*/}
                              <button type="button" className="flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={closeModalLinkedFingerprint}>Aceptar</button>
                          </div>
                      </div>
                    </div>
                
              </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
            </>
          : null
          }

{ showModalNoBiometricOldAppVersion ?
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none mt-0">
                  <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                      {/*content*/}
                      <div className="relative bg-white rounded-lg shadow pt-3 top-36">
                        {/*header*/}
                        <div className="flex justify-center items-start pt-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="teal" className="w-20 h-auto">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                          </svg>
                        </div>
                        {/*body*/}
                        <div className="relative px-3 pb-3 flex-auto overflow-auto modal-body">
                            <p className="text-sm text-neutral-500 text-center my-3">Para utilizar el acceso biométrico, es necesario que actualices a la última versión de la App.</p>
                            {/*<p className="text-sm text-neutral-500 text-center my-3">Se ha producido un error al vincular tu acceso biométrico a la aplicación ICOFCV. Por favor, inténtelo de nuevo</p>*/}
                            <button type="button" className="flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={closeModalNoBiometricOldAppVersion}>Volver</button>
                        </div>
                        {/*footer*/}                
                      </div>
                  </div> 
              </div>
             
              <div className="opacity-25 fixed inset-0 z-40 bg-black mt-0"></div>
            </>
          : null
          }          
      
      
        
          </>
    )
}