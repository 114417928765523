import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../store/index'
import { NavLink } from "react-router-dom";
import { AccessCollegiate } from '../components/colleagues/AccessCollegiate'
import { AccessList } from '../components/clinics/AccessList'
import { AccessWhere } from '../components/where-we-are/AccessWhere'
import { setTopLevelNavigation } from '../actions/configNavigation'
import { useStore } from 'react-redux'
import { COLLEGE_MODULE } from '../constants/modules'
import { SocialNetworks } from '../components/social-networks/SocialNetworks';
import { ModalNoLogin } from '../components/miProfile/ModalNoLogin';
import { ViewListAdvertisments } from '../components/advertisments/ViewListAdvertisements';
import { AccessEmployment } from '../components/employment/AccessEmployment';
import { AccessEmploymentLogin } from '../components/employment/AccesEmploymentLogin';
import { ModalCreateOfferNoCollegiate } from '../components/employment/ModalCreateOfferNoCollegiate';
import { AccessFormationLogin } from '../components/formation/AccessFormationLogin';
import jquery from 'jquery';


export function College() {

    const store = useStore();
    const session = useSelector((state: RootState) => state.session);
    const $: JQueryStatic = jquery;


    function scrollBlock() {
        $("#root").css("overflow-y", "hidden");
    }

    function scrollActive() {
        $("#root").css("overflow-y", "auto");
    }

    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [showModalCreateOfferNoCollegiate, setShowModalCreateOfferNoCollegiate] = useState(false);
    const openModalCreateOfferNoCollegiate = () => {
        setShowModalCreateOfferNoCollegiate(true)
        scrollBlock()
    };
    const closeModalCreateOfferNoCollegiate = () => {
        setShowModalCreateOfferNoCollegiate(false)
        scrollActive()
    };
    const updateTable = () => { }

    useEffect(() => {
        store.dispatch(setTopLevelNavigation(COLLEGE_MODULE));
    }, []);

    return (

        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-center header-top">
                <h1 className="font-medium text-white text-xl text-center">Colegio</h1>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-16">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <h1 className="pb-3 hidden md:flex font-medium text-lg flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper mx-2 h-6 w-6 stroke-neutral-800" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="3" y1="21" x2="21" y2="21" />
                            <path d="M5 21v-14l8 -4v18" />
                            <path d="M19 21v-10l-6 -4" />
                            <line x1="9" y1="9" x2="9" y2="9.01" />
                            <line x1="9" y1="12" x2="9" y2="12.01" />
                            <line x1="9" y1="15" x2="9" y2="15.01" />
                            <line x1="9" y1="18" x2="9" y2="18.01" />
                        </svg>
                        <span>Colegio</span>
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
                        <div className="card bg-white px-2 py-2 md:p-6 md:h-72">
                            <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-code-plus mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 12h6" />
                                    <path d="M12 9v6" />
                                    <path d="M6 19a2 2 0 0 1 -2 -2v-4l-1 -1l1 -1v-4a2 2 0 0 1 2 -2" />
                                    <path d="M18 19a2 2 0 0 0 2 -2v-4l1 -1l-1 -1v-4a2 2 0 0 0 -2 -2" />
                                </svg>
                                <span>Bolsa de trabajo</span>
                            </h1>
                            <div className="card-body py-1 px-4 flex md:justify-center md:items-center md:flex-row flex-col">
                                <div className="py-1 md:px-4 md:w-1/2">
                                    <h3 className="font-medium text-lg text-neutral-500">BOLSA DE TRABAJO PARA NO COLEGIADOS</h3>
                                    <p className="my-2 text-sm text-neutral-500 line-clamp-4">Crea oportunidades laborales en nuestra bolsa de trabajo en línea sin necesidad de estar colegiado. Sólo podrás crear la oferta, para poder verlas es necesario estar colegiado.</p>
                                    <div className="card-actions flex justify-center mb-2">
                                        <button onClick={openModalCreateOfferNoCollegiate} className="bg-teal-600 hover:bg-teal-800 text-white px-8 py-2 mt-2 flex items-center justify-center mx-auto w-auto rounded">
                                            <span className="ml-2">Crear empleo</span>
                                        </button>
                                    </div>
                                </div>
                                {!session.isLoggedIn ?
                                    <AccessEmployment />
                                    :
                                    <AccessEmploymentLogin />
                                }
                            </div>
                        </div>
                        <AccessFormationLogin />
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2">
                        <div>
                            <div className="card bg-white px-2 py-2 md:p-6 md:h-96">
                                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ad-2 mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M11.933 5h-6.933v16h13v-8" />
                                        <path d="M14 17h-5" />
                                        <path d="M9 13h5v-4h-5z" />
                                        <path d="M15 5v-2" />
                                        <path d="M18 6l2 -2" />
                                        <path d="M19 9h2" />
                                    </svg>
                                    <span>Tablón de anuncios</span>
                                </h1>
                                <div className="card-body px-4 flex justify-center items-center flex-column">
                                    {session.isLoggedIn ?
                                        <ViewListAdvertisments />
                                        :

                                        /* Inicio sesión */
                                        <div>
                                            <div className="flex flex-col items-center p-2">
                                                <h3 className="font-medium text-lg text-neutral-500">EXPLORA NUESTRA SELECCIÓN DE ANUNCIOS EN LÍNEA</h3>
                                                <p className="my-2 text-neutral-500 text-sm"> Los anuncios no están disponibles actualmente. Para acceder a ellos, se requiere iniciar sesión en su cuenta. Si aún no tienes una cuenta, puedes crear una en unos pocos pasos simples. Si ya tienes cuenta, inicia sesión para ver los anuncios.</p>
                                                <div className="card-actions flex justify-center mb-2">
                                                    <button onClick={openModal} className="btn bg-gray-400 hover:bg-gray-200 text-white px-8 mt-2 flex items-center justify-center mx-auto w-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock stroke-white" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <rect x="5" y="11" width="14" height="10" rx="2" />
                                                            <circle cx="12" cy="16" r="1" />
                                                            <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                                                        </svg>
                                                        <span className="ml-2 whitespace-nowrap">Acceso a sección de anuncios</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <AccessWhere />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
                        <AccessCollegiate />

                        <AccessList />
                    </div>
                </div>
                {/* Redes Sociales */}
                <SocialNetworks />

                {/* Avisos legales */}
                <div className="flex items-center justify-center flex-wrap pb-16 md:pb-0">
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Politica-privacidad.pdf" target="_blank">Política de privacidad</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Aviso-Legal.pdf" target="_blank">Aviso legal</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Normativa-cookies.pdf" target="_blank">Política de cookies</a>
                    <span className="text-teal-600">|</span>
                    <NavLink to="/contactform" className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline">Formulario de contacto</NavLink>
                </div>
            </div>
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
            <ModalCreateOfferNoCollegiate showModal={showModalCreateOfferNoCollegiate} closeModal={closeModalCreateOfferNoCollegiate} updateTable={updateTable} />
        </>
    )
}


