import store from '../store';
import React, { useState, useEffect }  from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from "../validationForm/validationSchema";
import Loader from '../components/spinner/Loader';
import { loginRequest, updatePasswordRequest, resetPasswordRequest } from '../api/icofcvAPI';
import cognitoUtils from '../lib/cognitoUtils' 
import { useDispatch } from "react-redux";
import { SET_SESSION } from '../constants/actionTypes'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { ModalRegister } from '../components/miProfile/ModalRegister'
import { LoginResponse } from '@icofcv/common';
import { ModalsLoginBiometric } from '../components/login-biometric/ModalsLoginBiometric';
import { useSelector } from "react-redux";
import type { RootState } from '../store/index';
import cordovaAPI from '../api/cordovaAPI';
import { config } from 'process';
import appConfig from '../config/app-config.json';


interface FormValues {
    username: string;
    password: string;
    newPassword: string;
    newPassword2: string;
    resetUsername: string,
    keepMeSignedIn: boolean;
}

enum FormMode {
    Login = 1,
    ResetPassword=2,
    NewPassword=3
  }

  enum ErrorType {
    UserPass = 1,
    Unknown=2
  }


export const LoginView = (props) => { 

    const [isBiometricButtonVisible, setIsBiometricButtonVisible] = useState (false);

    const [callOnlyOneFpIsAvailable, setCallOnlyOneFpIsAvailable] = useState (false);

    const nativeEvent = useSelector((state: RootState) => state.nativeEvent);
    const [lastFpEventId, setLastFpEventId] = useState(-9999);

    const [isLoading, setIsLoading] = useState (false) 
    const [currentSession, setCurrentSession] = useState('')
    const [currentUsername, setCurrentUsername] = useState('')
    const [viewForm, setViewForm] = useState(FormMode.Login)
    const dispatch = useDispatch();
    const navigate = useNavigate();
/////
    const [showModalAlertTouchId, setShowModalAlertTouchId] = useState (false) 
    const openModalAlertTouchId = () => setShowModalAlertTouchId(true)
    const closeModalAlertTouchId = () => setShowModalAlertTouchId(false)
    const [showModalNoBiometricOldAppVersion, setShowModalNoBiometricOldAppVersion] = useState (false) 

    const [showModalAccessInformation, setShowModalAccessInformation] = useState (false) 
    const openModalAccessInformation = () => setShowModalAccessInformation(true)
    
    const modalAccessInformationCallback = (secret) => {
        console.log('secret',secret)
        cordovaAPI.FP_registerSecret(secret);
    }

    const closeModalAccessInformation = () => setShowModalAccessInformation(false)

    const [showModalErrorTouchId, setShowModalErrorTouchId] = useState (false) 
    const openModalErrorTouchId = () => setShowModalErrorTouchId(true)
    const closeModalErrorTouchId = () => setShowModalErrorTouchId(false)

    const [showModalLinkedFingerprint, setShowModalLinkedFingerprint] = useState (false) 
    const openModalLinkedFingerprint = () => setShowModalLinkedFingerprint(true)
    const closeModalLinkedFingerprint = () => setShowModalLinkedFingerprint(false)
    const openModalNoBiometricOldAppVersion = () => setShowModalNoBiometricOldAppVersion(true)
    const closeModalNoBiometricOldAppVersion = () => setShowModalNoBiometricOldAppVersion(false)
///////
    const [showPassword, setShowPassword] = useState (false) 
    
    const [showModalRegister, setShowModalRegister] = useState(false);

    const openModalRegister = () => setShowModalRegister(true);
    
    const closeModalRegister = () => setShowModalRegister(false);

    const [credentials, setCredentials] = useState ('');


   
    const initialValues: FormValues = {
        username: "",
        password: "",
        newPassword: "",
        newPassword2: "",
        resetUsername: "",
        keepMeSignedIn: false,
      };

    const handleLoginError = (errorType: ErrorType) => {
        //TODO: mejorar

        if (errorType==ErrorType.UserPass) {
            toast.error('Usuario o contraseña incorrectos');
        }

        else {
            toast.error('Lo sentimos, no hemos podido procesar su solicitud en este momento');
            
        }

        setViewForm(FormMode.Login);

        setIsLoading(false);
    }

    const versionCompare = function (v1: any, v2: any) { 
    // vnum stores each numeric 
    // part of version 
    let vnum1 = 0;
    let vnum2 = 0; 
 
    // loop until both string are 
    // processed 
    for (var i = 0, j = 0; (i < v1.length || j < v2.length);) { 
        // storing numeric part of 
        // version 1 in vnum1 
        while (i < v1.length && v1[i] != '.') { 
            vnum1 = vnum1 * 10 + (v1[i] - 0); 
            i++; 
        } 
 
        // storing numeric part of 
        // version 2 in vnum2 
        while (j < v2.length && v2[j] != '.') { 
            vnum2 = vnum2 * 10 + (v2[j] - 0); 
            j++; 
        } 
 
        if (vnum1 > vnum2) 
            return 1; 
        if (vnum2 > vnum1) 
            return -1; 
 
        // if equal, reset variables and 
        // go for next numeric part 
        vnum1 = vnum2 = 0; 
        i++; 
        j++; 
    } 
    return 0; 
}      

    const checkFPIsAvailable = function () {
        const di = store.getState().deviceInfo;
        const appVersion = di.version; 
        const devicePlatform = di.platform;

        if(devicePlatform == 'android' && versionCompare(appVersion, appConfig.androidAppVersion) < 0) {
            openModalNoBiometricOldAppVersion();
        } else if(devicePlatform == 'ios' && versionCompare(appVersion, appConfig.iOSAppVersion) < 0) {
            openModalNoBiometricOldAppVersion();
        } else {
            cordovaAPI.FP_isAvailable();
        }
    }

    const onSubmit =  function (values: FormValues, { setValues }) {
    
        console.log(values);
        if (viewForm === FormMode.Login) {
            doLoginWithValues(values);
        }
        if (viewForm === FormMode.NewPassword) {
            updatePassword(values);
        }
        if (viewForm === FormMode.ResetPassword) {
            resetPassword(values);
        }
        setValues({
            newPassword: '',
            newPassword2: '',
            resetUsername: '',
            username: values.username,
            password: values.password
        });
    }

    const doLoginWithValues = function (values: FormValues) {
        doLogin(values.username, values.password);
    }

    const doLoginWithCredentials = function (credentials: any) {
        doLogin(credentials.username, credentials.password);
    }

    const doLogin = function (username: string, password: string) {
        setIsLoading(true)
        setTimeout(() => {
            
            const data = {
                username: username,
                password: password
            }

            setCurrentUsername(username);

            loginRequest(data)
                .then((response)=>{ 
                    handleLoginResponse(response);
                })
                .catch((error)  => {
                    setIsLoading(false);
                    console.error(error);
                    handleLoginError(ErrorType.Unknown);
                    throw error;
                });


        }, 500);
      };



    const updatePassword = function (values: FormValues) {
        setIsLoading(true)
        setTimeout(() => {
            
            const data = {
                username: currentUsername,
                password: values.newPassword,
                session: currentSession
            }
            updatePasswordRequest(data)
                .then((response)=>{ 
                    handleLoginResponse(response);
                })
                .catch((error)  => {
                    handleLoginError(ErrorType.Unknown);
                    throw error;
                });


        }, 500);
      };


    const resetPassword = function (values: FormValues) {
        setIsLoading(true)
        setTimeout(() => {
            
            const data = {
                username: values.resetUsername
            }
            resetPasswordRequest(data)
                .then((response)=>{ 
                    setIsLoading(false);
                    toast.info('Si encontramos su usuario en nuestro sistema le enviaremos un email con su nueva contraseña temporal');
                    setViewForm(FormMode.Login);
                    
                })
                .catch((error)  => {
                    handleLoginError(ErrorType.Unknown);
                    throw error;
                });


        }, 500);
      };

    const handleLoginResponse = function (response: LoginResponse) {
    
                     setIsLoading(false);
                    // Sin respuesta
                    if (!response.response || response.response.indexOf('NotAuthorizedException')>0 ) {
                        handleLoginError(ErrorType.UserPass);
                        return;
                    }

                    let authResponse = JSON.parse(response.response);

                    // Pendiente cambio de pass
                    if (authResponse.challenge) {
                        setCurrentSession(authResponse.session);
                        setViewForm(FormMode.NewPassword);
                        return;
                    }

                    // Autenticación normal
                        localStorage.setItem('cognito-auth', JSON.stringify (cognitoUtils.mapLoginResult(response.response)));
                        cognitoUtils.getStoredCognitoSession().then( (sses) => {
                            if (sses.isLoggedIn) {
                                dispatch({ type: SET_SESSION, session: sses })
                                setTimeout(()=> {
                                    navigate('/');
                                    setIsLoading(false);
                                }, 100);
                            } else {
                                handleLoginError(ErrorType.Unknown);
                            }
                        });
                    
    
    }

    useEffect(()=> {
        
        if(!callOnlyOneFpIsAvailable) {
            setCallOnlyOneFpIsAvailable(true);
            
            cordovaAPI.FP_isAvailable();

            return;
        }

        if (nativeEvent && nativeEvent.type==='biometric') {

            console.log(nativeEvent.payload);
            const notification = nativeEvent.payload;

            if(notification.id == lastFpEventId) return;             

            if(notification.type == 'FP_IS_AVAILABLE_RESULT') {
                //Si la biometría está activa en el dispositivo, intentamos hacer loadSecret
                if(!isBiometricButtonVisible) {
                    setLastFpEventId(notification.id);                    
                    
                    setIsBiometricButtonVisible(true);
                    
                    return;
                }

                cordovaAPI.FP_loadSecret();
            }            
            
            if(notification.type == 'FP_IS_AVAILABLE_ERROR') {
                //Popup con aviso de biometría no activa en el dispositivo
                if(isBiometricButtonVisible) {
                    setLastFpEventId(notification.id);                    

                    openModalAlertTouchId();
                    
                    return;
                }                
            }        

            if(notification.type == 'FP_LOAD_SECRET_RESULT') {
                //Biometría activa en el dispositivo y vinculada a la app, intentamos hacer doLogin()
                console.log("NotificationContent: " + notification.content);

                const json = atob(notification.content);
                console.log("json: " + json);

                const credentials = JSON.parse(json);
                console.log("credentials: " + JSON.stringify(credentials));
                
                doLoginWithCredentials(credentials);    
            }            
            
            if(notification.type == 'FP_LOAD_SECRET_ERROR') {
                //Popup con credenciales para vincular la huella dactilar y generar el secret
                openModalAccessInformation();
            }
            
            if(notification.type == 'FP_REGISTER_SECRET_RESULT') {
                //Popup con mensaje de huella dactilar vinculada 
                openModalLinkedFingerprint();
            } 

            if(notification.type == 'FP_REGISTER_SECRET_ERROR') {
                //Popup con mensaje de error en el proceso de vinculación de huella dactilar
                openModalErrorTouchId();

                //Llamar de nuevo al cordovaAPI.FP_registerSecret(secret) para que el usuario vuelva a registrar la huella
                setTimeout(() => {
                    closeModalErrorTouchId();
                    checkFPIsAvailable();
                }, 2000);
            }

            setLastFpEventId(notification.id);

        }

    });    

    return(
        <>
       
        <div className="fixed top-0 right-0 bottom-0 left-0 z-20 bg-neutral-100 md:p-0">
            <Link to="/"  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto flex items-center mt-3 fixed right-4 z-20" >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
            </Link>
           
            <div className="grid grid-cols-1 gap-0 md:grid-cols-2 h-full">
                <div className="h-full object-cover object-center">
                    <img className="hidden md:block h-full w-100" src="../bg-login.jpg" alt="BG login"/>
                </div>
                
                <Formik initialValues={initialValues} onSubmit={onSubmit}  > 
                <Form> 
                {viewForm === FormMode.Login &&
                <>
                {/* Inicio sesion */}
                <div className="flex relative h-screen items-center justify-center py-12 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8 rounded-lg card bg-white py-4 px-3 login-container">
                        <div>
                            <img className="mx-auto h-12 w-auto" src="../logo-icofcv.svg"
                            alt="Ilustre Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana"/>
                            <h2 className="mt-6 text-center text-2xl font-medium tracking-tight text-gray-900">
                            Inicia sesión con tu correo electrónico y contraseña
                            </h2>
                            <p className="text-sm text-neutral-500 text-center mt-2">Recuerda que para poder acceder a la aplicación debes haber cumplimentado previamente el formulario de <a onClick={openModalRegister} className="text-teal-600 underline">Solicitud de acceso</a> a la plataforma. </p>
                        </div>
                        <div className="mt-2">
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div>
                                <div className="h-20">
                                    <label htmlFor="email-address" className="text-sm my-2">
                                    Correo electrónico
                                    </label>
                                    <Field
                                    id="email-address"
                                    name="username"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Correo electrónico"
                                    />
                                </div>
                                
                                <div>
                                    <label htmlFor="password" className="text-sm my-2">
                                    Contraseña
                                    </label>
                                    {showPassword === true ?
                                    <Field
                                    id="password"
                                    name="password"
                                    // type="password"
                                    autoComplete="current-password"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Contraseña"
                                    />
                                    :
                                    <Field
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Contraseña"
                                    />
                                    }
                                    {showPassword === false ?
                                    <a className="relative bottom-8 left-72 md:left-96" onClick={()=>setShowPassword(true)}>
                                       <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off stroke-neutral-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5"  fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="3" y1="3" x2="21" y2="21" />
                                            <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                            <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                            </svg>
                                    </a>
                                    :
                                    <a className="relative bottom-8 left-72 md:left-96" onClick={()=>setShowPassword(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye stroke-neutral-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <circle cx="12" cy="12" r="2" />
                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                        </svg>
                                    </a>
                                    }
                                </div>
                            </div>

                            <div className="flex items-center justify-between mt-0">
                                <div className="flex items-center">
                                {/* <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded-xs border-gray-300 text-teal-600 focus:ring-teal-500"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Recordarme
                                </label> */}
                                </div>

                                <div className="text-sm">
                                    <a onClick={()=> setViewForm(FormMode.ResetPassword)} className="font-medium text-teal-600 hover:text-teal-800 underline">
                                    ¿Has olvidado la contraseña?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="group relative flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-teal-500 group-hover:text-teal-400" aria-hidden="true" />
                                    </span>
                                    Acceder
                                </button>
                                <Link to="/" className="group relative flex w-full justify-center items-center bg-neutral-300 hover:bg-neutral-500 text-neutral-600 hover:text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none">Volver</Link>
                                
                            </div>
                            {/* Biometric Access */}
                            <div className={`${isBiometricButtonVisible == false ? 'hidden' : 'block'} md:hidden flex w-full justify-center items-center gap-2 mt-6`}>
                                <div className="h-10 w-10 rounded-full border-2 border-teal-600 flex flex-col items-center justify-center">
                                    {/* Icono huella dactilar */}
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-auto text-teal-600" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M17.81,4.47C17.73,4.47 17.65,4.45 17.58,4.41C15.66,3.42 14,3 12,3C10.03,3 8.15,3.47 
                                        6.44,4.41C6.2,4.54 5.9,4.45 5.76,4.21C5.63,3.97 5.72,3.66 5.96,3.53C7.82,2.5 9.86,2 12,2C14.14,2 16,2.47 
                                        18.04,3.5C18.29,3.65 18.38,3.95 18.25,4.19C18.16,4.37 18,4.47 17.81,4.47M3.5,9.72C3.4,9.72 3.3,9.69 3.21,9.63C3,9.47 
                                        2.93,9.16 3.09,8.93C4.08,7.53 5.34,6.43 6.84,5.66C10,4.04 14,4.03 17.15,5.65C18.65,6.42 19.91,7.5 20.9,8.9C21.06,9.12 
                                        21,9.44 20.78,9.6C20.55,9.76 20.24,9.71 20.08,9.5C19.18,8.22 18.04,7.23 16.69,6.54C13.82,5.07 10.15,5.07 7.29,6.55C5.93,7.25 
                                        4.79,8.25 3.89,9.5C3.81,9.65 3.66,9.72 3.5,9.72M9.75,21.79C9.62,21.79 9.5,21.74 9.4,21.64C8.53,20.77 8.06,20.21 7.39,19C6.7,17.77 
                                        6.34,16.27 6.34,14.66C6.34,11.69 8.88,9.27 12,9.27C15.12,9.27 17.66,11.69 17.66,14.66A0.5,0.5 0 0,1 17.16,15.16A0.5,0.5 
                                        0 0,1 16.66,14.66C16.66,12.24 14.57,10.27 12,10.27C9.43,10.27 7.34,12.24 7.34,14.66C7.34,16.1 7.66,17.43 8.27,18.5C8.91,19.66 9.35,20.15 
                                        10.12,20.93C10.31,21.13 10.31,21.44 10.12,21.64C10,21.74 9.88,21.79 9.75,21.79M16.92,19.94C15.73,19.94 14.68,19.64 
                                        13.82,19.05C12.33,18.04 11.44,16.4 11.44,14.66A0.5,0.5 0 0,1 11.94,14.16A0.5,0.5 0 0,1 12.44,14.66C12.44,16.07 
                                        13.16,17.4 14.38,18.22C15.09,18.7 15.92,18.93 16.92,18.93C17.16,18.93 17.56,18.9 17.96,18.83C18.23,18.78 18.5,18.96 
                                        18.54,19.24C18.59,19.5 18.41,19.77 18.13,19.82C17.56,19.93 17.06,19.94 16.92,19.94M14.91,22C14.87,22 14.82,22 
                                        14.78,22C13.19,21.54 12.15,20.95 11.06,19.88C9.66,18.5 8.89,16.64 8.89,14.66C8.89,13.04 10.27,11.72 11.97,11.72C13.67,11.72 
                                        15.05,13.04 15.05,14.66C15.05,15.73 16,16.6 17.13,16.6C18.28,16.6 19.21,15.73 19.21,14.66C19.21,10.89 15.96,7.83 
                                        11.96,7.83C9.12,7.83 6.5,9.41 5.35,11.86C4.96,12.67 4.76,13.62 4.76,14.66C4.76,15.44 4.83,16.67 5.43,18.27C5.53,18.53 
                                        5.4,18.82 5.14,18.91C4.88,19 4.59,18.87 4.5,18.62C4,17.31 3.77,16 3.77,14.66C3.77,13.46 4,12.37 4.45,11.42C5.78,8.63 
                                        8.73,6.82 11.96,6.82C16.5,6.82 20.21,10.33 20.21,14.65C20.21,16.27 18.83,17.59 17.13,17.59C15.43,17.59 14.05,16.27 
                                        14.05,14.65C14.05,13.58 13.12,12.71 11.97,12.71C10.82,12.71 9.89,13.58 9.89,14.65C9.89,16.36 10.55,17.96 11.76,19.16C12.71,20.1 
                                        13.62,20.62 15.03,21C15.3,21.08 15.45,21.36 15.38,21.62C15.33,21.85 15.12,22 14.91,22Z" />
                                    </svg>
                                    {/* Icono face id */}
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-face-id w-7 h-auto stroke-teal-600" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                        <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                        <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                        <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                        <path d="M9 10l.01 0" />
                                        <path d="M15 10l.01 0" />
                                        <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                                    </svg>*/}
                                </div>
                            
                                <button type="button" onClick={checkFPIsAvailable} className="font-medium text-sm text-teal-600 hover:text-teal-800">Acceso biométrico</button>
                            </div>
                        </div> 
                        <ModalsLoginBiometric   showModalAlertTouchId={showModalAlertTouchId} showModalAccessInformation={showModalAccessInformation} showModalErrorTouchId={showModalErrorTouchId} showModalLinkedFingerprint={showModalLinkedFingerprint}  showModalNoBiometricOldAppVersion={showModalNoBiometricOldAppVersion} closeModalAlertTouchId={closeModalAlertTouchId} closeModalAccessInformation={closeModalAccessInformation} closeModalErrorTouchId={closeModalErrorTouchId} closeModalLinkedFingerprint={closeModalLinkedFingerprint} closeModalNoBiometricOldAppVersion={closeModalNoBiometricOldAppVersion} modalAccessInformationCallback={modalAccessInformationCallback} />
                        { isLoading && <Loader /> }
                       
                            
                       
                    </div>
                </div>
                
                </>
                
               }

               
               { viewForm === FormMode.NewPassword &&  
               <>  
                {/* Actualizar contraseña */}
                <div className="flex relative h-screen items-center justify-center py-12 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8 rounded-lg card bg-white py-5 px-3">
                        <div>
                            <img className="mx-auto h-12 w-auto" src="../logo-icofcv.svg"
                            alt="Ilustre Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana"/>
                            <h2 className="mt-6 text-center text-2xl font-medium tracking-tight text-gray-900 mb-3">Actualizar contraseña</h2>
                            <p className="text-sm text-neutral-500 text-center">Por favor, a continuación introduce tu nueva contraseña</p><br></br>
                            <p className="text-sm text-neutral-500 text-center">La contraseña debe tener al menos 8 caracteres una mayúscula, una minúscula, un número y tener algún carácter especial como !%&(#*).</p>
                        </div> 
                        <div className="mt-2 space-y-6"> 
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="-space-y-px ">
                                <div>
                                    <label htmlFor="new-password" className="text-sm my-2">
                                        Nueva contraseña
                                    </label>
                                    <Field
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Contraseña"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*()?&])([A-Za-z\d$@$!%*()?&]|[^ ]){8,}$"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="new-password" className="text-sm my-2">
                                     Repetir contraseña
                                    </label>
                                    <Field
                                    id="newPassword2"
                                    name="newPassword2"
                                    type="password"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Contraseña"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*()?&])([A-Za-z\d$@$!%*()?&]|[^ ]){8,}$"
                                    />
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="group relative flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-14 p-4 w-100 my-2 text-md font-medium focus:outline-none">Enviar</button>
                                <Link to="/loginview" className="group relative flex w-full justify-center items-center bg-neutral-300 hover:bg-neutral-500 text-neutral-600 hover:text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={()=> setViewForm(FormMode.Login)}>Volver</Link>
                            </div>
                            </div> 
                        { isLoading && <Loader /> }
                    </div>
                </div>
                 
                </>
                 
                }

                { viewForm === FormMode.ResetPassword &&  
                <>  
                
                {/* Recuperar contraseña */}
                <div className="flex relative h-screen items-center justify-center py-12 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8 rounded-lg card bg-white py-5 px-3">
                        <div>
                            <img className="mx-auto h-12 w-auto" src="../logo-icofcv.svg"
                            alt="Ilustre Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana"/>
                            <h2 className="mt-6 text-center text-2xl font-medium tracking-tight text-gray-900 mb-3">Recuperar contraseña</h2>
                            <p className="text-sm text-neutral-800 text-center">Ingresa tu correo electrónico a continuación y te enviaremos un mensaje para restablecer tu contraseña</p>
                        </div>
                        
                        <div className="mt-2 space-y-6">
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="-space-y-px ">
                                <div>
                                    <label htmlFor="email-address" className="text-sm my-2">
                                    Correo electrónico
                                    </label>
                                    <Field
                                    id="email-address"
                                    name="resetUsername"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2"
                                    placeholder="Correo electrónico"
                                    />
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="group relative flex w-full justify-center items-center bg-teal-700 hover:bg-teal-800 text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none">Restablecer mi contraseña</button>
                                <Link to="/loginview" className="group relative flex w-full justify-center items-center bg-neutral-300 hover:bg-neutral-500 text-neutral-600 hover:text-white rounded-lg h-12 p-4 w-100 my-2 text-md font-medium focus:outline-none" onClick={()=> setViewForm(FormMode.Login)}>Volver</Link>
                            </div>
                        </div>  
                        { isLoading && <Loader /> }
                    </div>
                </div>
                </>
                 
                }

             </Form>
            </Formik> 
         
            </div> 
           
        </div>
        
        <ModalRegister showModalRegister={showModalRegister} closeModalRegister={closeModalRegister}/> 
        </>
    
       
    )
}