import * as yup from "yup";

const isRequiredMessage = "Completa este campo";
const FILE_SIZE_50MB = 52428800;
const FILE_SIZE_10MB = 10485760;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/pdf'];
const SUPPORTED_FORMATS_PHOTO = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export default yup.object().shape({
    firstName: yup.string().required(isRequiredMessage)
    .max(20)
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras"),
    lastName: yup.string().required(isRequiredMessage)
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(30),
    dateofbirth: yup.string().required(isRequiredMessage),
    address: yup.string().required(isRequiredMessage).max(50),
    cp: yup.string()
    .required(isRequiredMessage)
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
    town: yup.string().required(isRequiredMessage)
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(20),
    province: yup.string().required(isRequiredMessage)
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(20),
    dni: yup.string()
    .required(isRequiredMessage)
    .matches(/[a-zA-Z][0-9]{7}[a-zA-Z]{1}|[0-9]{8}[a-zA-Z]{1}$/g, "Formato incorrecto")
    .min(9, 'Introduzca exactamente 9 caracteres')
    .max(9, 'Introduzca exactamente 9 caracteres'),
    sex: yup.string().required(isRequiredMessage),
    mobilephone: yup.string().required(isRequiredMessage)
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    otherphone: yup.string().required(isRequiredMessage)
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    email: yup.string().required(isRequiredMessage).email(),
    ///validacion datos profesionales
    workCenter: yup.string().max(30),
    addressCenter: yup.string().max(30),
    cpCenter:  yup.string()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(5, 'Introduzca exactamente 5 números')
    .max(5, 'Introduzca exactamente 5 números'),
    townCenter: yup.string()
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(30),
    provinceCenter: yup.string()
    .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .max(30),
    phoneCenter: yup.string()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    //validacion documentacion
    title: yup
    .mixed()
    .required(isRequiredMessage)
    .test(
      "fileSize",
      "El tamaño del fichero es demasiado grande",
      (value) => value === null || (value && value[0].size <= FILE_SIZE_50MB)
    )
    .test(
      "fileType",
      "Formato no soportado",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
    ),
    photoCara: yup
    .mixed()
    .required(isRequiredMessage)
    .test(
      "fileSize",
      "El tamaño del fichero es demasiado grande",
      (value) => value === null || (value && value[0].size <= FILE_SIZE_10MB)
    )
    .test(
      "fileType",
      "Formato no soportado",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
    ),
    photoDni: yup
    .mixed()
    .required(isRequiredMessage)
    .test(
      "fileSize",
      "El tamaño del fichero es demasiado grande",
      (value) => value === null || (value && value[0].size <= FILE_SIZE_10MB)
    )
    .test(
      "fileType",
      "Formato no soportado",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
    ),
    registration: yup
    .mixed()
    .required(isRequiredMessage)
    .test(
      "fileSize",
      "El tamaño del fichero es demasiado grande",
      (value) => value === null || (value && value[0].size <= FILE_SIZE_10MB)
    )
    .test(
      "fileType",
      "Formato no soportado",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
    ),
    ///validacion domiciliacion bancaria
    accountNumber: yup.string()
    .required(isRequiredMessage)
    .matches(/[A-Z]{2}[0-9]{22}$/g, "Formato incorrecto")
    .min(24, 'Introduzca exactamente 24 caracteres')
    .max(24, 'Introduzca exactamente 24 caracteres'),
    BicNumber: yup.string()
    .required(isRequiredMessage)
    .matches(/^[A-Z]+$/, "Introduzca solo letras mayúsculas")
    .min(8, 'Minimo 8 letras')
    .max(11, 'Maximo 11 letras'),
    //validacion checkbox
    checkbox1: yup.bool().isTrue(isRequiredMessage),
    checkbox2: yup.bool().isTrue(isRequiredMessage),
    checkbox3: yup.bool().isTrue(isRequiredMessage),
    checkbox4: yup.bool().isTrue(isRequiredMessage),
    checkbox5: yup.bool().isTrue(isRequiredMessage),
    checkbox6: yup.bool().isTrue(isRequiredMessage),
    checkbox7: yup.bool().isTrue(isRequiredMessage)
    
});


