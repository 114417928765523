import React, { useState, useRef } from "react";

export const PictureInput = ({ form, field, ...props }) => {

    const [fieldValue, setFieldValue] = useState<string | any>()

    const ref: any = useRef();

    const reset = () => {

        ref.current.value = "";
    };

    return (
        <>
            <div className="flex items-start md:items-start justify-between md:flex-row flex-col">
                <input
                    className="my-2 ml-4 flex items-center justify-center"
                    id="fil"
                    ref={ref}
                    name={field.name}
                    type="file"
                    data-accordion={props['data-accordion']}
                    accept="application/pdf, image/*, application/zip, .doc, .docm, .docx, .odt, .xlsx, .xls, .xlsb, .xlsm, .xlt, .ppt, .pptx"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const newValue = e.currentTarget.files;
                        form.setFieldValue(field.name, newValue)
                        console.log(setFieldValue(newValue))
                    }
                    }
                />
                {fieldValue && fieldValue.length !== 0 ?

                    <button onClick={reset} type="button" className="px-2.5 py-2 border border-red-500 text-red-500 bg-white text-sm flex items-center my-2 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash mr-1" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ef4444" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="4" y1="7" x2="20" y2="7" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                        <span>Borrar archivo</span>
                    </button>
                    :
                    <div></div>
                }
            </div>
        </>
    )
}