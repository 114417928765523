import { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setDetailNavigation } from '../actions/configNavigation'
import { HOME_MODULE } from '../constants/modules'
import { useStore } from 'react-redux'
import validationSchema from "../validationForm/validationSchema";
import base64Utils from '../lib/base64Utils';
import contentUtils from '../lib/contentUtils';
import { ContactGroup } from '@icofcv/common';
import { ContactGroupOption } from '@icofcv/common';
import { contactRequest } from '../api/icofcvAPI';
import { toast } from 'react-toastify';
import Loader from '../components/spinner/Loader';
import { Link } from "react-router-dom";
import { PictureInput } from '../components/advertisments/PictureInput'
import { getGroup } from '../api/drupalAPI';
import { getGroupOptions } from '../api/drupalAPI';
import { useSelector } from "react-redux";
import type { RootState } from '../store/index';
import { ModalNoLogin } from '../components/miProfile/ModalNoLogin';

interface Props {
    resetForm: () => void
}

interface FormValues {
    collegiateNumberContact: string,
    nameContact: string,
    surnamesContact: string,
    provinceContact: string,
    phoneContact: string,
    emailContact: string;
    fileUpload: null;
    subject: string;
    message: string;
    group: string;
    optionGroup: string;
    privacyPolicy: boolean;
}

export const ContactFormView = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const store = useStore();
    const session = useSelector((state: RootState) => state.session);
    const [groupsList, setGroupsList] = useState<ContactGroup[]>([]);
    const [groupOptionsList, setGroupOptionsList] = useState<ContactGroupOption[]>([]);

    const [showModalInfo, setShowModalInfo] = useState(false)

    const openModalInfo = () => setShowModalInfo(true)
    const closeModalInfo = () => setShowModalInfo(false)

    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const urlForm = 'https://www.colfisiocv.com/contacts?q=node/20062'

    const openLink = () => {
        contentUtils.openExternalUrl(urlForm)
    }

    const fetchGroupsList = async () => {
        setIsLoading(true)
        getGroup().then((response) => {
            console.log(response)
            setGroupsList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const fetchGroupOptionsList = async () => {
        setIsLoading(true)
        getGroupOptions().then((response) => {
            console.log(response)
            setGroupOptionsList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const initialValues: FormValues = {
        collegiateNumberContact: session.isLoggedIn ? session.collegiate?.memberId : "",
        nameContact: session.isLoggedIn ? session.collegiate?.firstname : "",
        surnamesContact: session.isLoggedIn ? session.collegiate?.lastname : "",
        provinceContact: "",
        phoneContact: session.isLoggedIn ? session.collegiate?.mobile : "",
        emailContact: session.isLoggedIn ? session.collegiate?.email : "",
        fileUpload: null,
        subject: "",
        message: "",
        group: "",
        optionGroup: "",
        privacyPolicy: false
    };

    const onSubmit = (values: FormValues) => {
        let images = [];
        convertImagesToBase64(values, images, () => sendEmail(values, images));
    }

    function sendEmail(values, images,) {
        setIsLoading(true)
        setTimeout(() => {
            console.log(values);
            var data = {
                collegiateNumber: values.collegiateNumberContact.toString(),
                name: values.nameContact,
                surnames: values.surnamesContact,
                province: values.provinceContact,
                phone: values.phoneContact,
                email: values.emailContact,
                files: images,
                message: values.message,
                group: values.group,
                optionGroup: values.optionGroup
            };

            contactRequest(data)
                .then((response) => {
                    toast.success('Solicitud enviada');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoading(false);
                    throw error;
                });
        }, 500);
    };

    function convertImagesToBase64(values, images, cb) {
        convertImageToBase64(values.fileUpload, images, () => {
            cb();
        });
    }

    function convertImageToBase64(files, images, cb) {
        if (!files || files.length == 0) {
            cb();
            return;
        }
        base64Utils.convertToBase64(files[0], (filename, path) => {
            var img = {
                filename: filename,
                path: path
            };
            images.push(img);
            cb();
        });
    }

    useEffect(() => {
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
        fetchGroupsList()
        fetchGroupOptionsList()
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:white w-10" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">Formulario contacto</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-100 hidden  md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Formulario contacto</span>
                    </Link>
                    <div className="mt-0">
                        <div className="w-100">
                            <div className="md:col-span-2 md:mt-0">
                                <Formik initialValues={initialValues} onSubmit={async (values, { resetForm }) => {
                                    await onSubmit(values)
                                    resetForm()
                                }} validationSchema={validationSchema} >
                                    {props => {
                                        return (
                                            <Form>
                                                <div className="overflow-hidden sm:rounded-md">
                                                    <div className="bg-white sm:p-6">
                                                        <div className="relative px-3 py-3 flex-auto overflow-y-hidden">
                                                        <h4 className="mt-4">Asunto</h4>
                                                            <div className="border-t border-gray-400 mb-4"></div>
                                                            <div className="grid md:grid-cols-2 gap-3 ">
                                                                <div className="relative z-0 w-full group mb-4">
                                                                    <label className="block mb-2 text-xs font-medium text-stone-600">Area*</label>
                                                                    <Field as='select' name="group" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                                                        <option value={""}>Seleccionar grupo</option>
                                                                        {groupsList.map((group) => (
                                                                            <option value={group.name}>{group.name}</option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="group" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-4">
                                                                    <label className="block mb-2 text-xs font-medium text-stone-600">Tema*</label>
                                                                    <Field as="select" name="optionGroup" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                                                        {props.values.group ?
                                                                            <>
                                                                                <option value={""}>Seleccionar opción</option>
                                                                                {groupOptionsList.map((groupOption) => (
                                                                                    <>
                                                                                        {groupsList.map((group) => (
                                                                                            <>
                                                                                                {props.values.group === group.name && groupOption.contactGroupId === group.id && !groupOption.private && !session.isLoggedIn ?
                                                                                                    <option value={groupOption.name}>{groupOption.name}</option>
                                                                                                    : null}
                                                                                                {props.values.group === group.name && groupOption.contactGroupId === group.id && session.isLoggedIn ?
                                                                                                    <option value={groupOption.name}>{groupOption.name}</option>
                                                                                                    : null}
                                                                                            </>
                                                                                        ))}
                                                                                    </>
                                                                                ))}
                                                                            </>
                                                                            : null}
                                                                    </Field>
                                                                    <ErrorMessage name="optionGroup" component="span" className="mt-2 text-xs text-red-600" />

                                                                    <div className="flex">
                                                                        {groupOptionsList.map((groupOption) => (
                                                                            <>
                                                                                {props.values.optionGroup && groupOption.description && props.values.optionGroup == groupOption.name ?

                                                                                    <button type="button" onClick={openModalInfo} className="flex items-center p-2 text-teal-600 text-xs underline">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle stroke-teal-600 hover:stroke-teal-800" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <circle cx="12" cy="12" r="9" />
                                                                                            <line x1="12" y1="8" x2="12.01" y2="8" />
                                                                                            <polyline points="11 12 12 12 12 16 13 16" />
                                                                                        </svg>
                                                                                        <span className="mx-1 text-left">
                                                                                            Información a exponer para el colegiado / público general
                                                                                        </span>
                                                                                    </button>
                                                                                    :
                                                                                    null}
                                                                            </>
                                                                        ))}
                                                                        {session.isLoggedIn ?
                                                                            <label className="hidden mt-2.5 text-xs text-neutral-500" >(Para visualizar más opciones de grupo, es necesario <a onClick={openModal} className="text-teal-600 hover:text-teal-800 underline">iniciar sesión</a> en la app.).</label>
                                                                            :
                                                                            <label className=" mt-2.5 text-xs text-neutral-500" >(Para visualizar más opciones de grupo, es necesario <a onClick={openModal} className="text-teal-600 hover:text-teal-800 underline">iniciar sesión</a> en la app.).</label>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h4 className="mt-2">Datos personales</h4>
                                                            <div className="border-t border-gray-400 mb-4"></div>
                                                            <div className="grid md:grid-cols-2 gap-3">
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="collegiateNumberContact" className="block mb-2 text-xs font-medium text-stone-600">Nº Colegiación</label>
                                                                    {!session.isLoggedIn ?
                                                                        <>
                                                                    <Field name="collegiateNumberContact" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="collegiateNumberContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                    </>
                                                                    :
                                                                    <Field name="collegiateNumberContact" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    }
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="nameContact" className="block mb-2 text-xs font-medium text-stone-600">Nombre*</label>
                                                                    {!session.isLoggedIn ?
                                                                        <>
                                                                    <Field name="nameContact" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="nameContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                    </>
                                                                    :
                                                                     <Field name="nameContact" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    
                                                                    }
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="surnamesContact" className="block mb-2 text-xs font-medium text-stone-600">Apellidos*</label>
                                                                    {!session.isLoggedIn ?
                                                                        <>
                                                                    <Field name="surnamesContact" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="surnamesContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                    </>
                                                                    :
                                                                    <Field name="surnamesContact" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    }
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="provinceContact" className="block mb-2 text-xs font-medium text-stone-600">Provincia*</label>
                                                                    <Field name="provinceContact" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1">
                                                                        <option value={''}>Seleccionar provincia</option>
                                                                        <option value={'Valencia'}>Valencia</option>
                                                                        <option value={'Alicante'}>Alicante</option>
                                                                        <option value={'Castellón'}>Castellón</option>
                                                                    </Field>
                                                                    <ErrorMessage name="provinceContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="phoneContact" className="block mb-2 text-xs font-medium text-stone-600">Teléfono*</label>
                                                                    {!session.isLoggedIn ?
                                                                        <>
                                                                    <Field name="phoneContact" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="phoneContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                        </>
                                                                    :
                                                                    <Field name="phoneContact" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    }
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                                <div className="relative z-0 w-full group mb-2">
                                                                    <label htmlFor="emailContact" className="block mb-2 text-xs font-medium text-stone-600">Email*</label>
                                                                    {!session.isLoggedIn ?
                                                                        <>
                                                                    <Field name="emailContact" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="emailContact" component="span" className="mt-2 text-xs text-red-600" />
                                                                    </>
                                                                    :
                                                                    <Field name="emailContact" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    }
                                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="mt-3">
                                                                    <label htmlFor="fileUpload" className="block text-xs font-medium text-stone-600">Adjuntar archivo</label>
                                                                    <Field name="fileUpload" component={PictureInput} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                    <ErrorMessage name="fileUpload" component="span" className="mt-2 text-xs text-red-600" />
                                                                    <p className="my-2 text-xs text-neutral-500">Los archivos deben ser menores que <b>10MB</b>. Tipos de archivo permitidos: <b>.gif .jpg .jpeg .png .pdf .docx .xlsx .pptx .zip</b>.</p>
                                                                </div>
                                                            </div>
                                                            <div className="relative z-0 w-full group my-3">
                                                                <label htmlFor="message" className="block mb-2 text-xs font-medium text-stone-600">Mensaje</label>
                                                                <Field as="textarea" name="message" id="message" rows={6} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                                <ErrorMessage name="message" component="span" className="mt-2 text-xs text-red-600" />
                                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                            </div>
                                                            <div className="flex flex-col items-start mb-2">
                                                                <div className="flex flex-column items-top">
                                                                    <div className="flex">
                                                                        <Field name="privacyPolicy" id="default-checkbox" type="checkbox" className="w-4 h-4 text-teal-600 bg-gray-100 rounded-sm border-gray-300" />
                                                                        <label htmlFor="privacyPolicy" className="ml-2 text-xs text-neutral-500">Acepto la <a onClick={openLink} className="text-teal-600 hover:text-teal-800 underline">política de privacidad</a>.</label>
                                                                    </div>
                                                                    <ErrorMessage name="privacyPolicy" component="span" className="mt-2 ml-6 text-xs text-red-600" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bg-white px-4 py-3 text-right sm:px-6">
                                                            {!isLoading ?

                                                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit">Enviar</button>
                                                                :
                                                                <button className="bg-teal-600 hover:bg-teal-100 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" disabled>Enviar</button>
                                                            }
                                                        </div>
                                                        {isLoading && <Loader />}
                                                    </div>
                                                </div>
                                                {showModalInfo ?
                                                    <>
                                                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 md:top-0 inset-0 z-50 outline-none focus:outline-none">
                                                            <div className="relative p-2 w-full max-w-3xl h-screen z-50 md:h-auto">
                                                                {/*content*/}
                                                                <div className="relative bg-white rounded-lg shadow">
                                                                    {/*header*/}
                                                                    <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                                                        <h3 className="text-lg font-medium">Información</h3>
                                                                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalInfo}>
                                                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                    {/*body*/}
                                                                    <div className="relative p-6 flex-auto overflow-auto modal-body modalBodyHeight">
                                                                        {groupOptionsList.map((groupOption) => (
                                                                            <>
                                                                                {props.values.optionGroup === groupOption.name ?
                                                                                    <p className="text-sm">{groupOption.description}</p>
                                                                                    : null}
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                                        </div>
                                                    </>
                                                    : null
                                                }
                                            </Form>
                                        )
                                    }}
                                </Formik>
                                <ModalNoLogin showModal={showModal} closeModal={closeModal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


