import { useState, useEffect } from 'react';
import type { RootState } from '../../store/index'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Employment } from '@icofcv/common';
import { PublicEmployment } from '@icofcv/common';
import { getItemsPerPage, getEmploymentsPaginated, getPublicEmploymentsPaginated } from '../../api/drupalAPI'
import { ModalCreateOffer } from './ModalCreateOffer';
import { useStore } from 'react-redux'
import { HOME_MODULE } from '../../constants/modules'
import { useNavigate } from "react-router-dom";
import { setDetailNavigation } from '../../actions/configNavigation'
import { format } from 'date-fns'
import Loader from '../spinner/Loader';
import { es } from 'date-fns/locale';
import dateUtils from '../../lib/dateUtils'
import jquery from 'jquery';

export const EmploymentList = () => {
    const session = useSelector((state: RootState) => state.session);
    const store = useStore();
    const navigate = useNavigate();
    let myEmploymentsCount = 0;
    const $: JQueryStatic = jquery;

    function scrollBlock() {
        $("#root").css("overflow-y", "hidden");
    }

    function scrollActive() {
        $("#root").css("overflow-y", "auto");
    }

    const [showModal, setShowModal] = useState(false);
    const openModal = () =>{ 
        setShowModal(true)
        scrollBlock()
    };
    const closeModal = () => {
        setShowModal(false)
        scrollActive()
    };

    const [showModalManage, setShowModalManage] = useState(false);
    const openModalManage = () => {
        setShowModalManage(true)
        scrollBlock()
    };
    const closeModalManage = () => {
        setShowModalManage(false)
        scrollActive()
    };

    const [showTabJob, setShowTabJob] = useState(true);
    const openTabJob = () => {
        setShowTabJob(true)
        setShowTabBenefits(false)
        setShowTabEmployment(false)

    }

    const [showTabBenefits, setShowTabBenefits] = useState(false);
    const openTabBenefits = () => {
        setShowTabBenefits(true)
        setShowTabJob(false)
        setShowTabEmployment(false)

    }

    const [showTabEmployment, setShowTabEmployment] = useState(false);
    const openTabEmployment = () => {
        setShowTabEmployment(true)
        setShowTabJob(false)
        setShowTabBenefits(false)

    }

    /// abrir y cerrar filtro 
    const [showFilter, setShowFilter] = useState(false);
    const openFilter = () => setShowFilter(true);
    const closeFilter = () => setShowFilter(false);


    ///llamada a los anuncios mas recientes con paginador
    const [employmentsList, setEmploymentsList] = useState<Employment[]>([]);
    const [publicEmploymentsList, setPublicEmploymentsList] = useState<PublicEmployment[]>([]);
    const [isLoading, setIsLoading] = useState(false)

    //filtrado
    const [employmentsSearchScope, setEmploymentsSearchScope] = useState('');
    const [employmentsSearchProvince, setEmploymentsSearchProvince] = useState('');
    const [employmentsSearchTitle, setEmploymentsSearchTitle] = useState('');
    const [searchEmployments, setSearchEmployments] = useState('');
    const [searchPublicEmployments, setSearchPublicEmployments] = useState('');

    const fetchEmploymentsList = async (filter) => {
        setIsLoading(true);
        getEmploymentsPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreEmploymentsDataDisabled(false);

                } else {
                    setViewMoreEmploymentsDataDisabled(true);
                }

                var list: Employment[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...employmentsList, ...response];
                }
                setEmploymentsList(list);
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const fetchPublicEmploymentsList = async (filter) => {
        setIsLoading(true);

        getPublicEmploymentsPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMorePublicEmploymentsDataDisabled(false);

                } else {
                    setViewMorePublicEmploymentsDataDisabled(true);
                }

                var list: PublicEmployment[] = [];
                if (filter.pagePublic == 0) {
                    list = response;
                } else {
                    list = [...publicEmploymentsList, ...response];
                }
                setPublicEmploymentsList(list);
                // setSearchEmploymentsList(list)
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }


    // funcion ver más datos  
    const [currentPageEmployments, setCurrentPageEmployments] = useState(0);
    const [viewMoreEmploymentsDataDisabled, setViewMoreEmploymentsDataDisabled] = useState(false);
    const [currentPagePublicEmployments, setCurrentPagePublicEmployments] = useState(0);
    const [viewMorePublicEmploymentsDataDisabled, setViewMorePublicEmploymentsDataDisabled] = useState(false);

    const handleShowMoreData = () => {
        setCurrentPageEmployments(currentPageEmployments + 1);

        var filter = {
            page: currentPageEmployments + 1,
            search: searchEmployments,
            searchProvince: employmentsSearchProvince,
            searchScope: employmentsSearchScope,
            searchTitle: employmentsSearchTitle

        };

        fetchEmploymentsList(filter);
        // fetchPublicEmploymentsList(filter)
    }

    //funcion cambio de filtro    
    const handleFilterChanged = (id, value) => {
        setCurrentPageEmployments(0);

        switch (id) {
            case 'scope': setEmploymentsSearchScope(value); break;
            case 'provinceType': setEmploymentsSearchProvince(value); break;
            case 'title': setEmploymentsSearchTitle(value); break;
        }
    }

    //funcion filtrado campos 
    const handleFilterButtonClicked = () => {
        const filter = {
            page: currentPageEmployments,
            search: searchEmployments,
            searchTitle: employmentsSearchTitle,
            searchProvince: employmentsSearchProvince,
            searchScope: employmentsSearchScope,
        };

        fetchEmploymentsList(filter);
    }

    //reiniciar busqueda
    const handleRestartButtonClicked = () => {
        setCurrentPageEmployments(0);
        setEmploymentsSearchScope('');
        setEmploymentsSearchProvince('');
        setEmploymentsSearchTitle('');
        const filter = {
            page: 0,
            search: searchEmployments,
            searchProvince: '',
            searchScope: '',
            searchTitle: ''
        };

        fetchEmploymentsList(filter);
    }

    const handleChangePublicEmployments = e => {
        setCurrentPagePublicEmployments(0);
        setSearchPublicEmployments(e.target.value);
        setTimeout(() => {
            const filter = {
                pagePublic: 0,
                searchPublic: e.target.value,
            };

            fetchPublicEmploymentsList(filter);
        }, 800);
    }


    ///funcion ver detalle
    function handleAddToDetail(id) {
        console.log('ID', id)
        navigate('employmentdetail?id=' + id);
    }

    function handleAddToDetailPublicEmployment(id) {
        console.log('ID', id)
        navigate('publicemploymentdetail?id=' + id);
    }

    function updateTable() {

        const filter = {
            page: 0,
            order: 'id desc'
        };

        fetchEmploymentsList(filter);
    }

    useEffect(() => {

        var filter = {
            page: 0,
            pagePublic: 0,
            searchPublic: '',
            search: '',
            searchProvince: '',
            searchScope: '',
            searchTitle: ''

        };
        store.dispatch(setDetailNavigation(HOME_MODULE, 'anuncios!'));
        fetchEmploymentsList(filter)
        fetchPublicEmploymentsList(filter)

    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Empleo</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Empleo</span>
                    </Link>
                    <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between flex-col-reverse md:flex-row gap-3">
                        <ul className="flex items-center justify-between flex-nowrap text-sm font-medium text-center icofcv-tabs" id="Tab" data-tabs-toggle="#onTabContent" role="tablist">
                            <li className="mr-2" role="presentation">
                                {showTabJob === true ?
                                    <button onClick={openTabJob} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="job-tab" data-tabs-target="#job" type="button" role="tab" aria-controls="job" aria-selected="false">Bolsa de trabajo</button>
                                    :
                                    <button onClick={openTabJob} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800 " id="job-tab" data-tabs-target="#job" type="button" role="tab" aria-controls="job" aria-selected="false">Bolsa de trabajo</button>
                                }
                            </li>
                            <li className="mr-2" role="presentation">
                                {showTabBenefits === true ?
                                    <button onClick={openTabBenefits} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="benefits-tab" data-tabs-target="#benefits" type="button" role="tab" aria-controls="benefits" aria-selected="false">Prestaciones de servicio</button>
                                    :
                                    <button onClick={openTabBenefits} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800 dark:hover:text-teal-300" id="benefits-tab" data-tabs-target="#benefits" type="button" role="tab" aria-controls="benefits" aria-selected="false">Prestaciones de servicio</button>
                                }
                            </li>

                            <li className="mr-2" role="presentation">
                                {showTabEmployment === true ?
                                    <button onClick={openTabEmployment} className="inline-flex px-2 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="employment-tab" data-tabs-target="#employment" type="button" role="tab" aria-controls="employment" aria-selected="false">Empleo público</button>
                                    :
                                    <button onClick={openTabEmployment} className="inline-flex px-2 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800 dark:hover:text-teal-300" id="employment-tab" data-tabs-target="#employment" type="button" role="tab" aria-controls="employment" aria-selected="false">Empleo público</button>
                                }
                            </li>
                        </ul>
                        {/* Botones Crear oferta y Gestionar mis ofertas: Desktop */}
                        <div className="md:flex items-center justify-center md:justify-end mx-3 md:mb-2 gap-2 hidden">
                            <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                    <line x1="12" y1="9" x2="12" y2="15" />
                                </svg>
                                Crear oferta
                            </button>
                            <button onClick={openModalManage} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit-circle mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z" />
                                    <path d="M16 5l3 3" />
                                    <path d="M9 7.07a7.002 7.002 0 0 0 1 13.93a7.002 7.002 0 0 0 6.929 -5.999" />
                                </svg>
                                Gestionar mis ofertas
                            </button>
                        </div>
                        {/* Botones Crear oferta y Gestionar mis ofertas: Phone */}
                        <div className="w-full bg-white fixed bottom-0 p-2 flex justify-around md:hidden whitespace-nowrap border-t-2 border border-neutral-300 z-10 h-20">
                            <button onClick={openModal} className="btn bg-teal-600 hover:bg-teal-800 text-white text-sm p-2 mt-2 mb-3 flex items-center h-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-white" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                    <line x1="12" y1="9" x2="12" y2="15" />
                                </svg>
                                Crear oferta
                            </button>

                            <button onClick={openModalManage} className="btn bg-teal-600 hover:bg-teal-800 text-white text-sm p-2 mt-2 mb-3 flex items-center h-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ad-2 mr-1 stroke-white" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M11.933 5h-6.933v16h13v-8" />
                                    <path d="M14 17h-5" />
                                    <path d="M9 13h5v-4h-5z" />
                                    <path d="M15 5v-2" />
                                    <path d="M18 6l2 -2" />
                                    <path d="M19 9h2" />
                                </svg>
                                Gestionar mis ofertas
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center md:items-baseline justify-start gap-2">

                        {showTabEmployment === true ?
                            <>
                                <div className="relative z-0 group mb-2 hidden">
                                    <select value={employmentsSearchScope}
                                        onChange={(e) => handleFilterChanged('scope', e.target.value)} name="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-56 p-2" data-accordion="accordion-collapse-body-1">
                                        <option value={''}>Seleccionar ámbito</option>
                                        <option value={"0"}>Nacional</option>
                                        <option value={"1"}>Internacional</option>
                                        <option value={"2"}>No definido</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                </div>
                            </>
                            :
                            <>
                                <div className="relative z-0 group mb-2 hidden md:block">
                                    <select value={employmentsSearchScope}
                                        onChange={(e) => handleFilterChanged('scope', e.target.value)} name="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-56 p-2" data-accordion="accordion-collapse-body-1">
                                        <option value={''}>Seleccionar ámbito</option>
                                        <option value={"0"}>Nacional</option>
                                        <option value={"1"}>Internacional</option>
                                        <option value={"2"}>No definido</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                </div>
                            </>
                        }
                        {showTabEmployment === true ?
                            <div className="relative z-0 group mb-2 hidden">
                                <select onChange={(e) => handleFilterChanged('provinceType', e.target.value)} value={employmentsSearchProvince} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-56 p-2">
                                    <option value={''}>Cualquier Provincia</option>
                                    <>
                                        <option value={'V'}>Valencia</option>
                                        <option value={'A'}>Alicante</option>
                                        <option value={'C'}>Castellón</option>
                                        <option value={'U'}>Comunidad Valenciana</option>
                                        <option value={"O"}>Otras provincias</option>
                                        <option value={"E"}>Extranjero</option>
                                    </>
                                </select>
                                <span className="mt-2 text-xs text-red-600"></span>
                            </div>
                            :
                            <div className="relative z-0 group mb-2 hidden md:block">
                                <select onChange={(e) => handleFilterChanged('provinceType', e.target.value)} value={employmentsSearchProvince} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-56 p-2">
                                    <option value={''}>Cualquier Provincia</option>
                                    <>
                                        <option value={'V'}>Valencia</option>
                                        <option value={'A'}>Alicante</option>
                                        <option value={'C'}>Castellón</option>
                                        <option value={'U'}>Comunidad Valenciana</option>
                                        <option value={"O"}>Otras provincias</option>
                                        <option value={"E"}>Extranjero</option>
                                    </>
                                </select>
                                <span className="mt-2 text-xs text-red-600"></span>
                            </div>
                        }
                        {showTabEmployment === false ?
                            <input
                                value={employmentsSearchTitle}
                                onChange={(e) => handleFilterChanged('title', e.target.value)}
                                type="search"
                                placeholder="Buscar..."
                                className="md:w-56 w-72 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2 mb-2"
                                aria-label="Search" />
                            :
                            <input
                                value={searchPublicEmployments}
                                onChange={handleChangePublicEmployments}
                                type="search"
                                placeholder="Buscar..."
                                className="md:w-56 w-72 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2 mb-2"
                                aria-label="Search" />
                        }

                        {showTabEmployment === false ?
                            <div className="hidden md:flex items-center gap-2 mb-2">
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleRestartButtonClicked}>Limpiar</button>
                            </div>
                            :
                            <div className="hidden flex md:hidden items-center gap-2 mb-2">
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" type="button" onClick={handleRestartButtonClicked}>Limpiar</button>
                            </div>
                        }                        

                        <div className="mb-2 flex items-center">

                            {/* Botón filtros sólo para móvil */}
                            {showTabEmployment === false ?
                                <>
                                    {showFilter === false ?
                                        <button onClick={openFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                            </svg>
                                        </button>
                                        :
                                        <button onClick={closeFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                            </svg>
                                        </button>
                                    }
                                </>
                                : null}
                        </div>
                    </div>

                    {showFilter === true && showTabEmployment === false ?
                        <>
                            <div className="md:hidden items-end justify-between grid grid-cols-1 gap-2 p-2">
                                <div className="relative z-0 w-full group mb-2">
                                    <select value={employmentsSearchScope}
                                        onChange={(e) => handleFilterChanged('scope', e.target.value)} name="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block md:w-56 w-full p-2" data-accordion="accordion-collapse-body-1">
                                        <option value={''}>Seleccionar ámbito</option>
                                        <option value={"0"}>Nacional</option>
                                        <option value={"1"}>Internacional</option>
                                        <option value={"2"}>No definido</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                </div>
                                <div className="relative z-0 w-full group mb-2">
                                    <select onChange={(e) => handleFilterChanged('provinceType', e.target.value)} value={employmentsSearchProvince} name="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block md:w-56 w-full p-2" data-accordion="accordion-collapse-body-1">
                                        <option value={''}>Seleccionar provincia</option>
                                        <option value={"A"}>Alicante</option>
                                        <option value={"C"}>Castellón</option>
                                        <option value={"v"}>Valencia</option>
                                        <option value={"O"}>Otras provincias</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                </div>
                                <div className="hidden md:flex items-center gap-2">
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleFilterButtonClicked} type="button" >Filtrar</button>
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleRestartButtonClicked} type="button" >Limpiar</button>
                                </div>
                            </div>
                            <div className="flex md:hidden items-center gap-2">
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleFilterButtonClicked} type="button">Filtrar</button>
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleRestartButtonClicked} type="button">Limpiar</button>
                            </div>
                        </>
                        : <div></div>
                    }

                    <div>
                        <p className="text-sm text-teal-800 text-left mt-2">El ICOFCV no se hace responsable de la veracidad de la información y del cumplimiento de todas las obligaciones administrativas del ofertante al ser un mero transmisor de la información aportada por el mismo. Si alguna de las ofertas de empleo no se ajusta a las condiciones que establecen, por favor informen al ICOFCV.</p>
                    </div>  

                    <div className="icofcv-ad-list-container">
                        <div id="onTabContent">
                            {/* Bolsa de trabajo */}
                            {isLoading && <Loader />}
                            {employmentsList.map((employment) => (
                                <>
                                    {showTabJob === true && employment.type === 0 && employment.status.toString() == "2" && employment.publishEndDate && employment.publishEndDate > new Date().toISOString().slice(0, -8) && employment.publishStartDate && employment.publishStartDate < new Date().toISOString().slice(0, -8) && parseInt(employment.publishStartDate) > new Date(employment.publishStartDate).getMonth() + 3 ?

                                        <div key={employment.id} className="p-0" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0 block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetail(employment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(employment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {employment.provinceType === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {employment.provinceType === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {employment.provinceType === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}
                                                            {employment.provinceType === "U" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Comunidad Valenciana</p>
                                                                : null}
                                                            {employment.provinceType === "O" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Otras provincias</p>
                                                                : null}
                                                            {employment.provinceType === "E" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Extranjero</p>
                                                                : null}
                                                            {employment.scope.toString() == "0" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: Nacional</p>
                                                                : null}
                                                            {employment.scope.toString() == "1" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: Internacional</p>
                                                                : null}
                                                            {employment.scope.toString() == "2" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: No definido</p>
                                                                : null}
                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{employment.title}</h3>
                                                            <p className="text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-2">
                                                                {employment.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(employment.id)}>VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                                </>
                            ))}

                            {/* Prestaciones de servicio */}
                            {employmentsList.map((employment) => (
                                <>
                                    {showTabBenefits === true && employment.type === 1 && employment.status.toString() == "2" && employment.publishEndDate && employment.publishEndDate > new Date().toISOString().slice(0, -8) && employment.publishStartDate && employment.publishStartDate < new Date().toISOString().slice(0, -8) && parseInt(employment.publishStartDate) > new Date(employment.publishStartDate).getMonth() + 3 ?

                                        <div className="p-0" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0 block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetail(employment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(employment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {employment.provinceType === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {employment.provinceType === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {employment.provinceType === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}
                                                            {employment.provinceType === "U" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Comunidad Valenciana</p>
                                                                : null}
                                                            {employment.provinceType === "O" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Otras provincias</p>
                                                                : null}
                                                            {employment.provinceType === "E" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Extranjero</p>
                                                                : null}
                                                            {employment.scope.toString() == "0" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: Nacional</p>
                                                                : null}
                                                            {employment.scope.toString() == "1" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: Internacional</p>
                                                                : null}
                                                            {employment.scope.toString() == "2" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Ámbito: No definido</p>
                                                                : null}
                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{employment.title}</h3>
                                                            <p className="text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-2">
                                                                {employment.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(employment.id)} >VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </>
                            ))}
                            {/* Empleo público */}
                            {publicEmploymentsList.map((publicEmployment) => (

                                <>
                                    {showTabEmployment === true && publicEmployment.visible === false && publicEmployment.publishStartDate && publicEmployment.publishStartDate < new Date().toISOString().slice(0, -8) ?

                                        <div key={publicEmployment.id} className="p-0" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0 block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetailPublicEmployment(publicEmployment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center">

                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(publicEmployment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>

                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{publicEmployment.title}</h3>
                                                            <p className="text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-2">
                                                                {publicEmployment.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetailPublicEmployment(publicEmployment.id)} >VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </>
                            ))}

                            {employmentsList.length == 0 ?
                                <div className="my-4">
                                    <div className="flex flex-col items-center py-24">
                                        <div className="mx-auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folder-plus" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="#9ca3af" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                                                <line x1="12" y1="10" x2="12" y2="16" />
                                                <line x1="9" y1="13" x2="15" y2="13" />
                                            </svg>
                                        </div>
                                        <h3 className="font-medium">No hay ofertas</h3>
                                        <p className="text-neutral-500 text-sm">Para empezar, cree una nueva oferta de trabajo</p>
                                        <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="12" cy="12" r="9" />
                                                <line x1="9" y1="12" x2="15" y2="12" />
                                                <line x1="12" y1="9" x2="12" y2="15" />
                                            </svg>
                                            Crear oferta de trabajo
                                        </button>
                                    </div>
                                </div>
                                :
                                null}
                                   
                                   <div className="p-3 lg:px-6 border-t border-gray-100">
                                        {viewMoreEmploymentsDataDisabled === false ?
                                            <div className="flex items-center justify-center mx-3">
                                                <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                   </div>
                        </div>
                    </div>

                    {/* Modal gestion empleos */}
                    {showModalManage ? (
                        <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed top-0 inset-0 z-50 outline-none focus:outline-none p-0">
                                <div className="relative p-2 w-screen md:w-full max-w-3xl h-full md:h-auto">
                                    {/*content*/}
                                    <div className="relative top-2 bg-white rounded-lg shadow">
                                        {/*header*/}
                                        <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                            <h3 className="text-lg font-medium">Gestionar ofertas de trabajo</h3>
                                            <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalManage}>
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="relative px-3 py-3 flex-auto overflow-y-auto modal-body">
                                            {isLoading && <Loader />}
                                            {employmentsList.map((employment) => (
                                                <>
                                                    {employment.creatorUsername === session.collegiate?.email ?
                                                        <div key={employment.id} className="bg-white px-2 py-3 md:h-33 border-b rounded-0">
                                                            <span className="hidden">{++myEmploymentsCount}</span>
                                                            <button type="button" className="text-left overflow-hidden pb-2" onClick={() => handleAddToDetail(employment.id)}>
                                                                <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                                                    <div className="w-100 ml-2 h-18">
                                                                        <div className="flex items-center justify-between">
                                                                            <small className="text-teal-600">
                                                                                {dateUtils.utcToDate(employment.publishStartDate)}

                                                                            </small>
                                                                            <div className="flex">
                                                                                {employment.status === 1 ?
                                                                                    <div className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Pendiente</div>
                                                                                    : null}
                                                                                {employment.status === 2 && employment.publishEndDate && employment.publishEndDate > new Date().toISOString().slice(0, -8) && employment.publishStartDate && employment.publishStartDate < new Date().toISOString().slice(0, -8) ?
                                                                                    <div className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Publicado</div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {employment.status == 2 && employment.publishEndDate && employment.publishEndDate < new Date().toISOString().slice(0, -8) || employment.publishStartDate && employment.publishStartDate > new Date().toISOString().slice(0, -8) && employment.status !== 1 && employment.status !== 3 && employment.status !== 4 ?
                                                                                    <div className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">No publicado / Despublicado</div>
                                                                                    : null}
                                                                                {employment.status === 3 ?
                                                                                    <div className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Rechazado</div>
                                                                                    : null}
                                                                                {employment.status === 4 ?
                                                                                    <div className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Retirado</div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>

                                                                        {employment.provinceType === "V" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Valencia</p>
                                                                            : null}
                                                                        {employment.provinceType === "A" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Alicante</p>
                                                                            : null}
                                                                        {employment.provinceType === "C" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Castellón</p>
                                                                            : null}
                                                                        {employment.provinceType === "U" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Comunidad Valenciana</p>
                                                                            : null}
                                                                        {employment.provinceType === "E" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Extranjero</p>
                                                                            : null}
                                                                        {employment.provinceType === "O" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Otras provincias</p>
                                                                            : null}
                                                                        <p className="text-lg text-neutral-800 font-medium news-title">{employment.title}</p>
                                                                        <p className="text-neutral-600 text-xs font-normal ">{employment.description}</p>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                            <div className="flex justify-end mx-3">
                                                                <button onClick={() => handleAddToDetail(employment.id)} className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button">VER MÁS</button>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div></div>
                                                    }

                                                </>
                                            ))}
                                            {myEmploymentsCount === 0 ?
                                                <div className="flex flex-col items-center p-2">
                                                    <div className="mx-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folder-plus" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#9ca3af" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                                                            <line x1="12" y1="10" x2="12" y2="16" />
                                                            <line x1="9" y1="13" x2="15" y2="13" />
                                                        </svg>
                                                    </div>
                                                    <h3 className="font-medium">No hay ofertas</h3>
                                                    <p className="text-neutral-500 text-sm">Para empezar, crea una nueva oferta de trabajo</p>
                                                    <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <circle cx="12" cy="12" r="9" />
                                                            <line x1="9" y1="12" x2="15" y2="12" />
                                                            <line x1="12" y1="9" x2="12" y2="15" />
                                                        </svg>
                                                        Crear oferta de trabajo
                                                    </button>
                                                </div>
                                                :
                                                null}
                                                 
                                                     </div>
                                        {/*footer*/}
                                        <div className="flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalManage}>Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        
                                {viewMoreEmploymentsDataDisabled === false ?
                                    <div className="flex items-center justify-end mx-3">
                                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                                    </div>
                                    :
                                    <div></div>
                                }
                        </>
                    ) : null}
                </div>
            </div>
            <ModalCreateOffer showModal={showModal} closeModal={closeModal} updateTable={updateTable} />
        </>
    )
}