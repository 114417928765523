const utcToDateTime = (value) => {
    return !value ? '' : new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString();
  }
  const utcToDate = (value) => {
    return !value ? '' : new Date(value).toLocaleDateString();
  }
  const utcToIsoDate = (value) => {
    return !value ? '' : new Date(value).toISOString().split('T')[0];
  }
  const isoDateToIsoDateTime = (value) => {
    if (!value) {
        return new Date().toISOString();
    }
    return new Date(value).toISOString();
  }
  
  export default {
    utcToDateTime,
    utcToDate,
    utcToIsoDate,
    isoDateToIsoDateTime
  }