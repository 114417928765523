import { useEffect, useState } from 'react';
import appConfig from '../../config/app-config.json';
import {v4 as uuidv4} from 'uuid';

export const PaymentAccepted = () => {

	useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search);
		const sessionId = queryParameters.get("session_id");
        const courseId = queryParameters.get("course_id");

		window.close();
		
		if (window.opener == null) {
			window.location.replace(`${appConfig.webSignoutUri}/college/formationlist/formationdetail?id=${courseId}&payment_result=ok&uuid=${uuidv4()}&session_id=${sessionId}`);
		} else {
			window.opener.postMessage(`STRIPE_COURSE_PAYMENT_SUCCEEDED:${sessionId}`, '*');
		}
	}, [])

	return (
		<>
		</>
	)
}