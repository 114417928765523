import { useState } from 'react'
import { ModalLogout } from './ModalLogout';
import cognitoUtils from '../../lib/cognitoUtils';
import { useNavigate } from "react-router-dom";
import { clearSession } from '../../actions/session';
import { useDispatch } from "react-redux";
import jquery from 'jquery';

export function LogoutCollegiate() {

    const $: JQueryStatic = jquery;
    function scrollBlock() {
        $("#root").css("overflow-y", "hidden");
    }
    function scrollActive() {
        $("#root").css("overflow-y", "auto");
    }    

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const openModal = () => {
        setShowModal(true);
        scrollBlock();
    }
    const closeModal = () => {
        setShowModal(false);
        scrollActive();
    }
    
    const logoutSession = () => {
        setShowModal(false);
        scrollActive();
        cognitoUtils.signOutCognitoSession();
        dispatch(clearSession());
        navigate('/');
    }

    return (
        <>
            <button className="bg-white text-gray-800 hover:text-teal-800 flex items-center justify-between rounded-lg h-14 p-4 w-100 md:w-50 border border-gray-400" type="button" onClick={openModal}>
                <div className="flex items-center">
                    <span className="font-medium">Cerrar sesión</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right stroke-gray-800 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9ca3af" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                </svg>
            </button>
            <ModalLogout showModal={showModal} closeModal={closeModal} logoutSession={logoutSession} />
        </>
    )
}