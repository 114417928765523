import store from '../store'
import { SET_STRIPE_PAYMENT_EVENT } from '../constants/actionTypes'

export default {
    init() {
        window.addEventListener("message", this.processCordovaWrapperMessage, false);
    },
    processCordovaWrapperMessage(evt)
    {
        var message = evt.data;
	
        if (!message.indexOf) return;

        console.log("*** Mensaje recibido: " + message);

        if (message.indexOf("PROCESS_URL:") == 0) {
            //const url = message.substring(13);
            if(message.indexOf("/college/formationlist/formationdetail?id=")) {
                const event = {
                    type: 'course',
                    sessionId: message.indexOf("&session_id=") != -1 ? message.split("&session_id=")[1] : null
                };

                store.dispatch({ type: SET_STRIPE_PAYMENT_EVENT, stripePaymentEvent: event });                
            }
        }

        if (message.indexOf("STRIPE_COURSE_PAYMENT_SUCCEEDED") == 0) {
            const event = {
                type: 'course',
                sessionId: message.split(":")[1]
            };

            store.dispatch({ type: SET_STRIPE_PAYMENT_EVENT, stripePaymentEvent: event });
        }
 
        if (message.indexOf("STRIPE_COURSE_PAYMENT_CANCELED") == 0) {
            const event = {
							type: 'course',
							sessionId: null
						};
						
            store.dispatch({ type: SET_STRIPE_PAYMENT_EVENT, stripePaymentEvent: event });
        }
		}
    
}
