import { SELECT_YOUTUBE } from '../constants/actionTypes'
import {getItem} from '../lib/localStorage'


const selectedYoutube = (state = null || getItem('youtube'), action) => {
  switch (action.type) {
    case SELECT_YOUTUBE:
      return Object.assign({},
        action.selectedYoutube)

    default:
      return state
  }
}

export default selectedYoutube;