import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { getClinic } from '../../api/drupalAPI'
import { Clinic, CollectionItem } from '@icofcv/common';
import { getClinicCollections } from '../../api/icofcvAPI';
import contentUtils from '../../lib/contentUtils'
import Loader from '../spinner/Loader';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import iconclinic from '../../assets/images/icono6.png'
import iconGoogle from '../../assets/images/pin1.png'
import Places from "./Places";
import config from '../../config/app-config.json'

interface Props {
    showModalLocator: boolean,
    closeModalLocator: () => void
}
type LatLngLiteral = google.maps.LatLngLiteral;

export const ClinicLocator: React.FC<Props> = ({ children, showModalLocator, closeModalLocator }) => {

    const [clinicList, setClinicList] = useState<Clinic[]>([]);
    const [clinicListFiltered, setClinicListFiltered] = useState<Clinic[]>([]);
    const [clinicSearchConcerts, setClinicSearchConcerts] = useState<CollectionItem[]>([]);
    const [clinicSearchConcertSelected, setClinicSearchConcertSelected] = useState(1);
    const [clinicSearchConcertMutuaSelected, setClinicSearchConcertMutuaSelected] = useState(2);
    const [clinicSearchConcertHealtSelected, setClinicSearchConcertHealtSelected] = useState(3);
    const [currentLatitude, setCurrentLatitude] = useState(39.4697500)
    const [currentLongitude, setCurrentLongitude] = useState(-0.3773900)
    const [direction, setDirection] = useState<LatLngLiteral>();
    const mapRef = useRef<GoogleMap>();

    //lista clinicas con nombres de los campos
    const [clinicFields, setClinicFields] = useState<CollectionItem[]>([]);
    const [clinicTreatments, setClinicTreatments] = useState<CollectionItem[]>([]);
    const [clinicConcerts, setClinicConcerts] = useState<CollectionItem[]>([]);
    const [clinicOthers, setClinicOthers] = useState<CollectionItem[]>([]);

    const fetchClinicList = async () => {
        getClinicCollectionsInfo();
    }

    function getClinicCollectionsInfo() {
        getClinicCollections()
            .then((response) => {
                //alert(JSON.stringify(response));
                setClinicSearchConcerts(response.featureFilters);
                fetchClinics()
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    const fetchClinics = async () => {
        //setIsLoading(true)
        getClinic()
            .then((response) => {
                console.log(response)
                setClinicList(response);
                setClinicListFiltered(response)
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    const fetchCollections = async () => {
        getClinicCollections()
            .then((response) => {
                console.log(response);
                setClinicFields(response.physioFields);
                setClinicTreatments(response.clinicTypes);
                setClinicConcerts(response.featureFilters);
                setClinicOthers(response.others);
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    ///funcion nombre de los campos
    function getType(type) {
        if (!type) {
            return '';
        }
        var field: number = parseInt(type);

        for (var i = 0; i < clinicTreatments.length; i++) {
            if (clinicTreatments[i].id == field) {
                return clinicTreatments[i].name;
            }
        }
    }

    function getPhysiotherapyFields(physiotherapyFields) {
        if (!physiotherapyFields) {
            return '';
        }
        var fields: number[] = physiotherapyFields.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicFields.length; i++) {
            if (fields.includes(clinicFields[i].id)) {
                names.push(clinicFields[i].name);
            }
        }

        return names.join(', ');
    }

    function getOthers(others) {
        if (!others) {
            return '';
        }
        var fields: number[] = others.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicOthers.length; i++) {
            if (fields.includes(clinicOthers[i].id)) {
                names.push(clinicOthers[i].name);
            }
        }

        return names.join(', ');
    }

    function getFeatureFilters(features) {
        if (!features) {
            return '';
        }
        var fields: number[] = features.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicConcerts.length; i++) {
            if (fields.includes(clinicConcerts[i].id)) {
                names.push(clinicConcerts[i].name);
            }
        }

        return names.join(', ');
    }

    ////cargar mapa
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${config.googlemapKey}`,
        libraries: ["places"]
    })

    const [map, setMap] = React.useState(null)

    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    ///tamaño mapa
    const containerStyle = {
        width: "100%",
        height: "100%"
    };

    ///centro al cargar mapa (geolocalizacion usuario)
    const center = useMemo<LatLngLiteral>(() => ({ lat: currentLatitude, lng: currentLongitude }), [currentLatitude, currentLongitude])

    ///Activar infoWindow al pulsar marker
    const [activeMarker, setActiveMarker] = useState(null)

    const divStyle = {
        background: `white`,
        border: `1px solid #ccc`,
        padding: 15
    }

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const aumentarZoom = () => {
        setZoom(15)
    }

    ////filtrar clinicas checkbox
    const [selectNum, setSelectNum] = useState({
        1: false,

    })

    const [selectNum1, setSelectNum1] = useState({
        2: false,

    })

    const [selectNum2, setSelectNum2] = useState({
        3: false,

    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        setSelectNum({
            ...selectNum,
            [e.target.value]: e.target.checked

        });

        if (e.target.checked) {

            var resultSearch = clinicList.filter((element) => {

                var feature: number[] = [];
                if (element.featureFilterList) {
                    feature = element.featureFilterList?.split(',').map(x => parseInt(x));
                }
                var isOk =
                    (feature.includes(clinicSearchConcertSelected))
                if (isOk) {
                    return element;
                }

            });

            setClinicListFiltered(resultSearch);

        } else {

            setClinicListFiltered(clinicList);
        }

    };

    const handleChangemutua = (e: React.ChangeEvent<HTMLInputElement>) => {

        setSelectNum1({
            ...selectNum1,
            [e.target.value]: e.target.checked

        });

        if (e.target.checked) {

            var resultSearch = clinicList.filter((element) => {

                var feature: number[] = [];
                if (element.featureFilterList) {
                    feature = element.featureFilterList?.split(',').map(x => parseInt(x));
                }
                var isOk =
                    (feature.includes(clinicSearchConcertMutuaSelected))
                if (isOk) {
                    return element;
                }

            });

            setClinicListFiltered(resultSearch);

        } else {

            setClinicListFiltered(clinicList);
        }

    };

    const handleChangehealt = (e: React.ChangeEvent<HTMLInputElement>) => {

        setSelectNum2({
            ...selectNum2,
            [e.target.value]: e.target.checked

        });

        if (e.target.checked) {

            var resultSearch = clinicList.filter((element) => {

                var feature: number[] = [];
                if (element.featureFilterList) {
                    feature = element.featureFilterList?.split(',').map(x => parseInt(x));
                }
                var isOk =
                    (feature.includes(clinicSearchConcertHealtSelected))
                if (isOk) {
                    return element;
                }

            });

            setClinicListFiltered(resultSearch);

        } else {

            setClinicListFiltered(clinicList)
        }

    };

    function ensureOneMarkerIsVisible() {
        /* @ts-ignore::disable-next-line */
        if (mapRef.current?.getBounds) {
            console.log("ensureOneMarkerIsVisible:");
            /* @ts-ignore::disable-next-line */
            let bounds = mapRef.current?.getBounds();

            let markerVisible = false;
            for (let clinic of clinicListFiltered) {

                if (!clinic.latitude || !clinic.longitude) {
                    continue;
                }

                if (bounds.contains(new google.maps.LatLng(clinic.latitude, clinic.longitude))) {
                    markerVisible = true;
                    break;
                }
            }

            console.log("Marker visible:" + markerVisible);
            if (!markerVisible) {
                /* @ts-ignore::disable-next-line */
                setZoom(mapRef.current?.getZoom() - 1);
            }
            else {
                orderByDistanceDirection();
                setClinicListFiltered(clinicListFiltered);
            }
        }

    }

    //Función auxiliar, calcula la distancia entre dos puntos por medio de la función de  
    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {

        var R = 6371; // radio de la tierraa km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad 
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distancia en km

        return d;

    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)

    }

    ///funcion ordena clinicas por distancia mas cercana a la geolocalizacion
    function orderByDistance() {
        clinicListFiltered.map(clinic => (
            clinic.distanceClinics = getDistanceFromLatLonInKm(currentLatitude, currentLongitude, clinic.latitude, clinic.longitude)
        ))

        clinicListFiltered.sort(function (a, b) {
            if (a.distanceClinics < b.distanceClinics)
                return -1;
            if (a.distanceClinics > b.distanceClinics)
                return 1;
            return 0;
        })

        console.log('clinicListFilter:', clinicListFiltered)
    }

    ///funcion ordena clinicas por distancia mas cercana segun busqueda
    function orderByDistanceDirection() {
        clinicListFiltered.map(clinic => (
            clinic.distanceClinicsDirection = getDistanceFromLatLonInKm(direction && direction.lat, direction && direction.lng, clinic.latitude, clinic.longitude)
        ))

        clinicListFiltered.sort(function (a, b) {
            if (a.distanceClinicsDirection < b.distanceClinicsDirection)
                return -1;
            if (a.distanceClinicsDirection > b.distanceClinicsDirection)
                return 1;
            return 0;
        })
    }

    //// zoom inicial
    const [zoom, setZoom] = useState(15)

    useEffect(() => {
        if (!navigator.geolocation) {

            setCurrentLatitude(39.4697500)
            setCurrentLongitude(-0.3773900)

        } else {

            navigator.geolocation.getCurrentPosition((position) => {
                console.log(position)
                setCurrentLatitude(position.coords.latitude)
                setCurrentLongitude(position.coords.longitude)
            })
        }

        fetchClinicList();
        fetchCollections();
    }, []);


    return (
        <>
            <div>
                {showModalLocator ? (
                    <>
                        <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative p-2 w-full max-w-5xl h-full md:h-auto">
                                {/*content*/}
                                <div className="relative top-9 md:top-0 bg-white rounded-lg shadow">
                                    {/*header*/}
                                    <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                        <h3 className="text-lg font-medium">Localizador de clinicas</h3>
                                        <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalLocator}>
                                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative px-3 py-3 flex-auto overflow-y-auto modal-body">
                                        <h2 className="text-sm font-medium mb-2">¿Dónde te encuentras?</h2>
                                        <Places setDirection={(position) => {
                                            setDirection(position);
                                            ///zoom cuando hay busqueda
                                            setZoom(10);
                                            mapRef.current?.panTo(position)
                                        }} />

                                        <div className="py-3 border-b flex flex-col md:flex-row items-start md:items-center md:justify-between justify-start gap-2">
                                            <div className="flex items-center justify-start flex-wrap">
                                                {clinicSearchConcerts.slice(0, 1).map(fields => (
                                                    <>
                                                        <label key={fields.id} className="inline-flex relative items-center mr-5 cursor-pointer my-2">
                                                            <input
                                                                className="sr-only peer"
                                                                type="checkbox"
                                                                onChange={handleChange}
                                                                value={fields.id}
                                                            />
                                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-teal-500 
                                                                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                                 after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                                                            <span className="ml-2 text-xs font-medium text-gray-900">{fields.name}</span>
                                                        </label>
                                                    </>
                                                ))}
                                                {clinicSearchConcerts.slice(1, 2).map(fields => (
                                                    <>
                                                        <label key={fields.id} className="inline-flex relative items-center mr-5 cursor-pointer">
                                                            <input
                                                                className="sr-only peer"
                                                                type="checkbox"
                                                                onChange={handleChangemutua}
                                                                value={fields.id}
                                                            />
                                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-teal-500 
                                                                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                                 after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                                                            <span className="ml-2 text-xs font-medium text-gray-900">{fields.name}</span>
                                                        </label>
                                                    </>
                                                ))}
                                                {clinicSearchConcerts.slice(2, 3).map(fields => (
                                                    <>
                                                        <label key={fields.id} className="inline-flex relative items-center mr-5 cursor-pointer">
                                                            <input
                                                                className="sr-only peer"
                                                                type="checkbox"
                                                                onChange={handleChangehealt}
                                                                value={fields.id}
                                                            />
                                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-teal-500 
                                                                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                                 after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-600"></div>
                                                            <span className="ml-2 text-xs font-medium text-gray-900">{fields.name}</span>
                                                        </label>
                                                    </>
                                                ))}
                                            </div>

                                        </div>
                                        <div>
                                            <h2 className="text-sm font-medium my-3">Resultados</h2>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div className="w-100 h-52 md:h-96">
                                                    {isLoaded && (
                                                        <GoogleMap
                                                            zoom={zoom}
                                                            mapContainerStyle={containerStyle}

                                                            center={center}
                                                            onLoad={onLoad}
                                                            onUnmount={onUnmount}
                                                            onIdle={ensureOneMarkerIsVisible}
                                                        >
                                                            <Marker position={center} options={{ icon: iconGoogle }} />

                                                            {direction && orderByDistanceDirection()}

                                                            {
                                                                clinicListFiltered.map((clinic) => (
                                                                    <>
                                                                        <React.Fragment key={clinic.id} >
                                                                            <Marker key={clinic.latitude} position={{ lat: clinic.latitude ? clinic.latitude : -1, lng: clinic.longitude ? clinic.longitude : -1 }} onClick={() => { handleActiveMarker(clinic.id); aumentarZoom() }} options={{ icon: iconclinic }}>

                                                                                {activeMarker === clinic.id ? (
                                                                                    <InfoWindow
                                                                                        position={{ lat: clinic.latitude ? clinic.latitude : -1, lng: clinic.longitude ? clinic.longitude : -1 }}
                                                                                        onCloseClick={() => setActiveMarker(null)}
                                                                                    >
                                                                                        <div style={divStyle}>
                                                                                            <h1>{clinic.title}</h1>
                                                                                            <p>{clinic.address}</p>
                                                                                            <p>{clinic.phone}</p>
                                                                                            <p>{clinic.email}</p>
                                                                                        </div>
                                                                                    </InfoWindow>
                                                                                ) : null}
                                                                            </Marker>
                                                                        </React.Fragment>
                                                                    </>
                                                                ))}

                                                            {!direction && orderByDistance()}

                                                            {!direction &&
                                                                clinicListFiltered.map((clinic) => (
                                                                    <>
                                                                        <Marker key={clinic.latitude} position={{ lat: clinic.latitude ? clinic.latitude : -1, lng: clinic.longitude ? clinic.longitude : -1 }} onClick={() => { handleActiveMarker(clinic.id); aumentarZoom() }} options={{ icon: iconclinic }}>

                                                                            {activeMarker === clinic.id ? (
                                                                                <InfoWindow
                                                                                    position={{ lat: clinic.latitude ? clinic.latitude : -1, lng: clinic.longitude ? clinic.longitude : -1 }}
                                                                                    onCloseClick={() => setActiveMarker(null)}
                                                                                >
                                                                                    <div style={divStyle}>
                                                                                        <h1>{clinic.title}</h1>
                                                                                        <p>{clinic.address}</p>
                                                                                        <p>{clinic.phone}</p>
                                                                                        <p>{clinic.email}</p>
                                                                                    </div>
                                                                                </InfoWindow>
                                                                            ) : null}
                                                                        </Marker>
                                                                    </>
                                                                ))}

                                                            {direction && (
                                                                <Marker position={direction} />
                                                            )}
                                                        </GoogleMap>
                                                    )}
                                                </div>
                                                <div className="overflow-auto max-h-96">

                                                    {direction && orderByDistanceDirection()}

                                                    {
                                                        clinicListFiltered.map((clinic) => (
                                                            <>
                                                                {direction && clinic.distanceClinicsDirection ?
                                                                    <div key={clinic.id} className="card bg-white px-2 py-3 h-62 md:h-50 mb-3">
                                                                        <button type="button" className="text-left">

                                                                            <div className=""><span>{clinic.distanceClinicsDirection.toFixed()}Km</span></div>
                                                                            <div className="flex items-center gap-2 md:gap-4 md:gap-4">
                                                                                <img className="h-24 w-2/5 min-w-40 object-container object-center rounded-lg" src={contentUtils.getLargeImageUrl(clinic.logo)} alt="#" />
                                                                                <div className="w-3/5">
                                                                                    <div className="text-md font-medium leading-5 clinic-title uppercase">{clinic.title}</div>
                                                                                    <div className="flex items-center gap-2">
                                                                                        <div className="text-neutral-500 text-sm">{clinic.propsPhone}</div>
                                                                                        <div className="text-neutral-500 text-sm">{clinic.mobile}</div>
                                                                                    </div>
                                                                                    <div className="text-teal-600 text-sm underline clinic-mail">{clinic.email}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getType(clinic.type)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getPhysiotherapyFields(clinic.physiotherapyFields)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getOthers(clinic.others)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getFeatureFilters(clinic.featureFilterList)}</div>
                                                                                    <div className="text-neutral-500 text-sm">{clinic.registry}</div>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    : null
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                    {!direction && orderByDistance()}

                                                    {
                                                        clinicListFiltered.map(clinic => (
                                                            <>
                                                                {!direction && clinic.distanceClinics ?
                                                                    <div key={clinic.id} className="card bg-white px-2 py-3 h-62 md:h-50 mb-3">
                                                                        <button type="button" className="text-left">
                                                                            <div className=""><span>{clinic.distanceClinics.toFixed()}Km</span></div>
                                                                            <div className="flex items-center gap-2 md:gap-4 md:gap-4">
                                                                                <img className="h-24 w-2/5 min-w-40 object-container object-center rounded-lg" src={contentUtils.getLargeImageUrl(clinic.logo)} alt="#" />
                                                                                <div className="w-3/5">
                                                                                    <div className="text-md font-medium leading-5 clinic-title uppercase">{clinic.title}</div>
                                                                                    <div className="flex items-currentLatitude, currentLongitude, clinic.latitude, clinic.longitudecenter gap-2">
                                                                                        <div className="text-neutral-500 text-sm">{clinic.propsPhone}</div>
                                                                                        <div className="text-neutral-500 text-sm">{clinic.mobile}</div>
                                                                                    </div>
                                                                                    <div className="text-teal-600 text-sm underline clinic-mail">{clinic.email}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getType(clinic.type)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getPhysiotherapyFields(clinic.physiotherapyFields)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getOthers(clinic.others)}</div>
                                                                                    <div className="text-neutral-500 text-xs">{getFeatureFilters(clinic.featureFilterList)}</div>
                                                                                    <div className="text-neutral-500 text-sm">{clinic.registry}</div>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    : null
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                        <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none focus:ring-teal-500 focus:border-teal-500" type="button" onClick={closeModalLocator}>Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </div>
        </>
    )
}