import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from './validationSchema'
import { createAccountRequest } from '../../api/icofcvAPI';
import { toast } from 'react-toastify';
import Loader from '../spinner/Loader';
import ScrollLock from 'react-scrolllock'

interface Props {
    showModalRegister: boolean,
    closeModalRegister: () => void
}

interface FormValues {
    //validacion datos personales
    numberCollegiate: string;
    email: string;
    phone: string;
    dni: String;
}

export const ModalRegister: React.FC<Props> = ({ children, showModalRegister, closeModalRegister }) => {

    const [isLoading, setIsLoading] = useState(false)

    const initialValues: FormValues = {
        //validacion datos personales
        numberCollegiate: "",
        phone: "",
        email: "",
        dni: "",
    };

    const onSubmit = (values: FormValues) => {
        setIsLoading(true)
        setTimeout(() => {
            console.log(values)
            var data = {
                memberId: Number(values.numberCollegiate),
                phone: values.phone,
                email: values.email,
                fiscalId: values.dni
            };
            createAccountRequest(data)
                .then((response) => {
                    if (response.code == '0') {

                        closeModalRegister();
                        toast.success('Cuenta de usuario creada correctamente. Se ha enviado un correo electrónico con los datos de acceso');
                    } else if (response.code == '1000') {

                        toast.error('No se ha encontrado ningún colegiado');
                    } else if (response.code == '1001') {
                        toast.error(`La identidad ya existe para ${data.email}`);
                    } else if (response.code == '1003') {
                        toast.error(`No se puede crear una cuenta para ${data.email}`);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    throw error;
                });
        }, 500);
    };

    return (
        <>
            {showModalRegister ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-20 md:top-0 inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative p-2 w-full max-w-md h-full md:h-auto">
                            {/*content*/}
                            <div className="relative bg-white rounded-lg shadow">
                                {/*header*/}
                                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                    <h3 className="text-lg font-medium">Solicitar acceso</h3>
                                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalRegister}>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                {/*body*/}
                                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
                                    <Form>
                                        <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                                            <div className="relative z-0 w-full group my-2">
                                                <label htmlFor="dni" className="block mb-2 text-xs font-medium text-stone-600">DNI*</label>
                                                <Field name="dni" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                <ErrorMessage name="dni" component="span" className="mt-2 text-xs text-red-600" />
                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                            </div>
                                            <div className="relative z-0 w-full group my-2">
                                                <label htmlFor="numberCollegiate" className="block mb-2 text-xs font-medium text-stone-600">Número de colegiado*</label>
                                                <Field name="numberCollegiate" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                <ErrorMessage name="numberCollegiate" component="span" className="mt-2 text-xs text-red-600" />
                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                            </div>
                                            <div className="relative z-0 w-full group my-2">
                                                <label htmlFor="email" className="block mb-2 text-xs font-medium text-stone-600">Email*</label>
                                                <Field name="email" type="email" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                <ErrorMessage name="email" component="span" className="mt-2 text-xs text-red-600" />
                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                            </div>
                                            <div className="relative z-0 w-full group my-2">
                                                <label htmlFor="phone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono*</label>
                                                <Field name="phone" type="tel" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                <ErrorMessage name="phone" component="span" className="mt-2 text-xs text-red-600" />
                                                <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalRegister}>Cancelar</button>
                                            {!isLoading ?
                                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit"  >Aceptar</button>
                                                :
                                                <button className="bg-teal-600 hover:bg-teal-100 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit" disabled >Aceptar</button>
                                            }
                                        </div>
                                    </Form>
                                </Formik>
                                {isLoading && <Loader />}
                            </div>
                        </div>
                         <ScrollLock />
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}