
import {Guide} from '@icofcv/common';
import { SELECT_GUIDES } from '../constants/actionTypes'

const selectedGuides = (state = null, action) => {
  switch (action.type) {
    case SELECT_GUIDES:
      return Object.assign({},
        action.selectedGuides)

    default:
      return state
  }
}

export default selectedGuides;