import { SELECT_CLINICS_INFO } from '../constants/actionTypes'

const selectedClinicsInfo = (state = null, action) => {
  switch (action.type) {
    case SELECT_CLINICS_INFO:
      return Object.assign({},
        action.selectedClinicsInfo)

    default:
      return state
  }
}

export default selectedClinicsInfo;