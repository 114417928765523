import React from 'react'

interface Props {
  showModalPaymentCancelled: boolean,
  closeModalPaymentCancelled: () => void,
}

export const ModalPaymentCancelled: React.FC<Props> = ({ showModalPaymentCancelled, closeModalPaymentCancelled }) => {

  return (
    <>
      {showModalPaymentCancelled ? (
        <>
          <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none p-0">
            <div className="relative p-2 w-full max-w-xl h-screen z-50 md:h-auto">
              {/*content*/}
              <div className="relative bg-white rounded-lg shadow">
                {/*header*/}
                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                  <h3 className="text-lg font-medium">Pago cancelado</h3>
                  <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalPaymentCancelled}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                  <p className="text-sm p-3 text-center text-neutral-600">Lamentamos informarle que su pago ha sido cancelado. Hemos detectado que durante el proceso de transacción no se ha completado exitosamente el pago en nuestra aplicación.</p>
                </div>
                {/* footer */}
                <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                  <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalPaymentCancelled}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}