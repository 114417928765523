import React , { useState, useEffect } from 'react';
import { App_Name } from '@icofcv/common';
import store from '../../store'
import './App.css';
import {
    BrowserRouter as Router, Route,Routes
  } from 'react-router-dom'
import Callback from '../../routes/Callback'
import Home from '../../routes/Home'
import { getNews } from '../../api/drupalAPI'
import { useNavigate } from 'react-router-dom'
import { NewsDetail } from '../news/NewsDetail';
import  { ClinicList } from '../clinics/ClinicList';
import { AccessList } from '../clinics/AccessList';
import {  ClinicDetail} from '../clinics/ClinicDetail';
import { NewsList } from '../news/NewsList' 
// import { SearchFilterClinics } from '../clinics/SearchFilterClinics'
  
import { Navigation } from  '../Navigation';
import { MagazineList } from '../magazine/MagazineList';
import { MagazineCarousel } from '../magazine/MagazineCarousel';
import { College } from '../../views/College'
import { CollegiateList } from '../colleagues/CollegiateList';
import { Documentation } from '../../views/Documentation';
import { ExtendedInformation } from '../where-we-are/ExtendedInformation';
import { ReportsAndDocuments } from '../../views/ReportsAndDocuments';
import { MiProfile } from '../../views/MiProfile';
import { NotificationManager } from './NotificationManager';
import { AdvantagesForCollegiate } from '../miProfile/AdvantagesForCollegiate';
import { GuideList } from '../documents/GuideList';
import { GuideDetail } from '../documents/GuideDetail';
import cordova from '../../api/cordovaAPI'
import payment from '../../api/paymentAPI'

import { useDispatch } from "react-redux";
import type { RootState } from '../../store/index';
import { useSelector } from "react-redux";
import {setSession} from '../../actions/session';
import cognitoUtils from '../../lib/cognitoUtils';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginView } from '../../views/LoginView';
import { ChangePassword } from '../../views/ChangePassword';
import { Youtube } from '../../views/Youtube';
import { YoutubeList } from '../youtube/YoutubeList';
import { YoutubeDetail } from '../youtube/YoutubeDetail';

import { InstagramGallery } from '../instagram/InstagramGallery';
import { EmploymentList } from '../employment/EmploymentList';
import { EmploymentDetail } from '../employment/EmploymentDetail';
import { PublicEmploymentDetail } from '../employment/PublicEmploymentDetail';
import { AdvertismentsList } from '../advertisments/AdvertismentsList';
import { AdvertismentsDetail } from '../advertisments/AdvertismentsDetail';
import { ContactFormView } from '../../views/ContactFormView';
import { FormationList } from '../formation/FormationList';
import { FormationDetail } from '../formation/FormationDetail';
import { PaymentAccepted } from '../formation/PaymentAccepted';
import { PaymentRefused } from '../formation/PaymentRefused';
import { CheckCollegiateStatus } from '../../views/CheckCollegiateStatus';
import { InstagramList } from '../instagram/InstagramList';


export default function App() {
    
    const session = useSelector((state: RootState) => state.session);
    const dispatch = useDispatch();
    
    cordova.init();
    cordova.initCompleted();
		payment.init();

    if (session.isLoggedIn == false) {
        cognitoUtils.getStoredCognitoSession().then((sses) => {
            if (sses.isLoggedIn) {
                dispatch(setSession(sses));
            }
        });
    }

    useEffect(()=> {
        cordova.readyToProcessUrl();
        cordova.readyToProcessNotifications();
    });


  return (
      <div>
     
        <Router>
            <Navigation />
            <div className="App fixNotchMarginTop">
            <div className="content-background md:hidden"></div>
            <div className="content-notch-header md:hidden"></div>
            <div className="container-fluid">
            <div className="row">
                
           <Routes>
                <Route  path="/" element={<Home />}/>
                <Route  path="/callback" element={<Callback />} />
                <Route  path="/docs"  element={<Documentation />} />
                <Route  path="/youtube" element={<Youtube />} />
                <Route  path="/docs/reportsanddocs"  element={<ReportsAndDocuments />} />
                <Route  path="/docs/magazinelist"  element={<MagazineList />} />
                <Route  path="/college"  element={<College />} />
                <Route  path="/college/extendedinformation"  element={<ExtendedInformation />}/>
                <Route  path="/college/collegiatelist"  element={<CollegiateList />} />
                <Route  path="/newslist/newsdetail"  element={<NewsDetail/>} />
                <Route  path="/college/clinicslist"  element={<ClinicList />} />
                <Route  path="/profile"  element={<MiProfile />} />
                <Route  path="/profile/advantagesforcollegiates"  element={<AdvantagesForCollegiate />} />
                <Route  path="/college/clinicslist/clinicdetail"  element={<ClinicDetail />} />
                <Route  path="/guidelist/guidedetail"  element={<GuideDetail />} />
                <Route  path="/docs/guidelist"  element={<GuideList />} />
                <Route  path="/newslist"  element={<NewsList />} />   
                <Route  path="/youtubelist"  element={<YoutubeList />} />   
                <Route  path="/youtubelist/youtubedetail"  element={<YoutubeDetail/>}/>
                <Route  path="/loginview"  element={<LoginView />} />  
                {/* <Route  path="/instagram" element={<InstagramGallery  count={12} pagination={true}/>} />  */}
                <Route  path="/college/employmentlist"  element={<EmploymentList />} /> 
                <Route  path="/college/employmentlist/employmentdetail"  element={<EmploymentDetail />} />  
                <Route  path="/college/employmentlist/publicemploymentdetail"  element={<PublicEmploymentDetail />} />  
                <Route  path="/college/advertismentslist"  element={<AdvertismentsList />} /> 
                <Route  path="/college/advertismentslist/advertismentsdetail" element={<AdvertismentsDetail />}  /> 
                <Route  path="/college/formationlist"  element={<FormationList />} /> 
                <Route  path="/college/formationlist/formationdetail"  element={<FormationDetail />} /> 
                <Route  path="/paymentaccepted"  element={<PaymentAccepted />} /> 
                <Route  path="/paymentrefused"  element={<PaymentRefused />}  /> 
                <Route  path="/contactform" element={<ContactFormView  />} /> 
                <Route  path="/instagram" element={<InstagramGallery  pagination={true}/>} />  
                <Route path="/instagramlist" element={<InstagramList />} />
                <Route  path="/check-collegiate-status" element={<CheckCollegiateStatus  />} />  
            </Routes>
            </div>   
            </div>
            </div>
            <NotificationManager/>

        </Router>

        <ToastContainer hideProgressBar />

    </div>
  );
}