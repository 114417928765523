import { UPDATE_CONFIG_NAVIGATION } from '../constants/actionTypes'

const defaultState = {
    moduleId: null,
    showMainHeader: true,
    showDetailHeader: false,
    detailTitle: false
}

  const configNavigation = (state = defaultState, action) => {
    switch (action.type) {
      case UPDATE_CONFIG_NAVIGATION:
        return Object.assign({}, defaultState,
          action.configNavigation)
  
      default:
        return state
    }
  }
  
  export default configNavigation;

