import React from "react";

export const PictureInput = ({ form, field, ...props }) => (
  <input
    className="my-2 ml-4 flex items-center justify-center"
    name={field.name}
    type="file"
    data-accordion={props['data-accordion']}
    accept="image/gif, image/jpg, image/jpeg, image/png, application/pdf"
    onChange={(e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.files;
        form.setFieldValue(field.name, newValue) }
    }    
  />
);
