import { useState, useEffect } from 'react';
import { getNews } from '../../api/drupalAPI'
import { News } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns'
import { es } from 'date-fns/locale';
import './NewsCarousel.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Loader from '../spinner/Loader';

export const NewsCarousel = () => {

    const [newsList, setNewsList] = useState<News[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    const fetchNewsList = async () => {
        setIsLoading(true)
        getNews().then((response) => {
            console.log(response)
            setNewsList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    function handleAddToDetail(id) {
        console.log('ID', id)
        navigate('newslist/newsdetail?id=' + id);
    }

    function goToPageNewsList() {
        navigate('newslist');
    }

    useEffect(() => {
        fetchNewsList();
    }, []);

    return (
        <>

            <div className="columns-1 mx-auto px-2">
                {/* Módulo actualidad */}
                <div className="card bg-white px-2 py-2 md:p-6 mt-3">
                    <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ad-2 mx-2 h-6 w-6 stroke-neutral-800 md:hidden" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11.933 5h-6.933v16h13v-8" />
                            <path d="M14 17h-5" />
                            <path d="M9 13h5v-4h-5z" />
                            <path d="M15 5v-2" />
                            <path d="M18 6l2 -2" />
                            <path d="M19 9h2" />
                        </svg>
                        <span>Actualidad</span>
                    </h1>
                    <div id="default-carousel" className="relative" data-carousel="static" >
                        {/* Carousel wrapper */}
                        <Swiper
                            centeredSlides={false}
                            breakpoints={{
                                375: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1280: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                1536: {
                                    slidesPerView: 5,
                                    spaceBetween: 10,
                                },
                                1920: {
                                    slidesPerView: 6,
                                    spaceBetween: 10,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper relative h-72 md:h-80 overflow-hidden rounded-lg lg:gap-4 md:gap-6 gap-0 flex flex-row"
                        >
                            {isLoading && <Loader />}
                            {newsList.slice(0, 8).map((news) => (
                                <SwiperSlide key={news.id} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 md:w-1/3 md:px-2 md:max-w-xs flex flex-shrink-0 relative w-full flex-col items-center">
                                    <div className="card h-64 w-100 md:h-72 bg-white md:border-1 carousel-card p-2">
                                        <button type="button" className="text-left" onClick={() => handleAddToDetail(news.id)}>
                                            <figure>
                                                <img className="h-44 md:h-48 w-100" src={contentUtils.getLargeImageUrl(news.picture)} alt="Doc" />
                                            </figure>
                                            <div className="card-body p-1">
                                                <small className="text-teal-600 mb-2">{format(new Date(news.creationDate), 'EEE dd/MM/Y', { locale: es }).toUpperCase()}</small>
                                                <h3 className="font-medium doc-title">{news.title}</h3>
                                            </div>
                                        </button>
                                    </div>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                        <button className="w-100 see-more-link px-2 py-3 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" onClick={() => goToPageNewsList()}>VER MÁS</button>
                    </div>
                </div>
            </div>
        </>
    );
}




