import { useState, useEffect } from 'react'
import { getClinicCollections } from '../../api/icofcvAPI';
import { CollectionItem } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { Link } from "react-router-dom";
import { getClinicById } from '../../api/drupalAPI'


export function ClinicDetail() {

    const [clinicFields, setClinicFields] = useState<CollectionItem[]>([]);
    const [clinicTreatments, setClinicTreatments] = useState<CollectionItem[]>([]);
    const [clinicConcerts, setClinicConcerts] = useState<CollectionItem[]>([]);
    const [clinicOthers, setClinicOthers] = useState<CollectionItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentClinic, setCurrentClinic] = useState<any>(null);

    const fetchCollections = async () => {
        getClinicCollections()
            .then((response) => {
                console.log(response);
                setClinicFields(response.physioFields);
                setClinicTreatments(response.clinicTypes);
                setClinicConcerts(response.featureFilters);
                setClinicOthers(response.others);
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    function getType(type) {
        if (!type) {
            return '';
        }
        var fields: number[] = type.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicTreatments.length; i++) {
            if (fields.includes(clinicTreatments[i].id)) {
                names.push(clinicTreatments[i].name);
            }
        }
        return names.join(', ');
    }

    function getPhysiotherapyFields(physiotherapyFields) {
        if (!physiotherapyFields) {
            return '';
        }
        var fields: number[] = physiotherapyFields.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicFields.length; i++) {
            if (fields.includes(clinicFields[i].id)) {
                names.push(clinicFields[i].name);
            }
        }

        return names.join(', ');
    }

    function getOthers(others) {
        if (!others) {
            return '';
        }
        var fields: number[] = others.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicOthers.length; i++) {
            if (fields.includes(clinicOthers[i].id)) {
                names.push(clinicOthers[i].name);
            }
        }

        return names.join(', ');
    }

    function getFeatureFilters(features) {
        if (!features) {
            return '';
        }
        var fields: number[] = features.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicConcerts.length; i++) {
            if (fields.includes(clinicConcerts[i].id)) {
                names.push(clinicConcerts[i].name);
            }
        }

        return names.join(', ');
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        fetchCollections();
        setIsLoading(true);
        getClinicById(id)
            .then((response) => {
                console.log(response);
                setCurrentClinic(response)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full font-medium text-lg text-white hover:text-white flex items-center" to="/college/clinicslist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">{currentClinic?.title}</span>
                </Link>
            </div>

            <div key={currentClinic?.id} className="columns-1 mx-auto px-2 main-container">
                <div className="card bg-white px-2 md:p-6 my-3">
                    <Link className="w-80 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 items-center" to="/college/clinicslist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Volver </span>
                    </Link>
                    <div className="card-body py-2 px-2 md:px-12 w-100">
                        <div>
                            <img className="max-w-xs md:max-w-sm mx-auto" src={contentUtils.getLargeImageUrl(currentClinic?.logo)} alt="Clinicas ICOFCV" />
                        </div>
                        <div className="grid grid-cols-1 gap-2 mb-4 border rounded-lg p-2">
                            <h2 className="text-teal-600 font-medium text-md">Datos</h2>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Nombre oficial:</p>
                                <h2 className="text-md font-normal text-neutral-800">{currentClinic?.title}</h2>
                            </div>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Registro:</p>
                                <h2 className="text-sm font-normal text-neutral-800">{currentClinic?.registry}</h2>
                            </div>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Dirección:</p>
                                <p className="text-sm font-normal text-neutral-800">{currentClinic?.address}</p>
                            </div>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Conciertos:</p>
                                <p className="text-sm font-normal text-neutral-800">{getFeatureFilters(currentClinic?.featureFilterList)}</p>
                            </div>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Horarios:</p>
                                <h2 className="text-sm font-normal text-neutral-800">{currentClinic?.propsMisc}</h2>
                            </div>
                            <div className="px-2 gap-2 mb-3">
                                <p className="text-sm font-medium text-neutral-500 mb-2">Tipo de tratamiento:</p>
                                <div className="w-full flex align-center justify-start flex-wrap gap-2 md:gap-0">
                                    {getType(currentClinic?.type) ?
                                        <span className="bg-teal-600 text-white text-sm font-medium px-2.5 py-1 rounded whitespace-nowrap">{getType(currentClinic?.type)}</span>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-2 my-4 border rounded-lg p-2">
                            <h2 className="text-teal-600 font-medium text-md">Contacto</h2>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Email:</p>
                                <a className="text-sm font-normal text-teal-600 underline">{currentClinic?.email}</a>
                            </div>
                            <div className="px-2 flex align-center gap-2">
                                <p className="text-sm font-medium text-neutral-500">Teléfono:</p>
                                <h2 className="text-sm font-normal text-neutral-800">{currentClinic?.propsPhone}</h2>
                            </div>
                        </div>
                        <div className="px-2 gap-2 mb-3">
                            <p className="text-sm font-medium text-neutral-500">Campos de la fisioterapia:</p>
                            <h2 className="text-sm font-normal text-neutral-800">{getPhysiotherapyFields(currentClinic?.physiotherapyFields)}</h2>
                        </div>
                        <div className="px-2 gap-2 mb-3">
                            <p className="text-sm font-medium text-neutral-500">Otros:</p>
                            <h2 className="text-sm font-normal text-neutral-800">{getOthers(currentClinic?.others)}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}





