import React from 'react'

export function EmigrantCertificate() {
    return (
        <>
            <div className="card bg-white px-2 py-3 md:p-6">
                <h1 className="pb-3 font-medium">Certificado buena conducta emigrantes</h1>
                <div className="card-body py-2 md:px-12 mx-auto">
                    <p className="text-neutral-600 text-sm">Desde el Ministerio de Educación se requiere que estos certificados deben de ser emitidos por el Consejo General de Colegios de Fisioterapeutas de España, por lo que deberás solicitarlo por correo electrónico (<a href="mailto:sede@consejo-fisioterapia.org" className="text-teal-600 hover:text-teal-800 underline">sede@consejo-fisioterapia.org</a>) adjuntando copia del título, DNI y certificado de colegiación actualizado.
                        El certificado se emite en el plazo aproximado de una semana.</p>
                </div>
            </div>
        </>
    )
}