import {News} from '@icofcv/common';
import { SELECT_NEWS } from '../constants/actionTypes'

const selectedNews = (state = null, action) => {
  switch (action.type) {
    case SELECT_NEWS:
      return Object.assign({},
        action.selectedNews)

    default:
      return state
  }
}

export default selectedNews;