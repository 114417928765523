import { useEffect, useState } from 'react';
import contentUtils from '../../lib/contentUtils';
import { Link } from "react-router-dom";
import { getGuideById } from '../../api/drupalAPI'

export const GuideDetail = () => {

    const [currentGuide, setCurrentGuide] = useState<any>(null);
    const parseFormDateToServiceFormat = (formDate?: string): string => {
        const date = (!!formDate) ? new Date(formDate) : new Date();
        return new Intl.DateTimeFormat('es-ES', { month: 'long', year: 'numeric' }).format(date);
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        getGuideById(id)
            .then((response) => {
                console.log(response);
                setCurrentGuide(response)
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/docs/guidelist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:white w-10" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">{currentGuide?.title}</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-100 hidden  md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/docs/guidelist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">{currentGuide?.title}</span>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <img className="rounded-lg mb-4 w-100 h-36 md:h-56 object-cover object-center" src={contentUtils.getLargeImageUrl(currentGuide?.imagePath)} alt="#" />
                            <div className="flex items-center justify-between">
                                <small className="text-teal-600">Fecha publicación: {currentGuide?.publishDate ? parseFormDateToServiceFormat(currentGuide?.publishDate) : '--'}</small>
                                <button onClick={() => contentUtils.openExternalUrl(currentGuide?.documentPath)} className="flex items-center text-teal-600 ">
                                    <span className="text-sm mx-1 underline hidden md:block">Ver guía</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link stroke-teal-600 hover:stroke-teal-800" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                                        <line x1="10" y1="14" x2="20" y2="4" />
                                        <polyline points="15 4 20 4 20 9" />
                                    </svg>
                                </button>
                            </div>
                            <p className="text-neutral-500 text-xs font-normal">{currentGuide?.creator}</p>
                            <div className="text-lg text-black font-medium my-1">{currentGuide?.title}</div>
                            <div className="text-neutral-600 news-text-description text-sm">{currentGuide?.description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}