import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper";
import Loader from '../spinner/Loader';
import './InstagramGallery.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
import { getPostsPaginated } from "../../api/icofcvAPI";
import contentUtils from '../../lib/contentUtils'

interface GalleryProps {
    pagination?: boolean;
    TokenInstagram?: any
}

export const InstagramGallery = (props: GalleryProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [instagramGalleryData, setInstagramGalleryData] = useState<any[]>([]);
    const navigate = useNavigate();

    const fetchInstagramData = () => {
        setIsLoading(true)
				var filter = {
					page: 0,
				};
        getPostsPaginated(filter).then((response) => {
            console.log(response)
            setInstagramGalleryData(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    };

        

    function goToPageInstagramList() {

        navigate('instagramlist');
    }

    
    useEffect(() => {
        fetchInstagramData()
    }, []);

    return (
        <>
            <div className="card bg-white px-2 py-2 md:p-6">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                        <line x1="8" y1="8" x2="12" y2="8" />
                        <line x1="8" y1="12" x2="12" y2="12" />
                        <line x1="8" y1="16" x2="12" y2="16" />
                    </svg>
                    <span>Fisio consejos</span>
                </h1>
                <div className="card-body py-1 px-0">
                    <div id="default-carousel" className="relative" data-carousel="static" >
                        {/* Carousel wrapper */}
                        <Swiper
                            centeredSlides={false}
                            breakpoints={{
                                375: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1280: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1536: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1920: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper relative h-80 overflow-hidden rounded-lg flex flex-row"
                        >
                            {isLoading && <Loader />}
                            {instagramGalleryData &&
                                instagramGalleryData.slice(0, 8).map((item: any, index: any) => (
                                    <SwiperSlide key={item.id} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 w-1/3 md:px-2 flex flex-shrink-0 relative flex-col items-center">
                                        <div className="card w-full md:w-100 h-72 bg-white md:border-1 carousel-card p-2 text-center">
                                            <div className="card-body p-1 mx-auto w-full">
                                                <button className="hover:text-neutral-500">
                                                    <a
                                                        onClick={() => contentUtils.openExternalUrl(`https://www.instagram.com/p/${item.media_items[0].code}/?hl=es`)}
                                                        className="instagram-link"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img
                                                            key={item.id}
                                                            className="h-56"
                                                            src={`data:image/png;base64,${item.base64Image}`}
                                                        />
                                                    </a>
                                                </button>
                                                <h3 className="font-medium doc-title">{item.title}</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
                <button  onClick={() => goToPageInstagramList()} type="button" className="see-more-link px-2 py-3 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" >VER MÁS</button>
            </div>
        </>
    );
};