import * as yup from "yup";

const isRequiredMessage = "Completa este campo";
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/zip', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
const FILE_SIZE_10MB = 10485760;

export default yup.object().shape({
   
  nameContact: yup.string().required(isRequiredMessage)
  .trim()
  .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
  .max(50),
  collegiateNumberContact: yup.string().trim(),
  surnamesContact: yup.string().required(isRequiredMessage)
  .trim()
  .matches(/[a-zA-Z]+$/, "Introduzca solo letras")
  .max(50),
  provinceContact: yup.string().required(isRequiredMessage),
  phoneContact: yup.string().required(isRequiredMessage)
  .trim()
  .matches(/^[0-9]+$/, "Introduzca solo números")
  .min(9, 'Introduzca exactamente 9 números')
  .max(9, 'Introduzca exactamente 9 números'),
  emailContact: yup.string().required(isRequiredMessage).email().trim(),
  message: yup.string().max(16777215),
  group: yup.string().required(isRequiredMessage),
  optionGroup: yup.string().required(isRequiredMessage),
  privacyPolicy: yup.bool().isTrue(isRequiredMessage),
  fileUpload: yup
  .mixed()
  .test(
    "fileSize",
    "El tamaño del fichero es demasiado grande, maximo 10MB",
    (value) => value === null || (value && value[0].size <= FILE_SIZE_10MB)
  )
  .test(
    "fileType",
    "Formato no soportado",
    (value) =>
      value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
  ),
  });

