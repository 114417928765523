import React from 'react'

interface Props {
    showModalPaymentConfirmation: boolean,
    closeModalPaymentConfirmation: () => void,
    checkoutSession: any
}

export const ModalPaymentConfirmation: React.FC<Props> = ({ showModalPaymentConfirmation, closeModalPaymentConfirmation, checkoutSession }) => {

    const copiCode = () => {
        navigator.clipboard.writeText(checkoutSession?.id);
        alert('codigo copiado')
    }

    return (
        <>
            {showModalPaymentConfirmation ? (
                <>
                    <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none p-0">
                        <div className="relative p-2 w-full max-w-2xl h-screen z-50 md:h-auto">
                            {/*content*/}
                            <div className="relative bg-white rounded-lg shadow">
                                {/*header*/}
                                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                    <h3 className="text-lg font-medium">Pago completado</h3>
                                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalPaymentConfirmation}>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                                    <div className="flex flex-col items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check w-12 h-12 my-2 stroke-teal-600" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="12" r="9" />
                                            <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                        <p className="text-sm pb-3 px-3 text-center text-neutral-600">Tu pago ha sido completado con éxito. ¡Gracias por utilizar nuestra aplicación para realizar esta transacción!.</p>
                                    </div>
                                    <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Datos personales</h2>
                                    <div className="flex items-center">
                                        <div className="mb-2">
                                            <span className="text-neutral-800 text-sm font-medium">Nombre:</span>
                                            <span className="text-sm text-neutral-600 font-normal mx-2">{checkoutSession?.customer_details.name}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-neutral-800 text-sm font-medium">Email:</span>
                                            <span className="text-sm text-neutral-600 font-normal mx-2">{checkoutSession?.customer_details.email}</span>
                                        </div>
                                    </div>
                                    <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Datos de la operación</h2>
                                    <div className="flex items-center flex-wrap">
                                        <div className="mb-2">
                                            <span className="text-neutral-800 text-sm font-medium">Importe:</span>
                                            <span className="text-sm text-neutral-600 font-normal mx-2">{(parseFloat(checkoutSession?.amount_total) / 100).toFixed(2).replace('.', ',') + ' €'}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-neutral-800 text-sm font-medium">Fecha:</span>
                                            <span className="text-sm text-neutral-600 font-normal mx-2">{new Date(checkoutSession?.created * 1000).toLocaleString('es', { year: "numeric", month: "2-digit", day: "2-digit" })}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-neutral-800 text-sm font-medium">ID Transacción:</span>
                                            <div className="flex items-center">
                                                <p className="text-sm text-teal-600 font-small break-all">{checkoutSession?.id}</p>
                                                <a href="#" onClick={copiCode}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy stroke-neutral-800 ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                                        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* footer */}
                                <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                    <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalPaymentConfirmation}>Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}