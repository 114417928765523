import * as yup from "yup";

const isRequiredMessage = "Completa este campo";


export default yup.object().shape({
    type: yup.string().required(isRequiredMessage),
    title: yup.string().required(isRequiredMessage)
    .trim()
    .max(255),
    description: yup.string().required(isRequiredMessage)
    .max(16777215),
    phone: yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    mobile: yup.string()
    .trim()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    notes: yup.string().max(16777215)
    .trim(),
    // publishStartDate: yup.string().required(isRequiredMessage),
    publishEndDate: yup.string().required(isRequiredMessage),
    provinceId: yup.string(), 
    provinceName: yup.string().matches(/[a-zA-Z]+$/, "Introduzca solo letras")
    .trim()
    .max(255),
    email: yup.string().required(isRequiredMessage)
    .trim()
    .email()  
    .max(255),
    companyName: yup.string().required(isRequiredMessage) 
    .trim()
    .max(100),
    regionalRegistrationNumber: yup.string().required(isRequiredMessage)
    .trim() 
    .max(255),
    countryId: yup.string().required(isRequiredMessage),
    city: yup.string().required(isRequiredMessage)
    .trim()
    .max(255),
    address: yup.string().required(isRequiredMessage)
    .trim()
    .max(255),
    postalCode: yup.string().required(isRequiredMessage)
    .trim()
    .max(255),
    web: yup.string()
    .trim()
    .max(2000),
    contractId: yup.string().required(isRequiredMessage),
    incorporation: yup.string()
    .trim()
    .max(255),
    schedule: yup.string()
    .trim()
    .max(255),
    scope: yup.string().required(isRequiredMessage)
    .trim(),
    provinceType: yup.string().required(isRequiredMessage),
    experience: yup.string()
    .trim(),
    experienceDescription: yup.string()
    .trim()
    .max(16777215),
   
    
});









