import { useEffect } from 'react'
import { CertificateFees } from '../components/reportsAndDocuments/CertificateFees'
import { CertificateOfRegistration } from '../components/reportsAndDocuments/CertificateOfRegistration'
import { EmigrantCertificate } from '../components/reportsAndDocuments/EmigrantCertificate'
import { InformedConsents } from '../components/reportsAndDocuments/InformedConsents'
import { setTopLevelNavigation } from '../actions/configNavigation'
import { useStore } from 'react-redux'
import { DOC_MODULE } from '../constants/modules'
import { Manuals } from '../components/reportsAndDocuments/Manuals'
import { Link } from "react-router-dom";

export function ReportsAndDocuments() {

    const store = useStore();

    useEffect(() => {
        store.dispatch(setTopLevelNavigation(DOC_MODULE));
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full font-medium text-xl text-white hover:text-white flex items-center" to="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Informes y documentos </span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-80 pb-3 hidden md:flex font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/profile">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Informes y documentos </span>
                    </Link>
                    <div className="card-body py-2 px-2 md:px-12 mx-auto mb-10">
                        <div className="mb-8">
                            <p className="text-neutral-600 text-sm mb-2">En esta sección podrás realizar los trámites de solicitud y recogida de la documentación que el Colegio puede emitir. </p>
                            <p className="text-neutral-600 text-sm mb-2">Haz click en el documento concreto y se activará el proceso de solicitud. La emisión del certificado no es instantánea: una vez procesada por los sistemas del Colegio recibirás un correo electrónico informando de la disponibilidad del documento en esta misma sección. </p>
                            <p className="text-neutral-600 text-sm">Recuerda que cada documento tienen una disponibilidad de 10 días, pasados los cuales será eliminado de la sección.</p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4">
                            <EmigrantCertificate />
                            <CertificateOfRegistration />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 my-4">
                            <CertificateFees />
                            <InformedConsents />
                            <Manuals />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}