import React from 'react'
import {Advertisment} from '@icofcv/common';
import {Where} from '@loopback/repository';

export function createAdvertismenWhereFilter(advertismentFilter:any) {
    const searchParam = advertismentFilter.search ? `%${advertismentFilter.search}%` : '';
    const searchProvinceParam = advertismentFilter.searchProvince;
    const searchTitleParam = advertismentFilter.searchTitle ? `%${advertismentFilter.searchTitle}%` : '';
    
    const searchParamsOr:Where<Advertisment>[] = [];
    if (searchParam && searchParam.trim().length > 0) {
        console.log(`*** searchParamsOr - title: '${searchParam}'`);
        searchParamsOr.push({ title: { like: searchParam, options: 'i' } });
    
    }

    const searchParamsAnd:Where<Advertisment>[] = [];


    if (searchProvinceParam && searchProvinceParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - province: '${searchProvinceParam}'`);
        searchParamsAnd.push({ province: { eq: searchProvinceParam } });
    }
    if (searchTitleParam && searchTitleParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - title: '${searchTitleParam}'`);
        searchParamsAnd.push({ title: { like: searchTitleParam, options: 'i' } });
    }

    let where = {};
    if (searchParamsOr.length > 0 || searchParamsAnd.length > 0) {
        if (searchParamsOr.length > 0 && searchParamsAnd.length > 0) {
            where = { 
                and: [
                    { or: searchParamsOr },
                    { searchParamsAnd }
                ]
            };
        } 
        if (searchParamsOr.length > 0 && searchParamsAnd.length == 0) {
            where = { 
                or: searchParamsOr
            };
        }
        if (searchParamsOr.length == 0 && searchParamsAnd.length > 0) {
            where = { 
                and: searchParamsAnd
            };
        }
    }

    return where;
}