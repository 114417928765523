import { combineReducers } from 'redux'
import session from './session'
import selectedNews from './selectedNews'
import selectedClinics from './selectedClinics'
import selectedClinicsInfo from './SelectedClinicsInfo'
import selectedGuides from './selectedGuides'
import configNavigation from './configNavigation'
import deviceInfo from './deviceInfo'
import nativeEvent from './nativeEvent'
import selectedYoutube from './selectedYoutube'
import selectedAdvertisments from './selectedAdvertisments'
import selectedEmployment from './selectedEmployment'
import selectedProvinces from './selectedProvinces'
import stripePaymentEvent from './stripePaymentEvent'

const rootReducer = combineReducers({
  session,
  selectedYoutube,
  selectedNews,
  selectedClinics,
  selectedClinicsInfo,
  selectedGuides,
  configNavigation,
  deviceInfo,
  nativeEvent,
  selectedAdvertisments,
  selectedEmployment,
	selectedProvinces,
	stripePaymentEvent
})

export default rootReducer
