import { useState, useEffect } from 'react';
import { getGuide } from '../../api/drupalAPI'
import { Guide } from '@icofcv/common';
import { getItemsPerPage, getGuidePaginated } from '../../api/drupalAPI';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
import Loader from '../spinner/Loader';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
// import required modules
import { setDetailNavigation } from '../../actions/configNavigation'
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { Link } from "react-router-dom";

export const GuideList = () => {

    const store = useStore();
    const [guideList, setGuideList] = useState<Guide[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [currentPageGuide, setCurrentPageGuide] = useState(0);
    const [viewMoreGuideDataDisabled, setViewMoreGuideDataDisabled] = useState(false);

    const parseFormDateToServiceFormat = (formDate?: string): string => {
        const date = (!!formDate) ? new Date(formDate) : new Date();
        return new Intl.DateTimeFormat('es-ES', { month: 'long', year: 'numeric' }).format(date);
    }

    const fetchGuideList = async () => {
        setIsLoading(true)
        getGuide().then((response) => {
            console.log(response)
            setGuideList(response);
            setIsLoading(false)
            var filter = {
                page: 0,
            };
            fetchGuidePaginated(filter)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const fetchGuidePaginated = async (filter) => {
        setIsLoading(true);
        getGuidePaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreGuideDataDisabled(false);

                } else {
                    setViewMoreGuideDataDisabled(true);
                }

                var list: Guide[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...guideList, ...response];
                }
                setGuideList(list);
                console.log(list);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const handleShowMoreData = () => {
        setCurrentPageGuide(currentPageGuide + 1);
        var filter = {
            page: currentPageGuide + 1,

        };
        fetchGuidePaginated(filter);
    }


    function handleAddToDetail(id) {
        navigate('/guidelist/guidedetail?id=' + id);
    }



    useEffect(() => {
        store.dispatch(setDetailNavigation(HOME_MODULE, 'guias!'));
        fetchGuideList();
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/docs">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Guías</span>
                </Link>
            </div>

            <div className="columns-1 mx-auto px-2 main-container pb-12">

                {/* GuideCarousel */}
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/docs">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Guias</span>
                    </Link>

                    {/* GuidesList */}
                    {isLoading && <Loader />}
                    {
                        guideList.map(guide => (
                            <div key={guide.id} className="bg-white px-2 py-3 md:h-32 border-b rounded-0 newslist-container">
                                <button type="button" className="text-left overflow-hidden pb-2 w-100" onClick={() => handleAddToDetail(guide.id)}>
                                    <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                        <img className="h-24 md:w-1/5 min-w-40 object-cover object-center rounded-md mb-2 md:mb-0" src={contentUtils.getLargeImageUrl(guide.imagePath)} alt="NewsImage" />
                                        <div className="md:w-4/5 ml-2">
                                            <div className="flex items-center justify-between">
                                                <small className="text-teal-600">Fecha publicación: {guide.publishDate ? parseFormDateToServiceFormat(guide.publishDate) : '--'}</small>
                                            </div>
                                            <p className="text-neutral-500 text-xs font-normal mb-1">{guide.creator}</p>
                                            <p className="text-lg text-neutral-800 font-medium news-title mb-1">{guide.title}</p>
                                            <p className="text-neutral-500 text-sm font-normal news-description">{guide.description}</p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}