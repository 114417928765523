import * as yup from "yup";

const isRequiredMessage = "Completa este campo";
const FILE_SIZE_50MB = 3242880;
const FILE_SIZE_10MB = 10485760;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export default yup.object().shape({
    contactFirstname: yup.string().required(isRequiredMessage)
    .trim()
    .max(100)
    .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, "Introduzca solo letras"),
    contactLastname: yup.string().required(isRequiredMessage)
    .trim()
    .matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, "Introduzca solo letras")
    .max(255),
    title: yup.string().required(isRequiredMessage)
    .trim()
    .max(255),
    description: yup.string().required(isRequiredMessage)
    .max(16777215),
    contactPhone: yup.string().required(isRequiredMessage)
    .trim()
    .matches(/^[0-9]+$/, "Introduzca solo números")
    .min(9, 'Introduzca exactamente 9 números')
    .max(9, 'Introduzca exactamente 9 números'),
    categoryId: yup.string().required(isRequiredMessage),
    publishStartDate: yup.string().required(isRequiredMessage),
    publishEndDate: yup.string().required(isRequiredMessage),
    province: yup.string().required(isRequiredMessage),
    contactEmail: yup.string().required(isRequiredMessage)
    .trim()
    .email()  
    .max(255),
    imageUpload: yup
    .mixed()
    .test(
      "fileSize",
      "El tamaño del fichero es demasiado grande, maximo 10MB",
      (value) => value === null || (value && value[0].size <= FILE_SIZE_10MB)
    )
    .test(
      "fileType",
      "Formato no soportado",
      (value) =>
        value === null || (value && SUPPORTED_FORMATS.includes(value[0].type))
    ),
    
});


