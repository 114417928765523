import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const ScrollToErrors = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    function expandAccordionIfNeeded(el) {
        const att:string = el.getAttribute('data-accordion');
        const accordionBody = document.getElementById(att);
        if (accordionBody?.className.indexOf('hidden:block') !== -1) return;
        const num = accordionBody?.id.substring(accordionBody?.id.lastIndexOf('-') + 1);
        const accordionHeadingButton = document.getElementById(`accordion-collapse-heading-button-${num}`);
        accordionHeadingButton?.click();
    }

    function scrollToElement(el, key) {
        const lbl = document.querySelector(`label[for="${key}"]`);
        if (lbl) {
            lbl.scrollIntoView();
        } else {
            el.scrollIntoView();
        }
    }

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors);
            if (keys.length == 0) return;
            const el = document.querySelector(`[name=${keys[0]}]`);
            if (el === null) return;
            expandAccordionIfNeeded(el);
            setTimeout(() => {
                scrollToElement(el, keys[0]);
            }, 200);
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};

export default ScrollToErrors;