import React from 'react'
import {Employment} from '@icofcv/common';
import {Where} from '@loopback/repository';

export function createEmploymentWhereFilter(employmentFilter:any) {
    const searchParam = employmentFilter.search ? `%${employmentFilter.search}%` : '';
    const searchTitleParam = employmentFilter.searchTitle ? `%${employmentFilter.searchTitle}%` : '';
    const searchProvinceParam = employmentFilter.searchProvince;
    const searchScopeParam = employmentFilter.searchScope;
    
    const searchParamsOr:Where<Employment>[] = [];
    if (searchParam && searchParam.trim().length > 0) {
        console.log(`*** searchParamsOr - title: '${searchParam}'`);
        searchParamsOr.push({ title: { like: searchParam, options: 'i' } });
    }

    const searchParamsAnd:Where<Employment>[] = [];
    if (searchScopeParam && searchScopeParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - scope: '${searchScopeParam}'`);
        searchParamsAnd.push({ scope:  { eq: searchScopeParam } });
    }
    if (searchProvinceParam && searchProvinceParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - provinceType: '${searchProvinceParam}'`);
        searchParamsAnd.push({ provinceType: { eq: searchProvinceParam } });
    }
    if (searchTitleParam && searchTitleParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - title: '${searchTitleParam}'`);
        searchParamsAnd.push({ title: { like: searchTitleParam, options: 'i' } });
    }

    let where = {};
    if (searchParamsOr.length > 0 || searchParamsAnd.length > 0) {
        if (searchParamsOr.length > 0 && searchParamsAnd.length > 0) {
            where = { 
                and: [
                    { or: searchParamsOr },
                    { searchParamsAnd }
                ]
            };
        } 
        if (searchParamsOr.length > 0 && searchParamsAnd.length == 0) {
            where = { 
                or: searchParamsOr
            };
        }
        if (searchParamsOr.length == 0 && searchParamsAnd.length > 0) {
            where = { 
                and: searchParamsAnd
            };
        }
    }

    return where;
}