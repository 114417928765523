import { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import './YoutubeList.css'
import Loader from '../spinner/Loader';
import { Link } from "react-router-dom";
import { selectYoutube } from '../../actions/detailYoutube';
import config from '../../config/app-config.json'
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation' 

export const YoutubeList = () => {

    const store = useStore();
    const [allVideos, setAllVideos] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nextPageToken, setNextPageToken] = useState('')

    const fetchYoutubeList = async () => {
        setIsLoading(true)

        fetch(`${config.urlYoutube}&playlistId=${config.playlistYoutubeKey}&order=date&key=${config.youtubeKey}`)
            .then(res => res.json())
            .then(data => {
                setIsLoading(false)

                const result = data.items?.map(item => {
                    return { title: item.snippet.title, vId: item.contentDetails.videoId, id: item.id, description: item.snippet.description, imgVideo: item.snippet.thumbnails.high.url, position: item.snippet.position }
                })
                setAllVideos(result)
                setNextPageToken(data.nextPageToken)
            });
    }

    const getMoreVideos = async () => {
        setIsLoading(true)
        fetch(`${config.urlYoutube}&playlistId=${config.playlistYoutubeKey}&order=date&key=${config.youtubeKey}&pageToken=` + nextPageToken)
            .then(res => res.json())
            .then(data => {
                setIsLoading(false)
                const result = data.items?.map(item => {
                    return { title: item.snippet.title, vId: item.contentDetails.videoId, description: item.snippet.description, imgVideo: item.snippet.thumbnails.high.url, long: item.length }
                })
                setAllVideos([...allVideos, ...result])
                setNextPageToken(data.nextPageToken)
            });
    }

    function handleAddToDetail(item) {
        dispatch(selectYoutube(item));
        navigate('youtubedetail');
    }

    useEffect(() => {
        fetchYoutubeList()
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-100 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Video consejos</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Video Consejos</span>
                    </Link>
                    { allVideos.map(item => (
                            <div key={item.id} className="bg-white px-2 py-3 md:h-56 border-b rounded-0">
                                <button onClick={() => handleAddToDetail(item)} type="button" className="text-left overflow-hidden pb-2" >
                                    <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                        <img className="h-48 md:w-72 md:w-1/5 object-cover object-center rounded-md image-news" src={item.imgVideo} alt="youtubeImage" />
                                        <div className="md:w-4/5 ml-2 md:h-36">
                                            <p className="text-lg text-neutral-800 font-medium news-title">{item.title}</p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ))
                    }
                </div>

                {allVideos.length !== 119 ?
                    <div className="flex items-center justify-end mx-3">
                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={() => getMoreVideos()}>VER MÁS</button>
                    </div>
                    : <div></div>
                }
                {console.log('longitud', allVideos.length)}
            </div>
            {isLoading && <Loader />}
        </>
    )
}