import { SELECT_ADVERTISMENTS } from '../constants/actionTypes'
import {getItem} from '../lib/localStorage'

const selectedAdvertisments = (state =  getItem('advertisment') || null,  action) => {
  switch (action.type) {
    case SELECT_ADVERTISMENTS:
      return Object.assign({},
        action.selectedAdvertisments)

    default:
      return state
  }
}

export default selectedAdvertisments;
