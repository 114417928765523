import store from '../store';
import { UPDATE_DEVICE_INFO } from '../constants/actionTypes';
import cognitoUtils from '../lib/cognitoUtils';
import { ON_NATIVE_EVENT, SET_SESSION, } from '../constants/actionTypes';
import { updateDevice } from '../api/icofcvAPI';
import {v4 as uuidv4} from 'uuid';


/* eslint-disable no-unused-vars */

let sharedstore : typeof store;

export default {
    init() {
        window.addEventListener("message", this.processCordovaWrapperMessage, false);
    },

    processCordovaWrapperMessage(evt)
    {
        var message = evt.data;
	
        if (!message.indexOf) return;

        console.log("*** Mensaje recibido: " + message);

        if (message.indexOf("SET_LOCAL_STORAGE:") == 0) {									
						//window.$vue.$emit('set-login-info', message.split(':')[2]);
                        var posI = message.indexOf(':');
                        var posF = message.indexOf(':',posI+2);
                        let key = message.substring(posI+1,posF);
                        localStorage.setItem(key, message.substring(posF+1));

                        if (key==='cognito-auth') {
                            cognitoUtils.getStoredCognitoSession().then( (sses) => {
                                if (sses.isLoggedIn) {
                                    store.dispatch({ type: SET_SESSION, session: sses })
                                }
                            });
                        }
                                
				}

        if (message.indexOf("NE_DEVICE_UUID:") == 0) {
            store.dispatch({ type: UPDATE_DEVICE_INFO, 
                deviceInfo: {
                    uuid: message.substring(15)
                }
            });
            updateDeviceInfo();
        }
 
        if (message.indexOf("NE_DEVICE_PLATFORM:") == 0) {
            store.dispatch({ type: UPDATE_DEVICE_INFO, 
                deviceInfo: {
                    platform: message.substring(19)
                }
            });
            updateDeviceInfo();
        }

        if (message.indexOf("NE_DEVICE_VERSION:") == 0) {
            store.dispatch({ type: UPDATE_DEVICE_INFO, 
                deviceInfo: {
                    version: message.substring(18)
                }
            });
            updateDeviceInfo();
        }

        if (message.indexOf("NE_DEVICE_TOKEN:") == 0) {
            store.dispatch({ type: UPDATE_DEVICE_INFO, 
                deviceInfo: {
                    notificationToken: message.substring(16)
                }
            });
            updateDeviceInfo();
        }

        if (message.indexOf("NE_PROCESS_AWS_CALLBACK:") == 0) {

            let callbackHref = message.substring(24);

            cognitoUtils.parseCognitoWebResponse(callbackHref) // parse the callback URL
                .then(() => cognitoUtils.getCognitoSession()) // get a new session
                .then((session) => {
                    store.dispatch({ type: SET_SESSION, session })
                });

        }
        

        if (message.indexOf("NE_PUSH:") == 0) {
            var json = message.substring(8);

            json = json.replaceAll('\\"', '"');
            json = json.replaceAll("\":\"{", "\":{");
            json = json.replaceAll("}\",", "},");
            
            var obj = JSON.parse(json);
            
            console.log(obj);

            let notification = obj.additionalData ? obj.additionalData : (obj.data ? obj.data : obj);

            const event = {
                type: 'notification',
                payload: notification
            }

            store.dispatch({ type: ON_NATIVE_EVENT, nativeEvent: event })
            //window.$vue.$emit('notification-received', section);
        }

        if (message.indexOf("NE_RESUME") == 0) {
            //window.$vue.$emit('ne_resume');
        }

        if (message.indexOf("NE_AWAKE:") == 0) {
            //window.$vue.$emit('ne_awake', message.substring(9));
        }

        if (message.indexOf("NE_BACKBUTTON") == 0) {
				//window.$vue.$emit('ne_backbutton');
        }

        ///Login biometrico

        if (message.indexOf("FP_") == 0) {   
            let parts = message.split(':');

            let content = {};

            if (parts.length > 1) {
                try {
                    content = JSON.parse(parts[1]);
                } catch (e) {
                    content = parts[1];
                }
            }

            const payload = {
                 id: uuidv4(),                
                 type: parts[0],
                 content: content,
            }

            const event = {    
                type: 'biometric',
                payload: payload
            }

            store.dispatch({ type: ON_NATIVE_EVENT, nativeEvent: event })            
        
        }        

    },
    sendCordovaWrapperMessage(message) {
        if (window !== parent) {
						parent.postMessage(message, "*");
        }
    },
    initCompleted() {
        this.sendCordovaWrapperMessage('INIT_COMPLETED');
        /*
				var me = this;

				var timerId = setTimeout(function(){
					me.processCordovaWrapperMessage({data: "NE_DEVICE_UUID:E2F7E6C0-D6D5-4F51-9FB3-CD730923102E"});
					me.processCordovaWrapperMessage({data: "NE_DEVICE_PLATFORM:browser"});
					me.processCordovaWrapperMessage({data: "NE_DEVICE_VERSION:1.0.7"});
					me.processCordovaWrapperMessage({data: "NE_DEVICE_TOKEN:740f4707bebcf74f9b7c25d48e3358945f6aa01da5ddb387462c7eaf61bb78ad"});
					//me.sendCordovaWrapperMessage("NE_AWAKE:10");
					//me.sendCordovaWrapperMessage("NE_AWAKE:10.001");
					//me.sendCordovaWrapperMessage("NE_BACKBUTTON");
          clearTimeout(timerId);
        }, 5000); */
    },
		setLocalStorageItem(key, value) {			
        this.sendCordovaWrapperMessage('SET_LOCAL_STORAGE:' + key + ':' + value);
		},
		removeLocalStorageItem(key) {			
        this.sendCordovaWrapperMessage('REMOVE_LOCAL_STORAGE:' + key);
		},
    initializationProgress(step) {			
        this.sendCordovaWrapperMessage('INITIALIZATION_PROGRESS:' + step);
		},
    readyToProcessUrl() {			
        this.sendCordovaWrapperMessage('READY_TO_PROCESS_URL'); 
        },       
    readyToProcessNotifications() {			
        this.sendCordovaWrapperMessage('READY_TO_PROCESS_NOTIFICATIONS');
		},
    cancelProcessNotifications() {			
        this.sendCordovaWrapperMessage('CANCEL_PROCESS_NOTIFICATIONS');
		},
		setTopbarColor(color) {			
        this.sendCordovaWrapperMessage('TOPBARCOLOR:' + color);
		},
		openUrl(url) {			
        this.sendCordovaWrapperMessage('OPEN_URL:' + url);
		},
		openExternalUrl(url) {			
        this.sendCordovaWrapperMessage('OPEN_EXTERNAL_URL:' + url);
		},
        downloadUrl(filename, url) {			
            this.sendCordovaWrapperMessage('DOWNLOAD_URL:' + filename + ':' + url);
            },
        FP_isAvailable(){
            this.sendCordovaWrapperMessage('FP_IS_AVAILABLE');
        },
        FP_show(){
            this.sendCordovaWrapperMessage('FP_SHOW:{"description": "Description Show"}');
        },
        FP_registerSecret(secret){
            this.sendCordovaWrapperMessage(`FP_REGISTER_SECRET:{"description": "Coloca tu dedo para registrar la huella", "secret": "${secret}" }`);
        }, 
        FP_loadSecret(){
            this.sendCordovaWrapperMessage('FP_LOAD_SECRET:{"description": "Coloca tu dedo para acceder a la aplicación", "confirmationRequired": "true"}');
        }, 
         
    isApp() {
        return store.getState().deviceInfo.platform !== 'browser';
    },
    
}

export function updateDeviceInfo() {
    const di = store.getState().deviceInfo;

    if (!di.uuid || !di.platform || !di.notificationToken || !di.version) {
        return;
    }

    const device = {
        uuid: di.uuid,
        token: di.notificationToken,
        nativeVersion: di.version,
        platform: di.platform,
        userNotification: true,
        created: new Date(),
        updated: new Date()
    }

    updateDevice(device);
}

 