import { useState } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { Link } from "react-router-dom";
import { ModalNoLogin } from '../miProfile/ModalNoLogin'

export function AccessReportsAndDocumentsLogin() {

    const session = useSelector((state: RootState) => state.session);
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <>
            {session.isLoggedIn ?
                <Link to="/docs/reportsanddocs" className="bg-white text-gray-800 hover:text-teal-800 flex items-center justify-between rounded-lg h-14 p-4 w-100 md:w-50 md:mr-2 my-2 border border-gray-400">
                    <div className="flex items-center">
                        <span className="font-medium">Informes y documentos</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right stroke-gray-800 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                    </svg>
                </Link>
                :
                <button className="bg-white text-gray-400 hover:text-gray-300 flex items-center justify-between rounded-lg h-14 p-4 w-100 md:w-50 border border-gray-400" onClick={openModal}>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock stroke-gray-400 hover:stroke-gray-300" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="5" y="11" width="14" height="10" rx="2" />
                            <circle cx="12" cy="16" r="1" />
                            <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                        </svg>
                        <span className="font-medium ml-2">Informes y documentos</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right stroke-gray-400 hover:stroke-gray-300" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9ca3af" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                    </svg>
                </button>
            }
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
        </>
    )
}