import { useState, useEffect } from 'react'
import './InstagramList.css'
import Loader from '../spinner/Loader';
import { Link } from "react-router-dom";
import { getPostsPaginated, getItemsPerPage } from "../../api/icofcvAPI";
import contentUtils from '../../lib/contentUtils'  
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation' 


export const InstagramList = () => {
    
    const store = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const [instagramGalleryData, setInstagramGalleryData] = useState<any[]>([]);
    const [currentPagePosts, setCurrentPagePosts] = useState(0);
    const [viewMorePostsDataDisabled, setViewMorePostsDataDisabled] = useState(false);

    const fetchInstagramList = async (filter) => {
        setIsLoading(true);
        getPostsPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMorePostsDataDisabled(false);
                } else {
                    setViewMorePostsDataDisabled(true);
                }
                let list: any[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...instagramGalleryData, ...response];
                }
                setInstagramGalleryData(list);
                console.log(list);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const handleShowMoreData = () => {
        setCurrentPagePosts(currentPagePosts + 1);
        var filter = {
            page: currentPagePosts + 1,
        };
        fetchInstagramList(filter);
    }

    useEffect(() => {
				var filter = {
					page: 0,
				};
			fetchInstagramList(filter)
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
    }, []);

    return (
        <>
        <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
            <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="5" y1="12" x2="11" y2="18" />
                    <line x1="5" y1="12" x2="11" y2="6" />
                </svg>
                <span className="ml-2">Fisio consejos</span>
            </Link>
        </div>
        <div className="columns-1 mx-auto px-2 main-container pb-12">
            {/* Instagram Carousel */}
            <div className="card bg-white px-2 py-3 md:p-8 my-3 content-card">
                <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Fisio consejos</span>
                </Link>
                {/* Instagram List */}
                {isLoading && <Loader />}
                { instagramGalleryData.map(item => (
                        <div key={item.id} className="bg-white px-2 py-3 md:h-58 border-b rounded-0">
                            <button type="button" className="text-left overflow-hidden pb-2 w-100"  onClick={() => contentUtils.openExternalUrl(`https://www.instagram.com/p/${item.media_items[0].code}/?hl=es`)}>
                                <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                         <img className="h-52 w-52 mx-auto"  src={`data:image/png;base64,${item.base64Image}`} alt="InstaImage" />
                                    <div className="ml-2 md:h-36">
                                        <p className="text-lg text-neutral-800 font-medium news-title instagram-title mb-2">{item.title}</p>
                                        <div className="text-neutral-600 text-xs font-normal creator-text text-ellipsis line-clamp-6" dangerouslySetInnerHTML={{ __html: item?.media_items[0].caption.text }}></div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end ">
                                <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => contentUtils.openExternalUrl(`https://www.instagram.com/p/${item.media_items[0].code}/?hl=es`)}>VER POST</button>
                            </div>
                            </button>
                           
                        </div>
                    ))
                }
            </div>
            {viewMorePostsDataDisabled === false ?
                    <div className="flex items-center justify-end mx-3">
                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                    </div>
                    :
                    <div></div>
                }
        </div>
    </>
    )
}