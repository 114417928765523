import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const AccessWhere = () => {

    const navigate = useNavigate();

    function goToPageCollegiateList() {
        navigate('extendedinformation');
    }

    return (
        <>
            <div className="card bg-white px-2 py-2 md:p-6 md:h-96">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="11" r="3" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                    </svg>
                    <span>Contacto</span>
                </h1>
                <div className="card-body py-1 px-2 flex justify-center items-center flex-column">
                    <div className="w-100">
                        <iframe
                            className="w-100 h-52 z-0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.3238269926374!2d-0.3805919350162851!3d39.46959682083709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd604f4bee0957f3%3A0x6686ff7d230b3965!2zQy4gZGUgU2FudCBWaWNlbnQgTcOgcnRpciwgNjEsIHBpc28gMsK6LCBwdGEgMsKqLCA0NjAwMiBWYWzDqG5jaWEsIEVzcGHDsWE!5e0!3m2!1ses!2sus!4v1662388390673!5m2!1ses!2sus"
                            loading="lazy">
                        </iframe>
                    </div>
                    <div className="text-center">
                        <p className="my-2 text-sm text-neutral-500 font-normal">
                            <span className="font-medium">SEDE COLEGIAL VALENCIA</span><br></br>
                            San Vicent Màrtir nº 61 piso 2º, pta 2ª
                            46002 Valencia
                        </p>
                    </div>
                </div>
                <div className="card-actions w-100 md:flex md:justify-around mb-2 text-center">
                    <button className="btn bg-teal-600 hover:bg-teal-800 text-white px-8 mt-2 md:text-base mx-1" onClick={() => goToPageCollegiateList()} >Ver más información</button>
                    <Link to='/contactform' className="btn bg-teal-600 hover:bg-teal-800 active:bg-teal-600 text-white px-8 mt-2 md:text-base mx-1">Formulario de contacto</Link>
                </div>
            </div>
        </>
    )
}