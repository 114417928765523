import { useState, useEffect } from 'react';
import { getMagazine } from '../../api/drupalAPI'
import { Magazine } from '@icofcv/common';
import { getItemsPerPage, getMagazinePaginated } from '../../api/drupalAPI';
import contentUtils from '../../lib/contentUtils'
import Loader from '../spinner/Loader';
import { setDetailNavigation } from '../../actions/configNavigation'
import { DOC_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { Link } from "react-router-dom";


export function MagazineList() {

    const store = useStore();
    const [magazineList, setMagazineList] = useState<Magazine[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPageMagazine, setCurrentPageMagazine] = useState(0);
    const [viewMoreMagazineDataDisabled, setViewMoreMagazineDataDisabled] = useState(false);


    const fetchMagazineList = async () => {
        setIsLoading(true)
        getMagazine().then((response) => {
            console.log(response)
            setMagazineList(response);
            setIsLoading(false)
            var filter = {
                page: 0,
            };
            fetchMagazinePaginated(filter)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const fetchMagazinePaginated = async (filter) => {
        setIsLoading(true);
        getMagazinePaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreMagazineDataDisabled(false);

                } else {
                    setViewMoreMagazineDataDisabled(true);
                }

                var list: Magazine[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...magazineList, ...response];
                }
                setMagazineList(list);
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const handleShowMoreData = () => {
        setCurrentPageMagazine(currentPageMagazine + 1);

        var filter = {
            page: currentPageMagazine + 1,
        };
        fetchMagazinePaginated(filter);
    }

    useEffect(() => {
        store.dispatch(setDetailNavigation(DOC_MODULE, 'Revistas'));
        fetchMagazineList();
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-50 font-medium text-xl text-white hover:text-white flex items-center" to="/docs">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Revistas</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container ">
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <Link className="w-50 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/docs">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#262626" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Revistas</span>
                    </Link>
                    <div className="card-body py-1 px-0 md:px-4 md:w-3/4 mx-auto">
                        <div className="grid grid-cols-2 md:grid-cols-6 gap-4 flex items-center justify-center">
                            {isLoading && <Loader />}
                            {magazineList.map(item => (
                                <div key={item.id} className="absolute inset-0 z-10 w-100 md:px-2 flex flex-shrink-0 relative sm:w-auto flex-col items-center">
                                    <a onClick={() => contentUtils.openExternalUrl(item.link)} className="hover:text-neutral-500">
                                        <div className="text-teal-600 mb-2 px-2">
                                            {item.title}
                                        </div>
                                        <img className="d-block w-100" src={contentUtils.getLargeImageUrl(item.picture)} alt="Revista" />
                                    </a>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
                {viewMoreMagazineDataDisabled === false ?
                    <div className="flex items-center justify-end mx-3">
                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                    </div>
                    :
                    <div></div>
                }
            </div>
        </>
    );
}