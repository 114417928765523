import { Formik, Form, Field, ErrorMessage } from "formik";
import validationSchema from '../../validation-form-course/validationSchema';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_STRIPE_PAYMENT_EVENT } from '../../constants/actionTypes';
import type { RootState } from '../../store/index'
import { createCheckoutSession, getCheckoutSessionById, getCourseInscription, updateCourseInscription, noLoginUpdateCourseInscription, getCollege, getCollegesByAgreementById } from '../../api/icofcvAPI';
import { toast } from 'react-toastify';
import Loader from '../spinner/Loader';
import { ModalNoLogin } from "../miProfile/ModalNoLogin";
import { Course, CourseInscription, College, Agreement } from '@icofcv/common';
import { courseInscriptionsPush, courseInscriptionsNoLoginPush } from "../../api/drupalAPI";
import { PopUpMessage } from "./PopUpMessage";
import { ModalPaymentCancelled } from "./ModalPaymentCancelled";
import { ModalPaymentConfirmation } from "./ModalPaymentConfirmation";
import formatNumbersUtils from "../../lib/formatNumbersUtils";
import contentUtils from '../../lib/contentUtils';

interface Props {
    showModalCourseRegistration: {
        show: boolean,
        id: number,
        fields: any | Course,
        
    },
    closeModalCourseRegistration: () => void,
    updateTable: () => void,
    agreementList: any | Agreement
}

interface FormValues {
    numCollegiate: string | any,
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    collegeId: string,
    userId: string | null,
    dni: string
}

export const ModalCourseRegistration: React.FC<Props> = ({ showModalCourseRegistration, closeModalCourseRegistration, updateTable, agreementList }) => {

    const dispatch = useDispatch();
    const session = useSelector((state: RootState) => state.session);
    let course = showModalCourseRegistration.fields
    const [lastRedirectId, setLastRedirectId] = useState<String | null>(null);
    const stripePaymentEvent = useSelector((state: RootState) => state.stripePaymentEvent);
    const [courseInscription, setCourseInscription] = useState<CourseInscription | null>(null);
    const [checkoutSession, setCheckoutSession] = useState<any>(null);

    const [showModalMessage, setShowModalMessage] = useState(false);
    const openModalMessage = () => setShowModalMessage(true)
    const closeModalMessage = () => setShowModalMessage(false)

    const [showModalPaymentCancelled, setShowModalPaymentCancelled] = useState(false);
    const openModalPaymentCancelled = () => setShowModalPaymentCancelled(true)
    const closeModalPaymentCancelled = () => setShowModalPaymentCancelled(false)

    const [showModalPaymentConfirmation, setShowModalPaymentConfirmation] = useState(false);
    const openModalPaymentConfirmation = () => setShowModalPaymentConfirmation(true)
    const closeModalPaymentConfirmation = () => setShowModalPaymentConfirmation(false)

    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [isLoading, setIsLoading] = useState(false)

    const initialValues: FormValues = {
        numCollegiate: session.isLoggedIn ? session.collegiate?.memberId : "",
        firstname: session.isLoggedIn ? session.collegiate?.firstname : "",
        lastname: session.isLoggedIn ? session.collegiate?.lastname : "",
        phone: session.isLoggedIn ? session.collegiate?.mobile : "",
        email: session.isLoggedIn ? session.collegiate?.email : "",
        collegeId: session.isLoggedIn ? "ICOFCV" : "",
        userId: session.isLoggedIn ? session.collegiate?.id : null,
        dni: session.isLoggedIn ? session.collegiate?.fiscalId : ""
    };

    const onSubmit = (values: FormValues) => {
        setIsLoading(true)
        setTimeout(() => {
            console.log(values);
            const data = {
                courseId: course.id,
                userId: session.isLoggedIn ? session.collegiate?.id : null,
                creationDate: new Date(),
                creatorUsername: !session.isLoggedIn ? values.email : '',
                numCollegiate: !session.isLoggedIn ? values.numCollegiate : '',
                firstname: !session.isLoggedIn ? values.firstname : '',
                lastname: !session.isLoggedIn ? values.lastname : '',
                phone: !session.isLoggedIn ? values.phone : '',
                email: !session.isLoggedIn ? values.email : '',
                dni: !session.isLoggedIn ? values.dni : '',
                collegeId: !session.isLoggedIn ? parseInt(values.collegeId) : 0,
                status: course.paymentEnabled === false ? 3 : 0,

            };
            console.log(values.collegeId)
            if (!session.isLoggedIn) {
                courseInscriptionsNoLoginPush(data)
                    .then((response: CourseInscription) => {
                        console.log(response)
                        setCourseInscription(response)
                        if (course.paymentEnabled) {
                            createNewCheckoutSession(response?.id)
                        } else {
                            openModalMessage()
                        }
                        closeModalCourseRegistration()
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                        throw toast.error('No se ha podido procesar su petición, error:' + error);
                    });
            } else {

                courseInscriptionsPush(data)
                    .then((response: CourseInscription) => {
                        console.log(response)
                        setCourseInscription(response)
                        if (course.paymentEnabled) {
                            createNewCheckoutSession(response?.id)
                        } else {
                            openModalMessage()
                        }
                        closeModalCourseRegistration()
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                        throw toast.error('No se ha podido procesar su petición, error:' + error);
                    });
            }
        }, 800);
    };

    const createNewCheckoutSession = (id) => {
        createCheckoutSession(id, window.location.origin)
            .then((response) => {
                console.log(response);
                contentUtils.openExternalUrl(response.url);
                setCheckoutSession(response);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const updateStatusCourseInscription = (status: number, sessionId: string) => {
        const data = {
            stripeCheckoutSessionId: sessionId ? sessionId : "",
            courseId: courseInscription?.courseId,
            userId: session.isLoggedIn ? session.collegiate?.id : null,
            creationDate: new Date(),
            creatorUsername: courseInscription?.email,
            numCollegiate: courseInscription?.numCollegiate,
            firstname: courseInscription?.firstname,
            lastname: courseInscription?.lastname,
            phone: courseInscription?.phone,
            email: courseInscription?.email,
            dni: courseInscription?.dni,
            collegeId: courseInscription?.collegeId,
            status: status,
        };
        if (!session.isLoggedIn) {
            noLoginUpdateCourseInscription(courseInscription?.id, data)
                .then((response) => {
                    console.log(response);
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    throw error;
                });
        } else {
            updateCourseInscription(courseInscription?.id, data)
                .then((response) => {
                    console.log(response);
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    throw error;
                });
        }
    }

    const [collegesList, setCollegesList] = useState<College[]>([]);

    const fetchCollegesList = async () => {
        setIsLoading(true)
        getCollege().then((response) => {
            console.log(response)
            setCollegesList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const [responseOk, setResponseOk] = useState(false)

    const calculatePercentage = (Id:number) =>{
        const filter = {
            collegeId: Id
        };
        if(Id){
        getCollegesByAgreementById(filter).then((response) => {
            console.log('COLEGIOID',response)
            if(response.length > 0){
                setResponseOk(true)
            }else{
                setResponseOk(false)
            }
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }
    }
console.log('respuesta', responseOk)

    var amount = course?.price * 100; //in cents 
    var discountICOFCV = agreementList.map(x => x.discountICOFCV)
    var discount = agreementList.map(x => x.discount)
    console.log('descuento', discount[0])
	var amountICOFCV = amount - (amount * (discountICOFCV[0] / 100.0)); //discount is betwen 0.0 and 100.0
    var amountOthers = amount - (amount * (discount[0] / 100.0));
    console.log('descuento1', amountOthers)
    
    const confirmPaymentAccepted = (sessionId : string) => {
        setIsLoading(true);
        getCheckoutSessionById(sessionId)
            .then((response: any) => {
                console.log(`*** PAGO ACEPTADO! ${response.id}`);
                setCheckoutSession(response);
                openModalPaymentConfirmation()
                closeModalCourseRegistration()
                //ACTUALIZAR ESTADO INSCRIPCION A 2 + ID SESION (response.id)
                updateStatusCourseInscription(2, response.id)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const confirmPaymentCancelled = () => {
        //if (!stripePaymentEvent.sessionId) {
            console.log('*** PAGO CANCELADO!');
            openModalPaymentCancelled()
            closeModalCourseRegistration()
            //ACTUALIZAR ESTADO INSCRIPCION A 1
            updateStatusCourseInscription(1, "")
            //return;
        //}
    }

    useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search);
        const paymentResult = queryParameters.get("payment_result");
        const uuid = queryParameters.get("uuid");

        console.log('******************window.location.search: ' + window.location.search);
        console.log('******************queryParameters: ' + JSON.stringify(queryParameters));
        console.log('******************paymentResult: ' + paymentResult);
        console.log('******************uuid: ' + uuid);

        if(paymentResult && lastRedirectId != uuid) {
            setLastRedirectId(uuid);
            const sessionId = queryParameters.get("session_id");

            console.log('******************sessionId: ' + sessionId);

            if(sessionId) {
                confirmPaymentAccepted(sessionId);
            } else {
                confirmPaymentCancelled();
            }
            
        } else {
            console.log(`*** fuera - stripePaymentEvent: ${JSON.stringify(stripePaymentEvent)}`);
            if (stripePaymentEvent && stripePaymentEvent.type === 'course') {
                console.log(`*** dentro - stripePaymentEvent: ${JSON.stringify(stripePaymentEvent)}`);
                // Reseteamos el evento
                dispatch({ type: CLEAR_STRIPE_PAYMENT_EVENT });
                console.log(`*** dispatch --> CLEAR_STRIPE_PAYMENT_EVENT`);
                if (!stripePaymentEvent.sessionId) {
                    confirmPaymentCancelled();
                    /*console.log('*** PAGO CANCELADO!');
                    openModalPaymentCancelled()
                    closeModalCourseRegistration()
                    //ACTUALIZAR ESTADO INSCRIPCION A 1
                    updateStatusCourseInscription(1, "")*/
                    return;
                }
                confirmPaymentAccepted(stripePaymentEvent.sessionId);
                /*setIsLoading(true);
                getCheckoutSessionById(stripePaymentEvent.sessionId)
                    .then((response: any) => {
                        console.log(`*** PAGO ACEPTADO! ${response.id}`);
                        setCheckoutSession(response);
                        openModalPaymentConfirmation()
                        closeModalCourseRegistration()
                        //ACTUALIZAR ESTADO INSCRIPCION A 2 + ID SESION (response.id)
                        updateStatusCourseInscription(2, response.id)
                        setIsLoading(false);
                    }).catch((error) => {
                        setIsLoading(false);
                        console.error(error);
                        throw error;
                    });*/
            }
        }
    });

    useEffect(() => {
        fetchCollegesList()
    }, []);

    return (
        <>
            {showModalCourseRegistration.show ? (
                <>
                    <div className="fixNotchMarginTop justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none p-0 mt-6">
                        <div className="relative p-2 w-full max-w-4xl h-screen z-50 md:h-auto">
                            {/*content*/}
                            <div className="relative bg-white rounded-lg shadow top-4 md:top-0">
                                {/*header*/}
                                <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                    <h3 className="text-lg font-medium">Inscripción curso</h3>
                                    <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalCourseRegistration}>
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                </div>
                                {/*body*/}
                                <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)} validationSchema={validationSchema}>

                                {props => {
                                     return (

                                    <Form>
                                        <div className="relative px-3 py-3 flex-auto overflow-auto modal-body">
                                            <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Datos del curso</h2>
                                            <div className="card-body px-2">
                                                <div className="mb-2">
                                                    <span className="text-stone-600 text-xs font-medium">Nombre del curso:</span>
                                                    <span className="text-lg text-neutral-800 font-medium news-title mb-2">{course.title}</span>
                                                </div>
                                                <div className="flex items-start md:items-center justify-start md:justify-between flex-col md:flex-row gap-4 mt-4">
                                                    <div className="mb-2 md:mb-4 flex items-center gap-2">
                                                        <span className="text-stone-600 text-xs font-medium whitespace-nowrap">Coste del curso:</span>
                                                        <span className="text-lg text-neutral-800 font-medium news-title">{formatNumbersUtils.formatNumber(course.price)}</span>
                                                    </div>
                                                    
                                                </div>
                                                {agreementList.map(agreement =>(
                                                    <>
                                                    {agreement.id == 1 && session.isLoggedIn  ?
                                                    <>
                                                     <div className="mb-2 flex items-center grid md:grid-cols-2 md:gap-4">
                                                        <div className="flex items-center gap-2 mb-2">
                                                            <span className="text-stone-600 text-xs font-medium whitespace-nowrap">Precio total a pagar:</span>
                                                            <p className="text-neutral-800 font-medium text-lg news-text-description">{(amountICOFCV / 100).toFixed(2).replace('.', ',') + ' €'}</p>
                                                        </div>
                                                    </div>
                                                    </>
                                                        :null}
                                                     </>
                                                     ))}

                                                    {agreementList.map(agreement =>(
                                                     <>
                                                     { responseOk ?
                                                    <div className="mb-2 flex items-center grid md:grid-cols-2 md:gap-4">
                                                        <div className="flex items-center gap-2 mb-2">
                                                            <span className="text-stone-600 text-xs font-medium whitespace-nowrap">Precio total a pagar: </span>
                                                            <p className="text-neutral-800 font-medium text-lg news-text-description">{(amountOthers / 100).toFixed(2).replace('.', ',') + ' €'}</p>
                                                        </div>
                                                    </div>
                                                    :null}
                                                    </> 
                                                ))}
                                                    {!session.isLoggedIn ?
                                                        <div className="mb-4 bg-teal-50 ring-1 ring-inset ring-teal-800/20 text-sm p-2.5 w-100 rounded-lg flex items-center flex-col md:flex-row gap-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle w-8 h-8 stroke-teal-800" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                                <path d="M12 8v4" />
                                                                <path d="M12 16h.01" />
                                                            </svg>
                                                            <p className="text-sm text-teal-800 text-left">
                                                                Este curso tiene un descuento para colegiados del ICOFCV, inicia sesión para aprovecharte de este descuento.
                                                                Para iniciar sesión pulsa <a className="text-teal-800 underline" onClick={openModal}>aquí.</a>
                                                            </p>
                                                        </div>
                                                        : null}
                                        </div>
                                            <h2 className="text-neutral-800 font-medium my-3 border-b border-neutral-300">Datos de contacto</h2>
                                            <div className="grid md:grid-cols-2 gap-2">
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="numCollegiate" className="block mb-2 text-xs font-medium text-stone-600">Número de colegiación*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="numCollegiate" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="numCollegiate" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="numCollegiate" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="phone" className="block mb-2 text-xs font-medium text-stone-600">Teléfono móvil*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="phone" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="phone" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="phone" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="firstname" className="block mb-2 text-xs font-medium text-stone-600">Nombre*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="firstname" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="firstname" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="firstname" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="lastname" className="block mb-2 text-xs font-medium text-stone-600">Apellidos*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="lastname" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="lastname" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="lastname" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>

                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="email" className="block mb-2 text-xs font-medium text-stone-600">Correo electrónico*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="email" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="email" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="email" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="collegeId" className="block mb-2 text-xs font-medium text-stone-600">Colegio</label>
                                                    {session.isLoggedIn ?
                                                        <>
                                                            <Field name="collegeId" type="text" id="" disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="collegeId" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="collegeId" as="select" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" data-accordion="accordion-collapse-body-1">
                                                           {calculatePercentage(parseInt(props.values.collegeId))}
                                                            <option value={""}>Seleccionar</option>
                                                            {collegesList.map(fields => ( 
                                                                <>
                                                               { console.log('fieldsssss',fields)}
                                                                {fields.enabled !== 0 ?
                                                                    <option key={fields.id} value={fields.id}>{fields.name}</option>  
                                                                :null}
                                                                </>
                                                            ))}

                                                        </Field>
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                <div className="relative z-0 w-full group mb-2">
                                                    <label htmlFor="dni" className="block mb-2 text-xs font-medium text-stone-600">DNI*</label>
                                                    {!session.isLoggedIn ?
                                                        <>
                                                            <Field name="dni" type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                            <ErrorMessage name="dni" component="span" className="mt-2 text-xs text-red-600" />
                                                        </>
                                                        :
                                                        <Field name="dni" disabled type="text" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                                    }
                                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                                </div>
                                                
                                            </div>
                                            <p className="text-xs text-teal-800 text-left mt-2 mb-2">El ICOFCV se reserva el derecho de cancelar la inscripción si los datos introducidos no son correctos. Además, el Colegio no asumirá la responsabilidad por los gastos adicionales generados por estas transacciones, siendo éstos responsabilidad del usuario y, por lo tanto, quienes deberán hacerse cargo.</p>
                                        </div>
                                        {/* footer */}
                                        <div className="bg-white flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalCourseRegistration}>Cancelar</button>
                                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="submit"  >Aceptar</button>
                                        </div>
                                    </Form>
                                          )
                                        }}
                                </Formik>
                                {/* { isLoading && <Loader /> } */}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
            <PopUpMessage showModalMessage={showModalMessage} closeModalMessage={closeModalMessage} />
            <ModalPaymentCancelled showModalPaymentCancelled={showModalPaymentCancelled} closeModalPaymentCancelled={closeModalPaymentCancelled} />
            <ModalPaymentConfirmation showModalPaymentConfirmation={showModalPaymentConfirmation} closeModalPaymentConfirmation={closeModalPaymentConfirmation} checkoutSession={checkoutSession} />
        </>
    )
}