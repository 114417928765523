import React, { Component } from 'react'
import logo from '../assets/images/logo.svg'
import './Home.css'
import { connect } from 'react-redux'
import cognitoUtils from '../lib/cognitoUtils'
import request from 'request'
import appConfig from '../config/app-config.json'
import { getNews } from '../api/drupalAPI'
import { NewsCarousel } from '../components/news/NewsCarousel'
import { RegistrationRequest } from '../components/register/RegistrationRequest'
import { HomeView } from '../views/HomeView'
//import Container from 'react-bootstrap/Container';
//import { NewsCarousel } from '../components/news/NewsCarousel'

const mapStateToProps = state => {
  return { session: state.session }
}

interface IHomeProps {
    session?: {
        isLoggedIn: boolean;
        user?: {
            userName: string;
            email: string;
        }
    };
  }
  
  interface IHomeState {
    apiStatus?: string;
    apiResponse?: string;
  }

  
class Home extends Component<IHomeProps,IHomeState> {
  constructor (props) {
    super(props)
    this.state = { apiStatus: 'Not called' }
  } 

  componentDidMount () {
    if (this?.props?.session?.isLoggedIn) {

      this.setState({ apiStatus: 'Loading...' });
      let apiStatus, apiResponse;
        getNews().then((response)=>{
            apiStatus = 'Successful response received.';
            apiResponse = JSON.stringify(response[0]);

            this.setState({ apiStatus, apiResponse });
        }).catch ( (error)  => {
            console.error(error);
            apiStatus = 'Unable to reach API';
            apiResponse = JSON.stringify(error);

            this.setState({ apiStatus, apiResponse });
        });
 
    }
  }

  onSignOut = (e) => {
    e.preventDefault()
    cognitoUtils.signOutCognitoSession()
  }

  render () {
    return (
        <>
        
            <HomeView  />           
         
         {/* <div className="columns-1 mx-auto px-2 main-container">  */}
            {/* Módulo actualidad */}
             {/* <div className="card bg-white px-2 py-3 md:p-6 my-3">
                <h1 className="pb-3 font-medium">Actualidad</h1>
                    <div id="default-carousel" className="relative" data-carousel="static">  */}
                        {/* Carousel wrapper */}
                         {/* <div className="relative h-64 overflow-hidden rounded-lg md:h-72">  */}
                            {/* Item 1 */}
                            
                             {/* <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 md:w-1/2 md:px-2" data-carousel-item="">
                                <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                                    <figure>
                                        <a href="#">
                                            <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-1.svg" alt="Doc" />
                                        </a>
                                    </figure>
                                    <div className="card-body p-1">
                                        <small className="text-teal-600 mb-2">MIÉ, 03/05/2017 - 10:06</small>
                                        <a href="#" className="hover:text-neutral-500">
                                           <h3 className="font-medium doc-title">NUEVA SESIÓN FORMATIVA SOBRE “NEUROMARKETING" PARA COLEGIADOS DEL ICOFCV</h3>
                                        </a>
                                    </div>
                                </div>  */}
                                {/*<img src="../carousel-1.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />*/}
                             {/* </div>  */}
                            {/* Item 2 */}
                             {/* <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-full z-10 md:w-1/2 md:px-2" data-carousel-item="">
                                <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                                    <figure>
                                        <a href="#">
                                            <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-2.svg" alt="Doc" />
                                        </a>
                                    </figure>
                                    <div className="card-body p-1">
                                        <small className="text-teal-600 mb-2">VIE, 20/01/2017 - 14:19</small>
                                        <a href="#" className="hover:text-neutral-500">
                                           <h3 className="font-medium doc-title">PUBLICADOS LOS LISTADOS DEFINITIVOS DE ADMITIDOS PARA LAS OPE DE FISIOTERAPEUTA...</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>  */}
                            {/* Item 3 */}
                             {/* <div className="duration-700 ease-in-out absolute inset-0 transition-all transform -translate-x-full z-10 md:w-1/2 md:px-2" data-carousel-item="">
                                <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                                    <figure>
                                        <a href="#">
                                            <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-3.svg" alt="Doc" />
                                        </a>
                                    </figure>
                                    <div className="card-body p-1">
                                        <small className="text-teal-600 mb-2">JUE, 12/01/2017 - 11:15</small>
                                        <a href="#" className="hover:text-neutral-500">
                                           <h3 className="font-medium doc-title">SI TIENES UN TRABAJO DE CARGA O UN TRABAJO DINÁMICO, ESCUCHA NUESTRO ESPACIO DE “SALUD Y FISIOTERAPIA”, EMITIDO EN ONDA CERO</h3>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        {/* </div>  */}
                         {/* Slider indicators  */}
            {/* //             <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2 indicators">
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white dark:bg-gray-800" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
            //             </div>
            //         </div> */}
            {/* //         <a className="see-more-link p-2 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" href="#">Ver más</a>
            // </div> */}
            {/* //     Módulo botones acción */}
            {/* //   <div> 
            //       <div className="block md:flex items-center w-100">
            //          <a href="#" className="bg-white items-center justify-between rounded-lg h-14 p-4 w-100 md:w-50 md:mr-2 my-2 border border-gray-400 hidden md:flex">
            //              <span className="font-medium">Colegiarse</span>
            //              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#231F20" fill="none" stroke-linecap="round" stroke-linejoin="round">
            //                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            //                  <polyline points="9 6 15 12 9 18" />
            //              </svg>
            //          </a>  */}
                  {/* Card solo movil */}
            {/* //           <div className="card w-100 bg-base-100 md:hidden bg-white px-2 py-3 my-3 action-card">
            //              <h1 className="pb-2 ml-1 font-medium">Colegiarse</h1>
            //              <div className="card-body p-1">
            //                  <h3 className="font-medium">SOLICITUD DE ALTA ONLINE  COMO EJERCIENTE</h3>
            //                  <p className="my-2">Si lo prefiere puede iniciar los trámites de alta, en la figura colegial de ejerciente, de forma completamente online sin necesidad de personarse en las Sedes del Colegio.</p>
            //                  <div className="card-actions flex justify-center">
            //                     <button className="btn bg-teal-600 text-white px-8 mt-2">Solicitar el alta online</button>
            //                  </div>
            //             </div>
            //         </div>
            //          <a href="#" className="bg-teal-700 text-white flex items-center justify-between rounded-lg h-14 p-4 w-100 md:w-50 md:ml-2 my-2">
            //              <span className="font-medium">Acceso usuario</span>
            //              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
            //                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            //                  <polyline points="9 6 15 12 9 18" />
            //              </svg>
            //          </a>
            //      </div> */}
            {/* //  </div>  */}
            {/* Módulo documentación */}
            {/* //  <div className="card bg-white px-2 py-3 md:p-6 my-3">
            //     <h1 className="pb-3 font-medium">Documentación</h1>
            //         <div id="default-carousel" className="relative" data-carousel="static">  */}
                        {/* Carousel wrapper */}
                        {/* //  <div className="relative h-64 overflow-hidden rounded-lg md:h-72">  */}
                            {/* Item 1 */}
                            {/* //  <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 md:w-1/2 md:px-2" data-carousel-item="">
                            //     <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                            //         <figure>
                            //             <a href="#">
                            //                 <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-1.svg" alt="Doc" />
                            //             </a>
                            //         </figure>
                            //         <div className="card-body p-1">
                            //             <small className="text-teal-600 mb-2">MIÉ, 03/05/2017 - 10:06</small>
                            //             <a href="#" className="hover:text-neutral-500">
                            //                <h3 className="font-medium doc-title">LA FISIOTERAPIA EN LAS MUTUAS DE TRABAJO: "PARLEM AMB…" TRES DESTACADOS FISIOTERAPEUTAS DE ESTE ÁMBITO</h3>
                            //             </a>
                            //         </div>
                            //     </div>  */}
                                {/*<img src="../carousel-1.svg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />*/}
                            {/* //  </div>  */}
                            {/* Item 2 */}
                            {/* //  <div className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-full z-10 md:w-1/2 md:px-2" data-carousel-item="">
                            //     <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                            //         <figure>
                            //             <a href="#">
                            //                 <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-2.svg" alt="Doc" />
                            //             </a>
                            //         </figure>
                            //         <div className="card-body p-1">
                            //             <small className="text-teal-600 mb-2">VIE, 20/01/2017 - 14:19</small>
                            //             <a href="#" className="hover:text-neutral-500">
                            //                <h3 className="font-medium doc-title">EL TRABAJO ESTÁTICO Y EL REPETITIVO EN NUESTRO ESPACIO “SALUD Y FISIOTERAPIA” EN ONDA CERO</h3>
                            //             </a>
                            //         </div>
                            //     </div>
                            // </div>  */}
                            {/* Item 3 */}
                        {/* //      <div className="duration-700 ease-in-out absolute inset-0 transition-all transform -translate-x-full z-10 md:w-1/2 md:px-2" data-carousel-item="">
                        //         <div className="card h-52 md:h-60 bg-white md:border-1 carousel-card p-2">
                        //             <figure>
                        //                 <a href="#">
                        //                     <img className="h-28 md:h-36 w-100 object-cover object-center rounded-lg" src="../carousel-3.svg" alt="Doc" />
                        //                 </a>
                        //             </figure>
                        //             <div className="card-body p-1">
                        //                 <small className="text-teal-600 mb-2">JUE, 12/01/2017 - 11:15</small>
                        //                 <a href="#" className="hover:text-neutral-500">
                        //                    <h3 className="font-medium doc-title">SI TIENES UN TRABAJO DE CARGA O UN TRABAJO DINÁMICO, ESCUCHA NUESTRO ESPACIO DE “SALUD Y FISIOTERAPIA”, EMITIDO EN ONDA CERO</h3>
                        //                 </a>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>  */}
                        {/* Slider indicators */}
            {/* //              <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2 indicators">
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white dark:bg-gray-800" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
            //                 <button type="button" className="w-3 h-3 rounded-full bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
            //             </div>
            //         </div> */}
            {/* //         <a className="see-more-link p-2 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" href="#">Ver más</a>
            // </div>  */}
            {/* Redes Sociales */}
        {/* //      <div className="flex justify-center items-center pb-4 md:hidden">
        //             <ul className="items-center flex">
        //                 <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
        //                     <a href="/#/">
        //                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-twitter" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        //                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        //                         <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
        //                     </svg>
        //                     </a>
        //                 </li>
        //                 <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
        //                     <a href="/#/college">
        //                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-youtube" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        //                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        //                         <rect x="3" y="5" width="18" height="14" rx="4" />
        //                         <path d="M10 9l5 3l-5 3z" />
        //                     </svg>
        //                     </a>
        //                 </li>
        //                 <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
        //                     <a href="/#/docs">
        //                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        //                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        //                         <rect x="4" y="4" width="16" height="16" rx="4" />
        //                         <circle cx="12" cy="12" r="3" />
        //                         <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
        //                     </svg>
        //                     </a>
        //                 </li>
        //                 <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
        //                     <a href="/#/profile">
        //                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        //                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        //                         <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
        //                     </svg>
        //                     </a>
        //                 </li>
        //             </ul>
        //         </div>

        // </div>  */}

      </>
    )
  }
}

export default connect(mapStateToProps)(Home)


        {/* <Container> 

       
        <div className="content-home">
            
              <div className="content-components">
                <NewsCarousel />
                <header className="Home-header">
                { this.props.session?.isLoggedIn ? (
                    <div>
                    <p>You are logged in as user {this.props.session?.user?.userName} ({this.props.session?.user?.email}).</p>
                    <p></p>
                    <div>
                        <div>API status: {this.state.apiStatus}</div>
                        <div className="Home-api-response">{this.state.apiResponse}</div>
                    </div>
                    <p></p>
                    <a className="Home-link" href="#" onClick={this.onSignOut}>Sign out</a>
                    </div>
                ) : (
                    <div>
                    <p>You are not logged in.</p>
                    <a className="Home-link" href={cognitoUtils.getCognitoSignInUri()}>Sign in</a>
                    </div>
                )}
                
                </header>
            </div>
       </div>
      </Container> */}