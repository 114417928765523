import { UPDATE_DEVICE_INFO } from '../constants/actionTypes'

const defaultState = {
    platform: 'browser',
    uuid: null,
    notificationToken: null,
    version: null
}

  const deviceInfo = (state = defaultState, action) => {
    switch (action.type) {
      case UPDATE_DEVICE_INFO:
        return Object.assign({}, defaultState, state,
          action.deviceInfo)
  
      default:
        return state
    }
  }
  
  export default deviceInfo;

