import { useEffect } from 'react'
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation'
import { COLLEGE_MODULE} from '../../constants/modules'
import { Link } from "react-router-dom";


export const ExtendedInformation = () => {

    const store = useStore();
 
    useEffect (() => {
        store.dispatch(setDetailNavigation(COLLEGE_MODULE, 'Donde estamos')); 
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start">
                <Link className="w-full pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">¿Dónde estamos?</span>
                </Link>
            </div>

            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-80 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">¿Dónde estamos?</span>
                    </Link>
                    <div className="card-body py-2 lg:px-12 w-full lg:w-3/4 mx-auto">
                        {/* Sede Valencia */}
                        <div className="w-100 mb-5 pb-4 border-bottom grid grid-cols-12 md:gap-8">
                            <iframe
                                className="w-100 h-64 md:h-96 col-span-12 md:col-span-7"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.3238269926374!2d-0.3805919350162851!3d39.46959682083709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd604f4bee0957f3%3A0x6686ff7d230b3965!2zQy4gZGUgU2FudCBWaWNlbnQgTcOgcnRpciwgNjEsIHBpc28gMsK6LCBwdGEgMsKqLCA0NjAwMiBWYWzDqG5jaWEsIEVzcGHDsWE!5e0!3m2!1ses!2sus!4v1662388390673!5m2!1ses!2sus"
                                loading="lazy">
                            </iframe>
                            <div className="col-span-12 md:col-span-5">
                                <h3 className="font-medium text-md mt-3">SEDE VALENCIA</h3>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    San Vicent Màrtir nº 61 piso 2º, pta 2ª<br></br>
                                    46002 Valencia
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de oficina: </span><br></br>
                                    De lunes a jueves: 9:00h-14:00h y 16:00h-19:00h<br></br>
                                    Viernes: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de verano (15 de junio a 15 de septiembre) </span><br></br>
                                    Lunes a miércoles: 8:00h-15:00h y 16:00 -19:00h<br></br>
                                    Jueves y viernes: 8:00h-15:00h<br></br>
                                    Agosto: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <a href="mailto: administracion@colfisiocv.com" className="text-teal-600 underline mr-2">administracion@colfisiocv.com</a><br></br>
                                    <span>Teléfono: 963 533 968</span>
                                </p>
                            </div>
                        </div>
                        {/* Sede Alicante */}
                        <div className="w-100 mb-5 pb-4 border-bottom grid grid-cols-12 md:gap-8">
                            <iframe
                                className="w-100 h-64 md:h-96 col-span-12 md:col-span-7"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1706.104065059169!2d-0.4937344843841675!3d38.3487818016013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd62365222f4973f%3A0x238951614fd48449!2sAv.%20General%20Marv%C3%A1%2C%2034%2C%20esc%201%2C%20bajo%201%2C%2003004%20Alacant!5e0!3m2!1ses!2ses!4v1698232148585!5m2!1ses!2ses" width="600" loading="lazy">
                            </iframe>
                            <div className="col-span-12 md:col-span-5">
                                <h3 className="font-medium text-md mt-3">SEDE ALICANTE</h3>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    Av. General Marvá, 34, esc 1, bajo 1<br></br>
                                    03004 Alicante
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de oficina: </span><br></br>
                                    Lunes a jueves: 9:00h-14:00h y 16:00h-19:00h<br></br>
                                    Viernes: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de verano (15 de junio a 15 de septiembre) </span><br></br>
                                    Lunes, jueves y viernes: 8:00h-15:00h<br></br>
                                    Martes y miércoles: 8:00h-15:00h y 16:00h-19:00h<br></br>
                                    Agosto: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <a href="mailto: sedealicante@colfisiocv.com" className="text-teal-600 underline mr-2">sedealicante@colfisiocv.com</a><br></br>
                                    <span>Teléfono: 963 215 005</span>
                                </p>
                            </div>
                        </div>
                        {/* Sede Castellon */}
                        <div className="w-100 mb-5 pb-4 grid grid-cols-12 md:gap-8">
                            <iframe
                                className="w-100 h-64 md:h-96 col-span-12 md:col-span-7"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.770004000899!2d-0.06080408461698022!3d39.99124487941695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5ffe19d4202081%3A0x37cd40e4533be74e!2sP.%C2%BA%20de%20la%20Universidad%2C%2011%2C%20Esc%202%2C%20Local%206%2C%2012006%20Castell%C3%B3n%20de%20la%20Plana%2C%20Castell%C3%B3n!5e0!3m2!1ses!2ses!4v1663062617605!5m2!1ses!2ses"
                                loading="lazy">
                            </iframe>
                            <div className="col-span-12 md:col-span-5">
                                <h3 className="font-medium text-md mt-3">SEDE CASTELLÓN</h3>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    Paseo de la Universidad, nº 11, Esc 2 Bajo 6<br></br>
                                    12006, Castellón de la Plana
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de oficina: </span><br></br>
                                    Lunes a jueves: 9:00h-14:00h y 16:00h-19:00h<br></br>
                                    Viernes: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <span className="font-medium">Horario de verano (15 de junio a 15 de septiembre) </span><br></br>
                                    Lunes, martes y viernes: 8:00h-15:00h<br></br>
                                    Miércoles: 8:00h-15:00h y 16:00h-18:00h<br></br>
                                    Jueves: 9:00h-14:00h y 16:00h-19:00h<br></br>
                                    Agosto: 8:00h-15:00h
                                </p>
                                <p className="my-2 text-sm text-neutral-500 font-normal">
                                    <a href="mailto: sedecastellon@colfisiocv.com" className="text-teal-600 underline mr-2">sedecastellon@colfisiocv.com</a><br></br>
                                    <span>Teléfono: 960 910 660</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
