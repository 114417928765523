
export const getItem = (key: string): any => {
    const value = localStorage.getItem(key);
    if (!value) {
        return null;
    }
    return JSON.parse(value);
};

export const setItem = (key: string, data: any) => {
    return localStorage.setItem(key, JSON.stringify(data));
};