import store from '../../store';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { Course, Agreement } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { Link } from "react-router-dom";
import Loader from '../spinner/Loader';
import { getCoursesById } from '../../api/drupalAPI';
import { getCourseById, getCourseInscription, getCourseInscriptionNoLogin, getAgreements } from '../../api/icofcvAPI';
import { ModalNoLogin } from '../miProfile/ModalNoLogin';
import { ModalCourseRegistration } from './ModalCourseRegistration';
import formatNumbersUtils from '../../lib/formatNumbersUtils';
import { PopUpMessageInscription } from './PopUpMessageIncription';
import statusTypeId from './StatusTypeId';
import dateUtils from '../../lib/dateUtils';
import appConfig from '../../config/app-config.json';
import { ModalStripeOldAppVersion } from './ModalStripeOldAppVersion';
import jquery from 'jquery';
import { PopUpMessageSoldOutPlaces } from './PopUpMessageSoldOutPlaces';

export const FormationDetail = () => {

    const session = useSelector((state: RootState) => state.session);
    const $: JQueryStatic = jquery;
    const [currentCourse, setCurrentCourse] = useState<any>(null);
    const [course, setCourse] = useState<Course[]>([]);
    const [agreementList, setAgreementList] = useState<Agreement[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [showModalMessageInscription, setShowModalMessageInscription] = useState(false);
    const openModalMessageInscription = () => {
        setShowModalMessageInscription(true);
        scrollBlock();
    }
    const closeModalMessageInscription = () => {
        setShowModalMessageInscription(false);
        scrollActive();
    }

    const [showModalMessageSoldOutPlaces, setShowModalMessageSoldOutPlaces] = useState(false);
    const openModalMessageSoldOutPlaces = () => {
        setShowModalMessageSoldOutPlaces(true);
        scrollBlock();
    }
    const closeModalMessageSoldOutPlaces = () => {
        setShowModalMessageSoldOutPlaces(false);
        scrollActive();
    }

    const [showModalCourseRegistration, setShowModalCourseRegistration] = useState({ show: false, id: -1, fields: {} });


    function scrollBlock() {
        $("#root").css("overflow-y", "hidden");
    }

    function scrollActive() {
        $("#root").css("overflow-y", "auto");
    }

    const [showModalStripeOldAppVersion, setShowModalStripeOldAppVersion] = useState(false);
    const openModalStripeOldAppVersion = () => setShowModalStripeOldAppVersion(true)
    const closeModalStripeOldAppVersion = () => setShowModalStripeOldAppVersion(false)

    const versionCompare = function (v1: any, v2: any) {
        // vnum stores each numeric 
        // part of version 
        let vnum1 = 0;
        let vnum2 = 0;

        // loop until both string are 
        // processed 
        for (var i = 0, j = 0; (i < v1.length || j < v2.length);) {
            // storing numeric part of 
            // version 1 in vnum1 
            while (i < v1.length && v1[i] != '.') {
                vnum1 = vnum1 * 10 + (v1[i] - 0);
                i++;
            }

            // storing numeric part of 
            // version 2 in vnum2 
            while (j < v2.length && v2[j] != '.') {
                vnum2 = vnum2 * 10 + (v2[j] - 0);
                j++;
            }

            if (vnum1 > vnum2)
                return 1;
            if (vnum2 > vnum1)
                return -1;

            // if equal, reset variables and 
            // go for next numeric part 
            vnum1 = vnum2 = 0;
            i++;
            j++;
        }
        return 0;
    }

    const openModalCourseRegistration = (id: number, fields: Course) => {
        if (session.isLoggedIn && fields.paymentEnabled) {

            const di = store.getState().deviceInfo;
            const appVersion = di.version;
            const devicePlatform = di.platform;

            if (devicePlatform == 'android' && versionCompare(appVersion, appConfig.androidAppVersionStripe) < 0) {
                openModalStripeOldAppVersion();
            } else if (devicePlatform == 'ios' && versionCompare(appVersion, appConfig.iOSAppVersionStripe) < 0) {
                openModalStripeOldAppVersion();
            } else {
                const filter = {
                    courseId: id,
                    userId: session.collegiate?.id,
                    status: 2
                };

                getCourseInscription(filter)
                    .then((response) => {
                        console.log(response);
                        if (response.length > 0) {
                            openModalMessageInscription();
                            scrollBlock();
                        } else {
                            const filter1 = {
                                courseId: id,
                                //userId: session.collegiate?.id,
                                status: 2
                            };
                            getCourseInscription(filter1)
                                .then((response) => {
                                    console.log(response);
                                    if (response.length >= currentCourse?.places) {
                                        openModalMessageSoldOutPlaces()
                                        scrollBlock();
                                    } else {
                                        fetchCourseById(id, fields);
                                    }
                                }).catch((error) => {
                                    setIsLoading(false);
                                    console.error(error);
                                    throw error;
                                });
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        console.error(error);
                        throw error;
                    });
            }

        } else if (session.isLoggedIn && fields.paymentEnabled === false) {
            const filter = {
                courseId: id,
                userId: session.collegiate?.id,
                status: 3
            };

            getCourseInscription(filter)
                .then((response) => {
                    console.log(response);
                    if (response.length > 0) {
                        openModalMessageInscription();
                        scrollBlock();
                    } else {
                        const filter1 = {
                            courseId: id,
                            //userId: session.collegiate?.id,
                            status: 3
                        };
                        getCourseInscription(filter1)
                            .then((response) => {
                                console.log(response);
                                if (response.length >= currentCourse?.places) {
                                    openModalMessageSoldOutPlaces()
                                    scrollBlock();
                                } else {
                                    fetchCourseById(id, fields);
                                }
                            }).catch((error) => {
                                setIsLoading(false);
                                console.error(error);
                                throw error;
                            });
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    throw error;
                });
        } else {

            const filter = {
                courseId: id,
            };

            getCourseInscriptionNoLogin(filter)
                .then((response) => {
                    console.log(response);
                    if (response.length >= currentCourse?.places) {
                        openModalMessageSoldOutPlaces()
                        scrollBlock();
                    } else {
                        fetchCourseById(id, fields);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    throw error;
                });
        }

        setIsLoading(false);
    };

    const fetchCourseById = (id, fields) => {
        getCourseById(id)
            .then((response: any) => {
                console.log(response);
                setShowModalCourseRegistration({ show: true, id, fields })
                scrollBlock()
                setCourse(response);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const closeModalCourseRegistration = () => {
        setShowModalCourseRegistration({ show: false, id: -1, fields: {} })
        scrollActive()
    };



    const fetchAgreementsList = () => {
        getAgreements()
            .then((response) => {
                console.log(response);
                setAgreementList(response)
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        setIsLoading(true);
        getCoursesById(id)
            .then((response) => {
                console.log(response);
                setCurrentCourse(response)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
        fetchAgreementsList()
    }, []);

    const updateTable = () => { }

    var amount = currentCourse?.price * 100; //in cents 
    var discountICOFCV = agreementList.map(x => x.discountICOFCV)
    var discount = agreementList.map(x => x.discount)
    console.log('descuento', discount[0])
    var amountICOFCV = amount - (amount * (discountICOFCV[0] / 100.0)); //discount is betwen 0.0 and 100.0
    var amountOthers = amount - (amount * (discount[0] / 100.0));
    console.log('descuento1', amountOthers)

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/college/formationlist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title ml-2">Volver</span>
                </Link>
            </div>
            <div key={currentCourse?.id} className="columns-1 mx-auto px-2 main-container pb-12 flex">
                <div className="flex card bg-white px-2 py-3 md:p-6 my-3 w-100">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college/formationlist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">Volver</h1>
                    </Link>
                    <div className="card-body md:p-6 md:m-10">
                        <div className="grid grid-cols-1 gap-4 my-2">

                            <div className="flex mb-2">
                                <p className="text-lg text-neutral-800 font-medium news-title">{currentCourse?.title}</p>
                                <p className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 ml-2 whitespace-nowrap">{statusTypeId(currentCourse?.typeId)}</p>
                            </div>
                            <div className="mb-2">
                                <div dangerouslySetInnerHTML={{ __html: currentCourse?.description ? currentCourse?.description : '' }}></div>
                            </div>
                            {currentCourse?.link ?
                                <div className="flex flex-wrap mb-1">
                                    <span className="text-neutral-800 text-sm font-normal whitespace-nowrap mr-2">Enlace externo:</span>
                                    <a className="text-teal-600 hover:text-teal-800 text-sm underline" href={currentCourse?.link} target="blank_">{currentCourse?.link}</a>
                                </div>
                                : null}
                            <div className="flex">
                                {currentCourse?.documentPath ?
                                    <button onClick={() => contentUtils.openExternalUrl(currentCourse?.documentPath)} className="flex items-center text-teal-600 ">
                                        <span className="text-sm mr-2 underline block">Programa del curso</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link stroke-teal-600 hover:stroke-teal-800" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                            <path d="M7 11l5 5l5 -5" />
                                            <path d="M12 4l0 12" />
                                        </svg>
                                    </button>
                                    : null}
                            </div>
                            <div className="grid grid-cols-2 gap-4 my-2">
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium">Organizador:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.organizer}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">Lugar:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.location}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">Duración:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.duration}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">Nº plazas:</span>
                                    {currentCourse?.places > 0 ?
                                        <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.places}</div>
                                        :
                                        <div className="text-neutral-600 font-normal text-sm news-text-description">Sin limite</div>
                                    }
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">Fecha del curso:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.durationDates}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">Modalidad:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentCourse?.modality}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">F. Inicio inscripción:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{dateUtils.utcToDate(currentCourse?.inscriptionStartDate)}</div>
                                </div>
                                <div className="">
                                    <span className="text-neutral-800 text-sm font-medium whitespace-nowrap">F. Fin inscripción:</span>
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{dateUtils.utcToDate(currentCourse?.inscriptionEndDate)}</div>
                                </div>
                            </div>
                            <hr></hr>

                            <div className="mb-2 flex items-center flex-col md:flex-row md:justify-start gap-5">
                                <div className="grid grid-cols-2 flex items-center justify-between gap-4">
                                    {!currentCourse?.internalManagement ?
                                        <>
                                            <span className="text-neutral-800 text-sm font-normal whitespace-normal">Precio del curso:</span>
                                            <p className="text-black text-sm font-medium text-right md:text-left">{formatNumbersUtils.formatNumber(currentCourse?.price)}</p>
                                        </>
                                        : null}
                                    {currentCourse?.typeId !== 9 &&
                                        <>
                                            <span className="text-neutral-800 text-sm font-normal whitespace-normal">Precio del curso colegiados ICOFCV:</span>
                                            {discountICOFCV && discountICOFCV[0] > 0.0 ?
                                                <p className="text-black text-sm font-medium text-right md:text-left">{(amountICOFCV / 100).toFixed(2).replace('.', ',') + ' €'}</p>
                                                : null}
                                            {!currentCourse?.internalManagement ?
                                                <>
                                                    <span className="text-neutral-800 text-sm font-normal whitespace-normal">Precio del curso Colegios con convenio:</span>
                                                    {discount && discount[0] > 0.0 ?
                                                        <p className="text-black text-sm font-medium text-right md:text-left">{(amountOthers / 100).toFixed(2).replace('.', ',') + ' €'}</p>
                                                        : null}
                                                </>
                                                : null}
                                            {!currentCourse?.internalManagement ?
                                                <>
                                                    <span className="text-neutral-800 text-sm font-normal whitespace-normal">Otros colegios:</span>
                                                    <p className="text-black text-sm font-medium text-right md:text-left">{formatNumbersUtils.formatNumber(currentCourse?.price)}</p>
                                                </>
                                                : null}
                                        </>}
                                    {currentCourse?.soldOutPlaces && currentCourse?.inscriptionStartDate < new Date().toISOString().slice(0, -8) && currentCourse?.inscriptionEndDate > new Date().toISOString().slice(0, -8) ?
                                        <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20 whitespace-nowrap">Plazas agotadas</div>
                                        : null}
                                    {currentCourse?.inscriptionStartDate < new Date().toISOString().slice(0, -8) && currentCourse?.inscriptionEndDate > new Date().toISOString().slice(0, -8) ?
                                        <div></div>
                                        :
                                        <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20 whitespace-nowrap">Fuera plazo inscripción</div>
                                    }
                                </div>
                            </div>
                            {!session.isLoggedIn && currentCourse?.typeId !== 9 &&
                                <div className="mb-2 bg-teal-50 ring-1 ring-inset ring-teal-800/20 text-sm p-2.5 w-3/3 rounded-lg flex items-center justify-center flex-col md:flex-row gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle w-8 h-8 stroke-teal-800" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M12 8v4" />
                                        <path d="M12 16h.01" />
                                    </svg>
                                    <p className="text-sm text-teal-800 text-center">Este curso tiene un descuento para colegiados del ICOFCV, inicia sesión para aprovecharte de este descuento. Para iniciar sesión pulsa <a className="text-teal-800 underline text-sm" onClick={openModal}>aquí.</a></p>
                                </div>
                            }
                            <div className="mb-2 flex justify-center">
                                <img className="rounded-lg my-2 w-2/2 md:w-2/3 h-44 md:h-96 max-w-full object-contain object-center" src={contentUtils.getLargeImageUrl(currentCourse?.imagePath)} alt="Sin imagen" />
                            </div>
                        </div>
                        {currentCourse?.typeId !== 9 &&
                            <div className="flex justify-center mb-2">
                                {currentCourse?.inscriptionStartDate.slice(0, -8) < new Date().toISOString().slice(0, -8) && currentCourse?.inscriptionEndDate.slice(0, -8) > new Date().toISOString().slice(0, -8) && !currentCourse?.soldOutPlaces && !currentCourse?.internalManagement ?

                                    <button onClick={() => openModalCourseRegistration(currentCourse?.id, currentCourse)} type="button" className="bg-teal-600 hover:bg-teal-800 text-white px-8 py-2 mt-2 flex items-center justify-center mx-auto w-auto rounded">
                                        <span className="ml-2">Inscribirme a este curso</span>
                                    </button>

                                    : null}
                                {currentCourse?.inscriptionStartDate.slice(0, -8) < new Date().toISOString().slice(0, -8) && currentCourse?.inscriptionEndDate.slice(0, -8) > new Date().toISOString().slice(0, -8) && currentCourse?.internalManagement && session.isLoggedIn ?

                                    <button onClick={() => openModalCourseRegistration(currentCourse?.id, currentCourse)} type="button" className="bg-teal-600 hover:bg-teal-800 text-white px-8 py-2 mt-2 flex items-center justify-center mx-auto w-auto rounded">
                                        <span className="ml-2">Inscribirme a este curso</span>
                                    </button>
                                    : null}
                                {currentCourse?.soldOutPlaces || currentCourse?.internalManagement && !session.isLoggedIn ?
                                    <button disabled type="button" className="bg-gray-300 hover:bg-gray-300 text-white text-sm active:bg-teal-700 px-8 outline-none focus:outline-none py-2 rounded">
                                        <span className="ml-2">Inscribirme a este curso</span>
                                    </button>
                                    : null}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
            <ModalCourseRegistration showModalCourseRegistration={showModalCourseRegistration} closeModalCourseRegistration={closeModalCourseRegistration} updateTable={updateTable} agreementList={agreementList} />
            <PopUpMessageInscription showModalMessageInscription={showModalMessageInscription} closeModalMessageInscription={closeModalMessageInscription} />
            <ModalStripeOldAppVersion showModalStripeOldAppVersion={showModalStripeOldAppVersion} closeModalStripeOldAppVersion={closeModalStripeOldAppVersion} />
            <PopUpMessageSoldOutPlaces showModalMessageSoldOutPlaces={showModalMessageSoldOutPlaces} closeModalMessageSoldOutPlaces={closeModalMessageSoldOutPlaces} />

        </>
    )
}  