import { useState } from 'react'
import { useEffect } from 'react'
import { NewsCarousel } from '../components/news/NewsCarousel'
import { RegistrationRequest } from '../components/register/RegistrationRequest'
import { useSelector } from "react-redux";
import type { RootState } from '../store/index'
import { NavLink } from "react-router-dom";
import { setTopLevelNavigation } from '../actions/configNavigation'
import { useStore } from 'react-redux'
import { HOME_MODULE } from '../constants/modules'
import contentUtils from '../lib/contentUtils';
import { ButtonAccessUsser } from '../components/user-acces/ButtonAccessUsser'
import { SocialNetworks } from '../components/social-networks/SocialNetworks';
import { YoutubeCarousel } from '../components/youtube/YoutubeCarousel';
import { InstagramGallery } from '../components/instagram/InstagramGallery';
// import { getConfig } from "../api/icofcvAPI";
import { Config } from '@icofcv/common';
import { AccessAdvantagesForCollegiate } from '../components/miProfile/AccessAdvantagesForCollegiate';
import { AccessFormationHomeButton } from '../components/miProfile/AccessFormation';

export const HomeView = () => {
    const store = useStore();
    const session = useSelector((state: RootState) => state.session);
    const urlPoliticaPrivacidad = "https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Politica-privacidad.pdf";
    const urlAvisoLegal = "https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Aviso-Legal.pdf";
    const urlNormativaCookies = "https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Normativa-cookies.pdf";
    const openLinkPoliticaPrivacidad = () => contentUtils.openExternalUrl(urlPoliticaPrivacidad);
    const openLinkAvisoLegal = () => contentUtils.openExternalUrl(urlAvisoLegal);
    const openLinkNormativaCookies = () => contentUtils.openExternalUrl(urlNormativaCookies);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        store.dispatch(setTopLevelNavigation(HOME_MODULE));
    }, []);

    return (
        <>
            <div className="fixNotchPaddingTop bg-teal-600 block md:hidden fixed w-full z-20 top-2 left-0 pt-3 pb-4 flex justify-between">
                <img className="block h-10 w-auto md:hidden mt-4" src="../logo-icofcv-white.svg" alt="Ilustre Colegio Oficial de Fisioterapeutas Comunidad Valenciana" />
                <div className="flex flex-column items-end justify-end py-0 w-5/6">
                    {session.isLoggedIn && session.collegiate ?
                        <>
                            <span className="text-xs font-medium text-white text-right block whitespace-nowrap text-ellipsis overflow-hidden w-32">{session.collegiate.firstname} {session.collegiate.lastname}</span>
                            <span className="text-xs font-light text-white text-right">Nº Colegiado: {session.collegiate.memberId}</span>
                        </>
                        : null
                    }
                </div>
            </div>
            <div className="px-0 main-container pb-16 top-8 md:top-5">
                <NewsCarousel />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 px-2 flex items-center">
                    {session.isLoggedIn ?
                        <>
                            <AccessAdvantagesForCollegiate />
                            <AccessFormationHomeButton />
                        </>
                        :
                        <>
                            <ButtonAccessUsser />
                            <RegistrationRequest />
                            <AccessAdvantagesForCollegiate />
                            <AccessFormationHomeButton />
                        </>
                    }
                </div>

                <div className="grid grid-cols-1 md:grid-cols-1 gap-3 my-3 ml-2 mr-2">
                    <YoutubeCarousel />
                    {/* <InstagramGallery  pagination={true}  /> */}
                </div>
                {/* Redes Sociales */}
                <SocialNetworks />
                {/* Avisos legales */}
                <div className="flex items-center justify-center flex-wrap pb-16 md:pb-0">
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" onClick={openLinkPoliticaPrivacidad}>Política de privacidad</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" onClick={openLinkAvisoLegal}>Aviso legal</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" onClick={openLinkNormativaCookies}>Política de cookies</a>
                    <span className="text-teal-600">|</span>
                    <NavLink to="/contactform" className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline">Formulario de contacto</NavLink>
                </div>
            </div>
        </>
    )
}
