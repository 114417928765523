import { useState, useEffect } from 'react'
import { getNews } from '../../api/drupalAPI'
import { News } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
import './NewsList.css'
import Loader from '../spinner/Loader';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getItemsPerPage, getNewsPaginated } from '../../api/drupalAPI';
import { setDetailNavigation } from '../../actions/configNavigation'
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { Link } from "react-router-dom";

export const NewsList = () => {

    const store = useStore();
    const [newsList, setNewsList] = useState<News[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [currentPageNews, setCurrentPageNews] = useState(0);
    const [viewMoreNewsDataDisabled, setViewMoreNewsDataDisabled] = useState(false);

    const fetchNewsList = async () => {
        setIsLoading(true);
        getNews().then((response) => {
            console.log(response);
            setNewsList(response);
            setIsLoading(false);
            var filter = {
                page: 0,
            };
            fetchNewsPaginated(filter)
        }).catch((error) => {
            setIsLoading(false);
            console.error(error);
            throw error;
        });
    }

    const fetchNewsPaginated = async (filter) => {
        setIsLoading(true);
        getNewsPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreNewsDataDisabled(false);

                } else {
                    setViewMoreNewsDataDisabled(true);
                }

                var list: News[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...newsList, ...response];
                }
                setNewsList(list);
                console.log(list);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const handleShowMoreData = () => {
        setCurrentPageNews(currentPageNews + 1);
        var filter = {
            page: currentPageNews + 1,
        };
        fetchNewsPaginated(filter);
    }

    function handleAddToDetail(id) {
        console.log('ID', id)
        navigate('newsdetail?id=' + id);
    }

    useEffect(() => {
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
        fetchNewsList()
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Actualidad</span>
                </Link>
            </div>

            <div className="columns-1 mx-auto px-2 main-container pb-12">
                {/* NewsCarousel */}
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Actualidad</span>
                    </Link>
                    {/* NewsList */}
                    {isLoading && <Loader />}
                    {
                        newsList.map(news => (
                            
                            <div key={news.id} className="bg-white px-2 py-3 md:h-56 border-b rounded-0">
                                <button type="button" className="text-left overflow-hidden pb-2 w-100" onClick={() => handleAddToDetail(news.id)}>
                                    <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                        <img className="h-48 md:w-72" src={contentUtils.getLargeImageUrl(news.picture)} alt="NewsImage" />
                                        <div className="ml-2 md:h-36">
                                            <small className="text-teal-600 mb-2">{format(new Date(news.creationDate), 'EEE dd/MM/Y', { locale: es }).toUpperCase()}</small>
                                            <p className="text-lg text-neutral-800 font-medium news-title">{news.title}</p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ))
                    }
                </div>
                {viewMoreNewsDataDisabled === false ?
                    <div className="flex items-center justify-end mx-3">
                        <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                    </div>
                    :
                    <div></div>
                }
            </div>
        </>
    )
}