import { useState, useEffect } from 'react';
import { getNewsById } from '../../api/drupalAPI'
import contentUtils from '../../lib/contentUtils'
import './NewsDetail.css'
import { Link } from "react-router-dom";
import dateUtils from '../../lib/dateUtils'

export const NewsDetail = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [currentNews, setCurrentNews] = useState<any>(null);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        setIsLoading(true);
        getNewsById(id)
            .then((response) => {
                console.log(response);
                setCurrentNews(response)
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/newslist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6 w-1/3 ml-0" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title">{currentNews?.title}</span>
                </Link>
            </div>
            <div key={currentNews?.id} className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/newslist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">{currentNews?.title}</h1>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <img className="mb-2 mx-auto max-w-xs md:max-w-xl h-52 md:h-96" src={contentUtils.getLargeImageUrl(currentNews?.picture)} alt="#" />
                            <small className="text-teal-600 mb-4">{dateUtils.utcToDate(currentNews?.creationDate)}</small>
                            <div className="text-lg text-neutral-800 font-medium my-2">{currentNews?.title}</div>
                            <div className="text-neutral-600 font-sans news-text-description mb-2" dangerouslySetInnerHTML={{ __html: currentNews?.content }}></div>
                            {currentNews?.videoUrl !== null ?
                                <iframe className="mb-2 mx-auto max-w-xs md:max-w-xl h-52 md:h-96" width="560" height="315" src={`https://www.youtube.com/embed/${currentNews?.videoUrl.split("https://youtu.be/")[1]}`} title="ICOFCV" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            :
                            null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



