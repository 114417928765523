import React from 'react'
import { useNavigate } from "react-router-dom";



export const AccessList = () => {
    const navigate = useNavigate();

    function goToPageClinicsList() {
        navigate('clinicslist');
    }

    return (
        <>

            <div className="card md:h-60 bg-white px-2 py-2 md:p-6">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-warehouse mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 21v-13l9 -4l9 4v13" />
                        <path d="M13 13h4v8h-10v-6h6" />
                        <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3" />
                    </svg>
                    <span>Clínicas</span>
                </h1>
                <div className="card-body py-1 px-4 flex justify-center items-center flex-column">
                    <h3 className="font-medium text-lg text-neutral-500">BUSCA Y LOCALIZA TU CLÍNICA MÁS CERCANA</h3>
                    <p className="my-2 text-sm text-neutral-500">Accede al localizador de nuestra red de clínicas del Colegio Oficial de Fisioterapeutas de la Comunidad Valenciana.</p>
                    <div className="card-actions flex justify-center mb-2">
                        <button type="button" className="btn bg-teal-600 hover:bg-teal-700 text-white px-8 mt-2" onClick={() => goToPageClinicsList()} >Ir al buscador de clínica</button>
                    </div>
                </div>
            </div>

        </>
    )
}