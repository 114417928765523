import { useState, useEffect } from 'react'
import { getDocument } from '../../api/drupalAPI'
import { Document } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'

export function Manuals() {

    const [documents, setDocuments] = useState<Document[]>([]);
    const [inputValue1, setInputValue1] = useState("")
    const [inputValue2, setInputValue2] = useState("")
    const [inputValue3, setInputValue3] = useState("")

    const fetchDocuments = async () => {
        getDocument().then((response) => {
            console.log(response)
            setDocuments(response);

        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    const handleInputChange1 = (e) => {
        e.preventDefault()
        inputValue1 === "Seleccione Anexo I" ? null
            :
            contentUtils.downloadFile(inputValue1)
    }

    const handleInputChange2 = (e) => {
        e.preventDefault()
        inputValue2 === "Seleccione Anexo II" ? null
            :
            contentUtils.downloadFile(inputValue2)
    }

    const handleInputChange3 = (e) => {
        e.preventDefault()
        inputValue3 === "Seleccione Anexo III" ? null
            :
            contentUtils.downloadFile(inputValue3)
    }

    useEffect(() => {

        fetchDocuments();
    }, []);

    return (
        <>
            <div className="card bg-white px-2 py-3 md:p-6">
                <h1 className="pb-3 font-medium">Manuales de Estándares</h1>
                <div className="card-body py-2 md:px-12 mx-auto w-100">
                    <p className="text-neutral-600 text-sm mb-2">Manual de estándares de la práctica de la Fisioterapia en Centros Sanitarios</p>
                    {documents.slice(54, 55).map(docu => (
                        <button onClick={() => contentUtils.downloadFile(docu.contentUrl)} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 rounded max-w-40" type="button">Descargar</button>
                    ))}
                    <p className="text-neutral-600 text-sm font-bold my-2">Desde el siguiente formulario podrás descargar los anexos del manual, se han clasificado en 2 grupos para una mejor búsqueda.</p>

                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Anexos Estándares*</label>
                            <select onChange={(e) => setInputValue1(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                <option selected defaultValue={`Selececcione Anexo I`} >Seleccione Anexo I</option>
                                {documents.slice(0, 11).map(docu => (
                                    <>
                                        <option value={docu.contentUrl} key={docu.id} >{docu.name}</option>
                                    </>
                                ))}
                            </select>
                        </div>
                        <button disabled={!inputValue1} onClick={handleInputChange1} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 ml-4 w-full md:w-40 my-2 md:my-0 rounded" >Descargar</button>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Anexos Estándares*</label>
                            <select onChange={(e) => setInputValue2(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                <option selected>Seleccione Anexo II</option>
                                {documents.slice(11, 29).map(docu => (
                                    <>
                                        <option value={docu.contentUrl} >{docu.name}</option>
                                    </>
                                ))}
                            </select>
                        </div>
                        <button disabled={!inputValue2} onClick={handleInputChange2} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Descargar</button>
                    </div>
                    <div className="flex flex-col md:flex-row items-end justify-between my-4">
                        <div className="relative z-0 w-full group">
                            <label className="block text-sm font-medium text-gray-900 mb-2">Anexos Estándares*</label>
                            <select onChange={(e) => setInputValue3(e.target.value)} id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                <option selected>Seleccione Anexo III</option>
                                {documents.slice(29, 30).map(docu => (
                                    <>
                                        <option value={docu.contentUrl} >{docu.name}</option>
                                    </>
                                ))}
                            </select>
                        </div>
                        <button disabled={!inputValue3} onClick={handleInputChange3} className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 ml-4 w-full md:w-40 my-2 md:my-0 rounded" type="button">Descargar</button>
                    </div>
                </div>
            </div>
        </>
    )
}