import {Clinic} from '@icofcv/common';
import {Where} from '@loopback/repository';

export function createClinicWhereFilter(clinicFilter:any) {
    const searchParam = clinicFilter.search ? `%${clinicFilter.search}%` : '';
    const searchFieldsParam = clinicFilter.searchFields != -1 ? `^${clinicFilter.searchFields},|,${clinicFilter.searchFields}$|,${clinicFilter.searchFields},|^${clinicFilter.searchFields}$` : '';
    const searchTreatmentsParam = clinicFilter.searchTreatments != -1 ? `^${clinicFilter.searchTreatments},|,${clinicFilter.searchTreatments}$|,${clinicFilter.searchTreatments},|^${clinicFilter.searchTreatments}$` : '';
    const searchConcertsParam = clinicFilter.searchConcerts != -1 ? `^${clinicFilter.searchConcerts},|,${clinicFilter.searchConcerts}$|,${clinicFilter.searchConcerts},|^${clinicFilter.searchConcerts}$` : '';
    const searchOthersParam = clinicFilter.searchOthers != -1 ? `^${clinicFilter.searchOthers},|,${clinicFilter.searchOthers}$|,${clinicFilter.searchOthers},|^${clinicFilter.searchOthers}$` : '';
    const searchProvinceParam = clinicFilter.searchProvince ? `^${clinicFilter.searchProvince}$|^${getProvinceInitials(clinicFilter.searchProvince)}$` : '';
    const searchTownParam = clinicFilter.searchTown ? `%${clinicFilter.searchTown}%` : '';
    
    var searchParamsOr:Where<Clinic>[] = [];
    if (searchParam && searchParam.trim().length > 0) {
        console.log(`*** searchParamsOr - title: '${searchParam}'`);
        searchParamsOr.push({ title: { like: searchParam, options: 'i' } });
        console.log(`*** searchParamsOr - email: '${searchParam}'`);
        searchParamsOr.push({ email: { like: searchParam, options: 'i' } });
    }

    var searchParamsAnd:Where<Clinic>[] = [];
    if (searchFieldsParam && searchFieldsParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - physiotherapyFields: '${searchFieldsParam}'`);
        searchParamsAnd.push({ physiotherapyFields: { regexp: searchFieldsParam } });
    }
    if (searchTreatmentsParam && searchTreatmentsParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - type: '${searchTreatmentsParam}'`);
        searchParamsAnd.push({ type: { regexp: searchTreatmentsParam, options: 'i' } });
    }
    if (searchConcertsParam && searchConcertsParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - featureFilterList: '${searchConcertsParam}'`);
        searchParamsAnd.push({ featureFilterList: { regexp: searchConcertsParam, options: 'i' } });
    }
    if (searchOthersParam && searchOthersParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - others: '${searchOthersParam}'`);
        searchParamsAnd.push({ others: { regexp: searchOthersParam, options: 'i' } });
    }
    if (searchProvinceParam && searchProvinceParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - province: '${searchProvinceParam}'`);
        searchParamsAnd.push({ province: { regexp: searchProvinceParam, options: 'i' } });
    }
    if (searchTownParam && searchTownParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - town: '${searchTownParam}'`);
        searchParamsAnd.push({ town: { like: searchTownParam, options: 'i' } });
    }

    var where = {};
    if (searchParamsOr.length > 0 || searchParamsAnd.length > 0) {
        if (searchParamsOr.length > 0 && searchParamsAnd.length > 0) {
            where = { 
                and: [
                    { or: searchParamsOr },
                    { searchParamsAnd }
                ]
            };
        } 
        if (searchParamsOr.length > 0 && searchParamsAnd.length == 0) {
            where = { 
                or: searchParamsOr
            };
        }
        if (searchParamsOr.length == 0 && searchParamsAnd.length > 0) {
            where = { 
                and: searchParamsAnd
            };
        }
    }

    return where;
}

function getProvinceInitials(province:string) {
    var initials:string = '';

    switch (province.toLowerCase()) {
        case 'alicante': initials = 'A'; break;
        case 'castellon':
        case 'castellón': initials = 'CS'; break;
        case 'valencia': initials = 'V'; break;
    }

    return initials;
}