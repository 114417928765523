import * as yup from "yup";

const isRequiredMessage = "Completa este campo";

export default yup.object().shape({
    numberCollegiate: yup.string().required(isRequiredMessage)
        .matches(/^[0-9]+$/, "Introduzca solo números"),
    email: yup.string().required(isRequiredMessage).email(),
    dni: yup.string()
        .required(isRequiredMessage)
        .min(9, 'Introduzca exactamente 9 caracteres')
        .max(9, 'Introduzca exactamente 9 caracteres'),
    phone: yup.string().required(isRequiredMessage)
});