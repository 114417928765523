import { useState } from 'react'
import { requestDocument } from '../../api/icofcvAPI'
import { PopUpMessage } from './PopUpMessage';

export const CertificateOfRegistration = () => {

    const [showModalMessage, setShowModalMessage] = useState(false);
    const openModalMessage = () => setShowModalMessage(true)
    const closeModalMessage = () => setShowModalMessage(false)

    function sendRegistrationCertificateByEmail() {
        var data = {
            documentType: 'CERTIFICADO_COLEGIACION'
        };

        requestDocument(data)
            .then((response) => {
                openModalMessage()
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    return (
        <>
            <div className="card bg-white px-2 py-3 md:p-6">
                <h1 className="pb-3 font-medium">Certificado de colegiación</h1>
                <div className="card-body py-2 md:px-12 mx-auto">
                    <p className="text-neutral-600 text-sm">Certificado que confirmará que eres miembro de pleno derecho.</p>
                </div>
                <div className="card-actions flex justify-center mb-2">
                    <button className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 mt-2 rounded" type="button" onClick={() => sendRegistrationCertificateByEmail()}>Solicitar</button>
                </div>
            </div>
            <PopUpMessage showModalMessage={showModalMessage} closeModalMessage={closeModalMessage} />
        </>
    )
}