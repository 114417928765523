import { useState } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { Link } from "react-router-dom";

export function AccessFormationHomeButton() {
    
    const session = useSelector((state: RootState) => state.session);

    return (
        <>
            <Link to="/college/formationlist" className={`${session.isLoggedIn ? 'mt-3' : ''} bg-white items-center justify-between rounded-lg h-14 p-4 w-100 border border-gray-400 flex hover:text-teal-800`} type="button">Acceso a formación
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#231F20" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                </svg>
            </Link>
        </>
    )
}