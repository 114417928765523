import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ModalAdvertisments } from './ModalAdvertisments';
import { useNavigate } from "react-router-dom";
import { Advertisment } from '@icofcv/common';
import { getItemsPerPage, getAdvertismentsPaginated } from '../../api/drupalAPI'
import { format } from 'date-fns'
import { es } from 'date-fns/locale';
import { HOME_MODULE } from '../../constants/modules'
import { setDetailNavigation } from '../../actions/configNavigation'
import { useStore } from 'react-redux'
import { useSelector } from "react-redux";
import contentUtils from '../../lib/contentUtils';
import type { RootState } from '../../store/index'
import './AdvertismentsList.css'
import jquery from 'jquery';

export const AdvertismentsList = () => {

    const store = useStore();
    const navigate = useNavigate();
    const session = useSelector((state: RootState) => state.session);
    let myAdvertisementsCount = 0;
    const $: JQueryStatic = jquery;

    function scrollBlock() {
        $("#root").css("overflow-y", "hidden");
    }

    function scrollActive() {
        $("#root").css("overflow-y", "auto");
    }

    const [showModalManage, setShowModalManage] = useState(false);
    const openModalManage = () => {
        setShowModalManage(true)
        scrollBlock()
    };
    const closeModalManage = () => {
        setShowModalManage(false)
        scrollActive()
    };

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true)
        scrollBlock()
    };
    const closeModal = () => {
        setShowModal(false)
        scrollActive()
    };

    const [showTabRent, setShowTabRent] = useState(true);
    const openTabRent = () => {
        setShowTabRent(true)
        setShowTabPurchase(false)
        setShowTabTransfer(false)
        setShowTabSale(false)
    }

    const [showTabPurchase, setShowTabPurchase] = useState(false);
    const openTabPurchase = () => {
        setShowTabPurchase(true)
        setShowTabRent(false)
        setShowTabTransfer(false)
        setShowTabSale(false)
    }

    const [showTabTransfer, setShowTabTransfer] = useState(false);
    const openTabTransfer = () => {
        setShowTabTransfer(true)
        setShowTabRent(false)
        setShowTabPurchase(false)
        setShowTabSale(false)
    }

    const [showTabSale, setShowTabSale] = useState(false);
    const openTabtSale = () => {
        setShowTabSale(true)
        setShowTabRent(false)
        setShowTabPurchase(false)
        setShowTabTransfer(false)
    }

    /// abrir y cerrar filtro 
    const [showFilter, setShowFilter] = useState(false);
    const openFilter = () => setShowFilter(true);
    const closeFilter = () => setShowFilter(false);

    ///llamada a los anuncios mas recientes con paginador
    const [advertismentsList, setAdvertismentsList] = useState<Advertisment[]>([]);
    const [advertismentsSearchProvince, setAdvertismentsSearchProvince] = useState('');
    const [advertismentsSearchTitle, setAdvertismentsSearchTitle] = useState('');
    const [searchAdvertisments, setSearchAdvertisments] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const fetchAdvertismentsList = async (filter) => {
        setIsLoading(true);

        getAdvertismentsPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreAdvertismentsDataDisabled(false);

                } else {
                    setViewMoreAdvertismentsDataDisabled(true);
                }

                var list: Advertisment[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...advertismentsList, ...response];
                }
                setAdvertismentsList(list);
                // setSearchAdvertismentsList(list)
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    ///funcion ver más datos  
    const [currentPageAdvertisments, setCurrentPageAdvertisments] = useState(0);
    const [viewMoreAdvertismentsDataDisabled, setViewMoreAdvertismentsDataDisabled] = useState(false);

    ///funcion ver detalle
    function handleAddToDetail(id) {
        console.log('ID', id)
        navigate('advertismentsdetail?id=' + id);
    }

    //funcion ver más datos
    const handleShowMoreData = () => {
        setCurrentPageAdvertisments(currentPageAdvertisments + 1);

        const filter = {
            page: currentPageAdvertisments + 1,
            search: searchAdvertisments,
            searchTitle: advertismentsSearchTitle,
            searchProvince: advertismentsSearchProvince,
        };

        fetchAdvertismentsList(filter);
    }

    //funcion cambio de filtro    
    const handleFilterChanged = (id, value) => {
        setCurrentPageAdvertisments(0);

        switch (id) {
            case 'province': setAdvertismentsSearchProvince(value); break;
            case 'title': setAdvertismentsSearchTitle(value); break;

        }
    }

    //funcion filtrado campos 
    const handleFilterButtonClicked = () => {
        const filter = {
            page: currentPageAdvertisments,
            search: searchAdvertisments,
            searchTitle: advertismentsSearchTitle,
            searchProvince: advertismentsSearchProvince,
        };
        fetchAdvertismentsList(filter);
    }

    //reiniciar busqueda
    const handleRestartButtonClicked = () => {
        setCurrentPageAdvertisments(0);
        setAdvertismentsSearchProvince('');
        setAdvertismentsSearchTitle('');

        const filter = {
            page: 0,
            search: searchAdvertisments,
            searchProvince: '',
            searchTitle: ''
        };

        fetchAdvertismentsList(filter);
    }

    //funcion filtro principal  
    const handleChange = e => {
        setCurrentPageAdvertisments(0);
        setSearchAdvertisments(e.target.value);
        setTimeout(() => {
            const filter = {
                page: 0,
                search: e.target.value,
                searchTitle: advertismentsSearchTitle,
                searchProvince: advertismentsSearchProvince,

            };

            fetchAdvertismentsList(filter);
        }, 800);
    }

    function updateTable() {
        const filter = {
            page: 0,
            order: 'id desc'
        };
    }
  const provinceName = localStorage.getItem('advertismentsSearchProvince');
  const searchInput = localStorage.getItem('search');
  console.log('search', searchInput)
      
      useEffect(() => {

        const searchInput = localStorage.getItem('search');
        const provinceName = localStorage.getItem('advertismentsSearchProvince');
            const filter = {
                page: 0,
                search: searchInput ? searchInput : searchAdvertisments,
                searchTitle: '',
                searchProvince: provinceName ? provinceName : ''
            };
        fetchAdvertismentsList(filter)
        
        store.dispatch(setDetailNavigation(HOME_MODULE, 'anuncios!'));
    }, []);

 return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-60 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Tablón de anuncios</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-44 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Tablón de anuncios</span>
                    </Link>
                    <div className="mb-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between flex-col-reverse md:flex-row gap-3">
                        <ul className="flex items-center justify-between flex-nowrap text-sm font-medium text-center icofcv-tabs" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                            <li className="mr-2" role="presentation">
                                {showTabRent === true ?
                                    <button onClick={openTabRent} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="rent-tab" data-tabs-target="#rent" type="button" role="tab" aria-controls="rent" aria-selected="false">Alquiler</button>
                                    :
                                    <button onClick={openTabRent} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800" id="rent-tab" data-tabs-target="#rent" type="button" role="tab" aria-controls="rent" aria-selected="false">Alquiler</button>
                                }
                            </li>
                            <li className="mr-2" role="presentation">
                                {showTabPurchase === true ?
                                    <button onClick={openTabPurchase} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="purchase-tab" data-tabs-target="#purchase" type="button" role="tab" aria-controls="purchase" aria-selected="false">Compra</button>
                                    :
                                    <button onClick={openTabPurchase} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800" id="purchase-tab" data-tabs-target="#purchase" type="button" role="tab" aria-controls="purchase" aria-selected="false">Compra</button>
                                }
                            </li>

                            <li className="mr-2" role="presentation">
                                {showTabTransfer === true ?
                                    <button onClick={openTabTransfer} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="transfer-tab" data-tabs-target="#transfer" type="button" role="tab" aria-controls="transfer" aria-selected="false">Traspaso</button>
                                    :
                                    <button onClick={openTabTransfer} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800" id="transfer-tab" data-tabs-target="#transfer" type="button" role="tab" aria-controls="transfer" aria-selected="false">Traspaso</button>
                                }
                            </li>
                            <li role="presentation">
                                {showTabSale === true ?
                                    <button onClick={openTabtSale} className="inline-flex px-2 md:px-4 py-2 border-b-4 text-teal-600 hover:text-teal-800 border-teal-600 hover:border-teal-800" id="sale-tab" data-tabs-target="#sale" type="button" role="tab" aria-controls="sale" aria-selected="false">Venta</button>
                                    :
                                    <button onClick={openTabtSale} className="inline-flex px-2 md:px-4 py-2 border-b-4 border-transparent hover:text-teal-800 hover:border-teal-800" id="sale-tab" data-tabs-target="#sale" type="button" role="tab" aria-controls="sale" aria-selected="false">Venta</button>
                                }
                            </li>
                        </ul>
                        {/* Botones Crear anuncios y Gestionar anuncios: Desktop */}
                        <div className="md:flex items-center justify-center md:justify-end mx-3 md:mb-2 gap-2 hidden">
                            <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                    <line x1="12" y1="9" x2="12" y2="15" />
                                </svg>
                                Crear anuncio
                            </button>
                            <button onClick={openModalManage} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ad-2 mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M11.933 5h-6.933v16h13v-8" />
                                    <path d="M14 17h-5" />
                                    <path d="M9 13h5v-4h-5z" />
                                    <path d="M15 5v-2" />
                                    <path d="M18 6l2 -2" />
                                    <path d="M19 9h2" />
                                </svg>
                                Gestionar mis anuncios
                            </button>
                        </div>

                        {/* Botones Crear anuncios y Gestionar anuncios: Phone */}
                        <div className="w-full bg-white fixed bottom-0 p-2 flex justify-around md:hidden whitespace-nowrap border-t-2 border border-neutral-300 z-10 h-20">
                            <button onClick={openModal} className="btn bg-teal-600 hover:bg-teal-800 text-white text-sm p-2 mt-2 mb-3 flex items-center h-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-white" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                    <line x1="12" y1="9" x2="12" y2="15" />
                                </svg>
                                Crear anuncios
                            </button>

                            <button onClick={openModalManage} className="btn bg-teal-600 hover:bg-teal-800 text-white text-sm p-2 mt-2 mb-3 flex items-center h-10">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ad-2 mr-1 stroke-white" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M11.933 5h-6.933v16h13v-8" />
                                    <path d="M14 17h-5" />
                                    <path d="M9 13h5v-4h-5z" />
                                    <path d="M15 5v-2" />
                                    <path d="M18 6l2 -2" />
                                    <path d="M19 9h2" />
                                </svg>
                                Gestionar mis anuncios
                            </button>
                        </div>
                    </div>
                    {/* listado  */}
                    <div className="flex items-center md:items-baseline justify-start gap-2">
                        <select onChange={(e) => handleFilterChanged('province', e.target.value)} value={advertismentsSearchProvince}
                            className="w-40 md:w-56 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 hidden md:block w-full p-2">
                            <option value={""}>Todas</option>
                            <option value={"A"}>Alicante</option>
                            <option value={"V"}>Valencia</option>
                            <option value={"C"}>Castellón</option>
                        </select>
                        <input
                            value={advertismentsSearchTitle}
                            onChange={(e) => handleFilterChanged('title', e.target.value)}
                            type="search"
                            placeholder="Buscar..."
                            className="md:w-56 w-72 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2 mb-2"
                            aria-label="Search" />
                        <div className="hidden md:flex items-center gap-2 mb-3">
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleRestartButtonClicked}>Limpiar</button>
                        </div>

                        <div className="mb-2 flex items-center">
                            {/* Botón filtros sólo para móvil */}
                            {showFilter === false ?
                                <button onClick={openFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                                :
                                <button onClick={closeFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center block md:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                    {showFilter === true ?
                        <>
                            <div className="md:hidden items-end justify-between grid grid-cols-1 gap-2 p-2">

                                <div className="relative z-0 w-full group mb-2">
                                    <select onChange={(e) => handleFilterChanged('province', e.target.value)} value={advertismentsSearchProvince} name="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block md:w-56 w-full p-2" data-accordion="accordion-collapse-body-1">
                                        <option value={''}>Seleccionar provincia</option>
                                        <option value={"A"}>Alicante</option>
                                        <option value={"C"}>Castellón</option>
                                        <option value={"v"}>Valencia</option>
                                        <option value={"O"}>Otras provincias</option>
                                    </select>
                                    <span className="mt-2 text-xs text-red-600"></span>
                                    <p className="mt-2 text-xs text-red-600 hidden">Completa este campo</p>
                                </div>

                                <div className="hidden md:flex items-center gap-2">
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleFilterButtonClicked} type="button" >Filtrar</button>
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" onClick={handleRestartButtonClicked} type="button" >Limpiar</button>
                                </div>
                            </div>
                            <div className="flex md:hidden items-center gap-2">
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleFilterButtonClicked} type="button">Filtrar</button>
                                <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" onClick={handleRestartButtonClicked} type="button">Limpiar</button>
                            </div>
                        </>
                        : <div></div>
                    }

                    <div className="icofcv-ad-list-container">
                        {advertismentsList.map((advertisment) => (
                            <>
                                <div id="myTabContent">

                                    {showTabRent === true && advertisment.categoryId === 0 && advertisment.publishEndDate && advertisment.publishEndDate > new Date().toISOString().slice(0, -8) && advertisment.status.toString() == "2" && advertisment.publishStartDate && advertisment.publishStartDate < new Date().toISOString().slice(0, -8) ?
                                        <div className="p-0" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0 block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetail(advertisment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(advertisment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {advertisment.province === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {advertisment.province === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {advertisment.province === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}

                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{advertisment.title}</h3>
                                                            <div className=" text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-8"><p className="parrafo">{advertisment.description}</p></div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(advertisment.id)}>VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                        </div>
                                    }

                                    {showTabPurchase === true && advertisment.categoryId === 1 && advertisment.publishEndDate && advertisment.publishEndDate > new Date().toISOString().slice(0, -8) && advertisment.status.toString() == "2" && advertisment.publishStartDate && advertisment.publishStartDate < new Date().toISOString().slice(0, -8) ?
                                        <div className="p-0" id="purchase" role="tabpanel" aria-labelledby="purchase-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0  md:block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetail(advertisment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center ">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(advertisment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {advertisment.province === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {advertisment.province === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {advertisment.province === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}
                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{advertisment.title}</h3>
                                                            <div className=" text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-8" ><p className="parrafo">{advertisment.description}</p></div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(advertisment.id)}>VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="purchase" role="tabpanel" aria-labelledby="purchase-tab">
                                        </div>
                                    }

                                    {showTabTransfer === true && advertisment.categoryId === 2 && advertisment.publishEndDate && advertisment.publishEndDate > new Date().toISOString().slice(0, -8) && advertisment.status.toString() == "2" && advertisment.publishStartDate && advertisment.publishStartDate < new Date().toISOString().slice(0, -8) ?
                                        <div className="p-0" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0  md:block">
                                                <button type="button" className="text-left mb-2" onClick={() => handleAddToDetail(advertisment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center ">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(advertisment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {advertisment.province === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {advertisment.province === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {advertisment.province === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}
                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{advertisment.title}</h3>
                                                            <div className=" text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-8"><p className="parrafo">{advertisment.description}</p></div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(advertisment.id)}>VER MÁS</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
                                        </div>
                                    }

                                    {showTabSale === true && advertisment.categoryId === 3 && advertisment.publishEndDate && advertisment.publishEndDate > new Date().toISOString().slice(0, -8) && advertisment.status.toString() == "2" && advertisment.publishStartDate && advertisment.publishStartDate < new Date().toISOString().slice(0, -8) ?

                                        <div className="p-0" id="sale" role="tabpanel" aria-labelledby="sale-tab">
                                            <div className="bg-white px-0 pt-3 border-b rounded-0  md:block">
                                                <button type="button" className="text-left" onClick={() => handleAddToDetail(advertisment.id)}>
                                                    <div className="flex items-top flex-col md:flex-row md:items-center">
                                                        <div className="md:w-100 h-auto pb-2">
                                                            <small className="text-teal-600 mb-2 px-2">Fecha publicación: {format(new Date(advertisment.creationDate), 'EEE dd/MM/Y ', { locale: es }).toUpperCase()}</small>
                                                            {advertisment.province === "V" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Valencia</p>
                                                                : null}
                                                            {advertisment.province === "A" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Alicante</p>
                                                                : null}
                                                            {advertisment.province === "C" ?
                                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">Provincia: Castellón</p>
                                                                : null}
                                                            <h3 className="font-medium text-neutral-800 doc-title px-2 my-1">{advertisment.title}</h3>
                                                            <div className="text-neutral-600 text-xs font-normal creator-text px-2 text-ellipsis line-clamp-8" ><p className="parrafo">{advertisment.description}</p></div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className="flex items-center justify-end mx-3">
                                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button" onClick={() => handleAddToDetail(advertisment.id)}>Ver más</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                            </>
                        ))}
                        {/* Contenido vacío */}
                        {advertismentsList.length == 0 ?
                            <div className="my-4">
                                <div className="flex flex-col items-center py-24">
                                    <div className="mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folder-plus" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="#9ca3af" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                                            <line x1="12" y1="10" x2="12" y2="16" />
                                            <line x1="9" y1="13" x2="15" y2="13" />
                                        </svg>
                                    </div>
                                    <h3 className="font-medium">No hay anuncios</h3>
                                    <p className="text-neutral-500 text-sm">Para empezar, crea un nuevo anuncio</p>
                                    <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="12" r="9" />
                                            <line x1="9" y1="12" x2="15" y2="12" />
                                            <line x1="12" y1="9" x2="12" y2="15" />
                                        </svg>
                                        Crear anuncio
                                    </button>
                                </div>
                            </div>
                            :
                            null}

                        {viewMoreAdvertismentsDataDisabled === false ?
                            <div className="flex items-center justify-center m-3">
                                <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                            </div>
                            :
                            <div></div>
                        }
                    </div>

                    {showModalManage ? (
                        <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed top-0 inset-0 z-50 outline-none focus:outline-none p-0">
                                <div className="relative p-2 w-screen md:w-full max-w-3xl h-full md:h-auto">
                                    {/*content*/}
                                    <div className="relative top-2 bg-white rounded-lg shadow">
                                        {/*header*/}
                                        <div className="flex justify-between items-start px-4 py-3 rounded-t border-b">
                                            <h3 className="text-lg font-medium">Gestionar anuncios</h3>
                                            <button className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeModalManage}>
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="relative px-3 py-3 flex-auto overflow-y-auto modal-body">
                                            {advertismentsList.map((advertisment) => (
                                                <>
                                                    {advertisment.creatorUsername === session.collegiate?.email ?
                                                        <div key={advertisment.id} className="bg-white px-2 py-3 md:h-33 border-b rounded-0">
                                                            <span className="hidden">{++myAdvertisementsCount}</span>
                                                            <button type="button" className="text-left overflow-hidden pb-2 w-100" onClick={() => handleAddToDetail(advertisment.id)}>
                                                                <div className="items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                                                    <img className="h-20 sm:w-100 md:w-32 object-cover object-center rounded-md hidden" src={contentUtils.getLargeImageUrl(advertisment.imagePath)} alt="AdvertismentImage" />
                                                                    <div className="md:w-100 ml-2 h-18">
                                                                        <div className="flex items-center justify-between">
                                                                            <small className="text-teal-600">{format(new Date(advertisment.creationDate), 'EEE dd/MM/Y', { locale: es }).toUpperCase()}</small>
                                                                            <div className="flex">
                                                                                {advertisment.status === 1 ?
                                                                                    <div className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Pendiente</div>
                                                                                    : null}
                                                                                {advertisment.status === 2 && advertisment.publishEndDate && advertisment.publishEndDate > new Date().toISOString().slice(0, -8) && advertisment.publishStartDate && advertisment.publishStartDate < new Date().toISOString().slice(0, -8) ?
                                                                                    <div className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Publicado</div>
                                                                                    : null}
                                                                                {advertisment.status === 2 && advertisment.publishEndDate && advertisment.publishEndDate < new Date().toISOString().slice(0, -8) || advertisment.publishStartDate && advertisment.publishStartDate > new Date().toISOString().slice(0, -8) && advertisment.status !== 4 ?
                                                                                    <div className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">No publicado/ Despublicado</div>
                                                                                    : null}
                                                                                {advertisment.status === 3 ?
                                                                                    <div className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Rechazado</div>
                                                                                    : null}
                                                                                {advertisment.status === 4 ?
                                                                                    <div className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full">Retirado</div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>

                                                                        {advertisment.province === "V" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Valencia</p>
                                                                            : null}
                                                                        {advertisment.province === "A" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Alicante</p>
                                                                            : null}
                                                                        {advertisment.province === "C" ?
                                                                            <p className="text-neutral-600 text-xs font-normal creator-text">Provincia: Castellón</p>
                                                                            : null}
                                                                        <p className="text-lg text-neutral-800 font-medium news-title">{advertisment.title}</p>
                                                                        <p className="text-neutral-600 text-xs font-normal "><p className="parrafo">{advertisment.description}</p></p>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                            <div className="flex justify-end mx-3">
                                                                <button onClick={() => handleAddToDetail(advertisment.id)} className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none underline uppercase" type="button">VER MÁS</button>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                </>
                                            ))}
                                            {myAdvertisementsCount === 0 ?
                                                <div className="flex flex-col items-center p-2">
                                                    <div className="mx-auto">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folder-plus" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="#9ca3af" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                                                            <line x1="12" y1="10" x2="12" y2="16" />
                                                            <line x1="9" y1="13" x2="15" y2="13" />
                                                        </svg>
                                                    </div>
                                                    <h3 className="font-medium">No hay anuncios</h3>
                                                    <p className="text-neutral-500 text-sm">Para empezar, cree un nuevo anuncio</p>
                                                    <button onClick={openModal} className="bg-transparent text-teal-600 hover:text-teal-800 text-sm font-semibold py-2 outline-none focus:outline-none underline mx-2 flex items-center whitespace-nowrap" type="button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus mr-1 stroke-teal-600 hover:stroke-teal-800" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <circle cx="12" cy="12" r="9" />
                                                            <line x1="9" y1="12" x2="15" y2="12" />
                                                            <line x1="12" y1="9" x2="12" y2="15" />
                                                        </svg>
                                                        Crear anuncio
                                                    </button>
                                                </div>
                                                :
                                                null}
                                        </div>
                                        {/*footer*/}
                                        <div className="flex items-center justify-end px-4 py-2 border-t border-solid border-slate-200 rounded-b gap-2">
                                            <button className="btn text-black text-sm background-transparent px-8 outline-none focus:outline-none mr-2" type="button" onClick={closeModalManage}>Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            {viewMoreAdvertismentsDataDisabled === false ?
                                <div className="flex items-center justify-end mx-3">
                                    <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                                </div>
                                :
                                <div></div>
                            }
                        </>
                    ) : null}
                </div>

            </div>
            <ModalAdvertisments showModal={showModal} closeModal={closeModal} updateTable={updateTable} />
        </>
    )
}