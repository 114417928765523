import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { getGuide } from '../../api/drupalAPI'
import { Guide } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import Loader from '../spinner/Loader';
import { Link } from "react-router-dom";
import { ModalNoLogin } from '../miProfile/ModalNoLogin';

export const GuideCarousel = () => {
    const [guideList, setGuideList] = useState<Guide[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const session = useSelector((state: RootState) => state.session);
    const parseFormDateToServiceFormat = (formDate?: string): string => {
        const date = (!!formDate) ? new Date(formDate) : new Date();
        return new Intl.DateTimeFormat('es-ES', { month: 'long', year: 'numeric' }).format(date);
    }

    const fetchGuideList = async () => {
        setIsLoading(true)
        getGuide().then((response) => {
            console.log(response)
            setGuideList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    function handleAddToDetail(id) {
        navigate('/guidelist/guidedetail?id=' + id);
    }

    useEffect(() => {
        fetchGuideList();
    }, []);

    return (
        <>

            {/* GuideCarousel */}
            <div className="card bg-white px-2 py-2 md:p-6 ">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-directions mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 5h10l2 2l-2 2h-10a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" />
                        <path d="M13 13h-7l-2 2l2 2h7a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1" />
                        <line x1="12" y1="22" x2="12" y2="17" />
                        <line x1="12" y1="13" x2="12" y2="9" />
                        <line x1="12" y1="5" x2="12" y2="3" />
                    </svg>
                    <span>Guías</span>
                </h1>
                <div className="card-body py-1 md:px-0 h-72 md:h-80 overflow-auto">
                    {/* List mode Item */}
                    {guideList.slice(0, 6).map((guide) => (
                        <>
                            {session.isLoggedIn ?
                                <div className="bg-white px-2 py-3 md:h-28 border-b rounded-0 hidden md:block">
                                    <button type="button" className="text-left" onClick={() => handleAddToDetail(guide.id)}>
                                        <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                            <img className="h-20 w-32 object-cover object-center rounded-md" src={contentUtils.getLargeImageUrl(guide.imagePath)} alt="Guias" />
                                            <div className="md:w-4/5 ml-2 h-18">
                                                <small className="text-teal-600 mb-2 px-2">Fecha publicación: {guide.publishDate ? parseFormDateToServiceFormat(guide.publishDate) : '--'}</small>
                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">{guide.creator}</p>
                                                <h3 className="font-medium text-neutral-800 doc-title px-2 ">{guide.title}</h3>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                :
                                <div className="bg-white px-2 py-3 md:h-28 border-b rounded-0 hidden md:block">
                                    <a className="text-left overflow-hidden pb-2 w-100">
                                        <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                            <img className="h-20 w-32 object-cover object-center rounded-md" src={contentUtils.getLargeImageUrl(guide.imagePath)} alt="Guias" />
                                            <div className="md:w-4/5 ml-2 h-18">
                                                <small className="text-teal-600 mb-2 px-2">Fecha publicación: {guide.publishDate ? parseFormDateToServiceFormat(guide.publishDate) : '--'}</small>
                                                <p className="text-neutral-600 text-xs font-normal creator-text px-2">{guide.creator}</p>
                                                <h3 className="font-medium text-neutral-800 doc-title px-2 ">{guide.title}</h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            }
                        </>
                    ))}

                    {/* Carousel mode */}
                    <div id="default-carousel" className="relative " data-carousel="static" >
                        {/* Carousel wrapper */}
                        <Swiper
                            centeredSlides={false}
                            breakpoints={{
                                375: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1536: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper relative h-64 md:h-80 overflow-hidden rounded-lg lg:gap-4 md:gap-6 gap-0 flex flex-row w-100 guide-carousel block md:hidden">
                            {isLoading && <Loader />}
                            {guideList.slice(0, 4).map((guide) => (

                                <SwiperSlide key={guide.id} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 md:px-2 flex flex-shrink-0 relative sm:w-auto flex-col items-center w-full h-80">
                                    <div className="card h-56 md:h-72 w-100 bg-white md:border-1 carousel-card p-2">
                                        {session.isLoggedIn ?
                                            <button type="button" className="text-left" onClick={() => handleAddToDetail(guide)}>
                                                <figure>
                                                    <img className="h-32 md:h-48 w-100 object-cover object-center rounded-lg" src={contentUtils.getLargeImageUrl(guide.imagePath)} alt="guide" />
                                                </figure>
                                                <div className="card-body p-2">
                                                    <small className="text-teal-600 mb-2 px-2">Fecha publicación: {guide.publishDate ? parseFormDateToServiceFormat(guide.publishDate) : '--'}</small>
                                                    <p className="text-neutral-600 text-xs font-normal creator-text px-2">{guide.creator}</p>
                                                    <h3 className="font-medium text-neutral-800 doc-title px-2 ">{guide.title}</h3>
                                                </div>
                                            </button>
                                            :
                                            <button type="button" className="text-left" onClick={openModal}>
                                                <figure>
                                                    <img className="h-32 md:h-48 w-100 object-cover object-center rounded-lg" src={contentUtils.getLargeImageUrl(guide.imagePath)} alt="guide" />
                                                </figure>
                                                <div className="card-body p-2">
                                                    <small className="text-teal-600 mb-2 px-2">Fecha publicación: {guide.publishDate ? parseFormDateToServiceFormat(guide.publishDate) : '--'}</small>
                                                    <p className="text-neutral-600 text-xs font-normal creator-text px-2">{guide.creator}</p>
                                                    <h3 className="font-medium text-neutral-800 doc-title px-2 ">{guide.title}</h3>
                                                </div>
                                            </button>
                                        }
                                    </div>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                    </div>
                </div>
                {session.isLoggedIn ?
                    <Link to='/docs/guidelist' className="w-100 see-more-link px-2 py-3 flex justify-end text-teal-700 hover:text-neutral-500 font-medium">VER MÁS</Link>
                    :
                    <button onClick={openModal} className="see-more-link text-gray-400 hover:text-gray-300 flex items-center justify-end px-2 py-3 w-100"  >
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock stroke-gray-400 hover:stroke-gray-300" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="5" y="11" width="14" height="10" rx="2" />
                            <circle cx="12" cy="16" r="1" />
                            <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                        </svg>
                        <span className="font-medium ml-2">VER MÁS</span>
                    </button>
                }
            </div>
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
        </>
    );
}
