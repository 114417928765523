import { useState, useEffect } from 'react'
import { getClinicCollections } from '../../api/icofcvAPI';
import { getItemsPerPage, getClinicPaginated } from '../../api/drupalAPI';
import { Clinic, CollectionItem } from '@icofcv/common';
import { useNavigate } from "react-router-dom";
import contentUtils from '../../lib/contentUtils'
import { ClinicLocator } from './ClinicLocator'
import Loader from '../spinner/Loader';
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation'
import { COLLEGE_MODULE } from '../../constants/modules'
import { Link } from "react-router-dom";


export const ClinicList = () => {

    ///constantes listados y nombres de los campos
    const store = useStore();
    const [clinicList, setClinicList] = useState<Clinic[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    ////constantes filtro clinicas
    const [clinicSearchFields, setClinicSearchFields] = useState<CollectionItem[]>([]);
    const [clinicSearchTreatments, setClinicSearchTreatments] = useState<CollectionItem[]>([]);
    const [clinicSearchConcerts, setClinicSearchConcerts] = useState<CollectionItem[]>([]);
    const [clinicSearchOthers, setClinicSearchOthers] = useState<CollectionItem[]>([]);
    const [clinicSearchFieldSelected, setClinicSearchFieldSelected] = useState(-1);
    const [clinicSearchTreatmentSelected, setClinicSearchTreatmentSelected] = useState(-1);
    const [clinicSearchConcertSelected, setClinicSearchConcertSelected] = useState(-1);
    const [clinicSearchOtherSelected, setClinicSearchOtherSelected] = useState(-1);
    const [clinicSearchTown, setClinicSearchTown] = useState('');
    const [clinicSearchProvince, setClinicSearchProvince] = useState('');
    const [searchClinic, setSearchClinic] = useState('');
    const [currentPageClinic, setCurrentPageClinic] = useState(0);
    const [viewMoreClinicDataDisabled, setViewMoreClinicDataDisabled] = useState(false);


    /// abrir y cerrar filtro 
    const [showFilter, setShowFilter] = useState(false);
    const openFilter = () => setShowFilter(true);
    const closeFilter = () => setShowFilter(false);

    ///modal control locator
    const [showModalLocator, setShowModalLocator] = useState(false);
    const openModalLocator = () => setShowModalLocator(true);
    const closeModalLocator = () => setShowModalLocator(false);

    const fetchClinicCollections = async () => {
        setIsLoading(true);
        getClinicCollections()
            .then((response) => {
                console.log(response);
                setClinicSearchFields(response.physioFields);
                setClinicSearchTreatments(response.clinicTypes);
                setClinicSearchConcerts(response.featureFilters);
                setClinicSearchOthers(response.others);

                var filter = {
                    page: 0,
                    search: '',
                    searchFields: -1,
                    searchTreatments: -1,
                    searchConcerts: -1,
                    searchOthers: -1,
                    searchProvince: '',
                    searchType: '',
                };

                fetchClinicList(filter);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    const fetchClinicList = async (filter) => {
        setIsLoading(true);

        getClinicPaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreClinicDataDisabled(false);

                } else {
                    setViewMoreClinicDataDisabled(true);
                }

                var list: Clinic[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...clinicList, ...response];
                }
                setClinicList(list);
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    //funcion ver más datos
    const handleShowMoreData = () => {
        setCurrentPageClinic(currentPageClinic + 1);

        var filter = {
            page: currentPageClinic + 1,
            search: searchClinic,
            searchFields: clinicSearchFieldSelected,
            searchTreatments: clinicSearchTreatmentSelected,
            searchConcerts: clinicSearchConcertSelected,
            searchOthers: clinicSearchOtherSelected,
            searchTown: clinicSearchTown,
            searchProvince: clinicSearchProvince,
        };

        fetchClinicList(filter);
    }

    //funcion cambio de filtro    
    const handleFilterChanged = (id, value) => {
        setCurrentPageClinic(0);

        switch (id) {
            case 'field': setClinicSearchFieldSelected(parseInt(value)); break;
            case 'treatment': setClinicSearchTreatmentSelected(parseInt(value)); break;
            case 'other': setClinicSearchOtherSelected(parseInt(value)); break;
            case 'concert': setClinicSearchConcertSelected(parseInt(value)); break;
            case 'province': setClinicSearchProvince(value); break;
            case 'town': setClinicSearchTown(value); break;
        }
    }

    //funcion filtrado campos 
    const handleFilterButtonClicked = () => {
        var filter = {
            page: currentPageClinic,
            search: searchClinic,
            searchFields: clinicSearchFieldSelected,
            searchTreatments: clinicSearchTreatmentSelected,
            searchConcerts: clinicSearchConcertSelected,
            searchOthers: clinicSearchOtherSelected,
            searchTown: clinicSearchTown,
            searchProvince: clinicSearchProvince,
        };

        fetchClinicList(filter);
    }

    //reiniciar busqueda
    const handleRestartButtonClicked = () => {
        setCurrentPageClinic(0);
        setClinicSearchFieldSelected(-1);
        setClinicSearchTreatmentSelected(-1);
        setClinicSearchConcertSelected(-1);
        setClinicSearchOtherSelected(-1);
        setClinicSearchProvince('');
        setClinicSearchTown('');

        var filter = {
            page: 0,
            search: searchClinic,
            searchFields: -1,
            searchTreatments: -1,
            searchConcerts: -1,
            searchOthers: -1,
            searchTown: '',
            searchProvince: '',
        };

        fetchClinicList(filter);
    }

    //funcion filtro principal
    const handleChange = e => {
        setCurrentPageClinic(0);
        setSearchClinic(e.target.value);

        setTimeout(() => {
            var filter = {
                page: 0,
                search: e.target.value,
                searchFields: clinicSearchFieldSelected,
                searchTreatments: clinicSearchTreatmentSelected,
                searchConcerts: clinicSearchConcertSelected,
                searchOthers: clinicSearchOtherSelected,
                searchTown: clinicSearchTown,
                searchProvince: clinicSearchProvince,
            };

            fetchClinicList(filter);
        }, 800);
    }

    ///funcion nombres de los campos

    function getType(type) {
        if (!type) {
            return '';
        }

        var fields: number[] = type.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicSearchTreatments.length; i++) {
            if (fields.includes(clinicSearchTreatments[i].id)) {
                names.push(clinicSearchTreatments[i].name);
            }
        }
        return names.join(', ');
    }

    function getPhysiotherapyFields(physiotherapyFields) {
        if (!physiotherapyFields) {
            return '';
        }

        var fields: number[] = physiotherapyFields.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicSearchFields.length; i++) {
            if (fields.includes(clinicSearchFields[i].id)) {
                names.push(clinicSearchFields[i].name);
            }
        }

        return names.join(', ');
    }

    function getOthers(others) {
        if (!others) {
            return '';
        }

        var fields: number[] = others.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicSearchOthers.length; i++) {
            if (fields.includes(clinicSearchOthers[i].id)) {
                names.push(clinicSearchOthers[i].name);
            }
        }

        return names.join(', ');
    }

    function getFeatureFilters(features) {
        if (!features) {
            return '';
        }

        var fields: number[] = features.split(',').map(x => parseInt(x));

        var names: string[] = [];

        for (var i = 0; i < clinicSearchConcerts.length; i++) {
            if (fields.includes(clinicSearchConcerts[i].id)) {
                names.push(clinicSearchConcerts[i].name);
            }
        }

        return names.join(', ');
    }

    function handleAddToDetail(id) {

        navigate('clinicdetail?id=' + id);

    }

    function getProvinceText(province: string | undefined) {
        if (!province) {
            return '';
        }

        var text: string = '';

        switch (province.toUpperCase()) {
            case 'A': text = 'Alicante'; break;
            case 'CS': text = 'Castellón'; break;
            case 'V': text = 'Valencia'; break;
            default: text = province; break;
        }

        return text;
    }

    const orderOthers = clinicSearchOthers.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    })


    const orderType = clinicSearchTreatments.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    })


    const orderPhysiotherapyFields = clinicSearchFields.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    })

    useEffect(() => {
        store.dispatch(setDetailNavigation(COLLEGE_MODULE, 'Clinicas'));
        fetchClinicCollections();
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Clínicas</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Clínicas</span>
                    </Link>
                    <div className="card-body py-1 px-0 md:px-4">
                        <p className="text-teal-600 text-xs">
                            El ICOFCV no se hace responsable de la veracidad de los datos publicados de las clínicas, siendo su actualización responsabilidad del titular del centro sanitario.
                            Si se observa alguna discrepancia en la información publicada, se ruega que se ponga en conocimiento del ICOFCV.
                        </p>
                        <div className="flex items-start lg:items-center justify-start lg:justify-end flex-row py-2 lg:py-4 lg:ml-2 border-bottom gap-2 flex-wrap">
                            <input
                                type="search"
                                value={searchClinic}
                                placeholder="Buscar..."
                                className="w-56 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2"
                                aria-label="Search"
                                onChange={handleChange}

                            />
                            {showFilter === false ?
                                <button type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center" onClick={openFilter}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                                :
                                <button type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center" onClick={closeFilter}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                    </svg>
                                </button>
                            }
                            <button type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center" onClick={openModalLocator}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-location" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                </svg>
                            </button>
                            <ClinicLocator showModalLocator={showModalLocator} closeModalLocator={closeModalLocator} />
                        </div>
                    </div>
                    {showFilter === true ?
                        <div className="md:flex md:flex-row items-end justify-between grid grid-cols-2 gap-3 p-2">
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Campos de la fisioterapia</label>
                                <select onChange={(e) => handleFilterChanged('field', e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ocus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option value={-1}>Todos</option>
                                    {orderPhysiotherapyFields.map(fields => (
                                        <option key={fields.id} value={fields.id}>{fields.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Tipo de tratamiento</label>
                                <select onChange={(e) => handleFilterChanged('treatment', e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ocus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option value={-1}>Todos</option>
                                    {orderType.map(fields => (
                                        <option key={fields.id} value={fields.id}>{fields.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Otros</label>
                                <select onChange={(e) => handleFilterChanged('other', e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ocus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option value={-1}>Todos</option>
                                    {orderOthers.map(fields => (
                                        <option key={fields.id} value={fields.id}>{fields.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Concierto</label>
                                <select onChange={(e) => handleFilterChanged('concert', e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ocus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option value={-1}>Todos</option>
                                    {clinicSearchConcerts.map(fields => (
                                        <option key={fields.id} value={fields.id}>{fields.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Provincia</label>
                                <select onChange={(e) => handleFilterChanged('province', e.target.value)} value={clinicSearchProvince} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                    <option value={''} >Todas</option>
                                    <option>Alicante</option>
                                    <option>Valencia</option>
                                    <option>Castellón</option>
                                </select>
                            </div>
                            <div className="relative z-0 w-full group">
                                <label className="block mb-2 text-xs font-medium text-stone-600">Ciudad</label>
                                <input onChange={(e) => handleFilterChanged('town', e.target.value)} value={clinicSearchTown} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                            </div>
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                            <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleRestartButtonClicked}>Reiniciar</button>
                        </div>
                        : <div></div>
                    }
                    {/* List */}
                    <div className="md:mt-4 overflow-y-auto overflow-x-hidden relative py-2 content-card">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:px-2">
                            {isLoading && <Loader />}
                            {clinicList.map((clinic) => (

                                <div key={clinic.id} className="card bg-white px-2 py-3">
                                    <button type="button" className="text-left" onClick={() => handleAddToDetail(clinic.id)}>
                                        <div className="flex md:items-start flex-col md:flex-row gap-4 md:gap-4">
                                            <img className="h-20 md:h-24 md:w-2/5 min-w-40 object-contain object-center rounded-lg mb-2 md:mb-0" src={contentUtils.getLargeImageUrl(clinic.logo)} alt="Clinicas ICOFCV" />
                                            <div className="md:w-3/5 ml-2">
                                                <div className="text-md font-medium leading-5 clinic-title uppercase">{clinic.title}</div>
                                                <div className="text-neutral-500 text-xs">{clinic.propsMisc}</div>
                                                <div className="text-neutral-500 text-xs">{clinic.propsPhone}</div>
                                                <div className="text-teal-600 text-xs underline clinic-mail">{clinic.email}</div>
                                                <div className="text-neutral-700 text-xs capitalize">{clinic.address}</div>
                                                <div className="text-neutral-700 text-xs mb-2 capitalize">{clinic.postalCode} {clinic.town} {getProvinceText(clinic.province)}</div>
                                                <div className="text-neutral-500 text-xs capitalize">{getType(clinic.type)}</div>
                                                <div className="text-neutral-500 text-xs capitalize">{getPhysiotherapyFields(clinic.physiotherapyFields)}</div>
                                                <div className="text-neutral-500 text-xs capitalize">{getOthers(clinic.others)}</div>
                                                <div className="text-neutral-500 text-xs capitalize">{getFeatureFilters(clinic.featureFilterList)}</div>
                                                <div className="text-neutral-500 text-xs">Registro: {clinic.registry}</div>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                            ))
                            }
                            {!isLoading ? clinicList.length === 0 && (<div>Clinica no encontrada</div>) : <Loader />}
                        </div>
                    </div>
                    {viewMoreClinicDataDisabled === false ?
                        <div className="flex items-center justify-end mx-3">
                            <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </>
    )
}







