import React, { useState } from 'react';
import { ModalNoLogin } from '../miProfile/ModalNoLogin'
import { ModalCreateOffer } from './ModalCreateOffer';

export function AccessEmployment() {


    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [showModalCreateOffer, setShowModalCreateOffer] = useState(false);
    const openModalCreateOffer = () => setShowModalCreateOffer(true);
    const closeModalCreateOffer = () => setShowModalCreateOffer(false);

    function updateTable() { }

    return (
        <>
            <div className="py-1 md:px-4 md:w-1/2">
                <h3 className="font-medium text-lg text-neutral-500">BOLSA DE TRABAJO PARA COLEGIADOS</h3>
                <p className="my-2 text-sm text-neutral-500 line-clamp-4">Explora las últimas oportunidades laborales en nuestra bolsa de trabajo en línea. Actualizamos regularmente nuestro sitio con nuevas oportunidades de empleo.</p>
                <div className="card-actions flex justify-center mb-2">
                    <button onClick={openModal} type="button" className="btn bg-gray-400 hover:bg-gray-200 text-white px-8 mt-2 flex items-center justify-center mx-auto w-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock stroke-white" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="5" y="11" width="14" height="10" rx="2" />
                            <circle cx="12" cy="16" r="1" />
                            <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                        </svg>
                        <span className="ml-2">Acceso Bolsa de trabajo</span>
                    </button>
                </div>
            </div>

            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
            <ModalCreateOffer showModal={showModalCreateOffer} closeModal={closeModalCreateOffer} updateTable={updateTable} />
        </>
    )
}