import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes'
import cordova from '../api/cordovaAPI';

const initialState = {
  isLoggedIn: false
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      let newState = Object.assign({},
        action.session,
        { isLoggedIn: true });

        sessionStorage.setItem('cognito-auth', JSON.stringify(newState));
        cordova.setLocalStorageItem('cognito-auth', JSON.stringify(newState));

      return newState;

    case CLEAR_SESSION:
        
        sessionStorage.setItem('cognito-auth', '');
        cordova.removeLocalStorageItem('cognito-auth');

      return initialState

    default:
      return state
  }
}

export default session