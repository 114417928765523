import React from 'react';
import { Link } from "react-router-dom";

export const ViewListAdvertisments = () => {

 return (
    <>
        <div>
            <h3 className="font-medium text-lg text-neutral-500 text-center">EXPLORA NUESTRA SELECCIÓN DE ANUNCIOS EN LÍNEA</h3>
            <p className="my-2 text-neutral-500 text-sm">Consulta aquí los anuncios de compra / venta / alquiler y traspaso de locales y/o material fisioterápico. Si eres colegiado/a del ICOFCV, también puedes publicar un anuncio desde aquí.</p>
            <div className="card-actions flex justify-center mb-2">
                <Link to='/college/advertismentslist' className="btn bg-teal-600 hover:bg-teal-700 text-white px-8 mt-2">Ir a sección de anuncios</Link>
            </div>
        </div> 
    </>
 )
}