import React, { useState, useEffect } from 'react'
import { getItemsPerPage, getCollegiatePaginated } from '../../api/drupalAPI'
import { Collegiate } from '@icofcv/common';
import './CollegiateList.css';
import Loader from '../spinner/Loader';
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation'
import { COLLEGE_MODULE } from '../../constants/modules'
import { Link } from "react-router-dom";

export const CollegiateList = () => {

    ///constantes lista y filtros
    const store = useStore();
    const [collegiateList, setCollegiateList] = useState<Collegiate[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [collegiateSearchName, setCollegiateSearchName] = useState('');
    const [collegiateSearchLastName, setCollegiateSearchLastName] = useState('');
    const [collegiateSearchNumber, setCollegiateSearchNumber] = useState('');
    const [collegiateSearchProvince, setCollegiateSearchProvince] = useState('');
    const [collegiateSearchType, setCollegiateSearchType] = useState('');
    const [searchCollegiate, setSearchCollegiate] = useState('');
    const [currentPageCollegiate, setCurrentPageCollegiate] = useState(0);
    const [viewMoreCollegiateDataDisabled, setViewMoreCollegiateDataDisabled] = useState(false);

    /// abrir y cerrar filtro 
    const [showFilter, setShowFilter] = useState(false);
    const openFilter = () => setShowFilter(true);
    const closeFilter = () => setShowFilter(false);


    const fetchCollegiateList = async (filter) => {
        setIsLoading(true);

        getCollegiatePaginated(filter)
            .then((response) => {
                console.log(`response.length: ${response.length}`);
                if (response.length > getItemsPerPage()) {
                    response.pop();
                    setViewMoreCollegiateDataDisabled(false);

                } else {
                    setViewMoreCollegiateDataDisabled(true);
                }

                var list: Collegiate[] = [];
                if (filter.page == 0) {
                    list = response;
                } else {
                    list = [...collegiateList, ...response];
                }
                setCollegiateList(list);
                console.log(list);

                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    //funcion ver más datos
    const handleShowMoreData = () => {
        setCurrentPageCollegiate(currentPageCollegiate + 1);

        var filter = {
            page: currentPageCollegiate + 1,
            search: searchCollegiate,
            searchNumber: collegiateSearchNumber,
            searchName: collegiateSearchName,
            searchLastName: collegiateSearchLastName,
            searchProvince: collegiateSearchProvince,
            searchType: collegiateSearchType,
        };

        fetchCollegiateList(filter);
    }

    //funcion cambio de filtro    
    const handleFilterChanged = (id, value) => {
        setCurrentPageCollegiate(0);

        switch (id) {
            case 'number': setCollegiateSearchNumber(value); break;
            case 'name': setCollegiateSearchName(value); break;
            case 'lastname': setCollegiateSearchLastName(value); break;
            case 'province': setCollegiateSearchProvince(value); break;
            case 'type': setCollegiateSearchType(value); break;
        }
    }

    //funcion filtrado campos 
    const handleFilterButtonClicked = () => {
        var filter = {
            page: currentPageCollegiate,
            search: searchCollegiate,
            searchNumber: collegiateSearchNumber,
            searchName: collegiateSearchName,
            searchLastName: collegiateSearchLastName,
            searchProvince: collegiateSearchProvince,
            searchType: collegiateSearchType,
        };

        fetchCollegiateList(filter);
    }

    //reiniciar busqueda
    const handleRestartButtonClicked = () => {
        setCurrentPageCollegiate(0);
        setCollegiateSearchNumber('');
        setCollegiateSearchName('');
        setCollegiateSearchLastName('');
        setCollegiateSearchProvince('');
        setCollegiateSearchType('');

        var filter = {
            page: 0,
            search: searchCollegiate,
            searchNumber: '',
            searchName: '',
            searchLastName: '',
            searchProvince: '',
            searchType: '',
        };

        fetchCollegiateList(filter);
    }

    //funcion filtro principal  
    const handleChange = e => {
        setCurrentPageCollegiate(0);
        setSearchCollegiate(e.target.value);

        setTimeout(() => {
            var filter = {
                page: 0,
                search: e.target.value,
                searchNumber: collegiateSearchNumber,
                searchName: collegiateSearchName,
                searchLastName: collegiateSearchLastName,
                searchProvince: collegiateSearchProvince,
                searchType: collegiateSearchType,
            };

            fetchCollegiateList(filter);
        }, 800);
    }

    useEffect(() => {
        store.dispatch(setDetailNavigation(COLLEGE_MODULE, 'Colegiados'));

        var filter = {
            page: 0,
            search: '',
            searchNumber: '',
            searchName: '',
            searchLastName: '',
            searchProvince: '',
            searchType: '',
        };

        fetchCollegiateList(filter);
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-40 pb-3 font-medium text-xl text-white hover:text-white flex items-center" to="/college">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Colegiados</span>
                </Link>
            </div>

            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-40 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Colegiados</span>
                    </Link>
                    <div className="card-body py-1 px-0 md:px-4">
                        <div className="flex items-start lg:items-center justify-start lg:justify-between flex-col lg:flex-row lg:py-4 border-bottom">
                            <div className="flex items-start md:items-center justify-start md:justify-between flex-wrap md:flex-row gap-2 border-b md:border-b-0">
                                <div className="flex items-center mb-2">
                                    <div className="h-8 w-8 bg-gray-300 text-gray-600 flex items-center justify-center"><span className="text-sm">CO</span></div>
                                    <p className="my-2 mx-2 text-xs text-neutral-500">Colegiados de Honor</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <div className="h-8 w-8 bg-amber-300 text-gray-600 flex items-center justify-center"><span className="text-sm">HO</span></div>
                                    <p className="my-2 mx-2 text-xs text-neutral-500">Honorarios</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <div className="h-8 w-8 bg-teal-600 text-white flex items-center justify-center"><span className="text-sm">EJ</span></div>
                                    <p className="my-2 mx-2 text-xs text-neutral-500">Ejercientes</p>
                                </div>
                                <div className="flex items-center mb-2">
                                    <div className="h-8 w-8 bg-red-600 text-white flex items-center justify-center"><span className="text-sm">NO</span></div>
                                    <p className="my-2 mx-2 text-xs text-neutral-500">No Ejercientes</p>
                                </div>
                            </div>

                            <div className="flex items-center my-2 md:my-0 flex-wrap">
                                <input
                                    type="search"
                                    value={searchCollegiate}
                                    placeholder="Nombre o apellidos o nº colegiado"
                                    className="w-64 me-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-2"
                                    aria-label="Search"
                                    onChange={handleChange}
                                />
                                {showFilter === false ?
                                    <button onClick={openFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                        </svg>
                                    </button>
                                    :
                                    <button onClick={closeFilter} type="button" className="h-10 w-10 bg-gray-50 border border-gray-300 rounded-lg focus:ring-teal-500 focus:border-teal-500 p-2 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="32" height="32" viewBox="0 0 24 24" strokeWidth="none" stroke="none" fill="#0d9488" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                                        </svg>
                                    </button>
                                }
                            </div>
                        </div>
                        {showFilter === true ?
                            <>
                                <div className="md:flex md:flex-row items-end justify-between grid grid-cols-2 gap-2 p-2">
                                    <div className="relative z-0 w-full group">
                                        <label htmlFor="" className="block mb-2 text-xs font-medium text-stone-600">Número colegiado</label>
                                        <input onChange={(e) => handleFilterChanged('number', e.target.value)} value={collegiateSearchNumber} name="" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <label htmlFor="" className="block mb-2 text-xs font-medium text-stone-600">Nombre</label>
                                        <input onChange={(e) => handleFilterChanged('name', e.target.value)} value={collegiateSearchName} name="" id="searchText" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <label htmlFor="" className="block mb-2 text-xs font-medium text-stone-600">Apellidos</label>
                                        <input onChange={(e) => handleFilterChanged('lastname', e.target.value)} value={collegiateSearchLastName} name="" id="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2" />
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <label htmlFor="" className="block mb-2 text-xs font-medium text-stone-600">Provincia</label>
                                        <select onChange={(e) => handleFilterChanged('province', e.target.value)} value={collegiateSearchProvince} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                            <option value={''}>Cualquier Provincia</option>
                                            <>
                                                <option value={'V'}>Valencia</option>
                                                <option value={'A'}>Alicante</option>
                                                <option value={'CS'}>Castellón</option>
                                            </>
                                        </select>
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <label htmlFor="" className="block mb-2 text-xs font-medium text-stone-600">Tipo</label>
                                        <select onChange={(e) => setCollegiateSearchType(e.target.value)} value={collegiateSearchType} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2">
                                            <option value={''}>Seleccionar tipo</option>
                                            <option value={'ejerciente'}>Ejerciente</option>
                                            <option value={'noejerciente'}>No ejercientes</option>
                                            <option value={'honorarios'}>Honorarios</option>
                                            <option value={'colehonor'}>Colegiados de honor</option>
                                        </select>
                                    </div>
                                    <div className="hidden md:flex items-center gap-2">
                                        <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                                        <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded" type="button" onClick={handleRestartButtonClicked}>Reiniciar</button>
                                    </div>
                                </div>
                                <div className="flex md:hidden items-center gap-2">
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" type="button" onClick={handleFilterButtonClicked}>Buscar</button>
                                    <button className="bg-teal-600 hover:bg-teal-700 text-white text-sm active:bg-teal-700 px-8 py-2 outline-none focus:outline-none rounded w-full" type="button" onClick={handleRestartButtonClicked}>Reiniciar</button>
                                </div>
                            </>
                            : <div></div>
                        }
                        <div className="md:my-4 overflow-auto relative content-card">
                            <table className="w-full text-sm text-left text-gray-500 hidden md:table">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3 px-6">Tipo</th>
                                        <th scope="col" className="py-3 px-6">Colegiado</th>
                                        <th scope="col" className="py-3 px-6">Nombre</th>
                                        <th scope="col" className="py-3 px-6">Apellido</th>
                                        <th scope="col" className="py-3 px-6">Provincia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* { isLoading && <Loader />} */}

                                    {collegiateList.map((collegiate) => (
                                        <>
                                        {collegiate.color === 'ejerciente' && collegiate.enabled ?
                                        <tr key={collegiate.id} className="bg-white border-b">
                                            <td className="py-2 px-6">
                                               
                                                    <div className="ej"><span className="text-sm">EJ</span></div>
                                                    </td>

                                                    {collegiate.enabled ?
                                                <>
                                                    <td className="py-2 px-6">
                                                        {collegiate.collegiate}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.firstname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.lastname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.provincia}
                                                    </td>
                                                </>
                                                :
                                                <div></div>
                                            }
                                                    </tr>
                                                    : null
                                                }

                                                {collegiate.color === 'noejerciente' && collegiate.enabled ?
                                                 <tr key={collegiate.id} className="bg-white border-b">
                                                 <td className="py-2 px-6">
                                                   <div className="no"><span className="text-sm">NO</span></div>
                                                   </td>

                                                    {collegiate.enabled ?
                                                <>
                                                    <td className="py-2 px-6">
                                                        {collegiate.collegiate}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.firstname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.lastname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.provincia}
                                                    </td>
                                                </>
                                                :
                                                <div></div>
                                            }
                                                    </tr>
                                                    : null
                                                 }

                                            {collegiate.color === 'honorarios' && collegiate.enabled  ?
                                                 <tr key={collegiate.id} className="bg-white border-b">
                                                 <td className="py-2 px-6">
                                                 <div className="ho"><span className="text-sm">HO</span></div>
                                                   </td>

                                                    {collegiate.enabled ?
                                                <>
                                                    <td className="py-2 px-6">
                                                        {collegiate.collegiate}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.firstname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.lastname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.provincia}
                                                    </td>
                                                </>
                                                :
                                                <div></div>
                                            }
                                                    </tr>
                                                    : null
                                                 }

                                            {collegiate.color === 'colehonor' && collegiate.enabled  ?
                                                 <tr key={collegiate.id} className="bg-white border-b">
                                                 <td className="py-2 px-6">
                                                 <div className="co"><span className="text-sm">CO</span></div>
                                                   </td>

                                                    {collegiate.enabled ?
                                                <>
                                                    <td className="py-2 px-6">
                                                        {collegiate.collegiate}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.firstname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.lastname}
                                                    </td>
                                                    <td className="py-2 px-6">
                                                        {collegiate.provincia}
                                                    </td>
                                                </>
                                                :
                                                <div></div>
                                            }
                                                    </tr>
                                                    : null
                                                 }
                                                </>
                                    ))
                                    }
                                </tbody>
                            </table>
                            {/* Mobile Table */}
                            <table className="w-full text-sm text-left text-gray-500 block md:hidden">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr className="w-full">
                                        <th scope="col" className="py-3 px-2">Tipo</th>
                                        <th scope="col" className="py-3 text-center">Col.</th>
                                        <th scope="col" className="py-3 pl-1">Nombre</th>
                                        <th scope="col" className="py-3 pr-1 text-center">Prov.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* { isLoading && <Loader />} */}

                                    {collegiateList.map((collegiate) => (
                                        <tr key={collegiate.id} className="bg-white border-b">
                                            <td className="py-2 px-2">
                                                {collegiate.color === 'ejerciente' && collegiate.enabled ?
                                                    <div className="ej"><span className="text-sm">EJ</span></div>
                                                    : null
                                                }
                                                {collegiate.color === 'noejerciente' && collegiate.enabled ?
                                                    <div className="no"><span className="text-sm">NO</span></div>
                                                    : null
                                                }
                                                {collegiate.color === 'honorarios' && collegiate.enabled ?
                                                    <div className="ho"><span className="text-sm">HO</span></div>
                                                    : null
                                                }
                                                {collegiate.color === 'colehonor' && collegiate.enabled ?
                                                    <div className="co"><span className="text-sm">CO</span></div>
                                                    : null
                                                }

                                            </td>
                                            {collegiate.enabled ?
                                                <>
                                                    <td className="py-2 text-center">
                                                        {collegiate.collegiate}
                                                    </td>
                                                    <td className="py-2 pl-1">
                                                        <span>{collegiate.firstname}</span>
                                                        <span className="ml-2">{collegiate.lastname}</span>
                                                    </td>
                                                    <td className="py-2 pr-1 text-center">
                                                        {collegiate.provincia}
                                                    </td>
                                                </>
                                                : null
                                            }
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            {!isLoading ? collegiateList.length === 0 && (<React.Fragment>Colegiado no encontrado</React.Fragment>) : <Loader />}
                        </div>
                    </div>
                    {viewMoreCollegiateDataDisabled === false ?
                        <div className="flex items-center justify-end mx-3">
                            <button className="bg-transparent text-teal-600 text-sm active:bg-teal-700 py-2 outline-none focus:outline-none rounded underline" type="button" onClick={handleShowMoreData}>VER MÁS</button>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </>
    )
}


