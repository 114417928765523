import { CollegiateDetails } from '@icofcv/common';
import store from '../store';

function getHeaders() {
    let headers = {
        'content-type': 'application/json;charset=UTF-8'
    };

    if (store.getState().session.credentials) {
        headers['Authorization'] = `Bearer ${store.getState().session.credentials.idToken}`;
    }

    return headers;
}

export async function checkCollegiateStatus(details): Promise<CollegiateDetails> { 
    const response = await fetch('/sugar/check-collegiate-status', {
        method: "POST",
        body: JSON.stringify(details),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCollegiateByFiscalId(fiscalId): Promise<CollegiateDetails> { 
    const response = await fetch('sugar/collegiate-details', {
        method: "GET",
        body: JSON.stringify(fiscalId),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}