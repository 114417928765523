import contentUtils from '../../lib/contentUtils';

export function SocialNetworks() {

    const urlWebsite = "https://colfisiocv.com";
    const urlTwiter = "https://twitter.com/Icofcv";
    const urlYoutube = "https://www.youtube.com/channel/UCYDxDmCQ_xfzk0ZnAkMt2WQ?view_as=subscriber";
    const urlInstagram = "https://www.instagram.com/icofcv/";
    const urlFacebook = "https://www.facebook.com/colegiofisioterapeutascv";
    const urlLinkedin = "https://es.linkedin.com/company/ilustrecolegiodefisioterapeutasdelacomunidadvalenciana";

    const openLinkWebsite = () => contentUtils.openExternalUrl(urlWebsite);
    const openLinkTwiter = () => contentUtils.openExternalUrl(urlTwiter);
    const openLinkYoutube = () => contentUtils.openExternalUrl(urlYoutube);
    const openLinkInstagram = () => contentUtils.openExternalUrl(urlInstagram);
    const openLinkFacebook = () => contentUtils.openExternalUrl(urlFacebook);
    const openLinkLinkedin = () => contentUtils.openExternalUrl(urlLinkedin);

    return (
        <div className="flex justify-center items-center pb-3 md:hidden">
            <ul className="items-center flex">
                <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
                    <a onClick={openLinkWebsite}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icons-tabler-outline icon-tabler-world-www" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19.5 7a9 9 0 0 0 -7.5 -4a8.991 8.991 0 0 0 -7.484 4" />
                            <path d="M11.5 3a16.989 16.989 0 0 0 -1.826 4" />
                            <path d="M12.5 3a16.989 16.989 0 0 1 1.828 4" />
                            <path d="M19.5 17a9 9 0 0 1 -7.5 4a8.991 8.991 0 0 1 -7.484 -4" />
                            <path d="M11.5 21a16.989 16.989 0 0 1 -1.826 -4" />
                            <path d="M12.5 21a16.989 16.989 0 0 0 1.828 -4" />
                            <path d="M2 10l1 4l1.5 -4l1.5 4l1 -4" />
                            <path d="M17 10l1 4l1.5 -4l1.5 4l1 -4" />
                            <path d="M9.5 10l1 4l1.5 -4l1.5 4l1 -4" />
                        </svg>
                    </a>
                </li>
                <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
                    <a onClick={openLinkYoutube}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-youtube" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="3" y="5" width="18" height="14" rx="4" />
                            <path d="M10 9l5 3l-5 3z" />
                        </svg>
                    </a>
                </li>
                <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
                    <a onClick={openLinkInstagram}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                        </svg>
                    </a>
                </li>
                <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
                    <a onClick={openLinkFacebook}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                        </svg>
                    </a>
                </li>
                <li className="mx-0.5 rounded-full bg-teal-600 h-8 w-8 flex items-center justify-center">
                    <a onClick={openLinkLinkedin}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="2" />
                            <line x1="8" y1="11" x2="8" y2="16" />
                            <line x1="8" y1="8" x2="8" y2="8.01" />
                            <line x1="12" y1="16" x2="12" y2="11" />
                            <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    )
}