import React from 'react'
import { Link } from "react-router-dom";

export function AccessEmploymentLogin() {
    return (
        <>
            <div className="py-1 md:px-4 md:w-1/2">
                <h3 className="font-medium text-lg text-neutral-500 line-clamp-2">CREA OFERTAS DE TRABAJO Y/O EXPLORA LAS ÚLTIMAS OPORTUNIDADES LABORALES</h3>
                <p className="my-2 text-sm text-neutral-500 line-clamp-4">Explora las últimas oportunidades laborales en nuestra bolsa de trabajo en línea. Actualizamos regularmente nuestro sitio con nuevas oportunidades de empleo.</p>
                <div className="card-actions flex justify-center mb-2">
                    <Link to="/college/employmentlist" className="bg-teal-600 hover:bg-teal-800 text-white px-8 py-2 mt-2 flex items-center justify-center mx-auto w-auto rounded">
                        <span className="ml-2">Acceso Bolsa de trabajo</span>
                    </Link>
                </div>
            </div>
        </>
    )
}