import {Collegiate} from '@icofcv/common';
import {Where} from '@loopback/repository';

export function createCollegiateWhereFilter(collegiateFilter:any) {
    const searchParam = collegiateFilter.search ? `%${collegiateFilter.search}%` : '';
    const searchNameParam = collegiateFilter.searchName ? `%${collegiateFilter.searchName}%` : '';
    const searchLastNameParam = collegiateFilter.searchLastName ? `%${collegiateFilter.searchLastName}%` : '';
    const searchNumberParam = collegiateFilter.searchNumber ? `%${collegiateFilter.searchNumber}%` : '';
    const searchProvinceParam = collegiateFilter.searchProvince ? `^${collegiateFilter.searchProvince}$` : '';
    const searchTypeParam = collegiateFilter.searchType;
    
    var searchParamsOr:Where<Collegiate>[] = [];
    if (searchParam && searchParam.trim().length > 0) {
        console.log(`*** searchParamsOr - firstname: '${searchParam}'`);
        searchParamsOr.push({ firstname: { like: searchParam, options: 'i' } });
        console.log(`*** searchParamsOr - lastname: '${searchParam}'`);
        searchParamsOr.push({ lastname: { like: searchParam, options: 'i' } });
        console.log(`*** searchParamsOr - collegiate: '${searchParam}'`);
        searchParamsOr.push({ collegiate: { like: searchParam, options: 'i' } });
        console.log(`*** searchParamsOr - provincia: '${searchParam}'`);
        searchParamsOr.push({ provincia: { regexp: searchParam, options: 'i' } });
    }

    var searchParamsAnd:Where<Collegiate>[] = [];
    if (searchTypeParam && searchTypeParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - color: '${searchTypeParam}'`);
        searchParamsAnd.push({ color: { eq: searchTypeParam } });
    }
    if (searchNameParam && searchNameParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - firstname: '${searchNameParam}'`);
        searchParamsAnd.push({ firstname: { like: searchNameParam, options: 'i' } });
    }
    if (searchLastNameParam && searchLastNameParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - lastname: '${searchLastNameParam}'`);
        searchParamsAnd.push({ lastname: { like: searchLastNameParam, options: 'i' } });
    }
    if (searchNumberParam && searchNumberParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - collegiate: '${searchNumberParam}'`);
        searchParamsAnd.push({ collegiate: { like: searchNumberParam, options: 'i' } });
    }
    if (searchProvinceParam && searchProvinceParam.trim().length > 0) {
        console.log(`*** searchParamsAnd - provincia: '${searchProvinceParam}'`);
        searchParamsAnd.push({ provincia: { regexp: searchProvinceParam, options: 'i' } });
    }

    var where = {};
    if (searchParamsOr.length > 0 || searchParamsAnd.length > 0) {
        if (searchParamsOr.length > 0 && searchParamsAnd.length > 0) {
            where = { 
                and: [
                    { or: searchParamsOr },
                    { searchParamsAnd }
                ]
            };
        } 
        if (searchParamsOr.length > 0 && searchParamsAnd.length == 0) {
            where = { 
                or: searchParamsOr
            };
        }
        if (searchParamsOr.length == 0 && searchParamsAnd.length > 0) {
            where = { 
                and: searchParamsAnd
            };
        }
    }

    return where;
}