import { useState, useEffect } from 'react';
import { getMagazine } from '../../api/drupalAPI'
import { Magazine } from '@icofcv/common';
import contentUtils from '../../lib/contentUtils'
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Loader from '../spinner/Loader';

export function MagazineCarousel() {

    const [magazineList, setMagazineList] = useState<Magazine[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    const parseFormDateToServiceFormat = (formDate?: string): string => {
        const date = (!!formDate) ? new Date(formDate) : new Date();
        return new Intl.DateTimeFormat('es-ES', { month: 'long', year: 'numeric' }).format(date);
    }

    const fetchMagazineList = async () => {
        setIsLoading(true)
        getMagazine().then((response) => {
            console.log(response)
            setMagazineList(response);
            setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            throw error;
        });
    }

    useEffect(() => {
        fetchMagazineList();
    }, []);

    function goToPageMagazineList() {
        navigate('magazinelist');
    }

    return (
        <>

            <div className="card bg-white px-2 py-2 md:p-6">
                <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                        <line x1="8" y1="8" x2="12" y2="8" />
                        <line x1="8" y1="12" x2="12" y2="12" />
                        <line x1="8" y1="16" x2="12" y2="16" />
                    </svg>
                    <span>Últimas revistas</span>
                </h1>
                <div className="card-body py-1 md:px-0 h-72 md:h-80 overflow-auto">
                    {/* Item */}
                    {magazineList.slice(0, 6).map(magazine => (
                        <div className="bg-white px-2 py-3 md:h-28 border-b rounded-0 hidden md:block">
                            <a className="text-left overflow-hidden pb-2 w-100" onClick={() => contentUtils.openExternalUrl(magazine.link)} >
                                <div className="flex items-top flex-col md:flex-row md:items-center gap-2 md:gap-4">
                                    <img className="h-20 w-32 object-cover object-center rounded-md" src={contentUtils.getLargeImageUrl(magazine.picture)} alt="Revista" />
                                    <div className="md:w-4/5 ml-2 h-18">
                                        <small className="text-teal-600 mb-2">Fecha publicación: {magazine.creationDate ? parseFormDateToServiceFormat(magazine.creationDate) : '--'}</small>
                                        <p className="text-lg text-neutral-800 font-medium news-title">{magazine.title}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                    <div id="default-carousel" className="relative block md:hidden" data-carousel="static" >
                        {/* Carousel wrapper */}
                        <Swiper
                            centeredSlides={false}
                            breakpoints={{
                                375: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1536: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper relative h-64 md:h-80 overflow-hidden rounded-lg flex flex-row"
                        >
                            {isLoading && <Loader />}
                            {magazineList.slice(0, 6).map(magazine => (
                                <SwiperSlide key={magazine.id} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 w-1/3 md:px-2 flex flex-shrink-0 relative flex-col items-center">
                                    <div className="card w-full md:w-100 h-56 md:h-72 bg-white md:border-1 carousel-card p-2">
                                        <div className="card-body p-1">
                                            <a onClick={() => contentUtils.openExternalUrl(magazine.link)} className="hover:text-neutral-500">
                                                <h3 className="font-medium text-teal-600 text-center text-sm md:my-2">{magazine.title}</h3>
                                                <figure>
                                                    <img className="h-40 md:h-56 w-100 object-contain object-center rounded-lg" src={contentUtils.getLargeImageUrl(magazine.picture)} alt="Revista" />
                                                </figure>
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                    </div>
                </div>
                <button type="button" className="see-more-link px-2 py-3 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" onClick={() => goToPageMagazineList()}>VER MÁS</button>
            </div>
        </>
    );
}




