import { useState, useEffect } from 'react';
import {getProvince } from '../../api/icofcvAPI';
import './EmploymentDetail.css'
import { Link } from "react-router-dom";
import { Province } from '@icofcv/common';
import { Country, EmploymentContract } from '@icofcv/common';
import { getEmploymentById, getCountry, getEmploymentContract } from '../../api/drupalAPI'
import dateUtils from '../../lib/dateUtils'

export const EmploymentDetail = () => {

    const [employmentsCountry, setEmploymentsCountry] = useState<Country[]>([]);
    const [employmentsProvince, setEmploymentsProvince] = useState<Province[]>([]);
    const [currentEmployment, setCurrentEmployment] = useState<any>(null);
    const [employmentsContract, setEmploymentsContract] = useState<EmploymentContract[]>([]);

    const fetchCountries = async () => {
        getCountry()
            .then((response) => {
                console.log(response);
                setEmploymentsCountry(response);
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    const fetchProvinces = async () => {
        getProvince()
            .then((response) => {
                console.log(response);
                setEmploymentsProvince(response);
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    const fetchEmploymentsContract = async () => {
        getEmploymentContract()
            .then((response) => {
                console.log(response);
                setEmploymentsContract(response);
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }

    function getEmploymentsCountry(countryId) {
        if (!countryId) {
            return '';
        }
        var field: number = parseInt(countryId);

        for (var i = 0; i < employmentsCountry.length; i++) {
            if (employmentsCountry[i].id == field) {
                return employmentsCountry[i].name;
            }
        }
    }


    function getEmploymentsProvince(provinceId) {
        if (!provinceId) {
            return '';
        }
        var field: number = parseInt(provinceId);

        for (var i = 0; i < employmentsProvince.length; i++) {
            if (employmentsProvince[i].id == field) {
                return employmentsProvince[i].name;
            }
        }
    }

    function getEmploymentContractNameById(employmentContractId) {
        if (!employmentContractId) {
            return '';
        }
        var field: number = parseInt(employmentContractId);

        for (var i = 0; i < employmentsContract.length; i++) {
            if (employmentsContract[i].id == field) {
                return employmentsContract[i].name;
            }
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        fetchCountries()
        fetchProvinces()
        fetchEmploymentsContract()
        getEmploymentById(id)
            .then((response) => {
                console.log(response);
                setCurrentEmployment(response)
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/college/employmentlist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6 w-1/3 ml-0" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title">{currentEmployment?.title}</span>
                </Link>
            </div>
            <div key={currentEmployment?.id} className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/college/employmentlist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">{currentEmployment?.title}</h1>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Fecha publicación:</span>
                                <span className="text-teal-600 text-sm mb-4 mx-2">{dateUtils.utcToDate(currentEmployment?.publishStartDate)}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Fecha Fin:</span>
                                <span className="text-teal-600 text-sm mb-4 mx-2">{dateUtils.utcToDate(currentEmployment?.publishEndDate)}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Oferta:</span>
                                <span className="text-sm text-neutral-600 font-normal mx-2">{currentEmployment?.title}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Descripción:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description" dangerouslySetInnerHTML={{ __html: currentEmployment?.description }}></div>
                            </div>

                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Nombre empresa:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.companyName}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Número  registro autonómico:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.regionalRegistrationNumber}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">País:</span>

                                <div className="text-neutral-600 font-normal text-sm news-text-description">{getEmploymentsCountry(currentEmployment?.countryId)}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Provincia:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.provinceName}</div>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{getEmploymentsProvince(currentEmployment?.provinceId)}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Teléfono móvil:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.mobile}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Web url:</span>
                                {currentEmployment?.web ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.web}</div>
                                    :
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">--</div>
                                }
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Email:</span>
                                <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.email}</div>
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium"> Modelo de contrato:</span>

                                {currentEmployment?.contractId ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{getEmploymentContractNameById(currentEmployment?.contractId)}</div>
                                    : null}
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Incorporación:</span>
                                {currentEmployment?.incorporation ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.incorporation}</div>
                                    :
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">--</div>
                                }
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Horario:</span>
                                {currentEmployment?.schedule ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.schedule}</div>
                                    :
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">--</div>
                                }
                            </div>
                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Ámbito:</span>
                                {currentEmployment?.scope === 0 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Nacional</div>
                                    : null}
                                {currentEmployment?.scope === 1 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">Internacional</div>
                                    : null}
                                {currentEmployment?.scope === 2 ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">No definido</div>
                                    : null}
                            </div>

                            <div className="mb-2">
                                <span className="text-neutral-800 text-sm font-medium">Experiencia previa:</span>
                                {currentEmployment?.experienceDescription ?
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">{currentEmployment?.experienceDescription}</div>
                                    :
                                    <div className="text-neutral-600 font-normal text-sm news-text-description">--</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}  
