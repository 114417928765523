import contentUtils from '../../lib/contentUtils'

export function AccessKnowledgePlatformLogin() {

    const urlLink = "https://app.colfisiocv.com/AccessKnowledgePlatformLogin.html"
    const openLink = () => {
        contentUtils.openExternalUrl(urlLink)
    }

    return (
        <div className="card bg-white px-2 py-3 md:p-6">
            <h1 className="pb-3 font-medium text-lg flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tools mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />
                    <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />
                    <polyline points="12 8 7 3 3 7 8 12" />
                    <line x1="7" y1="8" x2="5.5" y2="9.5" />
                    <polyline points="16 12 21 17 17 21 12 16" />
                    <line x1="16" y1="17" x2="14.5" y2="18.5" />
                </svg>
                <span>Plataforma del conocimiento Eureka</span>
            </h1>
            <div className="card-body py-1 px-4 flex justify-center items-center flex-column">
                <div className="w-100 flex flex-col md:flex-row items-center justify-start">
                    <img className="h-36 mx-auto max-w-full" src="../eureka.jpg" />
                    <div className="mx-10">
                        <p className="my-4 text-sm text-neutral-500">
                            Accede a la nuevas herramienta, Eureka, de editorial médica panamericana donde podrás encontrar mucha información interesante.
                        </p>
                        <a onClick={openLink} className="bg-teal-600 hover:bg-teal-800 text-white px-8 py-2 mt-2 flex items-center justify-center mx-auto w-64 rounded">
                            <span className="ml-2">Acceso eureka</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
