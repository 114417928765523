import { CollegesByAgreement } from '@icofcv/common';
import { Where } from '@loopback/repository';

export function createCollegesByAgreementWhereFilter(collegesByAgreementFilter: any) {
    const searchAgreementIdParam = collegesByAgreementFilter.agreementId;
    const searchCollegeIdParam = collegesByAgreementFilter.collegeId;

    const searchParamsAnd: Where<CollegesByAgreement>[] = [];

    if (searchAgreementIdParam) {
        console.log(`*** searchParamsAnd - agreementId: '${searchAgreementIdParam}'`);
        searchParamsAnd.push({ agreementId: { eq: searchAgreementIdParam } });
    }

    if (searchCollegeIdParam) {
        console.log(`*** searchParamsAnd - collegeId: '${searchCollegeIdParam}'`);
        searchParamsAnd.push({ collegeId: { eq: searchCollegeIdParam } });
    }

    let where = {};
    if (searchParamsAnd.length > 0) {
        if (searchParamsAnd.length > 0) {
            where = {
                and: [

                    { searchParamsAnd }
                ]
            };
        }

        if (searchParamsAnd.length > 0) {
            where = {
                and: searchParamsAnd
            };
        }
    }
    return where;
}