import { SELECT_EMPLOYMENT } from '../constants/actionTypes'

const selectedEmployment = (state = null, action) => {
  switch (action.type) {
    case SELECT_EMPLOYMENT:
      return Object.assign({},
        action.selectedEmployment)

    default:
      return state
  }
}

export default selectedEmployment;