import  { useState, useEffect } from 'react';
import type { RootState } from '../../store/index';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import contentUtils from '../../lib/contentUtils';

export function NotificationManager() {

    const navigate = useNavigate();
    const nativeEvent = useSelector((state: RootState) => state.nativeEvent);
    const [lastNotificationId, setLastNotificationId] = useState(-9999);

    const ToastWithButton = ({ notification }) => (
        <div className="fixNotchMarginTop min-w-[365px] w-full p-2 bg-white rounded">
            <div className="flex items-center justify-start gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notification" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#262626" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                    <circle cx="17" cy="7" r="3" />
                </svg>
                <p className="my-2 text-md font-bold text-neutral-800">{notification.notificationTitle}</p>
            </div>

            <p className="my-2 ml-1 text-sm text-neutral-500">{notification.notificationBody}</p>
            {notification.actionType == 1 && notification.actionTarget ?
            <div className="w-full flex justify-end">
                <button className="bg-transparent hover:text-teal-800 text-teal-600 mt-2 p-2 text-sm underline uppercase" onClick={() => {
                    if(notification.actionTarget.indexOf("http") == 0) {
                        contentUtils.openExternalUrl(notification.actionTarget);
                    } else {
                        navigate(notification.actionTarget);
                    }
                }}>
                    Ver
                </button>
            </div>
                :
                <div />
            }
        </div>
    );

    useEffect(() => {          

        if (nativeEvent && nativeEvent.type === 'notification') {

            console.log(nativeEvent.payload);
            const notification = nativeEvent.payload;

            if (lastNotificationId == notification.id) return;
           
            var toastId = toast(<ToastWithButton notification={notification} />, {
                                autoClose: false,
                                closeOnClick: false         
                            }
            );          
            setLastNotificationId(notification.id);
        }

    });

    return (
        <div>
        </div>
    )
}