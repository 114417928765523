import { CourseInscription } from '@icofcv/common';
import { Where } from '@loopback/repository';

export function createCoursesInscriptionWhereFilter(courseInscriptionFilter: any) {
    const searchCourseIdParam = courseInscriptionFilter.courseId;
    const searchUserIdParam = courseInscriptionFilter.userId;
    const searchStatusParam = courseInscriptionFilter.status;

    const searchParamsAnd: Where<CourseInscription>[] = [];
    if (searchCourseIdParam) {
        console.log(`*** searchParamsAnd - courseId: '${searchCourseIdParam}'`);
        searchParamsAnd.push({ courseId: { eq: searchCourseIdParam } });
    }

    if (searchUserIdParam) {
        console.log(`*** searchParamsAnd - userId: '${searchUserIdParam}'`);
        searchParamsAnd.push({ userId: { eq: searchUserIdParam } });
    }

    if (searchStatusParam) {
        console.log(`*** searchParamsAnd - status: '${searchStatusParam}'`);
        searchParamsAnd.push({ status: { eq: searchStatusParam } });
    }

    let where = {};
    if (searchParamsAnd.length > 0) {
        if (searchParamsAnd.length > 0) {
            where = {
                and: [

                    { searchParamsAnd }
                ]
            };
        }

        if (searchParamsAnd.length > 0) {
            where = {
                and: searchParamsAnd
            };
        }
    }

    return where;
}