import { useState, useEffect } from 'react';
import { Advantage } from '@icofcv/common';
import { getAdvantages } from '../../api/icofcvAPI';
import { Link } from "react-router-dom";
import contentUtils from '../../lib/contentUtils';
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation'
import { PROFILE_MODULE } from '../../constants/modules'

export const AdvantagesForCollegiate = () => {
    const [advantageList, setAdvantageList] = useState<Advantage[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const store = useStore();

    useEffect(() => {
        store.dispatch(setDetailNavigation(PROFILE_MODULE, 'advantages'));
        fetchAdvantageList();
    }, []);

    const fetchAdvantageList = async () => {
        setIsLoading(true);
        getAdvantages()
            .then((response) => {
                setIsLoading(false);
                setAdvantageList(response);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }

    function handleClick(e) {
        e.preventDefault();
        const el = e.target;
        if (el && el.tagName == 'A') {
            contentUtils.openExternalUrl(el.href);
        }
    }

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full font-medium text-xl text-white hover:text-white flex items-center" to="/profile">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="ml-2">Ventajas para colegiados</span>
                </Link>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-50 hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/profile">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#262626" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <span className="ml-2">Ventajas para colegiados</span>
                    </Link>
                    <div className="card-body py-1 px-2 md:px-4 advantagesForcedStyles">
                        {advantageList.map(advantage => (
                            <div className="border-b my-2">
                                <h2 className="text-teal-600 text-lg font-medium uppercase">{advantage.title}</h2>
                                <div onClick={handleClick} dangerouslySetInnerHTML={{ __html: advantage.content ? advantage.content : '' }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}