import { useState } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../store/index'
import { NavLink } from "react-router-dom";
import { ModalNoLogin } from '../components/miProfile/ModalNoLogin';
import contentUtils from '../lib/contentUtils';

const navigation = [
    { name: 'Inicio', href: '#', current: true },
    { name: 'Colegio', href: '#', current: false },
    { name: 'Recursos', href: '#', current: false },
    { name: 'Anuncios', href: '#', current: false },
    { name: 'Mi perfil', href: '#', current: false },
    { name: 'Contacto', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Navigation() {

    const currentNavigation = useSelector((state: RootState) => state.configNavigation);
    const session = useSelector((state: RootState) => state.session);

    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const urlWebsite = "https://colfisiocv.com";
    const urlTwiter = "https://twitter.com/Icofcv";
    const urlYoutube = "https://www.youtube.com/channel/UCYDxDmCQ_xfzk0ZnAkMt2WQ?view_as=subscriber";
    const urlInstagram = "https://www.instagram.com/icofcv/";
    const urlFacebook = "https://www.facebook.com/colegiofisioterapeutascv";
    const urlLinkedin = "https://es.linkedin.com/company/ilustrecolegiodefisioterapeutasdelacomunidadvalenciana";

    const openLinkWebsite = () => contentUtils.openExternalUrl(urlWebsite);
    const openLinkTwiter = () => contentUtils.openExternalUrl(urlTwiter);
    const openLinkYoutube = () => contentUtils.openExternalUrl(urlYoutube);
    const openLinkInstagram = () => contentUtils.openExternalUrl(urlInstagram);
    const openLinkFacebook = () => contentUtils.openExternalUrl(urlFacebook);
    const openLinkLinkedin = () => contentUtils.openExternalUrl(urlLinkedin);

    return (
        <div>
            <nav className="md:bg-white hidden md:block w-100 md:shadow fixed w-full z-20 top-0 left-0">
                <div className="mx-auto max-w-100 px-4 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">

                        <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
                            <div className="flex flex-shrink-0 items-center">
                                {/* Desktop logo */}
                                <NavLink to='/'>
                                    <img className="hidden h-9 w-auto md:block" src="../logo-icofcv.svg" alt="Ilustre Colegio Oficial de Fisioterapeutas Comunidad Valenciana" />
                                </NavLink>
                            </div>
                            <div className="hidden sm:ml-6 lg:block">
                                <div className="flex space-x-1 desktop-navigation">
                                    <ul className="flex bg-white md:flex-row md:space-x-1 md:mt-0 md:text-sm md:font-medium">
                                        <li>
                                            <NavLink to="/" className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap" aria-current="page">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="5 12 3 12 12 3 21 12 19 12" />
                                                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                                </svg>
                                                Inicio</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="college" className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="3" y1="21" x2="21" y2="21" />
                                                    <path d="M5 21v-14l8 -4v18" />
                                                    <path d="M19 21v-10l-6 -4" />
                                                    <line x1="9" y1="9" x2="9" y2="9.01" />
                                                    <line x1="9" y1="12" x2="9" y2="12.01" />
                                                    <line x1="9" y1="15" x2="9" y2="15.01" />
                                                    <line x1="9" y1="18" x2="9" y2="18.01" />
                                                </svg>
                                                Colegio</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="docs" className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                                    <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                                    <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                                                </svg>
                                                Recursos</NavLink>
                                        </li>
                                        {session.isLoggedIn ?
                                            <li>
                                                <NavLink to="profile" className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="7" r="4" />
                                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    </svg>
                                                    Mi perfil</NavLink>
                                            </li>
                                            :
                                            /* Item perfil no logeado */
                                            <li>
                                                <button onClick={openModal} className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap disabled">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="5" y="11" width="14" height="10" rx="2" />
                                                        <circle cx="12" cy="16" r="1" />
                                                        <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                                                    </svg>
                                                    Mi perfil
                                                </button>
                                            </li>
                                        }
                                        <li>
                                            <NavLink to="/contactform" className="flex items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium whitespace-nowrap">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-address-book mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" />
                                                    <path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                    <path d="M4 8h3" />
                                                    <path d="M4 12h3" />
                                                    <path d="M4 16h3" />
                                                </svg>
                                                Contacto</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div className="flex flex-column items-end justify-end px-3 py-0 border-r-2 border-teal-600 mr-3">
                                {session.isLoggedIn && session.collegiate ?
                                    <>
                                        <p className="text-xs font-medium text-teal-600 whitespace-nowrap"> {session.collegiate.firstname} {session.username} {session.collegiate.lastname} </p>
                                        <span className="text-xs font-light text-neutral-500">Nº Colegiado: {session.collegiate.memberId}</span>
                                    </>
                                    :
                                    <span className="text-xs font-medium text-teal-600">{session.user?.firstname} </span>
                                }
                            </div>

                            <ul className="items-center hidden md:flex">
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkWebsite}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icons-tabler-outline icon-tabler-world-www" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M19.5 7a9 9 0 0 0 -7.5 -4a8.991 8.991 0 0 0 -7.484 4" />
                                            <path d="M11.5 3a16.989 16.989 0 0 0 -1.826 4" />
                                            <path d="M12.5 3a16.989 16.989 0 0 1 1.828 4" />
                                            <path d="M19.5 17a9 9 0 0 1 -7.5 4a8.991 8.991 0 0 1 -7.484 -4" />
                                            <path d="M11.5 21a16.989 16.989 0 0 1 -1.826 -4" />
                                            <path d="M12.5 21a16.989 16.989 0 0 0 1.828 -4" />
                                            <path d="M2 10l1 4l1.5 -4l1.5 4l1 -4" />
                                            <path d="M17 10l1 4l1.5 -4l1.5 4l1 -4" />
                                            <path d="M9.5 10l1 4l1.5 -4l1.5 4l1 -4" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkTwiter}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-x" width="18" height="18" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                                            <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkYoutube}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-youtube" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <rect x="3" y="5" width="18" height="14" rx="4" />
                                            <path d="M10 9l5 3l-5 3z" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkInstagram}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <rect x="4" y="4" width="16" height="16" rx="4" />
                                            <circle cx="12" cy="12" r="3" />
                                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkFacebook}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                        </svg>
                                    </a>
                                </li>
                                <li className="mx-0.5 rounded-full bg-teal-600 h-7 w-7 flex items-center justify-center">
                                    <a onClick={openLinkLinkedin}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <rect x="4" y="4" width="16" height="16" rx="2" />
                                            <line x1="8" y1="11" x2="8" y2="16" />
                                            <line x1="8" y1="8" x2="8" y2="8.01" />
                                            <line x1="12" y1="16" x2="12" y2="11" />
                                            <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Navegación móvil */}
            {currentNavigation.showMainHeader === false ?
                <div className="fixed bottom-0 w-100 h-14 flex justify-around items-center d-none bg-white nav-phone pt-1">
                    <NavLink to="/" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium" aria-current="page">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home mx-2 h-5 w-5" viewBox="0 0 24 24" strokeWidth="2" stroke="#737373" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="5 12 3 12 12 3 21 12 19 12" />
                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                        </svg>
                        <span className="btm-nav-label">Inicio</span>
                    </NavLink>
                    <NavLink to="college" className="flex flex-column items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper mx-2 h-5 w-5" viewBox="0 0 24 24" strokeWidth="2" stroke="#737373" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="3" y1="21" x2="21" y2="21" />
                            <path d="M5 21v-14l8 -4v18" />
                            <path d="M19 21v-10l-6 -4" />
                            <line x1="9" y1="9" x2="9" y2="9.01" />
                            <line x1="9" y1="12" x2="9" y2="12.01" />
                            <line x1="9" y1="15" x2="9" y2="15.01" />
                            <line x1="9" y1="18" x2="9" y2="18.01" />
                        </svg>
                        <span className="btm-nav-label">Colegio</span>
                    </NavLink>
                    <NavLink to="docs" className="flex flex-column items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files mx-2 h-5 w-5" viewBox="0 0 24 24" strokeWidth="2" stroke="#737373" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                            <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                        </svg>
                        <span className="btm-nav-label">Recursos</span>

                    </NavLink>
                    <NavLink to="profile" className="flex flex-column items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user mx-2 h-5 w-5" viewBox="0 0 24 24" strokeWidth="2" stroke="#737373" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="7" r="4" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                        <span className="btm-nav-label">Mi perfil</span>
                    </NavLink>
                </div>
                :
                <div className="fixNotchPaddingBottom fixed bottom-0 w-100 flex justify-around items-center lg:hidden bg-white nav-phone pt-1">
                    {currentNavigation.moduleId !== "HOME_MODULE" ?
                        <NavLink to="/" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium" aria-current="page">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home mx-2  h-5 w-5 stroke-neutral-500 hover:stroke-teal-600" viewBox="0 0 24 24" stroke="#737373" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <polyline points="5 12 3 12 12 3 21 12 19 12" />
                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                            </svg>
                            Inicio</NavLink>
                        :
                        <NavLink to="/" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium" aria-current="page">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home mx-2  h-5 w-5 stroke-neutral-500 hover:stroke-teal-600" viewBox="0 0 24 24" stroke="#737373" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <polyline points="5 12 3 12 12 3 21 12 19 12" />
                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                            </svg>
                            Inicio</NavLink>
                    }

                    {currentNavigation.moduleId !== "COLLEGE_MODULE" ?

                        <NavLink to="college" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="3" y1="21" x2="21" y2="21" />
                                <path d="M5 21v-14l8 -4v18" />
                                <path d="M19 21v-10l-6 -4" />
                                <line x1="9" y1="9" x2="9" y2="9.01" />
                                <line x1="9" y1="12" x2="9" y2="12.01" />
                                <line x1="9" y1="15" x2="9" y2="15.01" />
                                <line x1="9" y1="18" x2="9" y2="18.01" />
                            </svg>
                            Colegio</NavLink>
                        :
                        <NavLink to="college" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="3" y1="21" x2="21" y2="21" />
                                <path d="M5 21v-14l8 -4v18" />
                                <path d="M19 21v-10l-6 -4" />
                                <line x1="9" y1="9" x2="9" y2="9.01" />
                                <line x1="9" y1="12" x2="9" y2="12.01" />
                                <line x1="9" y1="15" x2="9" y2="15.01" />
                                <line x1="9" y1="18" x2="9" y2="18.01" />
                            </svg>
                            Colegio</NavLink>
                    }

                    {currentNavigation.moduleId !== "DOC_MODULE" ?
                        <NavLink to="docs" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                            </svg>
                            Recursos</NavLink>
                        :
                        <NavLink to="docs" className="flex flex-column items-center text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                            </svg>
                            Recursos</NavLink>
                    }
                    {session.isLoggedIn ?
                        <NavLink to="profile" className="flex items-center flex-column text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                            Mi perfil</NavLink>
                        :
                        /* Item perfil no logeado */
                        <button onClick={openModal} className="flex items-center  flex-column text-neutral-500 hover:text-teal-600 active:text-teal-600 px-3 py-2 text-sm font-medium disabled">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock mx-2 stroke-neutral-500 hover:stroke-teal-600" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <rect x="5" y="11" width="14" height="10" rx="2" />
                                <circle cx="12" cy="16" r="1" />
                                <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                            </svg>
                            Mi perfil
                        </button>
                    }
                </div>
            }
            <ModalNoLogin showModal={showModal} closeModal={closeModal} />
        </div>
    )
}
