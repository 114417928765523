import React from "react";
import './Loader.css';

function Loader() {
  return (
    <>
     <div className="lds-ring">
      <div></div>
    </div>
    </>
  );
}

export default Loader;