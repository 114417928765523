import {News} from '@icofcv/common';
import {Magazine} from '@icofcv/common';
import {Clinic} from '@icofcv/common';
import {Collegiate} from '@icofcv/common';
import {Guide} from '@icofcv/common';
import {Document} from '@icofcv/common';
import {Advertisment} from '@icofcv/common';
import {Employment, EmploymentContract} from '@icofcv/common';
import {PublicEmployment} from '@icofcv/common';
import {Country} from '@icofcv/common';
import {Province} from '@icofcv/common';
import {ContactGroup} from '@icofcv/common';
import {ContactGroupOption} from '@icofcv/common';
import {createCollegiateWhereFilter} from '../components/colleagues/CollegiateWhereFilter';
import {createClinicWhereFilter} from '../components/clinics/ClinicWhereFilter';
import {createEmploymentWhereFilter} from '../components/employment/EmploymentWhereFilter'
import {createPublicEmploymentWhereFilter} from '../components/employment/PublicEmploymentWhereFilter'
import {createAdvertismenWhereFilter} from '../components/advertisments/AdvertismentWhereFilter';
import {GetUsersResponse} from '@icofcv/common';
import {Course, CourseInscription} from '@icofcv/common';
import store from '../store';
import { createCoursesWhereFilter } from '../components/formation/CourseWhereFilter';

const MAX_ITEMS_PER_PAGE = 10;
const MAX_ITEMS_PER_PAGEA = 40;


export function getItemsPerPage() {
    return MAX_ITEMS_PER_PAGE;
}

function getHeaders() {
    let headers = {
        'content-type': 'application/json;charset=UTF-8'
    };

    if (store.getState().session.credentials) {
        headers['Authorization'] = `Bearer ${store.getState().session.credentials.idToken}`;
    }

    return headers;
}

export async function getNews(): Promise<News[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500 ,
        "skip": 0,
        "order": ['id DESC'],
        "fields": {
        "id": true,
        "title": true,
        "creationDate": true,
        "enabled": true,
        "picture": true,
        "sticky": true,
        "promote": true,
        "created": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getNewsPaginated(newsList:any): Promise<News[]>  { 
    const offset = newsList.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    const filter = {
        skip: offset,
        limit: limit,
        "order": ['id DESC'],
        "fields": {
        "id": true,
        "title": true,
        "creationDate": true,
        "enabled": true,
        "picture": true,
        "sticky": true,
        "promote": true,
        "created": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getNewsById(id): Promise<News>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "title": true,
            "creationDate": true,
            "enabled": true,
            "content": true,
            "videoUrl": true,            
            "picture": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getYoutube(): Promise<News>  { 
    const filter = {
        "offset": 0,
        "limit": 500 ,
        "skip": 0,
        "order": ['creationDate DESC'],
        "fields": {
        "id": true,
        "title": true,
        "creationDate": true,
        "enabled": true,
        "picture": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getYoutubePaginated(youtubeList:any): Promise<News>  { 
    const offset = youtubeList.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    const filter = {
         skip: offset,
        limit: limit,
        "order": ['creationDate DESC'],
        "fields": {
        "id": true,
        "title": true,
        "creationDate": true,
        "enabled": true,
        "picture": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getYoutubeById(id): Promise<News>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "title": true,
            "creationDate": true,
            "enabled": true,
            "content": true,
            "picture": true
        }
    };

    const response = await fetch('/news?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}


export async function getClinic(): Promise<Clinic[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        "fields": {
            "id": true,
            "title": true,
            "creationDate": true,
            "logo": true,
            "logoWidth": true,
            "logoHeight": true,
            "officialName": true,
            "type": true,
            "registry": true,
            "phone": true,
            "mobile": true,
            "fax": true,
            "email": true,
            "physiotherapyFields": true,
            "others": true,
            "propsWeb": true,
            "propsPhone": true,
            "propsMisc": true,
            "featureFilterList": true,
            "address": true,
            "town": true,
            "postalCode": true,
            "province": true,
            "country": true,
            "latitude": true,
            "longitude": true,
            "enabled": true
        }
    };

    const response = await fetch('/clinics?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getClinicPaginated(clinicFilter:any): Promise<Clinic[]>  { 
    var whereFilter = createClinicWhereFilter(clinicFilter);

    const offset = clinicFilter.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    const filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        fields: {
            "id": true,
            "title": true,
            "creationDate": true,
            "logo": true,
            "logoWidth": true,
            "logoHeight": true,
            "officialName": true,
            "type": true,
            "registry": true,
            "phone": true,
            "mobile": true,
            "fax": true,
            "email": true,
            "physiotherapyFields": true,
            "others": true,
            "propsWeb": true,
            "propsPhone": true,
            "propsMisc": true,
            "featureFilterList": true,
            "address": true,
            "town": true,
            "postalCode": true,
            "province": true,
            "country": true,
            "latitude": true,
            "longitude": true,
            "enabled": true
        }
    };

    const response = await fetch('/clinics?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getClinicById(id): Promise<Clinic>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "title": true,
            "creationDate": true,
            "logo": true,
            "logoWidth": true,
            "logoHeight": true,
            "officialName": true,
            "type": true,
            "registry": true,
            "phone": true,
            "mobile": true,
            "fax": true,
            "email": true,
            "physiotherapyFields": true,
            "others": true,
            "propsWeb": true,
            "propsPhone": true,
            "propsMisc": true,
            "featureFilterList": true,
            "address": true,
            "town": true,
            "postalCode": true,
            "province": true,
            "country": true,
            "latitude": true,
            "longitude": true,
            "enabled": true
        }
    };

    const response = await fetch('/clinics?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getMagazine(): Promise<Magazine[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        "order": ['creationDate DESC'],
        "where": {
            "additionalProp1": {}
        },
        "fields": {
            "id": true,
            "title": true,
            "content": true,
            "creationDate": true,
            "link": true,
            "picture": true
        }
    };

    const response = await fetch('/magazines?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getMagazinePaginated(magazineList:any): Promise<Magazine[]>  { 
    const offset = magazineList.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    const filter = {
        skip: offset,
        limit: limit,
        "order": ['creationDate DESC'],
        "where": {
            "additionalProp1": {}
        },
        "fields": {
            "id": true,
            "title": true,
            "content": true,
            "creationDate": true,
            "link": true,
            "picture": true
        }
    };

    const response = await fetch('/magazines?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}


export async function getCollegiatePaginated(collegiateFilter:any): Promise<Collegiate[]>  {
    var whereFilter = createCollegiateWhereFilter(collegiateFilter);

    const offset = collegiateFilter.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    var filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        order: ['collegiate asc'],
        fields: {
            "id": true,
            "mail": true,
            "collegiate": true,
            "color": true,
            "firstname": true,
            "lastname": true,
            "provincia": true,
            "creationDate": true,
            "enabled": true
        }
    };

    const response = await fetch('/collegiate?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getGuide(): Promise<Guide[]>  { 
    const filter = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "order": ['publishDate DESC'],
        "fields": {
            "id": true,
            "creationDate": true,
            "creator": true,
            "title": true,
            "description": true,
            "publishDate": true,
            "documentPath": true,
            "imagePath": true,
            "enabled": true
        }
    };

    const response = await fetch('/guides?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getGuideById(id): Promise<Guide>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "creationDate": true,
            "creator": true,
            "title": true,
            "description": true,
            "publishDate": true,
            "documentPath": true,
            "imagePath": true,
            "enabled": true
        }
    };

    const response = await fetch('/guides?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getGuidePaginated(guideList:any): Promise<Guide[]>  { 
    const offset = guideList.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    const filter = {
         skip: offset,
        limit: limit,
        "order": ['publishDate DESC'],
        "fields": {
            "id": true,
            "creationDate": true,
            "creator": true,
            "title": true,
            "description": true,
            "publishDate": true,
            "documentPath": true,
            "imagePath": true,
            "enabled": true
        }
    };

    const response = await fetch('/guides?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getDocument(): Promise<Document[]>  { 
    const filter = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        "fields": {
          "id": true,
          "name": true,
          "type": true,
          "creationDate": true,
          "locale": true,
          "contentType": true,
          "contentUrl": true,
          "enabled": true
      }
    };

    const response = await fetch('/document?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getAdvertisments(): Promise<Advertisment[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        "order": 'creationDate desc',
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "description": true,
            "imagePath": true,
            "imageName": true,
            "contactFirstname": true,
            "contactLastname": true,
            "contactPhone": true,
            "contactEmail": true,
            "categoryId": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "status": true,
            "province": true
        }
    };

    const response = await fetch('/advertisments?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getAdvertismentsPaginated(advertismentFilter:any): Promise<Advertisment[]>  { 
    var whereFilter = createAdvertismenWhereFilter(advertismentFilter);
    

    const offset = advertismentFilter.page * MAX_ITEMS_PER_PAGEA;
    const limit = MAX_ITEMS_PER_PAGEA + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    var filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        order: ['creationDate desc'],
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "description": true,
            "imagePath": true,
            "imageName": true,
            "contactFirstname": true,
            "contactLastname": true,
            "contactPhone": true,
            "contactEmail": true,
            "categoryId": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "status": true,
            "province": true
        }
    };

    const response = await fetch('/advertisments?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getAdvertismentsModalPaginated(advertismentList:any): Promise<Advertisment[]>  { 
    const offset = advertismentList.page * MAX_ITEMS_PER_PAGEA;
    const limit = MAX_ITEMS_PER_PAGEA + 1;

    const filter = {
         skip: offset,
        limit: limit,
        order: ['creationDate desc'],
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "description": true,
            "imagePath": true,
            "imageName": true,
            "contactFirstname": true,
            "contactLastname": true,
            "contactPhone": true,
            "contactEmail": true,
            "categoryId": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "status": true,
            "province": true
        }
    };

    const response = await fetch('/advertisments?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}



export async function getAdvertismentById(id): Promise<Advertisment>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "description": true,
            "imagePath": true,
            "imageName": true,
            "contactFirstname": true,
            "contactLastname": true,
            "contactPhone": true,
            "contactEmail": true,
            "categoryId": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "status": true,
            "province": true
        }
    };

    const response = await fetch('/advertisments?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getUsers(): Promise<GetUsersResponse> { 
    const filter = {
                "offset": 0,
                "limit": 500,
                "skip": 0,
                "fields": {
                'id': true,
                'username': true,
                'email': true,
                'firstname': true,
                'lastname': true,
                'creationDate': true,
                'memberId': true,
                'fiscalId': true,
                'landline': true,
                'mobile': true,
                'didLeave': true,
                'enabled': true
                }
            };
  
    const response = await fetch('/user?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
  });

  const data = await response.json();

  if (response.ok) {
      return data;
  } else {
      return Promise.reject(response.status);
  }
}

export async function getEmployments(): Promise<Employment[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        order: ['creationDate desc'],
        "fields": {
            "id": true,
            "creationDate": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "status": true,
            "comments": true,
            "type": true,
            "code": true,
            "title": true,
            "description": true,
            "notes": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "companyName": true,
            "regionalRegistrationNumber": true,
            "countryId": true,
            "provinceId": true,
            "provinceName": true,
            "city": true,
            "address": true,
            "postalCode": true,
            "mobile": true,
            "phone": true,
            "email": true,
            "web": true,
            "contractId": true,
            "incorporation": true,
            "schedule": true,
            "scope": true,
            "provinceType": true,
            "experience": true,
            "experienceDescription": true
        
        }
    };

    const response = await fetch('/employments?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getGroup(): Promise<ContactGroup[]>  { 
    const filter = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        order: ['name asc'],
        "fields": {
            "id": true,
            "name": true,
         
        }
    };

    const response = await fetch('/contact-groups?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getGroupOptions(): Promise<ContactGroupOption[]>  { 
    const filter = {
        "offset": 0,
        "limit": 100,
        "skip": 0,
        order: ['name asc'],
        "fields": {
            "id": true,
            "contactGroupId": true,
            "private": true,
            "name": true,
            "description": true
         
        }
    };

    const response = await fetch('/contact-group-options?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getEmploymentsPaginated(employmentFilter:any): Promise<Employment[]>  { 
    const whereFilter = createEmploymentWhereFilter(employmentFilter);
    const offset = employmentFilter.page * MAX_ITEMS_PER_PAGEA;
    const limit = MAX_ITEMS_PER_PAGEA + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    const filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        order: ['creationDate desc'],
        "fields": {
            "id": true,
            "creationDate": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "status": true,
            "comments": true,
            "type": true,
            "code": true,
            "title": true,
            "description": true,
            "notes": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "companyName": true,
            "regionalRegistrationNumber": true,
            "countryId": true,
            "provinceId": true,
            "provinceName": true,
            "city": true,
            "address": true,
            "postalCode": true,
            "mobile": true,
            "phone": true,
            "email": true,
            "web": true,
            "contractId": true,
            "incorporation": true,
            "schedule": true,
            "scope": true,
            "provinceType": true,
            "experience": true,
            "experienceDescription": true
        }
    };

    const response = await fetch('/employments?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCountry(): Promise<Country[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        order: ['name asc'],
        "fields": {
            "id": true,
            "name": true
        
        }      
        
    };

    const response = await fetch('/countries?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getEmploymentById(id): Promise<Employment>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "creationDate": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "status": true,
            "comments": true,
            "type": true,
            "code": true,
            "title": true,
            "description": true,
            "notes": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "companyName": true,
            "regionalRegistrationNumber": true,
            "countryId": true,
            "provinceId": true,
            "provinceName": true,
            "city": true,
            "address": true,
            "postalCode": true,
            "mobile": true,
            "phone": true,
            "email": true,
            "web": true,
            "contractId": true,
            "incorporation": true,
            "schedule": true,
            "scope": true,
            "provinceType": true,
            "experience": true,
            "experienceDescription": true
        }
    };

    const response = await fetch('/employments?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getPublicEmploymentsPaginated(publicEmploymentList:any): Promise<PublicEmployment[]>  { 
    const whereFilter = createPublicEmploymentWhereFilter(publicEmploymentList);
    const offset = publicEmploymentList.pagePublic * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    const filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        order: ['creationDate desc'],
        "fields": {
            "id": true,
            "creationDate": true,
            "creatorUsername": true,
            "title": true,
            "description": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "year": true,
            "conveningBody": true,
            "link": true,
            "visible": true
        }
    };

    const response = await fetch('/public-employments?filter='+encodeURIComponent(JSON.stringify(filter)), {
      method: "GET",
      headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getPublicEmploymentById(id): Promise<PublicEmployment>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "creationDate": true,
            "creatorUsername": true,
            "title": true,
            "description": true,
            "publishStartDate": true,
            "publishEndDate": true,
            "year": true,
            "conveningBody": true,
            "link": true
        }
    };

    const response = await fetch('/public-employments?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function getEmploymentContract(): Promise<EmploymentContract[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        order: ['name asc'],
        "fields": {
            "id": true,
            "name": true
        
        }      
        
    };

    const response = await fetch('/employment-contracts?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCoursesPaginated(courseFilter:any): Promise<Course[]>  { 
    const whereFilter = createCoursesWhereFilter(courseFilter);
    const offset = courseFilter.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

    console.log(`*** skip: '${offset}'`);
    console.log(`*** limit: '${limit}'`);

    const filter = {
        skip: offset,
        limit: limit,
        where: whereFilter,
        order: 'id desc',
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "comments": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "status": true,
            "documentPath": true,
            "documentName": true,
            "imagePath": true,
            "imageName": true,
            "price": true,
            "places": true,
            "soldOutPlaces": true,
            "durationDates": true,
            "duration": true,
            "description": true,
            "inscriptionStartDate": true,
            "inscriptionEndDate": true,
            "visibilityStartDate": true,
            "visibilityEndDate": true,
            "typeId": true,
            "modality": true,
            "location": true,
            "preference": true,
            "organizer": true,
            "internalManagement": true,
            "paymentEnabled": true,
            "enabled": true,
            "link": true
          }
    };

    const response = await fetch('/courses?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCoursesById(id): Promise<Course>  { 
    const filter = {
        "where": {
            "id": id
        },
        "fields": {
            "id": true,
            "creationDate": true,
            "title": true,
            "comments": true,
            "creatorUsername": true,
            "lastStatusUsername": true,
            "lastStatusDate": true,
            "status": true,
            "documentPath": true,
            "documentName": true,
            "imagePath": true,
            "imageName": true,
            "price": true,
            "places": true,
            "soldOutPlaces": true,
            "durationDates": true,
            "duration": true,
            "description": true,
            "inscriptionStartDate": true,
            "inscriptionEndDate": true,
            "visibilityStartDate": true,
            "visibilityEndDate": true,
            "typeId": true,
            "modality": true,
            "location": true,
            "preference": true,
            "organizer": true,
            "internalManagement": true,
            "paymentEnabled": true,
            "enabled": true,
            "link": true
        }
    };

    const response = await fetch('/courses?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data[0];
    } else {
        return Promise.reject(response.status);
    }
}

export async function courseInscriptionsPush(info): Promise<CourseInscription> { 
    console.log(getHeaders())
    const response = await fetch('/course-inscriptions', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function courseInscriptionsNoLoginPush(info): Promise<CourseInscription> { 
    console.log(getHeaders())
    const response = await fetch('/no-login-course-inscriptions', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}







