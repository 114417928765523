import { useEffect } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../store/index'
import { NavLink } from "react-router-dom";
import { MagazineCarousel } from '../components/magazine/MagazineCarousel'
import { setTopLevelNavigation } from '../actions/configNavigation'
import { useStore } from 'react-redux'
import { DOC_MODULE } from '../constants/modules'
import { GuideCarousel } from '../components/documents/GuideCarousel';
import { AccessKnowledgePlatform } from '../components/knowledge-platform/AccessKnowledgePlatform';
import { AccessKnowledgePlatformLogin } from '../components/knowledge-platform/AccessKnowledgePlatformLogin';
import { AccessVirtualField } from '../components/virtualField/AccessVirtualField';
import { AccessVirtualFieldLogin } from '../components/virtualField/AccessVirtualFieldLogin';
import { SocialNetworks } from '../components/social-networks/SocialNetworks';
import { AccessProetField } from '../components/proet/AccessProetField';
import { AccessProetFieldLogin } from '../components/proet/AccessProetFieldLogin';
import { AccessPhysiocienciaField } from '../components/physiociencia/AccessPhysiocienciaField';
import { AccessPhysiocienciaFieldLogin } from '../components/physiociencia/AccessPhysiocienciaFieldLogin';

export function Documentation() {

    const store = useStore();
    const session = useSelector((state: RootState) => state.session);

    useEffect(() => {
        store.dispatch(setTopLevelNavigation(DOC_MODULE));
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-center header-top">
                <h1 className="d-md-none font-medium text-white text-xl text-center">Recursos digitales</h1>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-16">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <h1 className="pb-3 hidden md:flex font-medium text-lg flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files mx-2 h-6 w-6 stroke-neutral-800" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                            <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                        </svg>
                        <span>Recursos digitales</span>
                    </h1>

                    {/* Modulo botones accion */}
                    {!session.isLoggedIn ?
                        <>
                            <div className="py-2">
                                <div className="block md:flex items-center w-100">
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
                                <AccessVirtualField />
                                <AccessKnowledgePlatform />
                                <AccessProetField />
                                <AccessPhysiocienciaField />
                            </div>
                        </>
                        :
                        <>
                            <div className="py-2">
                                <div className="block md:flex items-center w-100">
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
                                <AccessVirtualFieldLogin />
                                <AccessKnowledgePlatformLogin />
                                <AccessProetFieldLogin />
                                <AccessPhysiocienciaFieldLogin />
                            </div>
                        </>
                    }
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <MagazineCarousel />

                        {/* GuideCarousel */}
                        <GuideCarousel />
                    </div>
                </div>
                {/* Redes Sociales */}
                <SocialNetworks />
                {/* Avisos legales */}
                <div className="flex items-center justify-center flex-wrap pb-16 md:pb-0">
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Politica-privacidad.pdf" target="_blank">Política de privacidad</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Aviso-Legal.pdf" target="_blank">Aviso legal</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Normativa-cookies.pdf" target="_blank">Política de cookies</a>
                    <span className="text-teal-600">|</span>
                    <NavLink to="/contactform" className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline">Formulario de contacto</NavLink>
                </div>
            </div>
        </>
    )
}