import { useEffect } from 'react';
import { useSelector } from "react-redux";
import type { RootState } from '../../store/index'
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import { setItem } from '../../lib/localStorage';
import { HOME_MODULE } from '../../constants/modules'
import { useStore } from 'react-redux'
import { setDetailNavigation } from '../../actions/configNavigation' 


export const YoutubeDetail = () => {

    const store = useStore();
    const currentYoutube = useSelector((state: RootState) => state.selectedYoutube);

    useEffect(() => {
        setItem('youtube', currentYoutube)
        store.dispatch(setDetailNavigation(HOME_MODULE, 'Actualidad!'));
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-start header-top">
                <Link className="w-full pb-3 font-medium text-lg text-white hover:text-white flex items-center" to="/youtubelist">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-white hover:stroke-white h-6 w-1/3 ml-0" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span className="news-detail-title">{currentYoutube?.title}</span>
                </Link>
            </div>
            <div key={currentYoutube?.id} className="columns-1 mx-auto px-2 main-container pb-12">
                <div className="card bg-white px-2 py-3 md:p-6 my-3">
                    <Link className="w-full hidden md:flex pb-3 font-medium text-neutral-800 hover:text-teal-600 flex items-center" to="/youtubelist">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left stroke-neutral-800 hover:stroke-teal-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <h1 className="font-medium text-neutral-800 text-xl text-center ml-2 news-detail-title">{currentYoutube?.title}</h1>
                    </Link>
                    <div className="card-body px-2">
                        <div className="" >
                            <ReactPlayer className="mb-2 mx-auto max-w-xs md:max-w-xl h-52 md:h-96 visorContainer" url={`https://www.youtube.com/watch?v=${currentYoutube?.vId}`}
                               controls
                            />
                            <div className="text-lg text-neutral-800 font-medium my-2">{currentYoutube?.title}</div>
                            <div className="text-neutral-600 font-sans news-text-description text-sm" dangerouslySetInnerHTML={{ __html: currentYoutube?.description }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}