import { requestDocument } from '../../api/icofcvAPI'
import { toast } from 'react-toastify';

export const CertificateFees = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    console.log('Current month: ' + currentMonth);
    let year = -1;

    function sendQuotaCertificateByEmail() {
        if (year == -1) {
            return;
        }

        let data = {
            documentType: 'CERTIFICADO_CUOTAS',
            documentYear: year
        };

        requestDocument(data)
            .then((response) => {
                toast.success('Solicitud enviada');
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    const handleYearChange = (e) => {
        e.preventDefault()
        year = parseInt(e.target.value)
    }

    return (
        <>
            <div className="card bg-white px-2 py-3 md:p-6">
                <h1 className="pb-3 font-medium">Certificado cuotas</h1>
                <div className="card-body py-2 md:px-4 mx-auto w-100">
                    <div className="flex items-center justify-between flex-col">
                        <div className="relative z-0 w-full group flex items-center">
                            <label className="block text-sm font-medium text-gray-900">Año*</label>
                            <select id="" onChange={(e) => { handleYearChange(e) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2 mx-4">
                                <option selected>Selecciona año</option>
                                {currentMonth >= 7 ?
                                    <option value={currentYear}>{currentYear}</option>
                                    :
                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                }
                            </select>
                        </div>
                        <button className="bg-teal-600 hover:bg-teal-700 text-white px-8 py-2 my-3 rounded" type="button" onClick={() => sendQuotaCertificateByEmail()} >Solicitar</button>
                    </div>
                </div>
            </div>
        </>
    )
}