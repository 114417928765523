import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import './YoutubeCarousel.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/navigation/navigation.min.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Loader from '../spinner/Loader';
import { selectYoutube } from '../../actions/detailYoutube';
import config from '../../config/app-config.json'

export function YoutubeCarousel() {

    const [allVideos, setAllVideos] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState (false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true)
        fetch(`${config.urlYoutube}&playlistId=${config.playlistYoutubeKey}&order=date&key=${config.youtubeKey}` )
        .then(res=>res.json())
        .then(data => {
            console.log(data)
            console.log(data.nextPageToken)
            setIsLoading(false)
            const result = data.items.map(item=> {
                return {title:item.snippet.title, vId:item.contentDetails.videoId, id:item.id, description:item.snippet.description, imgVideo:item.snippet.thumbnails.high.url}
            })
            setAllVideos(result)
        });
    }, []);
    

    function goToPageNewsList() {
        navigate('youtubelist');
    }

    function handleAddToDetail(item) {
        dispatch(selectYoutube(item));
        navigate('youtubelist/youtubedetail');     
}

  return (
      <>
        <div className="card bg-white px-2 py-2 md:p-6">
            <h1 className="pb-2 ml-1 font-medium text-lg flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news mx-2 h-6 w-6 stroke-neutral-800 md:hidden" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                    <line x1="8" y1="8" x2="12" y2="8" />
                    <line x1="8" y1="12" x2="12" y2="12" />
                    <line x1="8" y1="16" x2="12" y2="16" />
                </svg>
                <span>Video consejos</span>
            </h1>
            <div className="card-body py-1 px-0 overflow-hidden">
                <div id="default-carousel" className="relative" data-carousel="static">
                    {/* Carousel wrapper */}
                    <Swiper
                        centeredSlides={false}
                        breakpoints={{
                            375: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            1536: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                            },
                            1920: {
                                slidesPerView: 6,
                                spaceBetween: 10,
                            },
                          }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper relative h-80 overflow-hidden rounded-lg flex flex-row"
                    >
                         { isLoading && <Loader />}
                         { allVideos.slice(0 , 8).map(item =>(
                            <SwiperSlide key={item.id} className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10 w-1/3 max-w-sm md:px-2 flex flex-shrink-0 relative flex-col items-center">
                                <div className="card w-full md:w-100 h-72 bg-white md:border-1 carousel-card p-2 text-center">
                                    <div className="card-body p-1 mx-auto">
                                        <button onClick={() => handleAddToDetail(item)}  className="hover:text-neutral-500">
                                            {/* <h3 className="font-medium text-teal-600 text-center text-sm md:my-2">{guide.title }</h3> */}
                                            
                                            <img className="h-56" src={item.imgVideo} alt=''></img>
                                        </button>
                                        <h3 className="font-medium doc-title">{item.title}</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                            ))
                        }
                    </Swiper>     
                </div>
            </div>
            <button  onClick={() => goToPageNewsList()} type="button" className="see-more-link px-2 py-3 flex justify-end text-teal-700 hover:text-neutral-500 font-medium" >VER MÁS</button>
        </div>
     </>
  );
}