import {Partners, UserInfo} from '@icofcv/common';
import {DocumentRequest} from '@icofcv/common';
import {RegisterRequest, GenericResponse, Device, LoginRequest, LoginResponse} from '@icofcv/common';
import {ClinicCollectionData} from '@icofcv/common';
import {ContactRequest} from '@icofcv/common';
import {Advantage} from '@icofcv/common';
import {Advertisment} from '@icofcv/common';
import {Config} from '@icofcv/common';
import {Province} from '@icofcv/common';
import {Employment} from '@icofcv/common';
import {Course, CourseInscription, College, Agreement, CollegesByAgreement} from '@icofcv/common';
import store from '../store';
import {createCoursesInscriptionWhereFilter} from '../components/formation/CourseInscriptionWhereFilter'
import {createCollegesByAgreementWhereFilter} from '../components/formation/CollegesByAgreementWhereFilter'
import {createCollegeWhereFilter} from '../components/formation/CollegeWhereFilter'
// import {createConfigWhereFilter} from '../components/instagram/ConfigWhereFilter'

function getHeaders() {
    let headers = {
        'content-type': 'application/json;charset=UTF-8'
    };

    if (store.getState().session.credentials) {
        headers['Authorization'] = `Bearer ${store.getState().session.credentials.idToken}`;
    }

    return headers;
}

const MAX_ITEMS_PER_PAGE = 10;

export function getItemsPerPage() {
    return MAX_ITEMS_PER_PAGE;
}

export async function getSessionUser(idToken?): Promise<UserInfo> { 
    let headers = getHeaders();
    if (idToken) {
        headers['Authorization'] = `Bearer ${idToken}`;
    }
    
    const response = await fetch('/users/me', {
        method: "GET",
        headers: headers
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function registerRequest(info): Promise<RegisterRequest> { 
    const response = await fetch('/user/register', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}


export async function loginRequest(info): Promise<LoginResponse> { 
    const response = await fetch('/user/login', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}



export async function resetPasswordRequest(info): Promise<GenericResponse> { 
    const response = await fetch('/user/reset-password', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}


export async function updatePasswordRequest(info): Promise<LoginResponse> { 
    const response = await fetch('/user/update-password', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}



export async function updateDevice(device): Promise<Device> { 
    const response = await fetch('/device', {
        method: "POST",
        body: JSON.stringify(device),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}


export async function createAccountRequest(accountRequest): Promise<GenericResponse> { 
    const response = await fetch('/user/account', {
        method: "POST",
        body: JSON.stringify(accountRequest),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getClinicCollections(): Promise<ClinicCollectionData> { 
    const response = await fetch('/clinics/collections', {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function requestDocument(info): Promise<DocumentRequest> { 
    const response = await fetch('/document/request', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function contactRequest(info): Promise<ContactRequest> { 
    const response = await fetch('/user/contact-request', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getAdvantages(): Promise<Advantage[]> {
    const filter = {
        order: ['title asc'],
        "where": {
           "enabled": true
         }
    };

    const response = await fetch('/advantages?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function advertismentPush(info): Promise<Advertisment> { 
    console.log(getHeaders())
    const response = await fetch('/advertisments', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function commentAdvertismentPush(comment): Promise<void> { 
    console.log(getHeaders())
    const response = await fetch('/comment-advertisment', {
        method: "POST",
        body: JSON.stringify(comment),
        headers: getHeaders()
    });

}

export async function employmentPush(info): Promise<Employment> { 
    console.log(getHeaders())
    const response = await fetch('/employments', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function noCollegiateEmploymentPush(info): Promise<Employment> { 
    const response = await fetch('/no-collegiate/employments', {
        method: "POST",
        body: JSON.stringify(info),
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function commentEmploymentPush(comment): Promise<void> {
	console.log(getHeaders())
	const response = await fetch('/comment-employment', {
		method: "POST",
		body: JSON.stringify(comment),
		headers: getHeaders()
	});

}

export async function getProvince(): Promise<Province[]>  { 
    const filter = {
        "offset": 0,
        "limit": 500,
        "skip": 0,
        "fields": {
            "id": true,
            "name": true
        
        }
    };

    const response = await fetch('/provinces?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCourseById(id): Promise<Course[]> {
	const response = await fetch(`/courses/${id}`, {
		method: "GET",
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}

export async function getCourseInscriptionById(id): Promise<CourseInscription> {
	const response = await fetch(`/course-inscriptions/${id}`, {
		method: "GET",
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}

export async function getCourseInscription(courseInscriptionFilter:any): Promise<CourseInscription>  { 
    const whereFilter = createCoursesInscriptionWhereFilter(courseInscriptionFilter);
    const filter = {
        
        where: whereFilter
       
    };

    const response = await fetch('/course-inscriptions/?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getCourseInscriptionNoLogin(courseInscriptionFilter:any): Promise<CourseInscription>  { 
    const whereFilter = createCoursesInscriptionWhereFilter(courseInscriptionFilter);
    const filter = {
        
        where: whereFilter
       
    };

    const response = await fetch('/course-inscriptions-no-login/?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function updateCourseInscription(id, info): Promise<CourseInscription | string> {
	const response = await fetch(`/course-inscriptions/${id}`, {
		method: "PUT",
        body: JSON.stringify(info),
		headers: getHeaders()
	});

	const data = await response.text();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}

export async function noLoginUpdateCourseInscription(id, info): Promise<CourseInscription | string> {
	const response = await fetch(`/no-login-course-inscriptions/${id}`, {
		method: "PUT",
        body: JSON.stringify(info),
		headers: getHeaders()
	});

	const data = await response.text();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}



export async function createCheckoutSession(courseInscriptionId, url): Promise<any> {
	const response = await fetch(`/create-checkout-session/${courseInscriptionId}`, {
		method: "POST",
		body: JSON.stringify(url),
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}

export async function getCheckoutSessionById(sessionId): Promise<any> {
	const response = await fetch(`/get-checkout-session/${sessionId}`, {
		method: "GET",
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}

export async function getCollege(): Promise<College[]> {
    const filter = {
        "offset": 0,
        "limit": 300,
        "skip": 0,
        order: ['name asc'],
  
    };

    const response = await fetch('/colleges?filter=' + encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getPartnerDetailsByToken(token): Promise<Partners> {
    const filter = {
        "where": {
           "token": token
         }
    };
    const response = await fetch('/partners-token?filter='+encodeURIComponent(JSON.stringify(filter)), {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}

export async function getAgreements(): Promise<Agreement[]> {
	const response = await fetch(`/agreements/`, {
		method: "GET",
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}


export async function getCollegesByAgreementById(collegesByAgreementFilter): Promise<CollegesByAgreement[]> {

    const whereFilter = createCollegesByAgreementWhereFilter(collegesByAgreementFilter);
    const filter = {
        
        where: whereFilter
       
    };

    const response = await fetch('/colleges-by-agreements?filter='+encodeURIComponent(JSON.stringify(filter)), {
		method: "GET",
		headers: getHeaders()
	});

	const data = await response.json();

	if (response.ok) {
		return data;
	} else {
		return Promise.reject(response.status);
	}
}


export async function getPostsPaginated(postList:any): Promise<any>  { 
    const offset = postList.page * MAX_ITEMS_PER_PAGE;
    const limit = MAX_ITEMS_PER_PAGE + 1;

	const response = await fetch(`/instagram-posts/${offset}/${limit}`, {
        method: "GET",
        headers: getHeaders()
    });

    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return Promise.reject(response.status);
    }
}
