import { useEffect } from 'react'
import { useSelector } from "react-redux";
import type { RootState } from '../store/index'
import { NavLink } from "react-router-dom";
import { AccessAdvantagesForCollegiate } from '../components/miProfile/AccessAdvantagesForCollegiate'
import { LogoutCollegiate } from '../components/miProfile/LogoutCollegiate'
import { SocialNetworks } from '../components/social-networks/SocialNetworks';
import { ProfileImage } from '../components/miProfile/ProfileImage'
import { useStore } from 'react-redux'
import { PROFILE_MODULE } from '../constants/modules'
import { setTopLevelNavigation } from '../actions/configNavigation'
import { AccessReportsAndDocumentsLogin } from '../components/reportsAndDocuments/AccessReportsAndDocumentsLogin';


export function MiProfile() {

    const store = useStore();
    const session = useSelector((state: RootState) => state.session);

    useEffect(() => {
        store.dispatch(setTopLevelNavigation(PROFILE_MODULE));
    }, []);

    return (
        <>
            <div className="fixNotchMarginTop bg-teal-600 fixed block md:hidden w-full z-20 left-0 top-0 pt-2 pb-2 flex items-center justify-center header-top">
                <h1 className="d-md-none font-medium text-white text-xl text-center">Mi perfil</h1>
            </div>
            <div className="columns-1 mx-auto px-2 main-container pb-16">
                <div className="card bg-white px-2 py-3 md:p-6 my-3 content-card">
                    <h1 className="pb-3 hidden md:flex font-medium text-lg flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user mx-2 h-6 w-6 stroke-neutral-800" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="7" r="4" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                        <span>Mi perfil</span>
                    </h1>
                    {session.isLoggedIn && session.collegiate ?
                        <div className="grid grid-cols-1 gap-2 lg:w-3/4 mx-auto w-full">
                            <ProfileImage />
                            <AccessReportsAndDocumentsLogin />
                            {/* <AccessAdvantagesForCollegiate /> */}
                            <LogoutCollegiate />
                        </div>
                        :
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2 w-full lg:w-3/4 mx-auto">
                            <AccessReportsAndDocumentsLogin />
                            {/* <AccessAdvantagesForCollegiate /> */}
                            <LogoutCollegiate />
                        </div>
                    }
                </div>
                {/* Redes Sociales */}
                <SocialNetworks />
                {/* Avisos legales */}
                <div className="flex items-center justify-center flex-wrap pb-16 md:pb-0">
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Politica-privacidad.pdf" target="_blank">Política de privacidad</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Aviso-Legal.pdf" target="_blank">Aviso legal</a>
                    <span className="text-teal-600">|</span>
                    <a className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline" href="https://icofcv-colegiado-public.s3.eu-west-3.amazonaws.com/docs/Normativa-cookies.pdf" target="_blank">Política de cookies</a>
                    <span className="text-teal-600">|</span>
                    <NavLink to="/contactform" className="text-teal-600 hover:text-teal-800 mx-2 text-sm underline">Formulario de contacto</NavLink>
                </div>
            </div>
        </>
    )
}